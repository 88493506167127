export const hr = {
	page_id: 0,
	lang_id: 1,
	name: 'Cookie Details Page',
	slug: 'cookie',
	content: `<div class="custompage-view-cookie">
  <div class="custompage-view-cookie__content"><h1 class="mainhead__title"><span style="font-family: SuzukiHeadline; font-size: 60px;">POLITIKA KOLAČIĆA</span></h1>
  <h2><span style="font-family: SuzukiBold;">&Scaron;to je kolačić?</span></h2>
  <p>Kolačić predstavlja mali paket podataka koji web servisi pohranjuju u va&scaron;em pregledniku. Ova tehnologija je od ključne važnosti za ispravan rad mnogih web servisa te ju podržavaju svi veći preglednici od 1995. godine.</p>
  <h2><span style="font-family: SuzukiBold;">Za&scaron;to je kolačić dobar za korisnika?</span></h2>
  <p>Mnogi web servisi oslanjaju se na kolačiće za pravilan rad te zbog praktičnosti koju pruža korisnicima. Primjerice, kolačići vam pružaju mogućnost lake prijave na web stranice koje zahtijevaju lozinke te pristup personaliziranom sadržaju na web-u.</p>
  <h2><span style="font-family: SuzukiBold;">Kolačići na web stranicama te online servisima Magyar Suzuki korporacije.</span></h2>
  <p>Kako bismo osigurali najkvalitetnije funkcioniranje na&scaron;ih web stranica i najbolje moguće korisničko iskustvo, koristimo kolačiće na web stranicama i u uslugama Magyar Suzuki korporacije. Kolačiće možete izbrisati sa svog računala u bilo kojem trenutku, a možete i konfigurirati va&scaron; preglednik da ih onemogući. Međutim, onemogućavanjem kolačića prihvaćate da web stranica neće funkcionirati u potpunosti. Za vi&scaron;e informacija o kolačićima pogledajte "Pravila o privatnosti" kojima možete pristupiti klikom <a href="https://www.suzuki.hu/corporate/en/content/privacy-notice">ovdje</a>.</p>
  <h2><span style="font-family: SuzukiBold;">Kolačići trećih strana.</span></h2>
  <p>Na&scaron;e web stranice mogu sadržavati podatke koji potječu od trećih strana ili pružatelja oglasa koji nisu povezani s Kontrolorom. Te treće strane također mogu postavljati kolačiće i web svjetionike na korisnikovo računalo ili mogu koristiti slične tehnologije za prikupljanje podataka kako bi slali ciljane reklama korisniku u vezi s vlastitim uslugama. U takvim slučajevima obradu podataka uređuju pravila o privatnosti tih trećih strana.</p>
  <h2><span style="font-family: SuzukiBold;">Postavke kolačića u pregledniku.</span></h2>
  <p>Korisnik također može samostalno konfigurirati kolačiće. Svi pohranjeni kolačići mogu se ukloniti i njihovo spremanje može biti onemogućeno ispravnim postavkama. Te postavke možete konfigurirati slijedeći upute na poveznicama niže prema vrsti preglednika.</p>
  <p><strong>Microsoft Internet Explorer</strong><br /><a href="hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener">hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a><br /><strong>Microsoft Egde</strong><br /><a href="https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener">https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy</a><br /><strong>Firefox</strong><br /><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a><br /><strong>Chrome</strong><br /><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en" target="_blank" rel="noopener">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en</a><br /><strong>Safari</strong><br /><a href="https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac">https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac</a><br /><strong>IOS Safari</strong><br /><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener">https://support.apple.com/en-us/HT201265</a></p>
  <h2><span lang="hu-HU" style="font-family: SuzukiBold;">Funkcionalni kolačići:</span></h2>
  <p lang="en-GB" align="justify">Bez ovih kolačića web stranica neće ispravno raditi. Oni se ne mogu isključiti i obično se pokreću na temelju va&scaron;e interakcije s web stranicom, poput postavljanja opcija privatnosti, prijave ili ispunjavanja obrazaca.</p>
  <table style="width: 99.9318%;" border="1px" cellspacing="33.33%">
  <thead>
  <tr>
  <td style="width: 11.6537%; text-align: center;">
  <p><span style="font-size: 16px;">Name</span></p>
  </td>
  <td style="width: 76.8186%; text-align: center;">
  <p>Opis</p>
  </td>
  <td style="width: 71.2561%; text-align: center;">
  <p>Vrijeme pohranjivanja</p>
  </td>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td style="width: 11.6537%; text-align: center;">
  <p><span style="font-size: 16px;">lang</span></p>
  </td>
  <td style="width: 76.8186%; text-align: center;">
  <p class="gtx-body">Kolačići potrebni za promjenu jezika stranice.</p>
  </td>
  <td style="width: 71.2561%; text-align: center;">
  <p><span style="font-size: 16px;"> O zatvaranju preglednika</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.6537%; text-align: center;">
  <p><span style="font-size: 16px;">CookieConsent</span></p>
  </td>
  <td style="width: 76.8186%; text-align: center;">
  <p><span style="font-size: 16px;">Koristi se za provjeru da li je korisnik već prihvatio upotrebu kolačića na web mjestu.</span></p>
  </td>
  <td style="width: 71.2561%; text-align: center;">
  <p><span style="font-size: 16px;">1 godine</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.6537%; text-align: center;">rc::a</td>
  <td style="width: 76.8186%; text-align: center;">
  <p><span style="font-size: 16px;">Ovaj se kolačić koristi za razlikovanje ljudi i botova. To je korisno za web mjesto kako bi se izrađivala valjana izvje&scaron;ća o kori&scaron;tenju web mjesta.</span></p>
  </td>
  <td style="width: 71.2561%; text-align: center;">
  <p><span style="font-size: 16px;"> O zatvaranju preglednika</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.6537%; text-align: center;">rc::c</td>
  <td style="width: 76.8186%; text-align: center;">
  <p><span style="font-size: 16px;">Ovaj se kolačić koristi za razlikovanje ljudi i botova.&nbsp;</span></p>
  </td>
  <td style="width: 71.2561%; text-align: center;">
  <p><span style="font-size: 16px;"> O zatvaranju preglednika</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.6537%; text-align: center;">secondLevelRoute</td>
  <td style="width: 76.8186%; text-align: center;">
  <p><span style="font-size: 16px;">Prilikom promjene jezika pohranite adresu trenutne veze.</span></p>
  </td>
  <td style="width: 71.2561%; text-align: center;">
  <p><span style="font-size: 16px;"> O zatvaranju preglednika</span></p>
  </td>
  </tr>
  </tbody>
  </table>
  <h2 lang="en-GB" align="justify"><span lang="hu-HU"><br /><span style="font-family: SuzukiBold;">Kolačići za pobolj&scaron;anje izvedbe:</span></span></h2>
  <p lang="en-GB" align="justify"><span lang="hu-HU">Koriste se za praćenje rada preglednika kako bi se pružila pomoć u pobolj&scaron;anju informacija i usluga.</span></p>
  <table style="width: 100%;" border="1px" cellspacing="33.33%">
  <tbody>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">Name</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p>Opis</p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p>Vrijeme pohranjivanja</p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">.ga</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p><span style="font-size: 16px;">Ovaj kolačić pomaže Google Analyticsu u njegovom radu. Koristi se za razlikovanje korisnika dodjeljivanjem slučajnog ID broja svakom korisniku. Ovaj je kolačić prisutan u svim zahtjevima za web stranicu i koristi se za brojanje posjetitelja, sesija i podataka kampanje za analitiku web mjesta.</span></p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;">2 godine</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">_gid</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p><span style="font-size: 16px;">Ovaj kolačić pomaže Google Analyticsu u njegovom radu. Koristi se za razlikovanje korisnika.</span></p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;">24 sati</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">_gat</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p>Ovaj kolačić pomaže Google Analyticsu u njegovom radu. Koristi se za smanjivanje zahtjeva, tj. ograničava prikupljanje podataka na web lokacijama s velikim prometom.</p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;">1 minute</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">collect</td>
  <td style="text-align: center; width: 76.8596%;">
  <p><span style="font-size: 16px;">Koristi se za slanje podataka o uređaju i pona&scaron;anju posjetitelja prema Google Analyticsu. Prati posjetitelja na različitim uređajima i marketin&scaron;kim kanalima.</span></p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;"> O zatvaranju preglednika</span></p>
  </td>
  </tr>
  </tbody>
  </table>
  <h2><span lang="hu-HU">&nbsp;</span></h2>
  <h2><span lang="hu-HU" style="font-family: SuzukiBold;">Kolačići trećih strana:</span></h2>
  <p><span lang="hu-HU">Sadržaj koristi ove kolačiće. Npr. objave na dru&scaron;tvenim mrežama, mape ili videozapisi - ugrađeno na web mjestu.</span></p>
  <table style="width: 100%;" border="1px" cellspacing="33.33%">
  <tbody>
  <tr>
  <td style="text-align: center; width: 12.0523%;">
  <p><span style="font-size: 16px;">Name</span></p>
  </td>
  <td style="text-align: center;">
  <p>Opis</p>
  </td>
  <td style="width: 10.8127%; text-align: center;">
  <p>Vrijeme pohranjivanja</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">
  <p><span style="font-size: 16px;">NID</span></p>
  </td>
  <td style="text-align: center;"><span style="font-size: 16px;">NID kolačić sadrži jedinstveni ID pomoću kojeg Google pamti va&scaron;e postavke - poput željenog jezika (npr. Hrvatski), koliko rezultata pretraživanja želite prikazati (npr. 10 ili 20) i želite li imati uključene Googleove SafeSearch filtere te kako bi pohranio ostale va&scaron;e podatke.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">6 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">
  <p><span style="font-size: 16px;">IDE</span></p>
  </td>
  <td style="text-align: center;"><span style="font-size: 16px;">Ovaj kolačić koristi se za ponovno ciljanje, optimizaciju, prijavljivanje i dodjeljivanje internetskih oglasa.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">2 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">test_cookie</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Koristi se za provjeru podržava li korisnikov preglednik kolačiće.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">1 dan</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">fr</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Facebook ih koristi za isporuku raznih reklamnih proizvoda poput licitiranja u stvarnom vremenu od ogla&scaron;ivača trećih strana.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">3 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">tr</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Facebook ih koristi za isporuku raznih reklamnih proizvoda poput licitiranja u stvarnom vremenu od ogla&scaron;ivača trećih strana.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">Session</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">_fbp</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Facebook ih koristi za isporuku raznih reklamnih proizvoda poput licitiranja u stvarnom vremenu od ogla&scaron;ivača trećih strana.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">3 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">GPS</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Registrira jedinstveni ID na mobilnim uređajima kako bi omogućio praćenje na temelju geografskog GPS položaja.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">1 dan</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">VISITOR_INFO1_LIVE</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Poku&scaron;ava procijeniti mrežnu propusnost korisnika na stranicama s integriranim YouTube videozapisima.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">179 dani</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">YSC</td>
  <td style="text-align: center;"><span style="font-size: 16px;">Registrira jedinstveni ID radi vođenja statistike o tome koje je videozapise s YouTubea korisnik vidio.</span></td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Sesija</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-cast-installed</td>
  <td style="text-align: center;">Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Sesija</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-connected-devices</td>
  <td style="text-align: center;">Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">Presistent</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-device-id</td>
  <td style="text-align: center;">Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">Presistent</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-fast-check-period</td>
  <td style="text-align: center;">Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Sesija</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-session-app</td>
  <td style="text-align: center;">Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Sesija</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-session-name</td>
  <td style="text-align: center;">Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Sesija</p>
  </td>
  </tr>
  </tbody>
  </table>
  <p style="text-align: center;">Neprestano radimo na pobolj&scaron;anju na&scaron;ih web stranica i digitalnih usluga. U tu svrhu koristimo Google Analytics za analizu agregiranih, anonimnih podataka o pona&scaron;anju na&scaron;ih korisnika. Nadalje, također dopu&scaron;tamo na&scaron;em alatu za web analitiku Google Analytics da upravlja kolačićima i postavlja kolačiće na ovu web stranicu. Statistike dobivene na ovaj način koristimo za pobolj&scaron;anje korisničkog iskustva. Za vi&scaron;e informacija obratite se na&scaron;em službeniku za za&scaron;titu podataka na <a title="privacy@suzuki.hu" href="mailto:privacy@suzuki.hu">privacy@suzuki.hu</a></p>`,
};

import * as React from 'react';
import { Context, createContext, useState } from 'react';
import {
	ILangSwitch,
	IOption,
} from '../../../Interfaces/ILangSwitch.interface';
import { IRouterResponse } from '../Interfaces/IRouterResponse.interface';
import { getLangOptions } from '../../../Utils/GetLanguageFromDomain.util';
import { IRouterContext } from '../Interfaces/IRouterContext.interface';
import { useLocation, useHistory } from 'react-router';
import { ILinkRoute } from '../../../Interfaces/IRouteAsComponent.interface';
import { IMenuList } from '../../../Interfaces/IRoute.interface';
import { cloneDeep } from 'lodash';
import { IError } from '../../../Interfaces/IError.interface';
import { axios } from '../../../Api/axios';
import RouterDAO, { DictionaryDAO } from '../DAO/Router.DAO';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import Cookie from 'js-cookie';
import { IDictionary } from '../../../Interfaces/IDictionary.interface';

/**
 * ## RouterContext
 *
 * These are just 'default' values that will be overwritten when the Provider
 * receives the value. Currently, the way the dom is rendered
 */
export const RouterContext: Context<IRouterContext> = createContext<IRouterContext>(
	{
		menuData: {
			MAIN: [],
			SIDE: [],
			SUB: [],
		},
		lang: {
			value: getLangOptions()[0],
			options: [],
		},
		dictionary: [],
		routes: [],
		error: null,
		switchLanguage: () => null,
	}
);

RouterContext.displayName = 'RouterContext';

interface IRouteContextWrapperProps {}

/**
 * ## Router context wrapper component
 *
 */

export const RouteContextWrapper: React.FC<IRouteContextWrapperProps> = (
	props
) => {
	const [menuData, set_menuData] = useState<IMenuList | null>(null);
	const [routes, set_routes] = useState<ILinkRoute[]>([]);
	const [dictionary, set_dictionary] = useState<IDictionary[]>([]);
	const [error, set_error] = useState<IError>(null);
	const [lang, set_lang] = useState<ILangSwitch>({
		options: getLangOptions(),
		value: getLangOptions()[0],
	});

	const history = useHistory();
	const pathname = useLocation().pathname.substring(1);

	const langChangeRedirection = (routeList: ILinkRoute[]) => {
		//This is to avoid redirection on the first page load
		if (routes.length === 0) return;
		//This attempts to find the label of the current pathname, so in case of
		//offer, it'll return the ILinkRoute object of the OFFER label type.
		const labelName: ILinkRoute | undefined = routes.find(
			// eslint-disable-next-line
			(route: ILinkRoute) => {
				if (pathname.includes(route.link)) return route;
			}
		);

		//Check for undefined labelName (this shouldn't happen unless the user is on a non-existent page => e404)
		if (labelName) {
			//Attempts to find the route based on the previous label in the NEW route list.
			//For example, if the previous was 'ajanlatok' in HU and the user clicked on the lang switch
			//then the EN version could be something like 'offers' with the same OFFERS label.
			const redirectionRoute: ILinkRoute | undefined = routeList.find(
				(route: ILinkRoute) => route.label === labelName.label
			);

			if (redirectionRoute?.label === 'PAGES') {
				return history.push(`/`);
			}
			if (redirectionRoute) {
				let secondLevelRoute: string | undefined = Cookie.get(
					'secondLevelRoute'
				);
				if (secondLevelRoute) {
					Cookie.remove('secondLevelRoute');
					return history.push(`/${redirectionRoute.link}/${secondLevelRoute}`);
				}
				//If a route with a matching route was found, redirect the user to the english version of the page.
				if (redirectionRoute) {
					return history.push(`/${redirectionRoute.link}`);
				}
			}
		}

		//If the label of a route couldn't be found, redirect the user to the main page
		//when they click on the language switch.
		return history.push('/');
	};

	const loadMenu = (): void => {
		RouterDAO.getMenu()
			.then((data: IRouterResponse) => {
				//This function must be called before the new routes and menu items are set!
				langChangeRedirection(data.routes);

				set_menuData(data.menu);
				set_routes(data.routes);
			})
			.catch((error) => {
				set_error(error);
			});
	};

	let cookieLang: ILangSwitch;

	React.useEffect(() => {
		//eslint-disable-next-line
		cookieLang = Cookie.getJSON('lang');
	}, []);

	React.useEffect(
		() => {
			if (
				cookieLang &&
				cookieLang.value &&
				cookieLang.value.id &&
				cookieLang.value.id !== lang.value.id
			) {
				set_lang(cookieLang);
				axios.setLanguage(cookieLang.value.id);
				loadMenu();
			} else {
				axios.setLanguage(lang.value.id);
				loadMenu();
			}

			DictionaryDAO.getDictionary()
				.then((data: IDictionary[]) => {
					//This function must be called before the new routes and menu items are set!
					set_dictionary(data);
				})
				.catch((error) => {
					set_error(error);
				});
			return () => {};
		},

		//eslint-disable-next-line
		[lang]
	);

	const switchLanguage = (): void => {
		const langSwitch: ILangSwitch = cloneDeep(lang);
		const option: IOption = lang.value;
		//Just switch to the other option in the array...
		if (option.id === lang.options[0].id) langSwitch.value = lang.options[1];
		if (option.id === lang.options[1].id) langSwitch.value = lang.options[0];
		set_menuData(null);
		Cookie.set('lang', { options: lang.options, value: langSwitch.value });
		set_lang(langSwitch);
	};

	if (menuData === null) return <SplashScreen opacity={'1'} />;
	return (
		<RouterContext.Provider
			value={{
				lang,
				error,
				routes,
				dictionary,
				menuData,
				switchLanguage,
			}}
		>
			<RouterContext.Consumer>
				{(contextValue: IRouterContext) => props.children}
			</RouterContext.Consumer>
		</RouterContext.Provider>
	);
};

export default RouteContextWrapper;

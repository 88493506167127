import * as React from 'react';
import './FancyButton.css';
import { Link } from 'react-router-dom';

export interface IFancyButtonProps {
  onClick?: () => any;
  link?: string;
}
export const FancyButton: React.FC<IFancyButtonProps> = (
  props
): React.ReactElement => {
  const getButton = (): React.ReactElement => {
    return props.link ? (
      <Link to={props.link}>
        <div className='iline-button' onClick={props.onClick}>
          <p>{props.children}</p>
        </div>
      </Link>
    ) : (
      <div className='iline-button' onClick={props.onClick}>
        <p>{props.children}</p>
      </div>
    );
  };

  return getButton();
};

export default FancyButton;

import React, { useState } from "react";
import CookieSettingsView from "../View/CookieSettings.view";
import { ViewType } from "../../CookieMain/Interfaces/IViewType.interface";
import { CookieModel } from "../../CookieMain/Model/Cookie.model";
import { ISelectedCookieCategory } from "../../CookieMain/Interfaces/ICookie.interface";

export interface CookieSettingsControllerProps {
  selectedCategories: ISelectedCookieCategory[];
  selectCategoryHandler: (selectedId: string, isSelected: boolean) => void;
  saveCookieHandler: () => void;
  setViewType: (viewType: ViewType) => void;
  cookie_data: CookieModel;
  enableNecessaryCookieHandler: () => void;
}

const CookieSettingsController: React.FC<CookieSettingsControllerProps> = (
  props
) => {
  const [activeCategory, setActiveCategory] = useState<number>(0);
  return (
    <CookieSettingsView
      {...props}
      activeCategory={activeCategory}
      setActiveCategory={setActiveCategory}
    />
  );
};

export default CookieSettingsController;

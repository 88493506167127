import React from 'react';
import './Less/CookieDetailRow.css';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { GetLocaleText } from '../../../../../I18n';

export interface CookieDetailRowViewProps {
	open: boolean;
	openHandler: (open: boolean) => void;
	name: string;
	description: string;
}
const CookieDetailRowView: React.FC<CookieDetailRowViewProps> = (props) => {
	const { openHandler, open, name, description } = props;

	return (
		<div className='cookie-row-wrapper'>
			<div className='cookie-row'>
				<p className='cookie-row-title'>{name}</p>
				<button
					className='cookie-row-button'
					onClick={() => openHandler(!open)}
					aria-controls={`${name}-collapse-text`}
					aria-expanded={open}
				>
					<span className='button-text'>
						{GetLocaleText('bike_page_feature_more')}
					</span>
					<span className='button-icon'>
						<FontAwesomeIcon
							icon={faChevronDown}
							className={`${open ? 'button-icon-rotate' : ''}`}
						/>
					</span>
				</button>
			</div>
			<Collapse in={open}>
				<div id={`${name}-collapse-text`}>
					<div className='cookie-row-detail'>{description}</div>
				</div>
			</Collapse>
		</div>
	);
};

export default CookieDetailRowView;

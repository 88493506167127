import { INewDealerForm } from '../Interfaces/INewDealer.interface';

export const NewDealerForm: INewDealerForm = {
	title: {
		id: 'title',
		value: '',
		required: false,
		labelText: 'Title',
		type: 'text',
		textType: true,
	},
	name: {
		id: 'name',
		value: '',
		required: true,
		labelText: 'Name',
		type: 'text',
		textType: true,
	},
	surname: {
		id: 'surname',
		value: '',
		required: true,
		labelText: 'Surname',
		type: 'text',
		textType: true,
	},
	firm: {
		id: 'firm',
		value: '',
		required: false,
		labelText: 'Firm',
		type: 'text',
		textType: true,
	},
	address: {
		id: 'address',
		value: '',
		required: true,
		labelText: 'Address',
		type: 'text',
		textType: true,
	},
	city: {
		id: 'city',
		value: '',
		required: true,
		labelText: 'City',
		type: 'text',
		textType: true,
	},
	postcode: {
		id: 'postcode',
		value: '',
		required: true,
		labelText: 'Postcode',
		type: 'text',
		textType: true,
	},
	phone: {
		id: 'phone',
		value: '',
		required: false,
		labelText: 'Phone',
		type: 'tel',
		textType: true,
	},
	email: {
		id: 'email',
		value: '',
		required: true,
		labelText: 'Email',
		type: 'email',
		textType: true,
	},
	message: {
		id: 'message',
		value: '',
		textAreaType: true,
		required: false,
		className: 'text-area',
	},
	acceptance: {
		id: 'acceptance',
		value: false,
		required: true,
		href: '/adatkezeles',
		hrefText: 'adatvédelmi',
		labelTextBefore: 'Elfogadom az',
		labelTextAfter: 'nyilatkozatot.',
		booleanType: true,
	},
};

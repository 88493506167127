import * as React from 'react';
import { ISelectedCookieCategory } from '../Interfaces/ICookie.interface';
import { CookieModel } from '../Model/Cookie.model';
import { Row, Col } from 'react-bootstrap';
import './Less/Cookie.css';
import suzukiLogo from '../../../../Assets/suzukilogo.png';
import Start from '../../CookieStart/Controller/CookieStart.controller';
import Settings from '../../CookieSettings/Controller/CookieSettings.controller';
import Details from '../../CookieDetails/Controller/CookieDetails.controller';
import { ViewType } from '../Interfaces/IViewType.interface';
import { GetLocaleText } from '../../../../I18n';

export interface ICookieViewProps {
	cookie_data: CookieModel;
	selectedCategories: ISelectedCookieCategory[];
	selectCategoryHandler: (selectedId: string, isSelected: boolean) => void;
	saveCookieHandler: () => void;
	viewType: ViewType;
	setViewType: (viewType: ViewType) => void;
	enableAllCookieHandler: () => void;
	enableNecessaryCookieHandler: () => void;
	prevViewType: ViewType | undefined;
}

const CookieView: React.FC<ICookieViewProps> = (props): React.ReactElement => {
	const {
		selectedCategories,
		selectCategoryHandler,
		saveCookieHandler,
		viewType,
		setViewType,
		cookie_data,
		enableAllCookieHandler,
		enableNecessaryCookieHandler,
		prevViewType,
	} = props;

	return (
		<div
			className={`cookie-container ${
				viewType !== 'Start' ? 'cookie-container-large' : ''
			} ${viewType === 'Details' ? 'cookie-container-white' : ''}`}
		>
			<Row>
				<Col xs='12'>
					<div className='cookie-preheader'>
						<img src={suzukiLogo} alt='Suzuki' />
						{viewType === 'Details' && (
							<div
								className='cookie-preheader-close'
								onClick={() =>
									setViewType(
										prevViewType && prevViewType !== 'Details'
											? prevViewType
											: 'Start'
									)
								}
							>
								<div />
								<div />
							</div>
						)}
					</div>
				</Col>
			</Row>
			{viewType !== 'Details' && (
				<Row>
					<Col xs='12'>
						<div className='cookie-header'>
							<span className='cookie-header-title'>
								{GetLocaleText('cookie_content_title')}
							</span>
						</div>
					</Col>
				</Row>
			)}
			{viewType === 'Start' && (
				<Start
					setViewType={setViewType}
					enableAllCookieHandler={enableAllCookieHandler}
					enableNecessaryCookieHandler={enableNecessaryCookieHandler}
				/>
			)}
			{viewType === 'Settings' && (
				<Settings
					setViewType={setViewType}
					cookie_data={cookie_data}
					selectedCategories={selectedCategories}
					selectCategoryHandler={selectCategoryHandler}
					saveCookieHandler={saveCookieHandler}
					enableNecessaryCookieHandler={enableNecessaryCookieHandler}
				/>
			)}
			{viewType === 'Details' && <Details />}
		</div>
	);
};

export default CookieView;

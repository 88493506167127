import React from 'react';
import './Less/NewDealerForm.css';
import { INewDealerForm } from '../Interfaces/INewDealer.interface';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import TextInput from '../../UI/TextInput/TextInput';
import { TextArea } from '../../UI/TextArea/TextArea';
import { Checkbox } from '../../UI/Checkbox/Checkbox';
import Button from '../../UI/Button/Button';
import { GetLocaleText } from '../../../I18n';
import HTMLParser from 'html-react-parser';

export interface INewDealerFormViewProps {
	form: INewDealerForm;
	onChange: (args: IInputChange) => void;
	error: string | null;
	onSubmit: (e: React.FormEvent) => void;
	onDelete: (i: number) => void;
}

const NewDealerFormView: React.FC<INewDealerFormViewProps> = (
	props
): React.ReactElement => {
	const accBefore = GetLocaleText('contact_page__form_cb_before');
	const accAfter = GetLocaleText('contact_page__form_cb_after');
	const accHref = GetLocaleText('contact_page__form_cb_href');
	const accHrefText = GetLocaleText('contact_page__form_cb_href_text');
	const buttonText = GetLocaleText('contact_page__form_button');
	const nameLabel = GetLocaleText('partnership__form_upper__name');
	const addressLabel = GetLocaleText('partnership__form_upper__address');
	const cityLabel = GetLocaleText('partnership__form_upper__city');
	const emailLabel = GetLocaleText('partnership__form_upper__email');
	const firmLabel = GetLocaleText('partnership__form_upper__firm');
	const phoneLabel = GetLocaleText('partnership__form_upper__phone');
	const postcodeLabel = GetLocaleText('partnership__form_upper__postcode');
	const surnameLabel = GetLocaleText('partnership__form_upper__surname');
	const titleLabel = GetLocaleText('partnership__form_upper__title');
	const headlineOneLabel = GetLocaleText('partnership__headline_1');
	const headlineTwoLabel = GetLocaleText('partnership__headline_2');

	const messageDesc = GetLocaleText('partnership__form_lower__message_top');

	return (
		<form className='new_dealer-form' onSubmit={props.onSubmit}>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='headline'>
						<h3>{headlineOneLabel}</h3>
					</div>
				</div>
				<TextInput
					{...props.form.title}
					labelText={titleLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.name}
					labelText={nameLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.surname}
					labelText={surnameLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.firm}
					labelText={firmLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.address}
					labelText={addressLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.city}
					labelText={cityLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.postcode}
					labelText={postcodeLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.phone}
					labelText={phoneLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<TextInput
					{...props.form.email}
					labelText={emailLabel}
					onChange={props.onChange}
					className='col-lg-4'
				/>
				<div className='col-lg-12'>
					<div className='headline'>
						<h3>{headlineTwoLabel}</h3>
					</div>
				</div>
				<p>{messageDesc}</p>
				<TextArea
					{...props.form.message}
					onChange={props.onChange}
					className='col-lg-12'
				/>
				<div className='col-lg-12 error-text'>
					{props.error && HTMLParser(props.error)}
				</div>
				<div className='container'>
					<div className='row d-lg-flex align-items-center'>
						<div className='col-lg-9'>
							<Checkbox
								{...props.form.acceptance}
								labelTextBefore={accBefore}
								labelTextAfter={accAfter}
								href={accHref}
								hrefText={accHrefText}
								onChange={props.onChange}
							/>
						</div>
						<div className='col-lg-3 justify-content-lg-end d-flex mt-lg-0 mt-4 justify-content-center'>
							<Button
								isDark
								isSmall
								style={{ fontSize: '20px !important' }}
								type='submit'
							>
								{buttonText}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default NewDealerFormView;

import React from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { NewsModel } from '../Model/News.model';
import { map } from 'lodash';
import NewsElementView from './NewsElement.view';
import './Less/News.css';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import { NewsTypesModel } from '../Model/NewsTypes.model';
import NewsTypesView from './NewsTypes.view';
import Masonry from 'react-masonry-css';
import { changeNewsIdToName } from '../../../Utils/ChangeNewsIdToName.util';
import { GetLocaleText } from '../../../I18n';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import FancyButton from '../../UI/FancyButton/FancyButton';

export interface INewsFilterProps {
	filter: (type_id: string) => void;
}
export interface INewsViewProps {
	loading: Promise<any> | null;
	news: NewsModel[];
	news_types: NewsTypesModel[];
	error: IError;
	load_more: boolean;
	loading_load_more: boolean;
	lang: string;
	filter: (type_id: string) => void;
	active: number;
	page: () => void;
}

const NewsView: React.FC<INewsViewProps> = (props): React.ReactElement => {
	const split_locale_0 = GetLocaleText('load_more_news').split('-')[0];
	const split_locale_1 = GetLocaleText('load_more_news').split('-')[1];
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	React.useEffect(() => {
		props.loading !== null && props.loading_load_more === false
			? ControlSplash(true)
			: ControlSplash(false);
		return () => {};
		//eslint-disable-next-line
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null) {
		console.log(props.error);
		return <SplashScreen opacity='1' />;
	}

	const breakpointColumnsObj = {
		default: 2,
		700: 1,
	};
	const changed_type_news: NewsModel[] = changeNewsIdToName(
		props.news,
		props.news_types
	);

	return (
		<div className='news-view'>
			<NewsTypesView
				types={props.news_types}
				filter={props.filter}
				active={props.active}
			></NewsTypesView>
			<div className='news-view__list'>
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className='my-masonry-grid'
					columnClassName='my-masonry-grid_column'
				>
					{map(changed_type_news, (news: NewsModel, index: number) => (
						<NewsElementView key={`${index}-news-element`} news={news} />
					))}
				</Masonry>
			</div>

			{props.load_more === true ? (
				<FancyButton onClick={() => props.page()}>
					{split_locale_0} {split_locale_1}
				</FancyButton>
			) : (
				''
			)}
		</div>
	);
};

export default NewsView;

import { axios } from '../../../Api/axios';
import { ResellersModel } from '../Model/Resellers.model';
import { IResellers } from '../Interfaces/IResellers.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class ResellersDAO {
  private static readonly base: string = '/api/resellers';

  public static async getResellers(): Promise<ResellersModel[]> {
    const request: AxiosPromise<IResellers[]> = axios
      .getInstance()
      .get(this.base);
    const response: AxiosResponse<IResellers[]> = await request;
    return response.data.map(
      (reseller: IResellers) => new ResellersModel(reseller)
    );
  }
}

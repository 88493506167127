import { axios } from '../../../Api/axios';
import { NewsModel } from '../Model/News.model';
import { SingleNewsModel } from '../Model/SingleNews.model';
import { INews, ISingleNews } from '../Interfaces/INews.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { INewsTypes } from '../Interfaces/INewsTypes.interface';
import { NewsTypesModel } from '../Model/NewsTypes.model';

export default abstract class NewsDAO {
  private static readonly base: string = '/api/news/articles';
  private static readonly type_filter: string = '/api/news/types';
  private static readonly types: string = '/api/news/types';

  public static async getNews(params?: object): Promise<NewsModel[]> {
    const request: AxiosPromise<INews[]> = axios
      .getInstance()
      .get(this.base, { params: params });
    const response: AxiosResponse<INews[]> = await request;
    return response.data.map((news: INews) => new NewsModel(news));
  }

  public static async getNewsById(slug: string): Promise<SingleNewsModel> {
    const request: AxiosPromise<ISingleNews> = axios
      .getInstance()
      .get(`${this.base}/${slug}`);
    const response: AxiosResponse<ISingleNews> = await request;
    return new SingleNewsModel(response.data);
  }

  public static async getNewsByType(
    news_type_id: string,
    params?: object
  ): Promise<NewsModel[]> {
    const request: AxiosPromise<INews[]> = axios
      .getInstance()
      .get(`${this.type_filter}/${news_type_id}/articles`, { params: params });
    const response: AxiosResponse<INews[]> = await request;
    return response.data.map((news: INews) => new NewsModel(news));
  }

  public static async getNewsTypes(): Promise<NewsTypesModel[]> {
    const request: AxiosPromise<INewsTypes[]> = axios
      .getInstance()
      .get(this.types);
    const response: AxiosResponse<INewsTypes[]> = await request;
    return response.data.map(
      (news_types: INewsTypes) => new NewsTypesModel(news_types)
    );
  }
}

import React from 'react';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { IInputTextArea } from '../../../Interfaces/IInput.interface';
import './TextArea.css';

type ILocalProps = IInputTextArea & {
	onChange: (e: IInputChange) => void;
};

export const TextArea = (props: ILocalProps) => {
	let wrapperStyle: string[] = ['il-textarea-wrapper'];

	if (props.className) wrapperStyle.push(props.className);
	if (props.hidden) wrapperStyle.push('wrapper-hidden');

	let inputStyle: string[] = ['common-input', 'textarea-input'];

	return (
		<div className={wrapperStyle.join(' ')}>
			<label htmlFor={props.id} className='input-label'>
				{props.labelText}
			</label>
			<textarea
				className={inputStyle.join(' ')}
				id={props.id}
				required={props.required}
				disabled={props.disabled}
				onChange={(e) =>
					props.onChange({
						fieldName: props.id,
						value: e.target.value,
					})
				}
				value={props.value}
			/>
		</div>
	);
};

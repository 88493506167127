import React from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { CatalogModel } from '../Model/Catalog.model';
import './Less/Catalog.css';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import Img from 'react-image';
import BG from '../../../Assets/catalog_bg.jpg';
import { CatalogTypesModel } from '../Model/CatalogTypes.model';
import { changeCatalogIdToName } from '../../../Utils/ChangeCatalogIdToName.util';
import CatalogTypesView from './CatalogTypes.view';
import CatalogElementView from './CatalogElement.view';
import { GetLocaleText } from '../../../I18n';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import FancyButton from '../../UI/FancyButton/FancyButton';

export interface ICatalogFilterProps {
	filter: (type_id: string) => void;
	active: number;
}
export interface ICatalogPageProps extends ICatalogFilterProps {
	page: () => void;
}
export interface ICatalogViewProps extends ICatalogPageProps {
	loading: Promise<any> | null;
	catalogs: CatalogModel[];
	catalog_types: CatalogTypesModel[];
	error: IError;
	load_more: boolean;
	lang: string;
}

const CatalogView: React.FC<ICatalogViewProps> = (
	props
): React.ReactElement => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
		//eslint-disable-next-line
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null)
		return <SplashScreen opacity='1' isAbsolute={false} />;
	const changed_type_catalogs: CatalogModel[] = changeCatalogIdToName(
		props.catalogs,
		props.catalog_types
	);
	const split_locale_0 = GetLocaleText('load_more_catalog').split('-')[0];
	const split_locale_1 = GetLocaleText('load_more_catalog').split('-')[1];
	return (
		<div className='catalog-view'>
			<div className='catalog-bg'>
				<Img src={BG} alt='' />
			</div>
			<div className='container'>
				<CatalogTypesView
					types={props.catalog_types}
					filter={props.filter}
					active={props.active}
				></CatalogTypesView>
			</div>
			<div className='catalogs'>
				{changed_type_catalogs.map((catalogs, index) => {
					return (
						<div className='catalog-item' key={`catalog-item-${index}`}>
							<CatalogElementView catalogs={catalogs} />
						</div>
					);
				})}
			</div>
			{props.load_more === true ? (
				<FancyButton onClick={() => props.page()}>
					{split_locale_0} {split_locale_1}
				</FancyButton>
			) : (
				''
			)}
		</div>
	);
};

export default CatalogView;

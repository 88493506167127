import React, { useContext } from 'react';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import Img from 'react-image';
import { NewsModel } from '../Model/News.model';
import { formatDateString } from '../../../Utils/FormatDateString.util';
import HTMLParser from 'html-react-parser';
import './Less/News.css';
import './Less/RelatedNews.css';
import '../../../Css/Positions.css';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IRelatedNewsViewProps extends ILoadRelatedNewsProps {
  article: NewsModel;
  news_slug: string;
}

export interface ILoadRelatedNewsProps {
  loadRelatedNews: (news_id: string) => void;
}

const RelatedNewsView: React.FC<IRelatedNewsViewProps> = (
  props
): React.ReactElement => {
  const routerContext: IRouterContext = useContext(RouterContext);
  const { article } = props;
  const lang = routerContext.lang.value.name;
  const [hover, set_hover] = React.useState(false);
  return (
    <div
      className={`newsimage ${article.image_ratio}`}
      style={{ cursor: 'pointer' }}
    >
      <div className={`image-container ${hover ? 'hovering' : ''}`}>
        <Img
          src={article.image}
          alt={generateImgAlt(article.image)}
          className='img'
        />
      </div>
      <div
        className={`news__overimage position-${article.text_position_x}-${article.text_position_y} ${article.image_ratio}`}
        onClick={() => {
          props.loadRelatedNews(`/${props.news_slug}/${article.slug}`);
        }}
        onMouseEnter={() => set_hover(true)}
        onMouseLeave={() => set_hover(false)}
      >
        <div className='typeAndDate'>
          <div className='type'>
            {article.news_type_name}
            <span></span>
          </div>
          <div className='date'>
            <span></span>
            {formatDateString(article.published_at, '.', lang, true)}
          </div>
        </div>
        <div className='title'>{article.name}</div>
        <div className='desc'>{HTMLParser(article.short_desc)}</div>
      </div>
    </div>
  );
};

export default RelatedNewsView;

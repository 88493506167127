import { IDynamicObject } from '../Interfaces/IDynamicObjcet.interface';

/**
 * ## Set boolean
 * Returns **true** if the value is 1 whether its type is string or number.
 * If the passed type is boolean, it will simply return its value true|false.
 * In any other case, it returns **false**.
 */
export const setBoolean = (
  value: string | null | number | boolean
): boolean => {
  let result: boolean = false;
  if (typeof value === 'boolean') result = value;
  else {
    result = `${value}` === '1' ? true : false;
  }
  return result;
};

/**
 * ## Set string
 * It converts any value to string. If the value is null, -1 or 0,
 * it will return an empty string.
 */
export const setString = (value: string | number | null): string => {
  let val: string = `${value}`;
  if (value === null || value === 0) val = '';
  return val;
};

/**
 * ## Set number
 * Converts strings and nulls to numbers.
 * **Default** is the second param of this function which is of type number.
 * If the value you passed can't be converted to a number,
 * it returns the value of **Default** which by default is **0**.
 */
export const setNumber = (
  value: string | number | null,
  Default: number = 0
): number => {
  const parsed_number: number = Number(value);
  if (isNaN(parsed_number) || !parsed_number) return Default;
  return parsed_number;
};

/**
 * ## Model data setter
 * Based on the type of the **baseValue**, it converts the **incomingValue**
 * to the desired data type. It supports **strings**, **booleans**, and **numbers**.
 * If the **baseValue** doesn't match any of those types, it'll simply return the
 * **incomingValue**. For example, if the baseValue was a date and the incomingValue
 * was a string, a string would be returned which would be misleading.
 */
export const dataSetter = (
  baseValue: string | number | boolean,
  incomingValue: any
): any => {
  let returnValue = incomingValue;
  if (typeof baseValue === 'string') {
    returnValue = setString(incomingValue);
  }
  if (typeof baseValue === 'boolean') {
    returnValue = setBoolean(incomingValue);
  }
  if (typeof baseValue === 'number') {
    returnValue = setNumber(incomingValue);
  }
  return returnValue;
};

export const ModelConstructor = (
  APIData: IDynamicObject<any>,
  ModelProperties: IDynamicObject<any>
) => {
  //Iterate the incoming model properties
  for (let property in APIData) {
    //Avoid setting array types directly. They should be turned into their own
    //models in the Model constructor after calling super(ApiData);
    //if (APIData[property] instanceof Array) continue;

    //Based on the type of the default value of the model property, transform the incoming
    //api data and set the model value to it.
    //For example, an object property coming from the API => 'is_calc_available' = "1"
    //in the SiteModel and ISite should be a boolean but the raw API data is "0" or "1".
    //The setBoolean method of dataSetter will turn it into either true or false based on the number.
    ModelProperties[property] = dataSetter(
      ModelProperties[property],
      APIData[property]
    );
  }
  return;
};

import { axios } from '../../../Api/axios';
import { TestDriveBikesModel } from '../Model/TestDriveBikes.model';
import { TestDriveResellersModel } from '../Model/TestDriveResellers.model';
import {
  ITestDriveBikes,
  ITestDriveResellers,
} from './../Interfaces/ITestDrive.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class TestDriveDAO {
  private static readonly base: string = '/api/test-drives/bikes';
  private static readonly resellers: string = '/resellers';

  public static async getTestBikes(): Promise<TestDriveBikesModel[]> {
    const request: AxiosPromise<ITestDriveBikes[]> = axios
      .getInstance()
      .get(this.base);
    const response: AxiosResponse<ITestDriveBikes[]> = await request;
    return response.data.map(
      (bikes: ITestDriveBikes) => new TestDriveBikesModel(bikes)
    );
  }

  public static async getResellers(
    bike_id: number
  ): Promise<TestDriveResellersModel[]> {
    const request: AxiosPromise<
      ITestDriveResellers[]
    > = axios.getInstance().get(`${this.base}/${bike_id}${this.resellers}`);
    const response: AxiosResponse<ITestDriveResellers[]> = await request;
    return response.data.map(
      (reseller: ITestDriveResellers) => new TestDriveResellersModel(reseller)
    );
  }

  public static async create(
    bike_id: number,
    reseller_id: number,
    params: FormData
  ): Promise<void> {
    const request: AxiosPromise<void> = axios
      .getInstance()
      .post(
        `/api/test-drives/bikes/${bike_id}/resellers/${reseller_id}`,
        params
      );
    const response: AxiosResponse<void> = await request;
    return response.data;
  }
}

import { axios } from '../../../Api/axios';
import { FooterLinksModel } from '../Model/FooterLinks.model';
import { FooterLogosModel } from '../Model/FooterLogos.model';
import { IFooterLinks, IFooterLogos } from './../Interfaces/IFooter.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class FooterDAO {
  private static readonly logos: string = '/api/site/footer/logos';
  private static readonly links: string = '/api/site/footer/links';

  public static async getLogos(): Promise<FooterLogosModel[]> {
    const request: AxiosPromise<IFooterLogos[]> = axios
      .getInstance()
      .get(this.logos);
    const response: AxiosResponse<IFooterLogos[]> = await request;
    return response.data.map(
      (logos: IFooterLogos) => new FooterLogosModel(logos)
    );
  }

  public static async getLinks(): Promise<FooterLinksModel> {
    const request: AxiosPromise<IFooterLinks> = axios
      .getInstance()
      .get(this.links);
    const response: AxiosResponse<IFooterLinks> = await request;
    return response.data;
  }
}

import React, { useState } from 'react';
import Img from 'react-image';
import Spinner from '../../UI/Spinner/Spinner';
import { map } from 'lodash';
import { GetLocaleText } from '../../../I18n';
import { BlockElementType, FeatureBlockElement } from '../Model/Bike.model';
import './Less/FeatureSection.style.css';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IFeatureSectionProps {
	elements: BlockElementType[];
	hookID: number;
}

interface IPosX {
	RIGHT: 'flex-start';
	CENTER: 'flex-start';
	LEFT: 'flex-end';
}

const FeatureSection: React.FC<IFeatureSectionProps> = (props) => {
	const elements = props.elements as FeatureBlockElement[];
	const [element, setElement] = useState<null | FeatureBlockElement>(null);
	if (!elements.length) return null;

	const getAlignItems = (position: string): string => {
		let value: string = '';

		const positionY: any = {
			TOP: 'flex-start',
			BOTTOM: 'flex-end',
			CENTER: 'center',
		};

		value = positionY[position];

		return value;
	};

	const getJustifyContent = (position: string): string => {
		let value: string = '';

		const positionX: any = {
			RIGHT: 'flex-end',
			LEFT: 'flex-start',
			CENTER: 'center',
		};

		value = positionX[position];

		return value;
	};

	const getMoreAlign = (x: string): 'flex-start' | 'flex-end' => {
		let posX: IPosX = {
			RIGHT: 'flex-start',
			CENTER: 'flex-start',
			LEFT: 'flex-end',
		};

		return posX[x as keyof IPosX];
	};

	if (element) {
		return (
			<div className='bike-feature-element-fs'>
				<div className='bike-feature-element-fs__close'>
					<span className='close-icon' onClick={() => setElement(null)} />
				</div>
				<div className='bike-feature-element-fs__img-container'>
					<Img src={element.bigImage} loader={<Spinner />} />
				</div>
				<div className='bike-feature-element-fs__content-box'>
					<h3 className='__title'>{element.title}</h3>
					<p className='__content'>{element.content}</p>
				</div>
			</div>
		);
	}

	return (
		<div className='bike-feature-outer-container'>
			<div className='bike-feature-container' id={`BLOCK_${props.hookID}`}>
				{map(elements, (feat: FeatureBlockElement, index: number) => (
					<div className='bike-feature-outer' key={`${index}-feature-block`}>
						<div className='bike-feature' onClick={() => setElement(feat)}>
							<Img
								src={feat.smallImage}
								alt={generateImgAlt(feat.smallImage)}
								className='bike-feature__bg'
							/>
							<div
								className='bike-feature__title'
								style={{
									justifyContent: getJustifyContent(feat.textPositionX),
									alignItems: getAlignItems(feat.textPositionY),
								}}
							>
								<span>{feat.title}</span>
							</div>
							<div
								className='bike-feature__more'
								style={{
									justifyContent: getMoreAlign(feat.textPositionX),
								}}
							>
								<div className='more'>
									<div>{GetLocaleText('bike_page_feature_more')}</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FeatureSection;

export const sk = {
	categories: [
		{
			id: '1',
			name: 'Potrebné',
			description:
				'Potrebné súbory cookie pomáhajú vytvárať použiteľné webové stránky tak, že umožňujú základné funkcie, ako je navigácia stránky a prístup k chráneným oblastiam webových stránok. Webové stránky nemôžu riadne fungovať bez týchto súborov cookies.',
		},
		{
			id: '2',
			name: 'Štatistiky',
			description:
				'Štatistické súbory cookies pomáhajú majiteľom webových stránok, aby pochopili, ako komunikovať s návštevníkmi webových stránok prostredníctvom zberu a hlásenia informácií anonymne.',
		},
		{
			id: '3',
			name: 'Marketing',
			description:
				'Marketingové súbory cookies sa používajú na sledovanie návštevníkov na webových stránkach. Zámerom je zobrazovať reklamy, ktoré sú relevantné a pútavé pre jednotlivých užívateľov, a tým cennejšie pre vydavateľov a inzerentov tretích strán.',
		},
	],
	insert_codes: [
		{
			category: '2',
			tag: 'script',
			value:
				'(function (i, s, o, g, r, a, m) {i["GoogleAnalyticsObject"] = r; (i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }), (i[r].l = 1 * new Date());(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "UA-656346-10", "auto");ga("send", "pageview");',
		},
		{
			category: '3',
			tag: 'script',
			value:
				'!(function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "582159962479535");fbq("track", "PageView");',
		},
		{
			category: '3',
			tag: 'noscript',
			value:
				'<img height="1" width="1" src="https://www.facebook.com/tr?id=582159962479535&ev=PageView&noscript=1"/>',
		},
	],
	cookies: [
		{
			Name: 'iTechCookie',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Používajú sa na zistenie, či už používateľ súhlasil s používaním súborov cookie na webovej lokalite.',
		},
		{
			Name: 'lang',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Súbory cookie potrebné na zmenu jazyka stránky.',
		},
		{
			Name: 'secondLevelRoute',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Do zatvorenia prehliadača',
		},
		{
			Name: '_ga',
			Provider: 'suzuki.hu',
			ExpireDescription: '2 years',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Tento súbor cookie prispieva k činnosti služby Google Analytics. Používa sa na rozlišovanie používateľov tak, že každému používateľovi priradí náhodné identifikačné číslo. Tento súbor cookie je súčasťou všetkých požiadaviek stránok webovej lokality. Používa sa na počítanie návštevníkov, relácií a kampaní na účely analýzy lokality.',
		},
		{
			Name: '_gat',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Tento súbor cookie prispieva k činnosti služby Google Analytics. Používa sa na znižovanie počtu požiadaviek, t. j. obmedzuje zhromažďovanie údajov na webových lokalitách s veľkou aktivitou.',
		},
		{
			Name: '_gid',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Tento súbor cookie prispieva k činnosti služby Google Analytics. Používa sa na rozlišovanie používateľov.',
		},
		{
			Name: 'fr',
			Provider: 'facebook.com',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Používa ho Facebook na poskytovanie rôznych reklamných produktov, napríklad ponúk v reálnom čase od nezávislých inzerentov.',
		},
		{
			Name: 'tr',
			Provider: 'facebook.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'Pixel',
			Category: '3',
			PurposeDescription:
				'Používa ho Facebook na poskytovanie rôznych reklamných produktov, napríklad ponúk v reálnom čase od nezávislých inzerentov.',
		},
		{
			Name: 'NID',
			Provider: 'google.com',
			ExpireDescription: '6 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Súbor cookie NID obsahuje jedinečný identifikátor, ktorý používa spoločnosť Google na zapamätanie si vašich predvolieb. Príkladom je preferovaný jazyk (napr. slovenčina), požadovaný počet zobrazených výsledkov vyhľadávania (napr. 10 alebo 20) a zapnutie alebo vypnutie filtra Bezpečné vyhľadávanie od spoločnosti Google. Okrem toho slúži na ukladanie iných vašich informácií.',
		},
		{
			Name: '_fbp',
			Provider: 'suzuki.hu',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Používa ho Facebook na poskytovanie rôznych reklamných produktov, napríklad ponúk v reálnom čase od nezávislých inzerentov.',
		},
		{
			Name: 'x-bni-fpc',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
		{
			Name: 'x-bni-rncf',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
	],
};

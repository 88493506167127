import React, { useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { MainSlidersModel } from '../Model/MainSliders.model';
import './Less/MainPage.css';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import MainSliderCarouselView from './SliderCarousel';
import MouseImage from '../../../Assets/mouse.png';
import Img from 'react-image';
import FirstOffersView from './FirstOffers.view';
import FirstOffersBG from '../../../Assets/first-offers-bg.webp';
import MainNewsBG from '../../../Assets/main_news_bg.webp';
import FirstOffersBGPNG from '../../../Assets/first-offers-bg.png';
import MainNewsBGPNG from '../../../Assets/main_news_bg.png';
import { MainNewsModel } from '../Model/MainNews.model';
import { MainNewsTypesModel } from '../Model/MainNewsType.model';
import Masonry from 'react-masonry-css';
import HTMLParser from 'html-react-parser';
import { map } from 'lodash';
import { changeNewsIdToName } from '../../../Utils/ChangeNewsIdToName.util';
import MainNewsView from './News.view';
import { GetLocaleText } from '../../../I18n';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import FancyButton from '../../UI/FancyButton/FancyButton';
import { MainOffersModel } from '../Model/MainOffers.model';
import OffersSliderView from './OffersSlider.view';
import { OfferModel } from '../../Offers/Model/Offer.model';
import MainPageAnchorNav from './MainPageAnchorNav.view';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';
import ResellerPromotion from '../../Bike/View/ResellerPromotion.view';

export interface IMainSlidersViewProps {
	loading: Promise<any> | null;
	sliders: MainSlidersModel[];
	offers: MainOffersModel[];
	offers_slider: OfferModel[];
	news: MainNewsModel[];
	news_types: MainNewsTypesModel[];
	error: IError;
}

const MainPageView: React.FC<IMainSlidersViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	useEffect(() => {
		window.scrollTo(0, 0);
		let navimage: HTMLDivElement | null = document.querySelector('.navImage');
		navimage instanceof HTMLDivElement
			? (navimage.style.display = 'none')
			: (navimage = null);

		return () => {
			navimage instanceof HTMLDivElement
				? (navimage.style.display = 'block')
				: (navimage = null);
		};
	}, []);
	if (props.loading !== null) {
		ControlSplash(false);
		return <div></div>;
	} else ControlSplash(true);
	//TODO: create error pages
	if (props.error !== null) return <SplashScreen opacity='1' />;
	const breakpointColumnsObj = {
		default: 2,
		700: 1,
	};
	const changed_type_news: MainNewsModel[] = changeNewsIdToName(
		props.news,
		props.news_types
	);
	const scrollToHash = (hash: string): void => {
		const element: HTMLElement | null = document.getElementById(hash);
		if (!element) return;
		const yOffset = -60;
		const y =
			element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });
	};
	const isSafari =
		/Safari/.test(navigator.userAgent) &&
		/Apple Computer/.test(navigator.vendor);

	return (
		<div className='main-view'>
			<MainPageAnchorNav></MainPageAnchorNav>
			<div className='slider' id='SLIDER'>
				<MainSliderCarouselView slides={props.sliders}></MainSliderCarouselView>
				<div className='separator'>
					<Img
						src={MouseImage}
						alt=''
						onClick={() => {
							scrollToHash('FIRST_OFFERS');
						}}
					/>
				</div>
			</div>
			{isSafari ? (
				<div
					className='first-offers'
					style={{ backgroundImage: `url(${FirstOffersBGPNG})` }}
					id='FIRST_OFFERS'
				>
					<FirstOffersView
						offers={props.offers}
						context={routerContext}
					></FirstOffersView>
				</div>
			) : (
				<div
					className='first-offers'
					style={{ backgroundImage: `url(${FirstOffersBG})` }}
					id='FIRST_OFFERS'
				>
					<FirstOffersView
						offers={props.offers}
						context={routerContext}
					></FirstOffersView>
				</div>
			)}

			<div className='resellers' id='RESELLERS'>
				<ResellerPromotion></ResellerPromotion>
			</div>

			<div className='second-offers' id='SECOND_OFFERS'>
				<OffersSliderView
					offers={props.offers_slider}
					loading={props.loading}
					offers_slug={getRouteLink('OFFERS', routerContext)}
				></OffersSliderView>
			</div>
			<div className='news' id='NEWS'>
				{isSafari ? (
					<div
						style={{
							backgroundImage: `url(${MainNewsBGPNG})`,
							width: '100%',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
						}}
					>
						<div className='news-lead'>
							<div className='title'>{GetLocaleText('main_news_title')}</div>
							<div className='desc'>
								{HTMLParser(GetLocaleText('main_news_desc'))}
							</div>
						</div>
					</div>
				) : (
					<div
						style={{
							backgroundImage: `url(${MainNewsBG})`,
							width: '100%',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
						}}
					>
						<div className='news-lead'>
							<div className='title'>{GetLocaleText('main_news_title')}</div>
							<div className='desc'>
								{HTMLParser(GetLocaleText('main_news_desc'))}
							</div>
						</div>
					</div>
				)}
				{
					<Masonry
						breakpointCols={breakpointColumnsObj}
						className='my-masonry-grid'
						columnClassName='my-masonry-grid_column'
					>
						{map(changed_type_news, (article: MainNewsModel, index: number) => (
							<MainNewsView
								key={`main-news-${index}`}
								article={article}
								news_slug={getRouteLink('NEWS', routerContext)}
							></MainNewsView>
						))}
					</Masonry>
				}
				<FancyButton
					link={cleanLink(`/${getRouteLink('NEWS', routerContext)}`)}
				>
					{GetLocaleText('main_news_button')}
				</FancyButton>
				{
					//margin hack for masonry fix
				}
				<div style={{ paddingBottom: '0.5rem' }}></div>
			</div>
		</div>
	);
};

export default MainPageView;

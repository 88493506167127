import React from 'react';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import { BikesModel } from '../Model/Bikes.model';
import { IError } from '../../../Interfaces/IError.interface';
import './Less/Bikes.css';
import BikesCarouselView from './Carousel.view';
import { BikeTypesModel } from '../Model/BikeTypes.model';
import { useRouteMatch, match as IMatch } from 'react-router';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import BikesAnchorNav from './BikesAnchorNav.view';

export interface IBikesViewProps {
  loading: Promise<any> | null;
  bikes: BikesModel[];
  error: IError;
  lang: string;
  bike_types: BikeTypesModel[];
}

const BikesView: React.FC<IBikesViewProps> = (props): React.ReactElement => {
  let match: IMatch<{ slug: string }> = useRouteMatch();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    props.loading !== null ? ControlSplash(true) : ControlSplash(false);
    return () => {};
  }, [props.loading]);
  //TODO: create error pages
  if (props.error !== null)
    return <SplashScreen opacity='1' isAbsolute={false} />;

  return (
    <div className='bikes-view'>
      {!match.params.slug ? (
        props.bikes[0] !== undefined && props.bikes[0].type === 'BIKE' ? (
          <BikesAnchorNav types={props.bike_types}></BikesAnchorNav>
        ) : (
          ''
        )
      ) : (
        ''
      )}
      <div className='container'>
        <div className='content'>
          {!match.params.slug ? (
            props.bike_types.map((type, index) => {
              return (
                <BikesCarouselView
                  key={`${index}-bike-carousel-item`}
                  bikes={props.bikes}
                  custom_class={`${type.name}`}
                  type={type}
                />
              );
            })
          ) : props.bike_types[0] ? (
            <BikesCarouselView
              bikes={props.bikes}
              custom_class={`${props.bike_types[0].name}`}
              type={props.bike_types[0]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BikesView;

import * as React from "react";
import "./Spinner.css";

export interface ISpinnerProps {
    isTiny?: boolean;
    isLarge?: boolean;
    isNegatedColor?: boolean;
    isAbsolute?: boolean;
    className?: string;
}

const Spinner: React.FC<ISpinnerProps> = (props): React.ReactElement => {
    let classes: string[] = [ "spinner" ];
    if (props.isTiny) classes.push("tiny-spinner");
    if (props.isLarge) classes.push("large-spinner");
    if (props.isNegatedColor) classes.push("negated-spinner");
    if (props.isAbsolute) classes.push("absolute-spinner");
    if (props.className) classes.push(props.className);

    return (
        <div className={classes.join(" ")}>
            <div className="lds-dual-ring">
                <div className="lds-dual-ring-2" />
            </div>
            {props.children ? <div className="spinner-child">{props.children}</div> : null}
        </div>
    );
};

export default Spinner;

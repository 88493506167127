export const hr = {
	categories: [
		{
			id: '1',
			name: 'Nužni',
			description:
				'Nužni kolačići čine stranicu upotrebljivom omogućavajući osnovne funkcije kao što su navigacija stranicom i pristup zaštićenim područjima. Web-stranica ne može ispravno funkcionirati bez ovih kolačića.',
		},
		{
			id: '2',
			name: 'Statistički',
			description:
				'Statistički kolačići anonimnim prikupljanjem i slanjem podataka pomažu vlasnicima stranice da shvate na koji način posjetitelji komuniciraju sa stranicom.',
		},
		{
			id: '3',
			name: 'Marketinški',
			description:
				'Marketinški kolačići koriste se za praćenje posjetitelja kroz web-stranice.Namjera je prikazivanje oglasa koji su relevantni određenom korisniku i potiču ga na sudjelovanje, što je bitno za izdavače i oglašivače trećih strana.',
		},
	],
	insert_codes: [
		{
			category: '2',
			tag: 'script',
			value:
				'(function (i, s, o, g, r, a, m) {i["GoogleAnalyticsObject"] = r; (i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }), (i[r].l = 1 * new Date());(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "UA-656346-10", "auto");ga("send", "pageview");',
		},
		{
			category: '3',
			tag: 'script',
			value:
				'!(function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "582159962479535");fbq("track", "PageView");',
		},
		{
			category: '3',
			tag: 'noscript',
			value:
				'<img height="1" width="1" src="https://www.facebook.com/tr?id=582159962479535&ev=PageView&noscript=1"/>',
		},
	],
	cookies: [
		{
			Name: 'iTechCookie',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Koristi se za provjeru da li je korisnik već prihvatio upotrebu kolačića na web mjestu.',
		},
		{
			Name: 'lang',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Kolačići potrebni za promjenu jezika stranice.',
		},
		{
			Name: 'secondLevelRoute',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Prilikom promjene jezika pohranite adresu trenutne veze.',
		},
		{
			Name: '_ga',
			Provider: 'suzuki.hu',
			ExpireDescription: '2 years',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Ovaj kolačić pomaže Google Analyticsu u njegovom radu. Koristi se za razlikovanje korisnika dodjeljivanjem slučajnog ID broja svakom korisniku. Ovaj je kolačić prisutan u svim zahtjevima za web stranicu i koristi se za brojanje posjetitelja, sesija i podataka kampanje za analitiku web mjesta.',
		},
		{
			Name: '_gat',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Ovaj kolačić pomaže Google Analyticsu u njegovom radu. Koristi se za smanjivanje zahtjeva, tj. ograničava prikupljanje podataka na web lokacijama s velikim prometom.',
		},
		{
			Name: '_gid',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Ovaj kolačić pomaže Google Analyticsu u njegovom radu. Koristi se za razlikovanje korisnika.',
		},
		{
			Name: 'IDE',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Ovaj kolačić koristi se za ponovno ciljanje, optimizaciju, prijavljivanje i dodjeljivanje internetskih oglasa.',
		},
		{
			Name: 'test_cookie',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Koristi se za provjeru podržava li korisnikov preglednik kolačiće.',
		},
		{
			Name: 'fr',
			Provider: 'facebook.com',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Facebook ih koristi za isporuku raznih reklamnih proizvoda poput licitiranja u stvarnom vremenu od oglašivača trećih strana.',
		},
		{
			Name: 'tr',
			Provider: 'facebook.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'Pixel',
			Category: '3',
			PurposeDescription:
				'Facebook ih koristi za isporuku raznih reklamnih proizvoda poput licitiranja u stvarnom vremenu od oglašivača trećih strana.',
		},
		{
			Name: 'NID',
			Provider: 'google.com',
			ExpireDescription: '6 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'NID kolačić sadrži jedinstveni ID pomoću kojeg Google pamti vaše postavke - poput željenog jezika (npr. Hrvatski), koliko rezultata pretraživanja želite prikazati (npr. 10 ili 20) i želite li imati uključene Googleove SafeSearch filtere te kako bi pohranio ostale vaše podatke.',
		},
		{
			Name: '_fbp',
			Provider: 'suzuki.hu',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Facebook ih koristi za isporuku raznih reklamnih proizvoda poput licitiranja u stvarnom vremenu od oglašivača trećih strana.',
		},
		{
			Name: 'VISITOR_INFO1_LIVE',
			Provider: 'youtube.com',
			ExpireDescription: '179 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Pokušava procijeniti mrežnu propusnost korisnika na stranicama s integriranim YouTube videozapisima.',
		},
		{
			Name: 'YSC',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Registrira jedinstveni ID radi vođenja statistike o tome koje je videozapise s YouTubea korisnik vidio.',
		},
		{
			Name: 'yt-remote-cast-installed',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa',
		},
		{
			Name: 'yt-remote-connected-devices',
			Provider: 'youtube.com',
			ExpireDescription: 'Persistent',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa.',
		},
		{
			Name: 'yt-remote-device-id',
			Provider: 'youtube.com',
			ExpireDescription: 'Persistent',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa',
		},
		{
			Name: 'yt-remote-fast-check-period',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa',
		},
		{
			Name: 'yt-remote-session-app',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa',
		},
		{
			Name: 'yt-remote-session-name',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Pohranjuje korisničke postavke video playera pomoću ugrađenog YouTube videozapisa',
		},
		{
			Name: 'x-bni-fpc',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
		{
			Name: 'x-bni-rncf',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
	],
};

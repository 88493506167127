import React from 'react';
import './Less/CookieSettings.css';
import { ViewType } from '../../CookieMain/Interfaces/IViewType.interface';
import { Row, Col } from 'react-bootstrap';
import { CookieModel } from '../../CookieMain/Model/Cookie.model';
import { ISelectedCookieCategory } from '../../CookieMain/Interfaces/ICookie.interface';
import CookieDetailRow from '../../UI/CookieDetailRow/Controller/CookieDetailRow.controller';
import { GetLocaleText } from '../../../../I18n';

export interface ISize {
	width: number | undefined;
	height: number | undefined;
}

export interface CookieSettingsViewProps {
	selectedCategories: ISelectedCookieCategory[];
	selectCategoryHandler: (selectedId: string, isSelected: boolean) => void;
	saveCookieHandler: () => void;
	setViewType: (viewType: ViewType) => void;
	cookie_data: CookieModel;
	setActiveCategory: (num: number) => void;
	activeCategory: number;
	enableNecessaryCookieHandler: () => void;
}

const CookieSettingsView: React.FC<CookieSettingsViewProps> = (props) => {
	const {
		setViewType,
		cookie_data,
		selectedCategories,
		selectCategoryHandler,
		saveCookieHandler,
		setActiveCategory,
		activeCategory,
		enableNecessaryCookieHandler,
	} = props;

	const filteredCookieData = cookie_data.cookies.filter(
		(cookie) => cookie.Category === selectedCategories[activeCategory]?.id
	);

	return (
		<div className='cookie-settings-container'>
			<div className='cookie-settings-header'>
				<div className='cookie-spacer'>
					<Row>
						<Col xs='12'>
							<div className='cookie-content'>
								<p className='cookie-content-text'>
									{GetLocaleText('cookie_content_text')}
								</p>
							</div>
						</Col>
					</Row>
				</div>
				<div className='cookie-spacer'>
					<div className='cookie-content'>
						<Row>
							{cookie_data.categories.map((category, index) => {
								let isChecked = selectedCategories[index]?.isSelected || false;
								return (
									<Col xs='12' sm='6' lg='4' key={category.id}>
										<div className='cookie-check'>
											<label
												className={`cookie-check-label
													${activeCategory === index ? 'active' : ''}`}
												/*  htmlFor={category.id} */
												id={category.id}
												onClick={() => setActiveCategory(index)}
											>
												{category.name}
											</label>
											<input
												type='checkbox'
												className='cookie-check-input'
												checked={isChecked}
												disabled={index === 0 ? true : false}
												onChange={(e) =>
													selectCategoryHandler(e.target.id, e.target.checked)
												}
												id={category.id}
											/>
										</div>
									</Col>
								);
							})}
						</Row>
					</div>
				</div>
				<div className='cookie-spacer'>
					<Row>
						<Col xs='12'>
							<div className='cookie-content'>
								<p className='cookie-content-text'>
									{cookie_data.categories[activeCategory]?.description}
								</p>
							</div>
						</Col>
					</Row>
				</div>
				<div className='cookie-spacer'>
					<Row>
						<Col xs='12'>
							<div className='cookie-content'>
								{filteredCookieData.map((currentCookie, index) => (
									<CookieDetailRow
										key={index}
										name={currentCookie.Name}
										description={currentCookie.PurposeDescription}
									/>
								))}
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className='cookie-settings-footer'>
				<div className='cookie-spacer'>
					<Row>
						<Col xs='12' lg='6'>
							<div className='cookie-button cookie-button-right'>
								<button
									className='filled-cookie-button'
									onClick={() => saveCookieHandler()}
								>
									{GetLocaleText('cookie_content_save_button')}
								</button>
							</div>
						</Col>
						<Col xs='12' lg='6'>
							<div className='cookie-button cookie-button-left'>
								<button
									className='simple-cookie-button'
									onClick={() => enableNecessaryCookieHandler()}
								>
									{GetLocaleText('cookie_content_only_necessary')}
								</button>
							</div>
						</Col>
					</Row>
				</div>
				<Row>
					<Col xs='12'>
						<div className='cookie-footer'>
							<p className='cookie-footer-text'>
								{GetLocaleText('cookie_content_footer_text').split('-')[0]}
								<button onClick={() => setViewType('Details')}>
									{GetLocaleText('cookie_content_footer_text').split('-')[1]}
								</button>{' '}
								{GetLocaleText('cookie_content_footer_text').split('-')[2]}
							</p>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default CookieSettingsView;

import React from 'react';
import Img from 'react-image';
import { CatalogModel } from '../Model/Catalog.model';
import DownloadButton from '../../../Assets/download_icon.png';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface ICatalogElementViewProps {
	catalogs: CatalogModel;
}

const CatalogElementView: React.FC<ICatalogElementViewProps> = (
	props
): React.ReactElement => {
	const { catalogs } = props;

	return (
		<div className='container'>
			<div className='row'>
				<div className='col-lg-8 col-md-8 col-xs-8 left'>
					<div className='name'>
						<span></span>
						{catalogs.catalog_type_name}
					</div>
					<span className='imageborder'></span>
					<Img
						src={catalogs.image}
						alt={generateImgAlt(catalogs.image)}
						className='catalog-item--image'
					/>
				</div>
				<div className='col-lg-4 col-md-4 col-xs-4 right'>
					<div className='title'>{catalogs.title}</div>
					<div className='download-button'>
						<a href={catalogs.file} target='_blank' rel='noopener noreferrer'>
							<span className='download-text'>
								{GetLocaleText('catalog_download')}
							</span>
							<Img src={DownloadButton} alt='' />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CatalogElementView;

import React from "react";
import { Swipeable } from "react-swipeable";
import { generateImgAlt } from "../../../Utils/GenerateImageAlt.util";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MainSliderVideoIndicatorIcon from "../../../Assets/BikePage/mainSliderInicatorPlayButton.png";
import SliderIndicatorShadow from "../../../Assets/BikePage/mainSliderShadow.png";
import MainSliderBackArrow from "../../../Assets/BikePage/mainSliderBackarrow.png";
import MainSliderNextArrow from "../../../Assets/BikePage/mainSliderNextArrow.png";
import BikeModel from "../Model/Bike.model";
import Spinner from "../../UI/Spinner/Spinner";
import Popup from "reactjs-popup";
import Img from "react-image";
import "./Less/MainSliderSection.style.css";

export interface IMainSliderSectionProps {
    bike: BikeModel;
}

export interface IMainSliderSectionState {
    index: number;
    numberOfIndicatorElementsLeftAndRight: number;
    modalOpen: boolean;
    forward: boolean;
}

class MainSliderSection extends React.Component<IMainSliderSectionProps, IMainSliderSectionState> {
    state = {
        index: 0,
        numberOfIndicatorElementsLeftAndRight: 4,
        modalOpen: false,
        forward: false
    };

    componentDidMount() {
        this.updateNumberOfIndicators();
        window.addEventListener("resize", this.updateNumberOfIndicators, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateNumberOfIndicators);
    }

    updateNumberOfIndicators = () => {
        const width: number = window.innerWidth;
        if (width > 1500) this.setState({ numberOfIndicatorElementsLeftAndRight: 5 });
        else if (width > 1000) this.setState({ numberOfIndicatorElementsLeftAndRight: 3 });
        else if (width > 700) this.setState({ numberOfIndicatorElementsLeftAndRight: 2 });
        else if (width > 500) this.setState({ numberOfIndicatorElementsLeftAndRight: 1 });
        else this.setState({ numberOfIndicatorElementsLeftAndRight: 0 });
    };

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    };

    goTo = (index: number) => {
        if (index < this.state.index) this.setState({ index, forward: false });
        else this.setState({ index, forward: true });
    };

    goNext = () => {
        if (this.state.index < this.props.bike.simpleSlider.length - 1)
            this.setState({ index: this.state.index + 1, forward: true });
        else this.setState({ index: 0, forward: true });
    };

    goBack = () => {
        if (this.state.index !== 0) this.setState({ index: this.state.index - 1, forward: false });
        else this.setState({ index: this.props.bike.simpleSlider.length - 1, forward: false });
    };

    getYoutubeEmbed = (url: string): string => {
        const regExp: RegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match: string[] | null = url.match(regExp);

        return match && match[2].length === 11
            ? `https://www.youtube.com/embed/${match[2]}?autoplay=1&origin=${document.location.origin}`
            : "";
    };

    getYoutubeThumbnailImage = (url: string, type: string = "hqdefault"): string => {
        const regExp: RegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match: string[] | null = url.match(regExp);

        return match && match[2].length === 11
            ? `https://img.youtube.com/vi/${match[2]}/${type}.jpg`
            : "";
    };

    getRenderableIndexes = (): number[] => {
        let renderableIndexes: number[] = [];

        const numberOfRenderableItems = this.state.numberOfIndicatorElementsLeftAndRight * 2 + 1;
        const sliderLength: number = this.props.bike.simpleSlider.length;

        if (sliderLength <= numberOfRenderableItems) {
            for (let i = 0; i < sliderLength; i++) renderableIndexes.push(i);
            return renderableIndexes;
        }

        for (let i = 1; i <= this.state.numberOfIndicatorElementsLeftAndRight; i++) {
            const actualIndex = this.state.index - i;
            if (actualIndex < 0) renderableIndexes.push(sliderLength + actualIndex);
            else renderableIndexes.push(actualIndex);
        }

        renderableIndexes.push(this.state.index);

        for (let i = 1; i <= this.state.numberOfIndicatorElementsLeftAndRight; i++) {
            const actualIndex = this.state.index + i;
            if (actualIndex >= sliderLength) renderableIndexes.push(actualIndex - sliderLength);
            else renderableIndexes.push(actualIndex);
        }

        return renderableIndexes;
    };

    render() {
        const { index } = this.state;
        if (this.props.bike.simpleSlider.length === 0) return null;

        let styleSettings = {
            playButton: {
                zIndex: 0,
                opacity: 0
            },
            frame: {
                zIndex: 23
            },
            bottomBg: {
                backgroundColor: "transparent",
                pointerEvents: "none" as "none",
                transition: "background-color 0.2s linear"
            },
            topBg: {
                backgroundColor: "transparent",
                pointerEvents: "none" as "none",
                transition: "background-color 0.2s linear"
            }
        };

        if (this.props.bike.simpleSlider[index].type === "VIDEO") {
            styleSettings.playButton.zIndex = 23;
            styleSettings.playButton.opacity = 1;
            styleSettings.frame.zIndex = 10;
            styleSettings.bottomBg.backgroundColor = "#002c48";
            styleSettings.topBg.backgroundColor = "#002c48";
        }

        if (this.state.modalOpen) {
            styleSettings.playButton.zIndex = 0;
            styleSettings.playButton.opacity = 0;
            styleSettings.bottomBg.backgroundColor = "transparent";
            styleSettings.topBg.backgroundColor = "transparent";
            styleSettings.topBg.transition = "none";
            styleSettings.bottomBg.transition = "none";
        }

        return (
            <div className="bike-main-slider-component">
                <div className="top-bg-line main-slider-bg-line" style={styleSettings.topBg}></div>
                <div
                    draggable={false}
                    onDragStart={(e) => e.preventDefault()}
                    className="bike-main-slider-item__image--play-button"
                    style={styleSettings.playButton}
                    onClick={this.toggleModal}
                />
                <Swipeable trackMouse={true} onSwipedLeft={this.goNext} onSwipedRight={this.goBack}>
                    <div className="bike-main-slider-frame" style={styleSettings.frame}>
                        <TransitionGroup
                            className={[
                                "main-slider-trasition-group",
                                this.state.forward ? "main-slider-forward" : "main-slider-backward"
                            ].join(" ")}
                        >
                            <CSSTransition
                                key={this.props.bike.simpleSlider[index].sliderId}
                                timeout={300}
                                classNames={"slide"}
                            >
                                {this.props.bike.simpleSlider[index].type === "IMAGE" ? (
                                    <div className="bike-main-slider-item">
                                        <Img
                                            draggable={false}
                                            loader={<Spinner isTiny />}
                                            src={this.props.bike.simpleSlider[index].content}
                                            onDragStart={(e) => e.preventDefault()}
                                            alt={generateImgAlt(
                                                this.props.bike.simpleSlider[index].content
                                            )}
                                            className="bike-main-slider-item__image bms-img"
                                        />
                                    </div>
                                ) : (
                                    <div className="bike-main-slider-item video-container">
                                        <Img
                                            draggable={false}
                                            loader={<Spinner isTiny />}
                                            onDragStart={(e) => e.preventDefault()}
                                            src={[
                                                this.getYoutubeThumbnailImage(
                                                    this.props.bike.simpleSlider[index].content
                                                ),
                                                //fallback image if there is no image with maxresdefault
                                                this.getYoutubeThumbnailImage(
                                                    this.props.bike.simpleSlider[index].content,
                                                    "hqdefault"
                                                )
                                            ]}
                                            alt={`slider-${this.props.bike.simpleSlider[index].sliderId}`}
                                            className="bike-main-slider-item__image bike-main-slider-item__image--video bms-img"
                                        />
                                        <Popup
                                            open={this.state.modalOpen}
                                            closeOnDocumentClick
                                            onClose={this.toggleModal}
                                            lockScroll={true}
                                            contentStyle={{ width: "60%" }}
                                        >
                                            <div className="bike-mainslider-iframe-container">
                                                <iframe
                                                    className="bike-mainslider-iframe-container__iframe"
                                                    src={this.getYoutubeEmbed(
                                                        this.props.bike.simpleSlider[index].content
                                                    )}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    title={this.props.bike.lang.slogan}
                                                />
                                            </div>
                                        </Popup>
                                    </div>
                                )}
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </Swipeable>
                <div
                    className="bottom-bg-line main-slider-bg-line"
                    style={styleSettings.bottomBg}
                ></div>
                <div
                    className={[
                        "bike-main-slider-navigation",
                        this.state.modalOpen ? "bike-main-slider-navigation--modal-open" : null
                    ].join(" ")}
                >
                    <span className="bike-main-slider-navigation__container">
                        <span
                            className="bike-main-slider-navigation__back-arrow-button bike-main-slider-navigation__back-arrow-button--prev"
                            onClick={this.goBack}
                        >
                            <Img
                                draggable={false}
                                loader={<Spinner isTiny />}
                                src={MainSliderBackArrow}
                                alt="main-slider-prev"
                                className="bike-main-slider-navigation__back-arrow-image"
                            />
                        </span>
                        {this.getRenderableIndexes().map((index, i) => (
                            <span
                                className="bike-main-slider-navigation__indicator"
                                key={`${i}-ms-block`}
                                onClick={() => this.goTo(index)}
                            >
                                {this.props.bike.simpleSlider[index].type === "IMAGE" ? (
                                    <Img
                                        draggable={false}
                                        loader={<Spinner isTiny />}
                                        src={this.props.bike.simpleSlider[index].content}
                                        onDragStart={(e) => e.preventDefault()}
                                        alt="indcator"
                                        className="bike-main-slider-navigation__indicator-image bms-img"
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Img
                                            draggable={false}
                                            loader={<Spinner isTiny />}
                                            onDragStart={(e) => e.preventDefault()}
                                            src={MainSliderVideoIndicatorIcon}
                                            alt="play-button"
                                            className="bike-main-slider-navigation__indicator-image--play-button bms-img"
                                        />
                                        <Img
                                            loader={<Spinner isTiny />}
                                            onDragStart={(e) => e.preventDefault()}
                                            src={[
                                                this.getYoutubeThumbnailImage(
                                                    this.props.bike.simpleSlider[index].content
                                                ),
                                                //fallback image if there is no image with maxresdefault
                                                this.getYoutubeThumbnailImage(
                                                    this.props.bike.simpleSlider[index].content,
                                                    "hqdefault"
                                                )
                                            ]}
                                            alt="indcator"
                                            className="bike-main-slider-navigation__indicator-image bms-img"
                                        />
                                        {/* <div className="item-video">
                                            <div className="filter" />
                                            <a className="owl-video" href={slide.content}>
                                                {" "}
                                            </a>
                                            <div className="content">
                                                <div className="title">{slide.title}</div>
                                                <div className="desc">{slide.description}</div>
                                            </div>
                                        </div> */}
                                    </React.Fragment>
                                )}
                            </span>
                        ))}
                        <span
                            className="bike-main-slider-navigation__back-arrow-button bike-main-slider-navigation__back-arrow-button--next"
                            onClick={this.goNext}
                        >
                            <Img
                                draggable={false}
                                loader={<Spinner isTiny />}
                                src={MainSliderNextArrow}
                                alt="main-slider-next"
                                className="bike-main-slider-navigation__back-arrow-image"
                            />
                        </span>
                        <Img
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                            loader={<Spinner isTiny />}
                            src={SliderIndicatorShadow}
                            className="bike-main-slider-navigation__shadow bms-img"
                        />
                    </span>
                </div>
            </div>
        );
    }
}

export default MainSliderSection;

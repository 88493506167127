import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { ICatalog } from '../Interfaces/ICatalog.interface';

export class CatalogModel {
  constructor(Catalog?: ICatalog) {
    if (Catalog) {
      ModelConstructor(Catalog, this);
    }
  }

  catalog_id: string = '';
  type_id: string = '';
  title: string = '';
  image: string = '';
  file: string = '';
  lang: string = '';

  catalog_type_name: string = '';
}

import axios from 'axios';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class ContactRODAO {
	private static readonly contact: string =
		'https://suzuki.ilinedb.hu/api/v1/contact-ro';

	public static async create(params: FormData, lang: string): Promise<void> {
		const request: AxiosPromise<void> = axios.post(`${this.contact}`, params);
		const response: AxiosResponse<void> = await request;
		return response.data;
	}
}

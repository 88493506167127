import { axios } from '../../../Api/axios';
import { OwnersModel } from '../Model/Owners.model';
import { IOwners } from '../Interfaces/IOwners.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class OwnersDAO {
  private static readonly owners: string = '/api/owner/elements';

  public static async getOwners(): Promise<OwnersModel[]> {
    const request: AxiosPromise<IOwners[]> = axios
      .getInstance()
      .get(this.owners);
    const response: AxiosResponse<IOwners[]> = await request;
    return response.data.map((owners: IOwners) => new OwnersModel(owners));
  }
}

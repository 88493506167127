import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IBikeTypes } from '../Interfaces/IBikeTypes.interface';

export class BikeTypesModel {
  constructor(BikeType?: IBikeTypes) {
    if (BikeType) {
      ModelConstructor(BikeType, this);
    }
  }

  type_id: string = '';
  lang: string = '';
  name: string = '';
}

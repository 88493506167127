import React, { useContext, useState, useEffect, useRef } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { PricelistModel } from '../Model/Pricelist.model';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import { PricelistTextModel } from '../Model/PricelistText.model';
import Img from 'react-image';
import './Less/Pricelist.css';
import HTMLParser from 'html-react-parser';
import { orderBy } from 'lodash';
import Shape from '../../../Assets/pricelist_shape.png';
import { GetLocaleText } from '../../../I18n';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { BikeTypesModel } from '../../Bikes/Model/BikeTypes.model';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { Link } from 'react-router-dom';
import { hayaOrder } from '../../../Utils/HayaOrder.util';

export interface IPricelistProps {
	bikes: PricelistModel[];
	content: PricelistTextModel;
	error: IError;
	loading: Promise<any> | null;
	bike_types: BikeTypesModel[];
}

const PricelistView: React.FC<IPricelistProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const bike_new_text = GetLocaleText('bike_new');
	const bike_discount_text = GetLocaleText('bike_discount');
	const pricelist_news_slug_text = GetLocaleText('pricelist_news_slug');
	const pricelist_apr_before_text = GetLocaleText('pricelist_apr_before');
	const pricelist_apr_after_text = GetLocaleText('pricelist_apr_after');
	const pricelist_apr_button_text = GetLocaleText('pricelist_apr_button');
	const pricelist_price_text_text = GetLocaleText('pricelist_price_text');
	const [selector_active, set_selector_active] = useState(0);
	const [isSticky, setSticky] = useState(true);
	const ref = useRef<HTMLDivElement>(null);
	const [show_download, set_show_download] = useState(false);

	const handleScroll = () => {
		if (ref.current !== null)
			window.scrollY > 500 ? set_show_download(true) : set_show_download(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		setSticky(true);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', () => handleScroll);
		};
	}, []);

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null)
		return <SplashScreen opacity='1' isAbsolute={false} />;

	const formatAPR = (apr: string): string => {
		if (apr !== '0') apr = apr.slice(0, 1) + '.' + apr.slice(1);
		return apr;
	};

	const changeAbsStyle = (name: string): string => {
		let changeName: string = '';
		if (name.toUpperCase().includes(' ABS')) {
			const name_splitted: string[] = name.split(' ');

			name_splitted.map((piece) => {
				return piece === 'ABS'
					? (changeName += ' <div class="abs">' + piece + '</div> ')
					: (changeName += piece + ' ');
			});
		} else {
			changeName = name;
		}
		return changeName;
	};

	let orderedBikes = hayaOrder(orderBy(props.bikes, 'type_id'));

	const types_array: string[] = orderedBikes.map((bike) => {
		return bike.type_id;
	});

	const scrollToHash = (hash: string): void => {
		const element: HTMLElement | null = document.getElementById(hash);
		if (!element) return;
		const yOffset = -40;
		const y =
			element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });
	};

	return (
		<div className='pricelist-view'>
			<a
				href={props.content.pdf}
				target='_blank'
				rel='noopener noreferrer'
				className={`pdf-${show_download}`}
			>
				<div className='pdf-download'>
					<span>Download pricelist catalog</span>
				</div>
			</a>
			<div
				className={`pricelist-view__type-selector container ${
					isSticky ? 'sticky' : ''
				}`}
			>
				{props.bike_types.map((bike, index) => {
					return types_array.includes(bike.type_id) ? (
						<div
							key={`biketypes_${index}`}
							className={`type ${
								selector_active === +bike.type_id ? 'active' : ''
							}`}
							onClick={() => {
								set_selector_active(+bike.type_id);
							}}
						>
							<div
								onClick={() => {
									scrollToHash(bike.type_id);
								}}
							>
								{bike.name}
							</div>
						</div>
					) : (
						''
					);
				})}
			</div>
			<div className='container' ref={ref}>
				{orderedBikes.map((bike, index) => {
					return (
						<div
							key={index}
							id={bike.type_id !== types_array[index - 1] ? bike.type_id : ''}
						>
							<div
								className={` type-lead ${
									bike.type_id !== types_array[index - 1] ? `pricelist-mt` : ''
								}`}
							>
								{bike.type_id !== types_array[index - 1]
									? props.bike_types.map((type) => {
											return type.type_id === bike.type_id ? type.name : '';
									  })
									: ''}
							</div>
							<div className={`pricelist-view__item `}>
								<div className='pricelist-view__item--motor'>
									{bike.type === 'BIKE' ? (
										<Link
											to={cleanLink(
												`/${getRouteLink('BIKES', routerContext)}/${bike.slug}`
											)}
										>
											<Img src={bike.image} alt={generateImgAlt(bike.image)} />
										</Link>
									) : (
										<Link
											to={cleanLink(
												`/${getRouteLink('SCOOTER', routerContext)}/${
													bike.slug
												}`
											)}
										>
											<Img src={bike.image} alt={generateImgAlt(bike.image)} />
										</Link>
									)}
									<div className='datas'>
										<div className='datas__year'>{bike.year}</div>
										{bike.is_new === '1' ? (
											<div className='datas__new'>{bike_new_text}</div>
										) : bike.discount_price !== '' ? (
											<div className='datas__discount'>
												{bike_discount_text}
											</div>
										) : (
											''
										)}
										<div className='datas__colors'>
											{bike.colors.map((color) => {
												return (
													<div
														className='color'
														key={`color-${color.color_id}`}
														style={{
															background: `linear-gradient(90deg, #${color.color_01}, #${color.color_02})`,
														}}
													></div>
												);
											})}
										</div>
									</div>
								</div>
								<div className='pricelist-view__item--shape'>
									<Img src={Shape} alt='' />
								</div>
								<div className='pricelist-view__item--motordata'>
									<Link
										className='name'
										to={cleanLink(
											`/${getRouteLink('BIKES', routerContext)}/${bike.slug}`
										)}
									>
										{HTMLParser(changeAbsStyle(bike.name))}
									</Link>
									<div className='slogan'>{bike.slogan}</div>
									{bike.apr !== '' && bike.apr !== '10100' ? (
										<div className='pricelist-view__item--apr'>
											<Link
												className='text'
												to={`/${getRouteLink(
													'NEWS',
													routerContext
												)}/${pricelist_news_slug_text}`}
											>
												{pricelist_apr_before_text}{' '}
												<span>{formatAPR(bike.apr)}%</span>{' '}
												{pricelist_apr_after_text}
											</Link>
											<Link
												className='button'
												to={`/${getRouteLink('CALCULATOR', routerContext)}/${
													bike.slug
												}`}
											>
												{pricelist_apr_button_text}
											</Link>
										</div>
									) : (
										''
									)}
								</div>
								<div className='pricelist-view__item--shape'>
									<Img src={Shape} alt='' />
								</div>
								<div className='pricelist-view__item--price'>
									<div className='text'>{pricelist_price_text_text}</div>
									<div
										className={`price ${
											bike.discount_price !== '' ? 'discounted' : ''
										}`}
									>
										{HTMLParser(bike.price)}
									</div>
									{bike.discount_price !== '' ? (
										<div className='discount-price'>
											{HTMLParser(bike.discount_price)}
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className='pricelist-text'>
				<div className='container'>{HTMLParser(props.content.content)}</div>
			</div>
		</div>
	);
};

export default PricelistView;

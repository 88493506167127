export const si = {
	page_id: 0,
	lang_id: 1,
	name: 'Cookie Details Page',
	slug: 'cookie',
	content: `<div class="custompage-view-cookie">
  <div class="custompage-view-cookie__content"><h1 class="mainhead__title"><span style="font-family: SuzukiHeadline; font-size: 60px;">COOKIE POLICY</span></h1>
<h2><span style="font-family: SuzukiBold;">What is a cookie?</span></h2>
<p>A cookie is a small data package, which web services store in your web browser. This technology is essential for the proper functioning of many web services, and is supported by all major browsers since 1995.</p>
<h2><span style="font-family: SuzukiBold;">Why is it good for the user?</span></h2>
<p>Many web services depend on cookies for proper functioning and user convenience. Cookies enable you, for instance, to easily log in to password protected websites, or to access personalized content on the web.</p>
<h2><span style="font-family: SuzukiBold;">Cookies on the websites and in the online services of Magyar Suzuki Corporation.</span></h2>
<p>In order to ensure the highest quality functioning of our websites and the best possible user experience, we use cookies on the websites and in the services of Magyar Suzuki Corporation. You may delete the cookies from your computer at any time, and you may configure your browser to disable them. However, by disabling cookies, you acknowledge that without the cookies the website will not be fully operational. For more information on cookies, please refer to the Privacy Policy, which you may access by clicking&nbsp; <a href="https://www.suzuki.hu/corporate/en/content/privacy-notice">here</a>.</p>
<h2><span style="font-family: SuzukiBold;">Cookies placed by third parties.</span></h2>
<p>Our websites may contain such information that originate with third parties, or advertising providers, who are not related to the Controller. These third parties may also place cookies and web beacons on the user&rsquo;s computer, or may use similar technologies to collect data in order to send targeted advertisements to the user in connection with their own services. In such cases, the processing of data is governed by the privacy policies set forth by these third parties.</p>
<h2><span style="font-family: SuzukiBold;">Cookie settings in browsers.</span></h2>
<p>Cookies may also be manually configured by the user. All stored cookies may be removed, and their storing may be disabled with the correct settings. You can configure these settings by following the instructions provided on the below links by browser type.</p>
<p><strong>Microsoft Internet Explorer</strong><br /><a href="hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener">hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a><br /><strong>Microsoft Egde</strong><br /><a href="https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener">https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy</a><br /><strong>Firefox</strong><br /><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a><br /><strong>Chrome</strong><br /><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en" target="_blank" rel="noopener">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en</a><br /><strong>Safari</strong><br /><a href="https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac">https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac</a><br /><strong>IOS Safari</strong><br /><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener">https://support.apple.com/en-us/HT201265</a></p>
<h2><span lang="hu-HU" style="font-family: SuzukiBold;">Functional cookies:</span></h2>
<p lang="en-GB" align="justify">Without these cookies, the website will not work properly. They cannot be turned off, and are usually triggered based on your interactions with the website, such as setting privacy options, logging in, or filling out forms.</p>
<table style="width: 99.9318%;" border="1px" cellspacing="33.33%">
<thead>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">Name</span></p>
</td>
<td style="width: 76.8186%; text-align: center;">
<p><span style="font-size: 16px;">Description</span></p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">Storage period</span></p>
</td>
</tr>
</thead>
<tbody>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">lang</span></p>
</td>
<td style="width: 76.8186%; text-align: center;">
<p class="gtx-body">Cookies required to change the language of the page.</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">&nbsp;On browser closure</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">CookieConsent</span></p>
</td>
<td style="width: 76.8186%; text-align: center;">
<p><span style="font-size: 16px;">Used to monitor whether the user has already accepted the use of cookies on the website.</span></p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">1 years</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">rc::a</td>
<td style="width: 76.8186%; text-align: center;">
<p><span style="font-size: 16px;">This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.</span></p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">&nbsp;On browser closure</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">rc::c</td>
<td style="width: 76.8186%; text-align: center;">
<p><span style="font-size: 16px;">This cookie is used to distinguish between humans and bots.&nbsp;</span></p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">&nbsp;On browser closure</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">secondLevelRoute</td>
<td style="width: 76.8186%; text-align: center;">
<p><span style="font-size: 16px;">When changing the language, store the address of the current link.</span></p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">&nbsp;On browser closure</span></p>
</td>
</tr>
</tbody>
</table>
<h2 lang="en-GB" align="justify"><span lang="hu-HU"><br /><span style="font-family: SuzukiBold;">Performance cookies:</span></span></h2>
<p lang="en-GB" align="justify"><span lang="hu-HU">They are used to monitor a browser&rsquo;s operation to provide help in improving information and services.</span></p>
<table style="width: 100%;" border="1px" cellspacing="33.33%">
<tbody>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">Name</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p><span style="font-size: 16px;">Description</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">Storage period</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">.ga</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p><span style="font-size: 16px;">This cookie assists Google Analytics in its operation. Used to distinguish users by assigning a random ID number to each user. This cookie is present in all page requests for the website, and is used to count visitors, sessions, and campaign data for site analytics.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">2 years</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">_gid</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p><span style="font-size: 16px;">This cookie assists Google Analytics in its operation. Used to distinguish users.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">24 hours</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">_gat</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p><span style="font-size: 16px;">This cookie assists Google Analytics in its operation. Used to throttle request rate, i.e. limits data collection on websites with high traffic</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">1 minute</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">collect</td>
<td style="text-align: center; width: 76.8596%;">
<p><span style="font-size: 16px;">Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">&nbsp;On browser closure</span></p>
</td>
</tr>
</tbody>
</table>
<h2><span lang="hu-HU">&nbsp;</span></h2>
<h2><span lang="hu-HU" style="font-family: SuzukiBold;">Third-party cookies:</span></h2>
<p><span lang="hu-HU">These cookies are used by content&mdash;such as social media posts, maps or videos&mdash;embedded on the website.</span></p>
<table style="width: 100%;" border="1px" cellspacing="33.33%">
<tbody>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">Name</span></p>
</td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Description</span></p>
</td>
<td style="width: 10.8127%; text-align: center;">
<p><span style="font-size: 16px;">Storage period</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">NID</span></p>
</td>
<td style="text-align: center;"><span style="font-size: 16px;">The NID cookie contains a unique ID that Google uses to remember your preferences&mdash;such as your preferred language (e.g. Hungarian), how many search results you wish to have shown (e.g. 10 or 20), and whether or not you wish to have Google&rsquo;s SafeSearch filter turned on&mdash;and also to store your other information.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">6 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">IDE</span></p>
</td>
<td style="text-align: center;"><span style="font-size: 16px;">This cookie is used to re-target, optimize, report and assign online advertisements.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">2 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">test_cookie</td>
<td style="text-align: center;"><span style="font-size: 16px;">Used to check if the user's browser supports cookies.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">1 day</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">fr</td>
<td style="text-align: center;"><span style="font-size: 16px;">Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">3 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">tr</td>
<td style="text-align: center;"><span style="font-size: 16px;">Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">_fbp</td>
<td style="text-align: center;"><span style="font-size: 16px;">Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">3 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">GPS</td>
<td style="text-align: center;"><span style="font-size: 16px;">Registers a unique ID on mobile devices to enable tracking based on geographical GPS location.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">1 day</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">VISITOR_INFO1_LIVE</td>
<td style="text-align: center;"><span style="font-size: 16px;">Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">179 days</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">YSC</td>
<td style="text-align: center;"><span style="font-size: 16px;">Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-cast-installed</td>
<td style="text-align: center;"><span style="font-size: 16px;">Stores the user's video player preferences using embedded YouTube video</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-connected-devices</td>
<td style="text-align: center;"><span style="font-size: 16px;">Stores the user's video player preferences using embedded YouTube video</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Presistent</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-device-id</td>
<td style="text-align: center;"><span style="font-size: 16px;">Stores the user's video player preferences using embedded YouTube video</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Presistent</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-fast-check-period</td>
<td style="text-align: center;"><span style="font-size: 16px;">Stores the user's video player preferences using embedded YouTube video</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-session-app</td>
<td style="text-align: center;"><span style="font-size: 16px;">Stores the user's video player preferences using embedded YouTube video</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-session-name</td>
<td style="text-align: center;"><span style="font-size: 16px;">Stores the user's video player preferences using embedded YouTube video</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
</tbody>
</table>
<p style="text-align: center;"><br />We work continuously on improving our websites and digital services. For this purpose, we use Google<br />Analytics to analyze our users&rsquo; aggregated, anonymized behavioral data.<br />Furthermore, we also allow our web analytics tool Google Analytics to manage cookies, and place cookies on this website. We use statistics obtained this way to improve user experience.<br />For more information, please contact our Data Protection Officer at&nbsp; <a title="privacy@suzuki.hu" href="mailto:privacy@suzuki.hu">privacy@suzuki.hu</a></p>`,
};

import { IContactROForm } from './../Interfaces/IContact_ro.interface';

export const ContactROForm: IContactROForm = {
	reseller: {
		id: 'reseller',
		value: null,
		required: true,
		options: [],
		labelText: 'Reseller',
		selectType: true,
	},
	name: {
		id: 'name',
		value: '',
		required: true,
		labelText: 'Name',
		type: 'text',
		textType: true,
	},
	email: {
		id: 'email',
		value: '',
		required: true,
		labelText: 'Email',
		type: 'email',
		textType: true,
	},
	phone: {
		id: 'phone',
		value: '',
		required: true,
		labelText: 'Phone',
		type: 'tel',
		textType: true,
	},
	message: {
		id: 'message',
		value: '',
		textAreaType: true,
		labelText: 'Message',
		required: false,
		className: 'text-area',
	},
	preferred_mail: {
		id: 'preferred_mail',
		value: false,
		required: false,
		href: '',
		hrefText: '',
		labelTextBefore: '',
		labelTextAfter: '',
		booleanType: true,
	},
	preferred_phone: {
		id: 'preferred_phone',
		value: false,
		required: false,
		href: '',
		hrefText: '',
		labelTextBefore: '',
		labelTextAfter: '',
		booleanType: true,
	},
	acceptance: {
		id: 'acceptance',
		value: false,
		required: true,
		href: '',
		hrefText: '',
		labelTextBefore: '',
		labelTextAfter: '',
		booleanType: true,
	},
	acceptance2: {
		id: 'acceptance2',
		value: false,
		required: true,
		href: '',
		hrefText: '',
		labelTextBefore: '',
		labelTextAfter: '',
		booleanType: true,
	},
	acceptance3: {
		id: 'acceptance3',
		value: false,
		required: true,
		href: '',
		hrefText: '',
		labelTextBefore: '',
		labelTextAfter: '',
		booleanType: true,
	},
	acceptance4: {
		id: 'acceptance4',
		value: false,
		required: true,
		href: '',
		hrefText: '',
		labelTextBefore: '',
		labelTextAfter: '',
		booleanType: true,
	},
};

import { ISingleNews } from '../Interfaces/INews.interface';
import { NewsModel } from './News.model';
import { setString } from '../../../Utils/DataSetter.util';

export class SingleNewsModel extends NewsModel {
  constructor(News?: ISingleNews) {
    super(News);
    if (News) {
      this.content = setString(News.content);
    }
  }
  content: string = '';
}

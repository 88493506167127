import React from 'react';
import { OfferModel } from '../Model/Offer.model';
import {
	faLongArrowAltRight,
	faLongArrowAltLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetLocaleText } from '../../../I18n';
import { OfferNoImgElementView } from './OfferNoImgElement.view';
import Img from 'react-image';
import FilterBG from '../../../Assets/offers_filter.png';
import { OfferImgElementView } from './OfferImgElement.view';

export interface IOfferElementViewProps {
	offer: OfferModel;
	offer_length: number;
	index: number;
	lang: string;
	imageProps: Array<string>;
	prev_next_links?: boolean;
}

const OfferElementView: React.FC<IOfferElementViewProps> = (
	props
): React.ReactElement => {
	const { offer, index } = props;

	const scrollToHash = (hash: string): void => {
		const element: HTMLElement | null = document.getElementById(hash);
		if (!element) return;

		element.scrollIntoView({
			behavior: 'smooth',
			inline: 'center',
			block: 'start',
		});
	};

	return (
		<div className='offer' id={index + ''}>
			<div
				className='offer__container'
				style={{ backgroundImage: `url(${offer.background})` }}
			>
				<Img className='filter' alt='' src={FilterBG}></Img>
				<div
					className={`offer__container--prev-page display-none-mobile ${
						index === 0 || props.prev_next_links ? 'display-none' : ''
					}`}
				>
					<div
						onClick={() => {
							scrollToHash(index - 1 + '');
						}}
					>
						{GetLocaleText('prev_text')}
						{'  '}
						<FontAwesomeIcon icon={faLongArrowAltRight} />
					</div>
				</div>
				<div
					className={`offer__container--next-page display-none-mobile ${
						index === props.offer_length - 1 || props.prev_next_links
							? 'display-none'
							: ''
					}`}
				>
					<div
						onClick={() => {
							scrollToHash(index + 1 + '');
						}}
					>
						<FontAwesomeIcon icon={faLongArrowAltLeft} />
						{'  '}
						{GetLocaleText('next_text')}
					</div>
				</div>
				<div className='offer__container--item container'>
					{props.imageProps[index] === 'right' ||
					props.imageProps[index] === 'left' ? (
						<OfferImgElementView
							offer={offer}
							index={index}
							lang={props.lang}
							direction={props.imageProps[index]}
							swap={props.imageProps[index] === 'right' ? true : false}
						></OfferImgElementView>
					) : (
						<OfferNoImgElementView
							offer={offer}
							index={index}
							lang={props.lang}
						></OfferNoImgElementView>
					)}
				</div>
			</div>
		</div>
	);
};

export default OfferElementView;

import { axios } from '../../../Api/axios';
import {
	ILinkRoute,
	IPagesRoute,
} from './../../../Interfaces/IRouteAsComponent.interface';
import { IMenuList } from './../../../Interfaces/IRoute.interface';
import { IRouterResponse } from '../Interfaces/IRouterResponse.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { DictionaryModel } from '../../../Models/Dictionary.model';
import { IDictionary } from '../../../Interfaces/IDictionary.interface';

export default abstract class RouterDAO {
	private static readonly base: string = '/api/site/menu';
	private static readonly routes: string = '/api/site/routes';
	private static readonly pages: string = '/api/pages';

	public static async getMenu(): Promise<IRouterResponse> {
		const menuReq: AxiosPromise<IMenuList> = axios.getInstance().get(this.base);
		const routesReq: AxiosPromise<ILinkRoute[]> = axios
			.getInstance()
			.get(this.routes);
		const pagesReq: AxiosPromise<IPagesRoute[]> = axios
			.getInstance()
			.get(this.pages);

		const routesResp: AxiosResponse<ILinkRoute[]> = await routesReq;
		const pagesResp: AxiosResponse<IPagesRoute[]> = await pagesReq;
		pagesResp.data.map((data) => {
			return routesResp.data.push({ link: data.slug, label: 'PAGES' });
		});
		const menuResp: AxiosResponse<IMenuList> = await menuReq;
		return {
			menu: menuResp.data,
			routes: routesResp.data,
		};
	}
}
export abstract class DictionaryDAO {
	private static readonly base: string = '/api/dictionary';

	public static async getDictionary(): Promise<DictionaryModel[]> {
		const request: AxiosPromise<IDictionary[]> = axios
			.getInstance()
			.get(this.base);
		const response: AxiosResponse<IDictionary[]> = await request;
		return response.data;
	}
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IMainSliders } from '../Interfaces/IMainPage.interface';

export class MainSlidersModel {
  constructor(Sliders?: IMainSliders) {
    if (Sliders) {
      ModelConstructor(Sliders, this);
    }
  }
  slider_id: string = '';
  lang_id: string = '';
  type: string = '';
  content: string = '';
  title: string = '';
  description: string = '';
  text_position_x: string = '';
  text_position_y: string = '';
  link: string = '';
  order: string = '';
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { dateStringToDate } from '../../../Utils/DateStringToDate.util';
import {
  IOffer,
  ISingleOfferRelations,
} from './../Interfaces/IOffer.interface';

export class OfferModel {
  constructor(Offer?: IOffer) {
    if (Offer) {
      ModelConstructor(Offer, this);

      this.public_at_as_date = dateStringToDate(Offer.public_at);
    }
  }

  offer_id: string = '';
  lang_id: string = '';
  name: string = '';
  short_desc: string = '';
  image: string = '';
  inner_image: string = '';
  public_at: string = '';
  logo: string = '';
  background: string = '';
  slug: string = '';
  is_draft: string = '';
  order: string = '';
  og_desc: string = '';
  og_image: string = '';
  og_title: string = '';

  relations: ISingleOfferRelations[] = [
    {
      lang: '',
      name: '',
      news_id: '',
      slug: '',
    },
  ];
  //Added properties
  public_at_as_date: Date = new Date();
}

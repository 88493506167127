import { axios } from '../../../Api/axios';
import BikeModel from '../Model/Bike.model';
import { IBikeResponse } from './../Interfaces/IBike.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class BikesDAO {
	private static readonly base: string = '/api/bikes/';

	public static async getBike(slug: string): Promise<BikeModel> {
		const request: AxiosPromise<IBikeResponse> = axios
			.getInstance()
			.get(this.base + slug);
		const response: AxiosResponse<IBikeResponse> = await request;
		return new BikeModel(response.data);
	}

	public static async getBikeAccessories(category: string): Promise<any> {
		const request: AxiosPromise<any> = axios
			.getInstance()
			.get(
				'https://mysuzuki.hu/admin/api/bike-part-catalog/category/' +
					category +
					'?country=hu'
			);
		const response: AxiosResponse<any> = await request;
		return response.data;
	}
}

import React, { useContext } from 'react';
import '../../../../node_modules/react-owl-carousel2/lib/styles.css';
import OwlCarousel from 'react-owl-carousel2';
import './Less/BikeAccessorySection.style.css';
import AccessoryPlaceholderImg from '../../../Assets/BikePage/Suzuki-kiegeszito-default.jpg';

import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';

interface IBikeAccessiesProps {
	accessories: Array<any>;
}

interface IAccessory {
	id: number;
	description: string;
	identifier: string;
	images: [{ originalThumbnail: string }];
	name: string;
	price: string;
}

const BikeAccessories: React.FC<IBikeAccessiesProps> = (props) => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const options = {
		items: 3,
		nav: true,
		rewind: true,
		autoplay: false,
		URLhashListener: false,
		navText: ['', ''],
		dots: false,
		loop: false,
		pagination: false,
		lazyLoad: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			992: {
				items: 3,
			},
		},
	};
	if (routerContext.lang.value.id !== 'hu' || props.accessories.length === 0)
		return null;
	return (
		<div className='bike-accessories-slider'>
			<div className='eli-shadow'></div>
			<div className='container'>
				<div className='bike-accessories-slider__title'>
					<p className='lead'>Kiegészítők</p>
					<p>Válogass a motorhoz tartozó kiegészítőkből.</p>
				</div>
				<OwlCarousel options={options} className='owl-carousel'>
					{props.accessories.map((a: IAccessory) => (
						<div className='item bike-accessories-slider__item' key={a.id}>
							<div className='blueback'></div>
							<div className='whitefront'></div>
							<div className={`slide`}>
								{a.images.length > 0 ? (
									a.images.map((oi) => (
										<div className='imagebg' key={oi.originalThumbnail}>
											<div className='bike-accessories-slider__item__image'>
												<img src={oi.originalThumbnail} alt='' />
											</div>
										</div>
									))
								) : (
									<div className='imagebg'>
										<div className='bike-accessories-slider__item__image'>
											<img src={AccessoryPlaceholderImg} alt='' />
										</div>
									</div>
								)}

								<div className='bike-accessories-slider__item__text-wrapper'>
									<div className='bike-accessories-slider__item__title'>
										{a.name}
									</div>
									<div className='bike-accessories-slider__item__id'>
										<p>Cikkszám: {a.identifier}</p>
									</div>
									<div className='bike-accessories-slider__item__desc'>
										{a.description}
									</div>
									<div className='bike-accessories-slider__item__price'>
										<span>ár</span>
										<p>{a.price}</p>
									</div>
								</div>
							</div>
						</div>
					))}
				</OwlCarousel>
			</div>
		</div>
	);
};

export default BikeAccessories;

import * as React from 'react';
import './Button.css';

export interface IButtonProps {
	onClick?: () => any;
	hasContainer?: boolean;
	align?: 'left' | 'right';
	textButton?: boolean;
	linkButton?: boolean;
	fullWidth?: boolean;
	className?: string;
	disabled?: boolean;
	altColor?: boolean;
	isDark?: boolean;
	isSmall?: boolean;
	isWide?: boolean;
	style?: React.CSSProperties;
	type?: 'button' | 'submit' | 'reset';
}
/**
 * ## Button props
 * - **onClick?: () => any;** - The click event.
 * - **hasContainer?: boolean;** - Puts the button into a container div which has a width of 100%.
 * - **align?: "left"| "right";** - If hasContainer was set to true, you can set its alignment. Defaults to center.
 * - **textButton?: boolean;** -  Sets if the button should look like a text button.
 * - **linkButton?: boolean;** -  Sets if the button should be a link button. Just add a history push event to the onClick for redirection.
 * - **fullWidth?: boolean;** -  Sets if the button should take on 100% of the width.
 * - **className?: string;** -  You can add your own className to the button. (This doesn't affect the container.)
 * - **disabled?: boolean;** -  Controls whether the button is disabled or not.
 * - **altColor?: boolean;** - Turns the button into the alternative coloured version.
 * - **isWide?: boolean;** - Increases the left and right padding for the button.
 * ```
 */
export const Button: React.FC<IButtonProps> = (props): React.ReactElement => {
	const getButton = (): React.ReactElement => {
		let classString: string = `xa-button`;
		if (props.textButton) classString += ` xa-button__text-type`;
		if (props.linkButton) classString += ` xa-button__link-button`;
		if (props.fullWidth) classString += ` xa-button__full-width`;
		if (props.altColor) classString += ` xa-button__alt-color`;
		if (props.isDark) classString += ` xa-button__dark-color`;
		if (props.isSmall) classString += ` xa-button__small`;
		if (props.isWide) classString += ` xa-button__wide-button`;
		if (props.className) classString += ` ${props.className}`;

		return (
			<button
				style={props.style}
				onClick={props.onClick}
				disabled={props.disabled}
				className={classString}
				type={props.type}
			>
				{props.children}
			</button>
		);
	};

	if (props.hasContainer) {
		let containerClasses: string[] = ['button-wrapper'];
		if (props.align) containerClasses.push(`button-wrapper--${props.align}`);

		return <div className={containerClasses.join(' ')}>{getButton()}</div>;
	}

	return getButton();
};

export default Button;

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { VideoModel } from '../Model/Video.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import VideoView from '../View/Video.view';
import VideoDAO from '../DAO/Video.dao';
import { GetLocaleText } from '../../../I18n';
import { VideoTypesModel } from '../Model/VideoTypes.model';

export interface IVideoProps extends RouteChildrenProps {}

const Video: React.FC<IVideoProps> = (props): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const all_title: string = GetLocaleText('all_text');
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [loading_load_more, set_loading_load_more] = useState(false);
	const [videos, set_videos] = useState<VideoModel[]>([]);
	const [filter_state, set_filter_state] = useState('0');
	const [page_state, set_page_state] = useState(9);
	const [active_selector, set_active_selector] = useState(0);
	const [load_more, set_load_more] = useState(true);
	const [video_types, set_video_types] = useState<VideoTypesModel[]>([]);
	const [error, set_error] = useState<IError>(null);

	const filter = (id: string): void => {
		set_filter_state(id);
	};
	const page = (): void => {
		set_page_state(page_state + 9);
		set_loading_load_more(true);
	};

	const loadVideo = (page: number): void => {
		set_loading(
			VideoDAO.getVideo({ qty: page })
				.then((videos: VideoModel[]) => {
					set_videos(videos);
					if (page !== videos.length) {
						set_load_more(false);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
					set_loading_load_more(false);
				})
		);
	};
	const loadVideoByType = (type_id: string, page: number): void => {
		set_loading(
			VideoDAO.getVideoByType(type_id, { qty: page })
				.then((videos: VideoModel[]) => {
					set_videos(videos);
					if (page !== videos.length) {
						set_load_more(false);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const loadVideoTypes = (): void => {
		set_loading(
			VideoDAO.getVideoTypes()
				.then((video_types: VideoTypesModel[]) => {
					const all_types: VideoTypesModel = {
						video_type_id: '0',
						lang: routerContext.lang.value.name,
						name: all_title,
					};
					video_types.unshift(all_types);
					set_video_types(video_types);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};
	useEffect(
		() => {
			set_active_selector(0);
			set_load_more(true);
			loadVideoTypes();
			set_page_state(9);
			return () => {};
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);
	useEffect(
		() => {
			set_active_selector(+filter_state);
			set_load_more(true);
			set_page_state(9);
			if (+filter_state !== 0) {
				loadVideoByType(filter_state, 9);
			} else if (+filter_state === 0) {
				loadVideo(9);
			} else {
				set_page_state(9);
			}
		},

		//eslint-disable-next-line
		[filter_state]
	);

	useEffect(
		() => {
			if (page_state === 9) return;
			if (filter_state === '0' || filter_state === '') {
				loadVideo(page_state);
			} else {
				loadVideoByType(filter_state, page_state);
			}
		},
		//eslint-disable-next-line
		[page_state]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{GetLocaleText('video_menu')}</div>
			</div>
			<div className='video-wrapper-layout'>
				<div className='video-container container'>
					<VideoView
						loading={loading}
						videos={videos}
						filter={filter}
						video_types={video_types}
						loading_load_more={loading_load_more}
						page={page}
						error={error}
						load_more={load_more}
						active={active_selector}
					/>
				</div>
			</div>
		</div>
	);
};

export default Video;

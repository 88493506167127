import React from 'react';
import './Less/OffersSlider.css';
import { OfferModel } from '../../Offers/Model/Offer.model';
import MoreOffersView from '../../Offers/View/MoreOffers.view';

export interface IOffersSliderViewProps {
  offers: OfferModel[];
  offers_slug: string;
  loading: Promise<any> | null;
}

const getImagePositions = (offer: OfferModel[]): Array<string> => {
  let returner: Array<string> = [];
  let indexer: number = 1;
  //eslint-disable-next-line
  offer.map((o) => {
    if (o.image !== '') {
      indexer++;
    }
    o.image === ''
      ? returner.push('')
      : indexer % 2 === 0
      ? returner.push('left')
      : returner.push('right');
  });
  return returner;
};

const OffersSliderView: React.FC<IOffersSliderViewProps> = (
  props
): React.ReactElement => {
  const imageProps = getImagePositions(props.offers);

  return (
    <div>
      {props.loading === null && props.offers.length > 0 ? (
        <MoreOffersView
          loading={props.loading}
          imageProps={imageProps}
          offers={props.offers}
        ></MoreOffersView>
      ) : (
        ''
      )}
    </div>
  );
};

export default OffersSliderView;

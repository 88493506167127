import React from 'react';
import { FinancingBikePriceModel } from '../Model/FinancingBikePrice.model';
import { formatPrice } from '../../../Utils/FormatStringToCurrency.util';
import { FinancingPricesModel } from '../Model/FinancingPrices.model';
import './Less/Calculated.css';
import { GetLocaleText } from '../../../I18n';
import Img from 'react-image';
import FinishingForm from './FinishingForm.view';
import { ResellersModel } from '../../Resellers/Model/Resellers.model';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface ICalculatedPageViewProps {
	bike_price: FinancingBikePriceModel;
	bike_props: FinancingPricesModel;
	resellers: ResellersModel[];
	finish: (
		slug: string,
		price_own_part: number,
		duration: number,
		casco: number,
		name: string,
		city: string,
		email: string,
		tel: string,
		reseller_id: number | null
	) => void;
	form_sent: boolean;
}

const CalculatedView: React.FC<ICalculatedPageViewProps> = (
	props
): React.ReactElement => {
	const financing_status_one = GetLocaleText('financing_status_one');
	const financing_status_two = GetLocaleText('financing_status_two');
	const financing_status_three = GetLocaleText('financing_status_three');
	window.scrollTo(0, 0);
	const [finishing, set_finishing] = React.useState(0);
	return finishing === 1 || props.form_sent === true ? (
		<FinishingForm
			bike_props={props.bike_props}
			resellers={props.resellers}
			bike_price={props.bike_price}
			finish={props.finish}
			form_sent={props.form_sent}
		/>
	) : (
		<div className='financing__calculated-view container'>
			<div className='status-bar'>
				<div className='item active'>
					<div className='counter'>1.</div>
					<div className='text'>{financing_status_one}</div>
				</div>
				<div className='item active'>
					<div className='counter'>2.</div>
					<div className='text'>{financing_status_two}</div>
				</div>
				<div className='item'>
					<div className='counter'>3.</div>
					<div className='text'>{financing_status_three}</div>
				</div>
			</div>
			<div className='financing__calculated-view__content'>
				<div className='bike-data'>
					<div className='bike-data-logo'>
						<Img
							src={props.bike_props.logo}
							alt={generateImgAlt(props.bike_props.logo)}
						></Img>
					</div>
					<div className='bike-data-image-and-price'>
						<div className='bike-image'>
							<Img
								src={props.bike_props.image}
								alt={generateImgAlt(props.bike_props.image)}
							></Img>
						</div>
						<div className='bike-price'>
							{formatPrice(+props.bike_props.calculator_price / 100 + '')}
						</div>
					</div>
				</div>
				<div className='calculation-result'>
					<div className='calculation-result__monthly-price item'>
						<p>{GetLocaleText('financing_calculate_result_monthly')}</p>
						<p>{formatPrice(props.bike_price.monthly_price / 100 + '')}</p>
					</div>
					<div className='calculation-result__duration item'>
						<p>{GetLocaleText('financing_calculate_result_duration')}</p>
						<p>
							{props.bike_price.duration}{' '}
							{GetLocaleText('financing_duration-value')}
						</p>
					</div>
					<div className='calculation-result__casco item'>
						<p>{GetLocaleText('financing_calculate_result_casco')}</p>
						<p>
							{props.bike_price.casco === false
								? GetLocaleText('without_casco')
								: GetLocaleText('with_casco')}
						</p>
					</div>
					<div className='calculation-result__total item'>
						<p>{GetLocaleText('financing_calculate_result_total')}</p>
						<p>
							{formatPrice(props.bike_price.total_amount_refunded / 100 + '')}
						</p>
					</div>
					<div className='calculation-result__financed-amount item'>
						<p>{GetLocaleText('financing_calculate_result_financed')}</p>
						<p>{formatPrice(props.bike_price.amount_financed / 100 + '')}</p>
					</div>
					<div className='calculation-result__public-rate item'>
						<p>{GetLocaleText('financing_calculate_result_public-rate')}</p>
						<p>{props.bike_price.public_rate / 100}%</p>
					</div>
					<div className='calculation-result__thm item'>
						<p>{GetLocaleText('financing_calculate_result_apr')}</p>{' '}
						<p>{props.bike_price.apr / 100}%</p>
					</div>
					<div className='calculation-result__interest_amount item'>
						<p>{GetLocaleText('financing_interest_amount')}</p>
						<p>
							{formatPrice(props.bike_price.total_interest_amount / 100 + '')}
						</p>
					</div>
				</div>
			</div>
			<div className='buttons'>
				<button
					className='button'
					onClick={() => {
						return window.location.reload(false);
					}}
				>
					{GetLocaleText('financing_calculate_button_new')}
				</button>
				<button
					className='button inverse'
					onClick={() => {
						return set_finishing(1);
					}}
				>
					{GetLocaleText('financing_calculate_button_finish')}
				</button>
			</div>
		</div>
	);
};

export default CalculatedView;

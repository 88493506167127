import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IFinancingPrices } from '../Interfaces/IFinancing.interface';

export class FinancingPricesModel {
  constructor(FinancingPrices?: IFinancingPrices) {
    if (FinancingPrices) {
      ModelConstructor(FinancingPrices, this);
    }
  }

  bike_id: string = '';
  type_id: string = '';
  name: string = '';
  slug: string = '';
  year: string = '';
  logo: string = '';
  image: string = '';
  calculator_price: string = '';
  apr: string = '';
}

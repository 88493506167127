import React from 'react';
import './Less/FirstOffers.css';
import { GetLocaleText } from '../../../I18n';
import Img from 'react-image';
import { MainOffersModel } from '../Model/MainOffers.model';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { strictOnDomain } from '../../../Utils/StrictOnDomain.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';

export interface IFirstOffersViewProps {
	offers: MainOffersModel[];
	context: IRouterContext;
}

const FirstOffersView: React.FC<IFirstOffersViewProps> = (
	props
): React.ReactElement => {
	return (
		<div className='first-offers-item'>
			<div className='images'>
				<div className='title'>
					<p data-text={GetLocaleText('main_first_offers')}>
						{GetLocaleText('main_first_offers')}
					</p>
				</div>
				{props.offers.map((offer) => {
					return (
						<a
							href={offer.url}
							className={`item${
								strictOnDomain(['cz'], props.context) ? ' item--too_long' : ''
							}`}
							key={`offeritem${offer.element_id}`}
						>
							<Img
								src={offer.background}
								alt={generateImgAlt(offer.background)}
							/>
							<div className='text'>{offer.title}</div>
							<div className='text__under'>{offer.title}</div>
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default FirstOffersView;

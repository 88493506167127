export const generateImgAlt = (img_name: string): string => {
  const fileNumber: number = img_name.lastIndexOf('/') + 1;
  const fileName: string = img_name.substr(fileNumber);
  const withoutExtension: string = fileName.split('.')[0];
  let splittedString: string[] = withoutExtension.split('-');
  splittedString = splittedString.map((text) => {
    return text.toLowerCase();
  });
  let returnString = splittedString.join(' ');
  returnString = returnString.charAt(0).toUpperCase() + returnString.slice(1);
  return returnString;
};

import React, { FormEvent } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { TestDriveBikesModel } from '../Model/TestDriveBikes.model';
import './Less/TestDrive.css';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import { TestDriveResellersModel } from '../Model/TestDriveResellers.model';
import { GetLocaleText } from '../../../I18n';
import BG from '../../../Assets/testdrive_bg.jpg';
import HTMLParser from 'html-react-parser';
import ReCAPTCHA from 'react-google-recaptcha';
import FormSentView from './FormSent.view';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';

export interface ITestDriveViewProps {
	loading: Promise<any> | null;
	bikes: TestDriveBikesModel[];
	resellers: TestDriveResellersModel[];
	error: IError;
	onSubmit: (e: React.FormEvent) => void;
	bike_selected: number;
	bike_change: (bike_id: number) => void;
	reseller_change: (reseller_id: number) => void;
	name_change: (e: any) => void;
	place_change: (e: any) => void;
	email_change: (e: any) => void;
	tel_change: (e: any) => void;
	form_sent: boolean;
}

const TestDriveView: React.FC<ITestDriveViewProps> = (
	props
): React.ReactElement => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null) return <SplashScreen opacity='1' />;
	const handleBikeChange = (event: any) => {
		props.bike_change(event.target.value);
	};
	const handleResellerChange = (event: any) => {
		props.reseller_change(event.target.value);
	};
	const handleSubmit = (e: FormEvent): void => {
		props.onSubmit(e);
	};
	return (
		<div className='testdrive-view' style={{ backgroundImage: `url(${BG})` }}>
			{props.form_sent ? (
				<FormSentView></FormSentView>
			) : (
				<form onSubmit={handleSubmit}>
					<div className='container'>
						<div className='testdrive-view__start-text'>
							{GetLocaleText('testdrive_start')}
						</div>
						<div className='testdrive-view__form-top'>
							<div className='testdrive-view__form-top--text'>
								{GetLocaleText('testdrive_form_top_text')}
							</div>
							<div className='testdrive-view__form-top--row row'>
								<div className='col-lg-6 col-xs-12 col-md-6 select-option select-option__bike'>
									<select
										name='bike'
										id='bike-select'
										onChange={handleBikeChange}
										value={props.bike_selected}
										required
										aria-required='true'
									>
										<option aria-selected value=''>
											{GetLocaleText('testdrive_bike')}
										</option>
										{props.bikes.map((bike) => {
											return (
												<option
													key={`bike-id-${bike.bike_id}`}
													value={bike.bike_id}
												>
													{bike.name}
												</option>
											);
										})}
									</select>
								</div>
								<div className='col-lg-6 col-xs-12 col-md-6 select-option select-option__resellers'>
									<select
										name='reseller'
										id='bike-resellers'
										onChange={handleResellerChange}
										required
										aria-required='true'
									>
										<option aria-selected value=''>
											{GetLocaleText('testdrive_reseller')}
										</option>
										{props.resellers.map((reseller) => {
											return reseller ? (
												<option
													key={`reseller_${reseller.reseller_id}`}
													value={reseller.reseller_id}
												>
													{reseller.city} - {reseller.name}
												</option>
											) : (
												''
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className='testdrive-view__form-bottom'>
							<div className='testdrive-view__form-bottom--text'>
								{GetLocaleText('testdrive_form_bottom_text')}
							</div>
							<div className='testdrive-view__form-bottom--row row'>
								<div className='col-lg-6 col-xs-12 col-md-6'>
									<input
										type='text'
										name='name'
										id='name'
										onChange={props.name_change}
										required
										placeholder={GetLocaleText('testdrive_name')}
									/>
								</div>
								<div className='col-lg-6 col-xs-12 col-md-6'>
									<input
										type='text'
										name='place'
										id='place'
										onChange={props.place_change}
										required
										placeholder={GetLocaleText('testdrive_place')}
									/>
								</div>
								<div className='col-lg-6 col-xs-12 col-md-6 '>
									<input
										type='email'
										name='email'
										id='email'
										onChange={props.email_change}
										required
										placeholder={GetLocaleText('testdrive_email')}
									/>
								</div>
								<div className='col-lg-6 col-xs-12 col-md-6'>
									<input
										type='tel'
										name='tel'
										id='tel'
										onChange={props.tel_change}
										required
										placeholder={GetLocaleText('testdrive_tel')}
									/>
								</div>
								<div className='col-lg-12 col-xs-12 col-md-12 checkbox'>
									<label>
										{HTMLParser(GetLocaleText('testdrive_acceptance'))}
										<input name='acceptance' type='checkbox' required />
									</label>
									<div className='recaptcha'>
										<ReCAPTCHA
											size='invisible'
											sitekey='6LdObeYUAAAAAIrBvZXWm25JKNB9af7sEGMPXnIe'
										/>
									</div>
									<button className='submit'>
										{GetLocaleText('testdrive_submit')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</div>
	);
};

export default TestDriveView;

export const ro = {
	page_id: 0,
	lang_id: 1,
	name: 'Cookie Details Page',
	slug: 'cookie',
	content: `<div class="custompage-view-cookie">
  <div class="custompage-view-cookie__content"><h1 class="mainhead__title"><span style="font-family: SuzukiHeadline; font-size: 60px;">Politica de Cookie</span></h1>
<h2><span style="font-family: SuzukiBold;">Ce este un cookie?</span></h2>
<p>Un cookie este un mic pachet de date, pe care serviciile web &icirc;l stochează &icirc;n browserul dvs. web. Această tehnologie este esențială pentru buna funcționare a multor servicii web și este susținută de toate browserele importante din 1995.</p>
<h2><span style="font-family: SuzukiBold;">De ce este bine pentru utilizator?</span></h2>
<p>Multe servicii web depind de cookie-uri pentru funcționarea corectă și confortul utilizatorului. Cookie-urile vă permit, de exemplu, să vă conectați cu ușurință la site-uri web protejate prin parolă sau să accesați conținut personalizat de pe web.</p>
<h2><span style="font-family: SuzukiBold;">Cookie-uri pe site-urile web și &icirc;n serviciile online ale Magyar Suzuki Corporation.</span></h2>
<p>Pentru a asigura funcționarea de cea mai &icirc;naltă calitate a site-urilor noastre web și cea mai bună experiență de utilizare posibilă, folosim cookie-uri pe site-urile web și &icirc;n serviciile Magyar Suzuki Corporation. Puteți șterge cookie-urile de pe computerul dvs. &icirc;n orice moment și puteți configura browserul pentru a le dezactiva. Cu toate acestea, prin dezactivarea cookie-urilor, recunoașteți că fără cookie-urile site-ul web nu va fi pe deplin operațional. Pentru mai multe informații despre cookie-uri, vă rugăm să consultați Politica de confidențialitate, la care puteți accesa făc&acirc;nd clic aici.</p>
<h2><span style="font-family: SuzukiBold;">Cookie-uri plasate de terți.</span></h2>
<p>Site-urile noastre web pot conține astfel de informații care provin de la terți sau furnizori de publicitate, care nu au legătură cu operatorul. Aceste terțe părți pot, de asemenea, să plaseze cookie-uri și semnalizatoare web pe computerul utilizatorului sau pot utiliza tehnologii similare pentru a colecta date pentru a trimite reclame direcționate către utilizator &icirc;n legătură cu propriile servicii. &Icirc;n astfel de cazuri, prelucrarea datelor este guvernată de politicile de confidențialitate stabilite de acești terți.</p>
<h2><span style="font-family: SuzukiBold;">Setările cookie-urilor din browsere.</span></h2>
<p>Cookie-urile pot fi configurate manual de către utilizator. Toate cookie-urile stocate pot fi eliminate, iar stocarea lor poate fi dezactivată cu setările corecte. Puteți configura aceste setări urm&acirc;nd instrucțiunile furnizate pe linkurile de mai jos &icirc;n funcție de tipul de browser.</p>
<p><strong>Microsoft Internet Explorer</strong><br /><a href="hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener">hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a><br /><strong>Microsoft Egde</strong><br /><a href="https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener">https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy</a><br /><strong>Firefox</strong><br /><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a><br /><strong>Chrome</strong><br /><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en" target="_blank" rel="noopener">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en</a><br /><strong>Safari</strong><br /><a href="https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac">https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac</a><br /><strong>IOS Safari</strong><br /><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener">https://support.apple.com/en-us/HT201265</a></p>
<h2><span lang="hu-HU" style="font-family: SuzukiBold;">Cookie-uri funcționale:</span></h2>
<p lang="en-GB" align="justify">Fără aceste cookie-uri, site-ul web nu va funcționa corect. Nu pot fi dezactivate și, de obicei, sunt declanșate pe baza interacțiunilor dvs. cu site-ul web, cum ar fi setarea opțiunilor de confidențialitate, conectarea sau completarea formularelor.</p>
<table style="width: 99.9318%;" border="1px" cellspacing="33.33%">
<thead>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p>Name</p>
</td>
<td style="text-align: center; width: 71.0593%;">
<p>Descriere</p>
</td>
<td style="width: 17.3626%; text-align: center;">
<p>Perioada de stocare</p>
</td>
</tr>
</thead>
<tbody>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">lang</span></p>
</td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Cookie-uri necesare pentru a schimba limba paginii.</span></p>
</td>
<td style="width: 17.3626%; text-align: center;">
<p><span style="font-size: 16px;">La inchiderea browserului</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">CookieConsent</span></p>
</td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Folosit pentru a monitoriza dacă utilizatorul a acceptat deja utilizarea cookie-urilor de pe site.</span></p>
</td>
<td style="width: 17.3626%; text-align: center;">
<p><span style="font-size: 16px;">1 Ani</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;"><span style="font-size: 16px;">rc::a</span></td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Acest cookie este utilizat pentru a distinge &icirc;ntre oameni și roboți. Acest lucru este benefic pentru site-ul web, pentru a face rapoarte valabile cu privire la utilizarea site-ului lor web.</span></p>
</td>
<td style="width: 17.3626%; text-align: center;">
<p><span style="font-size: 16px;">La inchiderea browserului</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;"><span style="font-size: 16px;">rc::c</span></td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Acest cookie este utilizat pentru a distinge &icirc;ntre oameni și roboți.</span></p>
</td>
<td style="width: 17.3626%; text-align: center;">
<p><span style="font-size: 16px;">La inchiderea browserului</span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;"><span style="font-size: 16px;">secondLevelRoute</span></td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">C&acirc;nd schimbați limba, stocheaza adresa linkului curent.</span></p>
</td>
<td style="width: 17.3626%; text-align: center;">
<p><span style="font-size: 16px;">La inchiderea browserului</span></p>
</td>
</tr>
</tbody>
</table>
<h2 lang="en-GB" align="justify"><span lang="hu-HU"><span style="font-family: SuzukiBold;"><br />Cookie-uri de performanță:</span></span></h2>
<p lang="en-GB" align="justify"><span lang="hu-HU">Acestea sunt utilizate pentru a monitoriza funcționarea unui browser pentru a oferi ajutor &icirc;n &icirc;mbunătățirea informațiilor și serviciilor.</span></p>
<table style="width: 100%;" border="1px" cellspacing="33.33%">
<tbody>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">Name</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p>Descriere</p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p>Perioada de stocare</p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">.ga</span></p>
</td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Acest cookie asistă Google Analytics &icirc;n funcționarea sa. Folosit pentru a distinge utilizatorii prin atribuirea unui număr de identificare aleatoriu fiecărui utilizator. Acest cookie este prezent &icirc;n toate solicitările de pagină pentru site-ul web și este utilizat pentru a număra vizitatorii, sesiunile și datele campaniei pentru analiza site-ului.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">2 Ani</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">_gid</span></p>
</td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Acest cookie asistă Google Analytics &icirc;n funcționarea sa. Folosit pentru a distinge utilizatorii.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">24 Ore</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">_gat</span></p>
</td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Acest cookie asistă Google Analytics &icirc;n funcționarea sa. Folosit pentru a limita rata solicitării, adică limitează colectarea datelor pe site-urile cu trafic mare.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">1 Minute</span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;"><span style="font-size: 16px;">collect</span></td>
<td style="text-align: center;">
<p><span style="font-size: 16px;">Folosit pentru a trimite date către Google Analytics despre dispozitivul și comportamentul vizitatorului. &nbsp;Urmărește vizitatorul pe diferite dispozitive și canale de marketing.</span></p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">La inchiderea browserului</span></p>
</td>
</tr>
</tbody>
</table>
<h2><span lang="hu-HU" style="font-family: SuzukiBold;"><br />Cookie-uri terță parte:</span></h2>
<p><span lang="hu-HU">Aceste cookie-uri sunt utilizate de conținut - cum ar fi postările de pe rețelele sociale, hărți sau videoclipuri - &icirc;ncorporate pe site.</span></p>
<table style="width: 100%;" border="1px" cellspacing="33.33%">
<tbody>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">Name</span></p>
</td>
<td style="text-align: center;">
<p>Descriere</p>
</td>
<td style="width: 10.8127%; text-align: center;">
<p>Perioada de stocare</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">NID</span></p>
</td>
<td style="text-align: center;"><span style="font-size: 16px;">Cookie-ul NID conține un ID unic pe care Google &icirc;l folosește pentru a vă aminti preferințele - cum ar fi limba preferată (de exemplu, romană), c&acirc;te rezultate de căutare doriți să afiseze (de exemplu, 10 sau 20) și dacă doriți sau nu să aveți Google Filtrul Sigur de Căutare &nbsp; activat și, de asemenea, pentru a stoca celelalte informații.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">6 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">IDE</span></p>
</td>
<td style="text-align: center;"><span style="font-size: 16px;">&bdquo;Acest cookie este utilizat pentru a re-targeta, optimiza, raporta și aloca reclame online.&rdquo;</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">2 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">test_cookie</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Folosit pentru a verifica dacă browserul utilizatorului acceptă cookie-uri.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">1 Zi</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">fr</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Folosit de Facebook pentru a livra o serie de produse publicitare, cum ar fi licitarea &icirc;n timp real de la agenții de publicitate terți.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">3 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">tr</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Folosit de Facebook pentru a livra o serie de produse publicitare, cum ar fi licitarea &icirc;n timp real de la agenții de publicitate terți.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">_fbp</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Folosit de Facebook pentru a livra o serie de produse publicitare, cum ar fi licitarea &icirc;n timp real de la agenții de publicitate terți.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">3 months</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">GPS</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">&Icirc;nregistrează un ID unic pe dispozitivele mobile pentru a permite urmărirea pe baza locației geografice GPS.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">1 Zi</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">VISITOR_INFO1_LIVE</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">&Icirc;ncearcă să estimeze lățimea de bandă a utilizatorilor pe paginile cu videoclipuri YouTube integrate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">179 Zile</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">YSC</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">&Icirc;nregistrează un ID unic pentru a păstra statistici despre videoclipurile de pe YouTube pe care le-a văzut utilizatorul.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">yt-remote-cast-installed</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Stochează preferințele playerului video ale utilizatorului utiliz&acirc;nd videoclipuri YouTube &icirc;ncorporate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">yt-remote-connected-devices</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Stochează preferințele playerului video ale utilizatorului utiliz&acirc;nd videoclipuri YouTube &icirc;ncorporate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Presistent</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">yt-remote-device-id</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Stochează preferințele playerului video ale utilizatorului utiliz&acirc;nd videoclipuri YouTube &icirc;ncorporate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Presistent</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">yt-remote-fast-check-period</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Stochează preferințele playerului video ale utilizatorului utiliz&acirc;nd videoclipuri YouTube &icirc;ncorporate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Sesiune</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">yt-remote-session-app</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Stochează preferințele playerului video ale utilizatorului utiliz&acirc;nd videoclipuri YouTube &icirc;ncorporate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Sesiune</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;"><span style="font-size: 16px;">yt-remote-session-name</span></td>
<td style="text-align: center;"><span style="font-size: 16px;">Stochează preferințele playerului video ale utilizatorului utiliz&acirc;nd videoclipuri YouTube &icirc;ncorporate.</span></td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Sesiune</span></p>
</td>
</tr>
</tbody>
</table>
<p style="text-align: center;"><br />Lucrăm continuu la &icirc;mbunătățirea site-urilor noastre web și a serviciilor digitale. &Icirc;n acest scop, folosim Google Analytics pentru a analiza datele comportamentale agregate și anonimizate ale utilizatorilor noștri.<br />Mai mult, permitem și instrumentului nostru de analiză web Google Analytics să gestioneze cookie-uri și să plaseze cookie-uri pe acest site web. Folosim statistici obținute &icirc;n acest mod pentru a &icirc;mbunătăți experiența utilizatorului. Pentru mai multe informații, vă rugăm să contactați responsabilul cu protecția datelor la&nbsp; <a title="privacy@suzuki.hu" href="mailto:privacy@suzuki.hu">privacy@suzuki.hu</a></p>`,
};

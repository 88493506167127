import React, { CSSProperties } from "react";
import OwlCarousel from "react-owl-carousel2";
import { generateImgAlt } from "../../../Utils/GenerateImageAlt.util";
import { BlockElementType, TextSliderBlockElement } from "../Model/Bike.model";
import HTMLParser from "html-react-parser";
import "./Less/TextSliderSection.style.css";

export interface ITextSliderSectionProps {
    elements: BlockElementType[];
    hookID: number;
}

export interface ITextSliderSectionState {}

class TextSliderSection extends React.Component<ITextSliderSectionProps, ITextSliderSectionState> {
    getAlignItems: (position: string) => string = (position) => {
        let value: string = "";

        const positionY: any = {
            TOP: "flex-start",
            BOTTOM: "flex-end",
            CENTER: "center"
        };

        value = positionY[position];

        return value;
    };

    getJustifyContent: (position: string) => string = (position) => {
        let value: string = "";

        const positionX: any = {
            RIGHT: "flex-end",
            LEFT: "flex-start",
            CENTER: "center"
        };

        value = positionX[position];

        return value;
    };

    getTextAlign: (position: string) => "right" | "left" | "center" = (position) => {
        let value: "right" | "left" | "center" = "left";

        const positionX: any = {
            RIGHT: "right",
            LEFT: "left",
            CENTER: "center"
        };

        value = positionX[position];

        return value;
    };

    render() {
        const isNav: boolean = this.props.elements.length > 1 ? true : false;
        return (
            <div className="bike-text-slider-component" id={`BLOCK_${this.props.hookID}`}>
                <OwlCarousel
                    options={{
                        items: 1,
                        nav: isNav,
                        loop: isNav,
                        mouseDrag: isNav,
                        touchDrag: isNav,
                        autoplay: false,
                        URLhashListener: false,
                        navElement: "div",
                        navText: ["", ""],
                        dotsData: false,
                        dotData: false,
                        pagination: false
                    }}
                >
                    {this.props.elements.map((slider, index: number) => {
                        const block = slider as TextSliderBlockElement;

                        let flexPosition = {
                            justifyContent: "",
                            alignItems: ""
                        };

                        let descStyle: CSSProperties = {
                            textAlign: "left"
                        };

                        let titleStyle: any = {};

                        flexPosition.justifyContent = this.getAlignItems(block.textPositionY);
                        flexPosition.alignItems = this.getJustifyContent(block.textPositionX);
                        descStyle.textAlign = this.getTextAlign(block.textPositionX);
                        titleStyle.textAlign = this.getTextAlign(block.textPositionX);

                        return (
                            <div
                                className="bike-text-slider-item"
                                key={`${index}-owl-bike-text-slider`}
                            >
                                <img
                                    src={block.background}
                                    alt={generateImgAlt(block.background)}
                                    className="bike-text-slider-item__background-image"
                                />
                                <div className="bike-text-wrapper" style={flexPosition}>
                                    <div className="bike-text-slider-content-container">
                                        <div
                                            className="bike-text-slider-content-container__title"
                                            style={titleStyle}
                                        >
                                            {HTMLParser(block.title)}
                                        </div>
                                        <div
                                            className="bike-text-slider-content-container__desc"
                                            style={descStyle}
                                        >
                                            {HTMLParser(block.content)}
                                        </div>
                                        {block.isButton() && (
                                            <div
                                                className="bike-text-slider-content-container__button"
                                                style={flexPosition}
                                            >
                                                <a
                                                    target="_blank"
                                                    href={block.btn_link}
                                                    rel="noreferrer noopener"
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor: `#${block.btn_bg_color}`,
                                                            color: `#${block.btn_text_color}`
                                                        }}
                                                    >
                                                        {block.btn_text}
                                                    </button>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </OwlCarousel>
            </div>
        );
    }
}

export default TextSliderSection;

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { MainSlidersModel } from '../Model/MainSliders.model';
import { MainNewsModel } from '../Model/MainNews.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import MainPageView from '../View/MainPage.view';
import MainPageDAO from '../DAO/MainPage.DAO';
import { MainNewsTypesModel } from '../Model/MainNewsType.model';
import { MainOffersModel } from '../Model/MainOffers.model';
import OfferDAO from '../../Offers/DAO/Offer.dao';
import { OfferModel } from '../../Offers/Model/Offer.model';

export interface IOwnersProps extends RouteChildrenProps {}

const MainPageController: React.FC<IOwnersProps> = (
  props
): React.ReactElement => {
  const [loading, set_loading] = useState<Promise<any> | null>(null);
  const [sliders, set_sliders] = useState<MainSlidersModel[]>([new MainSlidersModel()]);
  const [offers, set_offers] = useState<MainOffersModel[]>([new MainOffersModel()]);
  const [offers_slider, set_offers_slider] = useState<OfferModel[]>([new OfferModel()]);
  const [news, set_news] = useState<MainNewsModel[]>([new MainNewsModel()]);
  const [error, set_error] = useState<IError>(null);
  const [news_types, set_news_types] = useState<MainNewsTypesModel[]>([new MainNewsTypesModel()]);

  const routerContext: IRouterContext = useContext(RouterContext);

  const loadSliders = (): void => {
    set_loading(
      MainPageDAO.getSliders()
        .then((sliders: MainSlidersModel[]) => set_sliders(sliders))
        .catch((error: IError) => set_error(error))
        .finally(() => {
          loadNewsTypes();
        })
    );
  };

  const loadOffersSlider = (): void => {
    set_loading(
      OfferDAO.getOffers()
        .then((offers: OfferModel[]) => filterOffersSliderDrafts(offers))
        .catch((error: IError) => set_error(error))
        .finally(() => set_loading(null))
    );
  };
  const filterOffersSliderDrafts = (full_list: OfferModel[]): void => {
    let removedDraftsOffers: OfferModel[] = [];
    full_list.map((item) => {
      if (item.is_draft === '0') {
        return removedDraftsOffers.push(item);
      }
      return '';
    });
    set_offers_slider(removedDraftsOffers);
  };

  const loadOffers = (): void => {
    set_loading(
      MainPageDAO.getOffers()
        .then((offers: MainOffersModel[]) => set_offers(offers))
        .catch((error: IError) => set_error(error))
        .finally(() => {
          loadOffersSlider();
        })
    );
  };

  const loadNews = (): void => {
    set_loading(
      MainPageDAO.getNews()
        .then((news: MainNewsModel[]) => {
          filterDrafts(news);
        })
        .catch((error: IError) => set_error(error))
        .finally(() => {
          loadOffers();
        })
    );
  };

  const filterDrafts = (full_list: MainNewsModel[]): void => {
    if(full_list.length===0){
      set_news([new MainNewsModel()]);
    } else {
      let removedDraftsNews: MainNewsModel[] = [];
      full_list.map((item) => {
        if (item.is_draft === '0') {
          return removedDraftsNews.push(item);
        } else return '';
      });
      getCompatibleSizeNews(removedDraftsNews);
    }
  };

  const getCompatibleSizeNews = (removedDraftsNews: MainNewsModel[]): void => {
    const compatibleSizeWide: MainNewsModel[] = [];
    const compatibleSizes: MainNewsModel[] = [];
    let compatibleSizeNormal: MainNewsModel = new MainNewsModel();
    let wideCounter = 0;
    let normalCounter = 0;
    removedDraftsNews.map((item) => {
      if (item.image_ratio === 'WIDE' && wideCounter < 2) {
        compatibleSizeWide.push(item);
        wideCounter++;
      } else if (item.image_ratio === 'NORMAL' && normalCounter < 1) {
        compatibleSizeNormal = item;
        normalCounter++;
      }
      return '';
    });
    if(compatibleSizeWide[0] && compatibleSizeWide[1] && compatibleSizeNormal){
      compatibleSizes.push(compatibleSizeWide[0]);
      compatibleSizes.push(compatibleSizeNormal);
      compatibleSizes.push(compatibleSizeWide[1]);
      set_news(compatibleSizes);
    }
  };

  const loadNewsTypes = (): void => {
    set_loading(
      MainPageDAO.getNewsTypes()
        .then((news_types: MainNewsTypesModel[]) => {
          news_types.length !== 0 ?
          set_news_types(news_types) :
          set_news_types([new MainNewsTypesModel()])
        })
        .catch((error: IError) => set_error(error))
        .finally(() => {
          loadNews();
        })
    );
  };

  useEffect(
    () => {
      loadSliders();
    },
    //eslint-disable-next-line
    [routerContext.lang]
  );

  return (
    <MainPageView
      loading={loading}
      sliders={sliders}
      offers={offers}
      error={error}
      news={news}
      offers_slider={offers_slider}
      news_types={news_types}
    />
  );
};

export default MainPageController;

import { IBikeDetailsResponse } from './../../Bike/Interfaces/IBike.interface';
import { axios } from '../../../Api/axios';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class ExportFinancingDAO {
	private static readonly bikeByType: string = '/api/bikes/types/';
	private static readonly mail: string =
		'http://localhost:5000/api/v1/financial';

	public static async getBikeByType(
		type: string | undefined
	): Promise<IBikeDetailsResponse[]> {
		const request: AxiosPromise<
			IBikeDetailsResponse[]
		> = axios.getInstance().get(`${this.bikeByType}${type}/bikes`);
		const response: AxiosResponse<IBikeDetailsResponse[]> = await request;
		return response.data;
	}

	public static async sendMail(data: FormData): Promise<any> {
		const request: AxiosPromise<any> = axios
			.getInstance()
			.post(`${this.mail}/en`, data);
		const response: AxiosResponse<any> = await request;
		return response.data;
	}
}

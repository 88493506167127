import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import {GetLocaleText} from "../../../I18n";

interface IBikeModalViewProps {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	manual: string;
}

const BikeModalView: React.FC<IBikeModalViewProps> = (props) => {
	const [checkboxValue, setCheckboxValue] = useState(false);
	Modal.setAppElement('body');
	const history = useHistory();
	useEffect(() => {
		if (Cookies.get('manual_ok') === 'true')
			history.push(`/manual/${props.manual}`);
		//eslint-disable-next-line
	}, []);
	return (
		<div>
			<Modal isOpen={true} contentLabel='Felhasználási feltételek'>
				<p style={{ marginTop: '2rem' }}></p>
				<p
					style={{
						position: 'absolute',
						top: '0',
						right: '1rem',
						fontSize: '3rem',
						cursor: 'pointer',
					}}
					onClick={() => {
						props.setShowModal(false);
					}}
				>
					X
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_1')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_1')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_2')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_2')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_3')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_4')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_5')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_3')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_6')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_7')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_8')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_9')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_4')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_10')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_11')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_5')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_12')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_13')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_6')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_14')}
				</p>
				<p>
					{GetLocaleText('bike_manual_t_15')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_7')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_16')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_8')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_17')}
				</p>
				<p>
					<b>{GetLocaleText('bike_manual_h_9')}</b>
				</p>
				<p>
					{GetLocaleText('bike_manual_t_18')}
				</p>
				<p>
					<label>
						<input
							type='checkbox'
							onChange={(e) => {
								setCheckboxValue(!checkboxValue);
							}}
							value={checkboxValue + ''}
							style={{ marginRight: '1rem' }}
						/>
						{GetLocaleText('bike_manual_checkbox_t')}
					</label>
				</p>
				<p style={{ marginBottom: '2rem', textAlign: 'center' }}>
					{checkboxValue && (
						<Link
							to={`/manual/${props.manual}`}
							style={{
								borderRadius: '1rem',
								background: '#005aab',
								outline: 'none',
								border: 'none',
								color: 'white',
								padding: '.5rem 1rem',
							}}
							onClick={() => {
								Cookies.set('manual_ok', 'true');
							}}
						>
							{GetLocaleText('bike_manual_next_t')}
						</Link>
					)}
				</p>
			</Modal>
		</div>
	);
};

export default BikeModalView;

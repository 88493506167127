import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { ICustomPage } from '../Interfaces/ICustomPage.interface';

export class CustomPageModel {
  constructor(CustomPage?: ICustomPage) {
    if (CustomPage) {
      ModelConstructor(CustomPage, this);
    }
  }

  page_id: string = '';
  lang_id: string = '';
  name: string = '';
  slug: string = '';
  content: string = '';
}

import React from 'react';
import HTMLParser from 'html-react-parser';
import { CustomPageModel } from '../Model/CustomPage.model';
import { IError } from '../../../Interfaces/IError.interface';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';

import './Less/CustomPage.css';

export interface ICustomPageViewProps {
	loading: Promise<any> | null;
	custom_page: CustomPageModel;
	error: IError;
}

const CustomPageView: React.FC<ICustomPageViewProps> = (
	props
): React.ReactElement => {
	const { custom_page: news } = props;

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
	}, [props.loading]);
	return (
		<div className='custompage-view'>
			<div className='custompage-view__content'>{HTMLParser(news.content)}</div>
		</div>
	);
};

export default CustomPageView;

import React, { useState } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { OwnersModel } from '../Model/Owners.model';
import './Less/Owners.css';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import Img from 'react-image';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IOwnersViewProps {
	loading: Promise<any> | null;
	owners: OwnersModel[];
	error: IError;
}

const OwnersView: React.FC<IOwnersViewProps> = (props): React.ReactElement => {
	const [hover, set_hover] = useState(false);
	const [hover_index, set_hover_index] = useState(0);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null) return <SplashScreen opacity='1' />;
	const toggleHover = (index: number): void => {
		set_hover_index(index);
		set_hover(false);
	};
	const addHover = (index: number): void => {
		set_hover_index(index);
		set_hover(true);
	};
	return (
		<div className='owners-view'>
			{props.owners.map((element, index) => {
				return (
					<div
						key={index}
						className={`owners-view__item ${
							hover ? 'show' : ''
						} i${index} hi${hover_index}`}
					>
						<div
							className='owners-view__item--container cont'
							onMouseEnter={() => addHover(index)}
							onMouseLeave={() => toggleHover(index)}
						>
							<div className='owners-view__item--container image'>
								<a href={element.url} target='_blank' rel='noopener noreferrer'>
									<Img
										src={element.background}
										alt={generateImgAlt(element.background)}
									/>
								</a>
							</div>
							<div className='owners-view__item--container number'>
								0{index + 1}
							</div>
							<div className='owners-view__item--container title'>
								{element.title}
								<div className='owners-view__item--container desc'>
									{element.description}
								</div>
								<div className='owners-view__item--container button'>
									<a
										href={element.url}
										target='_blank'
										rel='noopener noreferrer'
									>
										{element.btn_text}
									</a>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default OwnersView;

import React, { useContext } from 'react';
import './Less/MainInfo.style.css';
import BikeModel, { IOfferItem } from '../Model/Bike.model';
import HTMLReactParser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import toolTipIcon from '../../../Assets/BikePage/information.png';
import { GetLocaleText } from '../../../I18n';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface IMainInfoViewProps {
	model: BikeModel;
}

const MainInfoView: React.FC<IMainInfoViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const history = useHistory();

	return (
		<div className='bike-main-info-component'>
			{props.model.site.price || props.model.site.discountPrice ? (
				<div className='bike-main-info-component__price-block'>
					<span className='price-block-label'>
						{GetLocaleText('bike_page_price')}
					</span>
					<span className='price-block-value'>
						{HTMLReactParser(props.model.site.price)}
						{props.model.site.discountPrice ? (
							<span>{HTMLReactParser(props.model.site.discountPrice)}</span>
						) : null}
					</span>
				</div>
			) : null}
			<div className='bike-main-info-component__year-block'>
				<span
					className={[
						'price-block-label',
						props.model.bike.isNew ? 'price-block-label--new-year' : null,
					].join(' ')}
				>
					{GetLocaleText('bike_page_year')}
				</span>
				<span
					className={[
						'year-block-main-year',
						props.model.bike.isNew ? 'year-block-main-year--new-year' : null,
					].join(' ')}
				>
					{props.model.bike.year}
					{props.model.bike.isNew ? (
						<span className='year-block-main-year__new'>
							{GetLocaleText('bike_page_new_label')}
						</span>
					) : null}
				</span>
				{props.model.realtions
					.sort((model1, model2) => model2.year - model1.year)
					.slice(0, 2)
					.map((model, index) => (
						<span
							onClick={() => {
								history.push(
									cleanLink(
										`/${getRouteLink('BIKES', routerContext)}/${model.slug}`
									)
								);
							}}
							key={`${index}-rel-link`}
							className={[
								'year-block-other-year',
								props.model.bike.isNew
									? 'year-block-other-year--new-year'
									: null,
							].join(' ')}
						>
							{model.year}
						</span>
					))}
			</div>
			<div className='bike-main-info-component__offer-block'>
				{props.model.offers.length !== 0 ? (
					<span className='price-block-label'>
						{GetLocaleText('bike_page_offer-label')}
					</span>
				) : null}
				{props.model.offers.map((offer: IOfferItem, index) => (
					<span
						onClick={() =>
							history.push(
								cleanLink(
									`/${getRouteLink('OFFERS', routerContext)}/${offer.slug}`
								)
							)
						}
						className='offer-block-item'
						key={`${index}-offer-block`}
					>
						<img
							src={offer.image}
							alt={generateImgAlt(offer.image)}
							className='offer-block-item__image'
						/>
						<img
							src={toolTipIcon}
							alt=''
							className='offer-block-item__tooltip-icon'
						/>
						<div className='offer-block-item__tooltip'>{offer.description}</div>
					</span>
				))}
			</div>
		</div>
	);
};

export default MainInfoView;

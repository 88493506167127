import React, { useContext } from 'react';
import './Less/ContactForm_ro.css';
import { IContactROForm } from '../Interfaces/IContact_ro.interface';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import Select from '../../UI/Select/Select';
import TextInput from '../../UI/TextInput/TextInput';
import { TextArea } from '../../UI/TextArea/TextArea';
import { Checkbox } from '../../UI/Checkbox/Checkbox';
import Button from '../../UI/Button/Button';
import { GetLocaleText } from '../../../I18n';
import HTMLParser from 'html-react-parser';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';

export interface IContactFormViewProps {
	form: IContactROForm;
	onChange: (args: IInputChange) => void;
	error: string | null;
	onSubmit: (e: React.FormEvent) => void;
}

const ContactROFormView: React.FC<IContactFormViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const resellersLabel = GetLocaleText('contact_page__form_reseller');
	const placeholder = GetLocaleText('contact_page__form_s_select');
	const noOp = GetLocaleText('contact_page__form_s_noop');
	const nameLabel = GetLocaleText('contact_page__form_name');
	const emailLabel = GetLocaleText('contact_page__form_email');
	const messageLabel = GetLocaleText('contact_form__ro__message');
	const accBefore = GetLocaleText('contact_form__ro__acc_b');
	const accAfter = GetLocaleText('contact_form__ro__acc_a');
	const accHref = GetLocaleText('contact_form__ro__acc_h');
	const accHrefText = GetLocaleText('contact_form__ro__acc_ht');
	const accBefore2 = GetLocaleText('contact_form__ro__acc2_b');
	const accAfter2 = GetLocaleText('contact_form__ro__acc2_a');
	const accHref2 = GetLocaleText('contact_form__ro__acc2_h');
	const accHrefText2 = GetLocaleText('contact_form__ro__acc2_ht');
	const accBefore3 = GetLocaleText('contact_form__ro__acc3_b');
	const accAfter3 = GetLocaleText('contact_form__ro__acc3_a');
	const accHref3 = GetLocaleText('contact_form__ro__acc3_h');
	const accHrefText3 = GetLocaleText('contact_form__ro__acc3_ht');
	const acc4 = GetLocaleText('contact_form__ro__acc4');
	const buttonText = GetLocaleText('contact_page__form_button');
	const phoneLabel = GetLocaleText('contact_form__ro__telefon');

	return (
		<form className='contact-form' onSubmit={props.onSubmit}>
			<Select
				{...props.form.reseller}
				labelText={resellersLabel}
				noOptionsMessage={noOp}
				placeholder={placeholder}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.name}
				labelText={nameLabel}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.email}
				labelText={emailLabel}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.phone}
				labelText={phoneLabel}
				onChange={props.onChange}
			/>
			<TextArea
				{...props.form.message}
				labelText={messageLabel}
				onChange={props.onChange}
			/>
			<div className='row error-text'>
				{props.error && HTMLParser(props.error)}
			</div>
			<div className='row d-lg-flex align-items-center'>
				<div className='col-lg-12'>
					<Checkbox
						{...props.form.acceptance}
						labelTextBefore={acc4}
						onChange={props.onChange}
					/>
				</div>
				<div className='col-lg-12'>
					<Checkbox
						{...props.form.acceptance2}
						labelTextBefore={accBefore3}
						labelTextAfter={accAfter3}
						href={accHref3}
						hrefText={accHrefText3}
						onChange={props.onChange}
					/>
				</div>
				<Checkbox
					{...props.form.preferred_mail}
					labelTextBefore={'Email'}
					onChange={props.onChange}
					className={'col-6 justify-content-center'}
					labelStyle={'d-flex'}
				/>
				<Checkbox
					{...props.form.preferred_phone}
					labelTextBefore={
						routerContext.lang.value.id === 'ro' ? 'Telefon' : 'Phone'
					}
					onChange={props.onChange}
					className={'col-6 justify-content-center'}
					labelStyle={'d-flex'}
				/>
				<div className='col-lg-12'>
					<Checkbox
						{...props.form.acceptance3}
						labelTextBefore={accBefore2}
						labelTextAfter={accAfter2}
						href={accHref2}
						hrefText={accHrefText2}
						onChange={props.onChange}
					/>
				</div>
				<div className='col-lg-12'>
					<Checkbox
						{...props.form.acceptance4}
						labelTextBefore={accBefore}
						labelTextAfter={accAfter}
						href={accHref}
						hrefText={accHrefText}
						onChange={props.onChange}
					/>
				</div>
				<div className='col-lg-12 d-flex mt-4 justify-content-center'>
					<Button
						isDark
						isSmall
						style={{ fontSize: '20px !important' }}
						type='submit'
					>
						{buttonText}
					</Button>
				</div>
			</div>
		</form>
	);
};

export default ContactROFormView;

import { axios } from '../../../Api/axios';
import { OfferModel } from '../Model/Offer.model';
import { SingleOfferModel } from '../Model/SingleOffer.model';
import { IOffer, ISingleOffer } from './../Interfaces/IOffer.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class OfferDAO {
  private static readonly base: string = '/api/offers';

  public static async getOffers(): Promise<OfferModel[]> {
    const request: AxiosPromise<IOffer[]> = axios.getInstance().get(this.base);
    const response: AxiosResponse<IOffer[]> = await request;
    return response.data.map((offer: IOffer) => new OfferModel(offer));
  }

  public static async getOfferById(
    offer_id: string
  ): Promise<SingleOfferModel> {
    const request: AxiosPromise<ISingleOffer> = axios
      .getInstance()
      .get(`${this.base}/${offer_id}`);
    const response: AxiosResponse<ISingleOffer> = await request;
    return new SingleOfferModel(response.data);
  }
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import {
  IAboutUs,
  Data,
  Slider,
  History,
  Info,
} from '../Interfaces/IAboutUs.interface';

export class AboutUsModel {
  constructor(AboutUs?: IAboutUs) {
    if (AboutUs) {
      ModelConstructor(AboutUs, this);
    }
  }

  data: Data = {
    lang_id: '',
    title: '',
    background: '',
    description: '',
  };
  info: Info = {
    lang_id: '',
    customer_service_title: '',
    customer_service_content: '',
    customer_service_background: '',
    press_contact_title: '',
    press_contact_content: '',
    press_contact_background: '',
  };
  sliders: Slider[] = [
    {
      slider_id: '',
      lang_id: '',
      content: '',
      image: '',
      order: '',
    },
  ];
  history: History = {
    data: {
      lang_id: '',
      title: '',
      background: '',
      subtitle: '',
    },
    elements: [
      {
        history_id: '',
        lang_id: '',
        year: '',
        image: '',
        description: '',
      },
    ],
  };
}

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GetLocaleText } from '../../../I18n';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { INewDealerForm } from '../Interfaces/INewDealer.interface';
import NewDealerFormView from './NewDealerForm.view';
import './Less/NewDealer.css';

export interface INewDealerViewProps {
	form: INewDealerForm;
	onChange: (args: IInputChange) => void;
	loading: Promise<any> | null;
	error: string | null;
	form_sent: boolean;
	onSubmit: (e: React.FormEvent) => void;
	onDelete: (i: number) => void;
}

const NewDealerView: React.FC<INewDealerViewProps> = (
	props
): React.ReactElement => {
	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
		//eslint-disable-next-line
	}, [props.loading]);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className='new_dealer-view container'>
			<div className='new_dealer-wrapper row'>
				<div className='new_dealer-text' style={{ width: '100%' }}>
					{!props.form_sent ? (
						<>
							<h3 className='new_dealer-text--title'>
								{GetLocaleText('partnership__lead')}
							</h3>
							<p className='pt-2'></p>
							<h2 className='new_dealer-text--highlight'>
								{GetLocaleText('partnership__desc_1')}
							</h2>
							<p className='pt-2'></p>
							<h2 className='new_dealer-text--italic'>
								{GetLocaleText('partnership__desc_2')}
							</h2>
							<p className='pt-2'></p>
							<p className='new_dealer-text--long'>
								{GetLocaleText('partnership__desc_3')}
							</p>
						</>
					) : (
						<div className='col-lg-12'>
							<div className='d-flex justify-content-center flex-column text-center'>
								<h2 className='new_dealer-text--title'>
									{GetLocaleText('contact_page__success_lead')}
								</h2>
								<p className='new_dealer-text--long'>
									{GetLocaleText('contact_page__success_text')}
								</p>
								<p className='new_dealer-text--italic'>
									{GetLocaleText('contact_page__success_text_2')}
								</p>
							</div>
						</div>
					)}
				</div>
				<div className='new_dealer-wrapper--left col-lg-12 col-12'>
					{!props.form_sent && (
						<NewDealerFormView
							form={props.form}
							onChange={props.onChange}
							onSubmit={props.onSubmit}
							onDelete={props.onDelete}
							error={props.error}
						/>
					)}
				</div>
			</div>

			<div className='recaptcha'>
				<ReCAPTCHA
					size='invisible'
					sitekey='6LdObeYUAAAAAIrBvZXWm25JKNB9af7sEGMPXnIe'
				/>
			</div>
		</div>
	);
};

export default NewDealerView;

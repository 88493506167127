import React, { useContext } from 'react';
import './Less/ContactForm.css';
import { IContactForm } from '../Interfaces/IContact.interface';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import Select from '../../UI/Select/Select';
import TextInput from '../../UI/TextInput/TextInput';
import { TextArea } from '../../UI/TextArea/TextArea';
import { Checkbox } from '../../UI/Checkbox/Checkbox';
import Button from '../../UI/Button/Button';
import { GetLocaleText } from '../../../I18n';
import HTMLParser from 'html-react-parser';
import FileUpload from '../../UI/File/FileUpload';
import { Link } from 'react-router-dom';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';

export interface IContactFormViewProps {
	form: IContactForm;
	onChange: (args: IInputChange) => void;
	error: string | null;
	onSubmit: (e: React.FormEvent) => void;
	onDelete: (i: number) => void;
}

const ContactFormView: React.FC<IContactFormViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const resellersLabel = GetLocaleText('contact_page__form_reseller');
	const placeholder = GetLocaleText('contact_page__form_s_select');
	const noOp = GetLocaleText('contact_page__form_s_noop');
	const nameLabel = GetLocaleText('contact_page__form_name');
	const emailLabel = GetLocaleText('contact_page__form_email');
	const messageLabel = GetLocaleText('contact_page__form_message');
	const accBefore = GetLocaleText('contact_page__form_cb_before');
	const accAfter = GetLocaleText('contact_page__form_cb_after');
	const accHref = GetLocaleText('contact_page__form_cb_href');
	const accHrefText = GetLocaleText('contact_page__form_cb_href_text');
	const buttonText = GetLocaleText('contact_page__form_button');
	const dealerText1 = GetLocaleText('contact_form_dealer_text_1');
	const dealerText2 = GetLocaleText('contact_form_dealer_text_2');
	const vinText = GetLocaleText('contact_form_vin');
	const vinDesc = GetLocaleText('contact_form_vin_desc');
	const attachmentLabel = GetLocaleText('contact_form_attachment_label');
	const attachmentText = GetLocaleText('contact_form_attachment_text');
	const phoneLabel = GetLocaleText('contact_form_phone');

	return (
		<form className='contact-form' onSubmit={props.onSubmit}>
			<Select
				{...props.form.recipient}
				labelText={resellersLabel}
				noOptionsMessage={noOp}
				placeholder={placeholder}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.name}
				labelText={nameLabel}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.email}
				labelText={emailLabel}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.phone}
				labelText={phoneLabel}
				onChange={props.onChange}
			/>
			<TextInput
				{...props.form.vin}
				labelText={vinText}
				onChange={props.onChange}
				style={{ marginBottom: '0' }}
			/>
			<small>{vinDesc}</small>
			<FileUpload
				onChange={props.onChange}
				onDelete={props.onDelete}
				labelText={attachmentLabel}
				{...props.form.attachment}
			>
				{attachmentText}
			</FileUpload>
			<TextArea
				{...props.form.message}
				labelText={messageLabel}
				onChange={props.onChange}
			/>
			<div className='row error-text'>
				{props.error && HTMLParser(props.error)}
			</div>
			<div className='row d-lg-flex align-items-center'>
				<div className='col-lg-12'>
					<Checkbox
						{...props.form.acceptance}
						labelTextBefore={accBefore}
						labelTextAfter={accAfter}
						href={accHref}
						hrefText={accHrefText}
						onChange={props.onChange}
					/>
				</div>
				<div className='col-lg-12 d-flex mt-4 justify-content-center'>
					<Button
						isDark
						isSmall
						style={{ fontSize: '20px !important' }}
						type='submit'
					>
						{buttonText}
					</Button>
				</div>
				<div className='row d-flex align-items-center'>
					<div className='col-12'>
						<p>
							{dealerText1}{' '}
							<Link to={getRouteLink('RESELLERS', routerContext)}>
								{dealerText2}
							</Link>
						</p>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ContactFormView;

export const sk = {
	page_id: 0,
	lang_id: 1,
	name: 'Cookie Details Page',
	slug: 'cookie',
	content: `<div class="custompage-view-cookie">
  <div class="custompage-view-cookie__content"><p><span style="font-family: SuzukiHeadline; font-size: 60px;">Pravidl&aacute; cookies</span></p>
  <p>&bdquo;Cookie&ldquo; je mal&yacute; textov&yacute; s&uacute;bor, obsahuj&uacute;ci inform&aacute;cie, uložen&eacute; vo Va&scaron;om poč&iacute;tači. Cookies sa použ&iacute;vaj&uacute; len z&nbsp;technick&yacute;ch d&ocirc;vodov a&nbsp;na uľahčenie použ&iacute;vania webov&yacute;ch str&aacute;nok. Jeden typ s&uacute;boru cookie ulož&iacute; s&uacute;bor nast&aacute;lo do V&aacute;&scaron;ho poč&iacute;tača. Ten sa m&ocirc;že nesk&ocirc;r použiť na prisp&ocirc;sobenie webovej str&aacute;nky, na z&aacute;klade v&yacute;berov a&nbsp;z&aacute;ujmov použ&iacute;vateľa. Ďal&scaron;&iacute;m často sa vyskytuj&uacute;cim typom s&uacute;boru cookie, je tzv. &bdquo;session cookie&ldquo;. Pri n&aacute;v&scaron;teve webovej str&aacute;nky sa tieto &bdquo;session cookies&ldquo; odosielaj&uacute; medzi Va&scaron;im poč&iacute;tačom a&nbsp;serverom a&nbsp;zhromažďuj&uacute; tak inform&aacute;cie. Po zatvoren&iacute; prehliadača sa v&scaron;ak &bdquo;session cookies&ldquo; neukladaj&uacute;.</p>
  <p>Viac inform&aacute;ci&iacute; ohľadom cookies m&ocirc;žete n&aacute;jsť na nasledovn&yacute;ch webov&yacute;ch str&aacute;nkach:</p>
  <p><a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener">www.allaboutcookies.org&nbsp;</a></p>
  <p><a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener">www.youronlinechoices.eu</a></p>
  <p><a title="www.google.co.uk/policies/technologies/cookies" href="http://www.google.co.uk/policies/technologies/cookies" target="_blank" rel="noopener">www.google.co.uk/policies/technologies/cookies</a></p>
  <p>S&uacute;bory cookies m&ocirc;žete obmedziť alebo zablokovať, t&yacute;m že zmen&iacute;te nastavenia V&aacute;&scaron;ho prehliadača. Taktiež m&ocirc;žete z&nbsp;V&aacute;&scaron;ho prehliadača vymazať už uložen&eacute; s&uacute;bory cookies. Viac inform&aacute;cii n&aacute;jdete na str&aacute;nkach podpory webov&eacute;ho prehliadača. Upozorňujeme V&aacute;s v&scaron;ak, že niektor&eacute; oblasti a&nbsp;funkcie na tejto webovej str&aacute;nke vyžaduj&uacute; cookies a&nbsp;nemusia fungovať v&nbsp;pr&iacute;pade, že bud&uacute; s&uacute;bory cookies odstr&aacute;nen&eacute; alebo odmietnut&eacute;.</p>
  <h2><span style="font-family: SuzukiBold;">T&aacute;to str&aacute;nka použ&iacute;va nasledovn&eacute; s&uacute;bory cookies</span></h2>
  <p>Z&aacute;kladn&eacute; cookies; bez nich webov&aacute; str&aacute;nka nefunguje spr&aacute;vne. Nem&ocirc;žete ich vypn&uacute;ť a&nbsp;zvyčajne s&uacute; nastaven&eacute; na z&aacute;klade va&scaron;ej aktivity na str&aacute;nke, ako napr. nastavenie va&scaron;ich osobn&yacute;ch &uacute;dajov, prihlasovanie alebo vyplňanie formul&aacute;rov.</p>
  <h2><span lang="hu-HU" style="font-family: SuzukiBold;">Functional cookies</span></h2>
  <p>Tieto sa použ&iacute;vaj&uacute; na zapam&auml;tanie nastaven&iacute;, ktor&eacute; vytvor&iacute;te na webov&yacute;ch str&aacute;nkach, ako napr. veľkosť textu, mena a jazyk.</p>
  <table style="width: 99.9318%; height: 300px;" border="1px" cellspacing="33.33%">
  <thead>
  <tr style="height: 50px;">
  <td style="width: 11.6537%; text-align: center; height: 50px;">
  <p><strong>N&aacute;zov</strong></p>
  </td>
  <td style="width: 75.5781%; text-align: center; height: 50px;">
  <p><strong>&Uacute;čel</strong></p>
  </td>
  <td style="width: 72.4966%; text-align: center; height: 50px;">
  <p><strong>Doba&nbsp; </strong><strong>uloženia</strong></p>
  </td>
  </tr>
  </thead>
  <tbody>
  <tr style="height: 50px;">
  <td style="width: 11.6537%; text-align: center; height: 50px;">
  <p><span style="font-size: 16px;">lang</span></p>
  </td>
  <td style="width: 75.5781%; text-align: center; height: 50px;">
  <p class="gtx-body">S&uacute;bory cookie potrebn&eacute; na zmenu jazyka str&aacute;nky.</p>
  </td>
  <td style="width: 72.4966%; text-align: center; height: 50px;">
  <p>Do zatvorenia prehliadača</p>
  </td>
  </tr>
  <tr style="height: 50px;">
  <td style="width: 11.6537%; text-align: center; height: 50px;">
  <p><span style="font-size: 16px;">CookieConsent</span></p>
  </td>
  <td style="width: 75.5781%; text-align: center; height: 50px;">
  <p>Použ&iacute;vaj&uacute; sa na zistenie, či už použ&iacute;vateľ s&uacute;hlasil s použ&iacute;van&iacute;m s&uacute;borov cookie na webovej lokalite.</p>
  </td>
  <td style="width: 72.4966%; text-align: center; height: 50px;">
  <p><span style="font-size: 16px;">1 roky</span></p>
  </td>
  </tr>
  <tr style="height: 50px;">
  <td style="width: 11.6537%; text-align: center; height: 50px;">rc::a</td>
  <td style="width: 75.5781%; text-align: center; height: 50px;">
  <p>Tento s&uacute;bor cookie sa použ&iacute;va na rozli&scaron;ovanie ľud&iacute; a robotov. Tento s&uacute;bor je užitočn&yacute; pre webov&uacute; lokalitu. Pom&aacute;ha vytv&aacute;rať platn&eacute; zostavy o jej použ&iacute;van&iacute;.</p>
  </td>
  <td style="width: 72.4966%; text-align: center; height: 50px;">
  <p>Do zatvorenia prehliadača</p>
  </td>
  </tr>
  <tr style="height: 50px;">
  <td style="width: 11.6537%; text-align: center; height: 50px;">rc::c</td>
  <td style="width: 75.5781%; text-align: center; height: 50px;">
  <p>Tento s&uacute;bor cookie sa použ&iacute;va na rozli&scaron;ovanie ľud&iacute; a robotov.&nbsp;</p>
  </td>
  <td style="width: 72.4966%; text-align: center; height: 50px;">
  <p>Do zatvorenia prehliadača</p>
  </td>
  </tr>
  <tr style="height: 50px;">
  <td style="width: 11.6537%; text-align: center; height: 50px;">secondLevelRoute</td>
  <td style="width: 75.5781%; text-align: center; height: 50px;">Do zatvorenia prehliadača</td>
  <td style="width: 72.4966%; text-align: center; height: 50px;">
  <p><span style="font-size: 16px;">&nbsp;On browser closure</span></p>
  </td>
  </tr>
  </tbody>
  </table>
  <h2 lang="en-GB" align="justify"><span lang="hu-HU"><br /><span style="font-family: SuzukiBold;">S&uacute;bory cookie v&yacute;konu:</span></span></h2>
  <p lang="en-GB" align="justify">Sl&uacute;žia na sledovanie spr&aacute;vania pri prehliadan&iacute; str&aacute;nok, s cieľom zlep&scaron;iť na&scaron;e inform&aacute;cie a služby.</p>
  <table style="width: 100%;" border="1px" cellspacing="33.33%">
  <tbody>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><strong>N&aacute;zov</strong></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p><strong>&Uacute;čel</strong></p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><strong>Doba&nbsp; </strong><strong>uloženia</strong></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">.ga</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p>Tento s&uacute;bor cookie prispieva k činnosti služby Google Analytics. Použ&iacute;va sa na rozli&scaron;ovanie použ&iacute;vateľov tak, že každ&eacute;mu použ&iacute;vateľovi prirad&iacute; n&aacute;hodn&eacute; identifikačn&eacute; č&iacute;slo. Tento s&uacute;bor cookie je s&uacute;časťou v&scaron;etk&yacute;ch požiadaviek str&aacute;nok webovej lokality. Použ&iacute;va sa na poč&iacute;tanie n&aacute;v&scaron;tevn&iacute;kov, rel&aacute;ci&iacute; a kampan&iacute; na &uacute;čely anal&yacute;zy lokality.</p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;">2 rokov</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">_gid</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p>Tento s&uacute;bor cookie prispieva k činnosti služby Google Analytics. Použ&iacute;va sa na rozli&scaron;ovanie použ&iacute;vateľov.</p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;">24 hod&iacute;n</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">
  <p><span style="font-size: 16px;">_gat</span></p>
  </td>
  <td style="text-align: center; width: 76.8596%;">
  <p>Tento s&uacute;bor cookie prispieva k činnosti služby Google Analytics. Použ&iacute;va sa na znižovanie počtu požiadaviek, t. j. obmedzuje zhromažďovanie &uacute;dajov na webov&yacute;ch lokalit&aacute;ch s veľkou aktivitou.</p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p><span style="font-size: 16px;">1 min&uacute;ta</span></p>
  </td>
  </tr>
  <tr>
  <td style="width: 11.8457%; text-align: center;">collect</td>
  <td style="text-align: center; width: 76.8596%;">
  <p>Použ&iacute;va sa na odoslanie &uacute;dajov o zariaden&iacute; a spr&aacute;van&iacute; n&aacute;v&scaron;tevn&iacute;ka do služby Google Analytics. Sleduje n&aacute;v&scaron;tevn&iacute;ka v r&aacute;mci r&ocirc;znych zariaden&iacute; a marketingov&yacute;ch kan&aacute;lov.</p>
  </td>
  <td style="width: 11.3636%; text-align: center;">
  <p>Do zatvorenia prehliadača</p>
  </td>
  </tr>
  </tbody>
  </table>
  <h2><span lang="hu-HU" style="font-family: SuzukiBold;"><br />S&uacute;bory cookie tret&iacute;ch str&aacute;n:<br /></span></h2>
  <p>Tieto s&uacute;bory cookie použ&iacute;va obsah, napr&iacute;klad pr&iacute;spevky zo soci&aacute;lnych siet&iacute;, mapy alebo vide&aacute;, vložen&yacute; na webovej lokalite.</p>
  <table style="width: 100%;" border="1px" cellspacing="33.33%">
  <tbody>
  <tr>
  <td style="text-align: center; width: 12.0523%;">
  <p><strong>N&aacute;zov</strong></p>
  </td>
  <td style="text-align: center;">
  <p><strong>&Uacute;čel</strong></p>
  </td>
  <td style="width: 10.8127%; text-align: center;">
  <p><strong>Doba&nbsp; </strong><strong>uloženia</strong></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">
  <p><span style="font-size: 16px;">NID</span></p>
  </td>
  <td style="text-align: center;">S&uacute;bor cookie NID obsahuje jedinečn&yacute; identifik&aacute;tor, ktor&yacute; použ&iacute;va spoločnosť Google na zapam&auml;tanie si va&scaron;ich predvolieb. Pr&iacute;kladom je preferovan&yacute; jazyk (napr. slovenčina), požadovan&yacute; počet zobrazen&yacute;ch v&yacute;sledkov vyhľad&aacute;vania (napr. 10 alebo 20) a zapnutie alebo vypnutie filtra Bezpečn&eacute; vyhľad&aacute;vanie od spoločnosti Google. Okrem toho sl&uacute;ži na ukladanie in&yacute;ch va&scaron;ich inform&aacute;ci&iacute;.</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">6 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">
  <p><span style="font-size: 16px;">IDE</span></p>
  </td>
  <td style="text-align: center;">Tento s&uacute;bor cookie sa použ&iacute;va na op&auml;tovn&eacute; zacielenie, optimaliz&aacute;ciu, nahlasovanie a priraďovanie online rekl&aacute;m. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">2 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">test_cookie</td>
  <td style="text-align: center;">Použ&iacute;va sa na kontrolu, či prehliadač použ&iacute;vateľa podporuje s&uacute;bory cookie. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">1 deň</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">fr</td>
  <td style="text-align: center;">Použ&iacute;va ho Facebook na poskytovanie r&ocirc;znych reklamn&yacute;ch produktov, napr&iacute;klad pon&uacute;k v re&aacute;lnom čase od nez&aacute;visl&yacute;ch inzerentov.</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">3 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">tr</td>
  <td style="text-align: center;">Použ&iacute;va ho Facebook na poskytovanie r&ocirc;znych reklamn&yacute;ch produktov, napr&iacute;klad pon&uacute;k v re&aacute;lnom čase od nez&aacute;visl&yacute;ch inzerentov.</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Rel&aacute;cia</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">_fbp</td>
  <td style="text-align: center;">Použ&iacute;va ho Facebook na poskytovanie r&ocirc;znych reklamn&yacute;ch produktov, napr&iacute;klad pon&uacute;k v re&aacute;lnom čase od nez&aacute;visl&yacute;ch inzerentov.</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">3 months</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">GPS</td>
  <td style="text-align: center;">Registruje v mobiln&yacute;ch zariadeniach jedinečn&yacute; identifik&aacute;tor, ktor&yacute; umožňuje sledovanie na z&aacute;klade geografickej polohy GPS.</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">1 deň</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">VISITOR_INFO1_LIVE</td>
  <td style="text-align: center;">Pok&uacute;&scaron;a sa odhadn&uacute;ť &scaron;&iacute;rku p&aacute;sma použ&iacute;vateľov na str&aacute;nkach s integrovan&yacute;mi videami YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">179 dni</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">YSC</td>
  <td style="text-align: center;">Registruje jedinečn&yacute; identifik&aacute;tor, ktor&yacute; uchov&aacute;va &scaron;tatistiku o prehrat&yacute;ch vide&aacute;ch YouTube použ&iacute;vateľa. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Rel&aacute;cia</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-cast-installed</td>
  <td style="text-align: center;">Uklad&aacute; predvoľby prehr&aacute;vača vide&iacute; použ&iacute;vateľa prostredn&iacute;ctvom vložen&eacute;ho videa YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Rel&aacute;cia</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-connected-devices</td>
  <td style="text-align: center;">Uklad&aacute; predvoľby prehr&aacute;vača vide&iacute; použ&iacute;vateľa prostredn&iacute;ctvom vložen&eacute;ho videa YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">Presistent</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-device-id</td>
  <td style="text-align: center;">Uklad&aacute; predvoľby prehr&aacute;vača vide&iacute; použ&iacute;vateľa prostredn&iacute;ctvom vložen&eacute;ho videa YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p><span style="font-size: 16px;">Presistent</span></p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-fast-check-period</td>
  <td style="text-align: center;">Uklad&aacute; predvoľby prehr&aacute;vača vide&iacute; použ&iacute;vateľa prostredn&iacute;ctvom vložen&eacute;ho videa YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Rel&aacute;cia</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-session-app</td>
  <td style="text-align: center;">Uklad&aacute; predvoľby prehr&aacute;vača vide&iacute; použ&iacute;vateľa prostredn&iacute;ctvom vložen&eacute;ho videa YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Rel&aacute;cia</p>
  </td>
  </tr>
  <tr>
  <td style="text-align: center; width: 12.0523%;">yt-remote-session-name</td>
  <td style="text-align: center;">Uklad&aacute; predvoľby prehr&aacute;vača vide&iacute; použ&iacute;vateľa prostredn&iacute;ctvom vložen&eacute;ho videa YouTube. &nbsp; &nbsp;</td>
  <td style="text-align: center; width: 10.8127%;">
  <p>Rel&aacute;cia</p>
  </td>
  </tr>
  </tbody>
  </table>
  <p style="text-align: center;">&nbsp;</p>
  <p style="text-align: center;">Na&scaron;e webov&eacute; lokality a digit&aacute;lne služby neust&aacute;le zlep&scaron;ujeme. Na tento &uacute;čel využ&iacute;vame službu Google<br />Analytics, ktor&aacute; analyzuje s&uacute;hrnn&eacute; a anonymizovan&eacute; &uacute;daje o spr&aacute;van&iacute; použ&iacute;vateľov.<br />N&aacute;&scaron; n&aacute;stroj na anal&yacute;zu webu Google Analytics m&ocirc;že okrem toho spravovať s&uacute;bory cookie a umiestňovať ich na tejto webovej lokalite. &Scaron;tatistick&eacute; &uacute;daje z&iacute;skan&eacute; t&yacute;mto sp&ocirc;sobom využ&iacute;vame na zlep&scaron;enie použ&iacute;vania.<br />Ak chcete z&iacute;skať ďal&scaron;ie inform&aacute;cie, kontaktujte pr&iacute;slu&scaron;n&uacute; zodpovedn&uacute; osobu na adrese <a href="mailto:privacy@suzuki.hu">privacy@suzuki.hu</a>.</p>`,
};

import React from 'react';
import HTMLParser from 'html-react-parser';
import { GetLocaleText } from '../../../I18n';
import './Less/FormSent.css';

export interface IFinancingFormSentViewProps {}

const FormSent: React.FC<IFinancingFormSentViewProps> = (
	props
): React.ReactElement => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='formsent-view'>
			<div className='formsent-view__content container'>
				{HTMLParser(GetLocaleText('financing_finish_text'))}
			</div>
		</div>
	);
};

export default FormSent;

import { VideoModel } from '../Components/Video/Model/Video.model';
import { VideoTypesModel } from '../Components/Video/Model/VideoTypes.model';
import { map } from 'lodash';

export const changeVideoIdToName = (
  videos: VideoModel[],
  video_type_array: VideoTypesModel[]
): VideoModel[] => {
  if (videos.length !== 0) {
    map(videos, video => {
      map(video_type_array, (video_type, index) => {
        if (video.video_type_id === video_type.video_type_id) {
          video.video_type_name = String(video_type_array[index].name);
        }
      });
    });
  }
  return videos;
};

import axios from 'axios';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class NewDealerDAO {
	private static readonly new_dealer: string =
		'https://suzuki.ilinedb.hu/api/v1/partnership';

	public static async create(params: FormData): Promise<void> {
		const request: AxiosPromise<void> = axios.post(
			`${this.new_dealer}`,
			params
		);
		const response: AxiosResponse<void> = await request;
		return response.data;
	}
}

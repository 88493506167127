export const ControlSplash = (stop: boolean): void => {
  const element: HTMLDivElement | null = document.querySelector(
    '.splash-screen'
  );
  if (element instanceof HTMLDivElement) {
    element.classList.add('start');
  }
  if (element instanceof HTMLDivElement && !stop) {
    setTimeout(() => {
      element.classList.remove('start');
    }, 1000);
  }
};

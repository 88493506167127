import React, { useState, useEffect, useRef, useContext } from 'react';
import {
	IInsertCodes,
	ISelectedCookieCategory,
} from '../Interfaces/ICookie.interface';
import CookieView from '../View/Cookie.view';
import { useLocation } from 'react-router';
import { hu } from '../DAO/Cookie_hu';
import { en } from '../DAO/Cookie_en';
import { hr } from '../DAO/Cookie_hr';
import { ro } from '../DAO/Cookie_ro';
import { si } from '../DAO/Cookie_si';
import { sk } from '../DAO/Cookie_sk';
import { cz } from '../DAO/Cookie_cz';
import { CookieModel } from '../Model/Cookie.model';
import { RouterContext } from '../../../Router/Context/Router.context';
import { IRouterContext } from '../../../Router/Interfaces/IRouterContext.interface';
import Cookies from 'js-cookie';
import { ViewType } from '../Interfaces/IViewType.interface';
import { Modal, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

export interface ICookieControllerProps {}

const CookieController: React.FC<ICookieControllerProps> = (
	props
): React.ReactElement => {
	const [cookie_data, set_cookie_data] = useState<CookieModel>(
		new CookieModel()
	);
	const [selectedCategories, setSelectedCategories] = useState<
		ISelectedCookieCategory[]
	>([]);
	const [isSetted, setIsSetted] = useState<boolean>(false);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [viewType, setViewType] = useState<ViewType>('Start');
	const [savedCategoryIds, setSavedCategoryIds] = useState<string[]>([]);
	const routerContext: IRouterContext = useContext(RouterContext);

	const usePrevious = <T extends unknown>(value: T): T | undefined => {
		const ref = useRef<T>();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	};

	const prevViewType = usePrevious(viewType);

	const saveCategoryIdsHandler = () => {
		setSavedCategoryIds(
			selectedCategories
				.filter((selected) => selected.isSelected)
				.map((category) => category.id)
		);
	};

	const selectCategoryHandler = (selectedId: string, isSelected: boolean) => {
		setIsSetted(false);
		setSelectedCategories(
			selectedCategories.map((category) => {
				if (category.id === selectedId) {
					return {
						...category,
						isSelected: isSelected,
					};
				} else {
					return category;
				}
			})
		);
	};

	const saveCookieHandler = (selected?: ISelectedCookieCategory[]) => {
		setIsSetted(true);
		const oneYearLater = new Date(
			new Date().setFullYear(new Date().getFullYear() + 1)
		);
		Cookies.set('ITechCookie', selected ? selected : selectedCategories, {
			domain: window.location.hostname,
			expires: oneYearLater,
		});
		setModalShow(false);
	};

	const enableAllCookieHandler = () => {
		const newSelection = selectedCategories.map((category) => {
			return {
				...category,
				isSelected: true,
			};
		});
		setSelectedCategories(newSelection);
		saveCookieHandler(newSelection);
	};

	const enableNecessaryCookieHandler = () => {
		const newSelection = selectedCategories.map((category, index) => {
			if (index === 0) {
				return {
					...category,
					isSelected: true,
				};
			} else {
				return {
					...category,
					isSelected: false,
				};
			}
		});
		setSelectedCategories(newSelection);
		saveCookieHandler(newSelection);
	};

	const loadData = (): void => {
		if (routerContext.lang.value.id === 'hu') set_cookie_data(hu);
		if (routerContext.lang.value.id === 'en') set_cookie_data(en);
		if (routerContext.lang.value.id === 'hr') set_cookie_data(hr);
		if (routerContext.lang.value.id === 'ro') set_cookie_data(ro);
		if (routerContext.lang.value.id === 'si') set_cookie_data(si);
		if (routerContext.lang.value.id === 'sk') set_cookie_data(sk);
		if (routerContext.lang.value.id === 'cz') set_cookie_data(cz);
	};

	useEffect(
		() => {
			loadData();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	useEffect(() => {
		const savedCookie = Cookies.getJSON('ITechCookie');
		if (savedCookie) {
			setSelectedCategories(savedCookie);
			setIsSetted(true);
		} else {
			setSelectedCategories(
				cookie_data.categories.map((cookie, index) => ({
					id: cookie.id,
					name: cookie.name,
					isSelected: index === 0 ? true : false,
				}))
			);
		}
		//eslint-disable-next-line
	}, [cookie_data]);

	useEffect(() => {
		saveCategoryIdsHandler();
		//eslint-disable-next-line
	}, [selectedCategories]);

	const appLocation = useLocation();
	const ro_lang = routerContext.lang.options.find((e) => e.id === 'ro');
	const cz_lang = routerContext.lang.options.find((e) => e.id === 'cz');

	function sendPageView(location: any) {
		if (ro_lang) {
			ReactGA.initialize('UA-92531672-35');
			TagManager.initialize({ gtmId: 'GTM-M9NSK7V' });
		} else if (cz_lang) {
			ReactGA.initialize('G-Y71ENM2HPS');
			TagManager.initialize({ gtmId: 'GTM-NM74XR9' });
		} else ReactGA.initialize('UA-656346-10');
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location?.pathname + location?.search);
	}

	const sendFbPixel = () => {
		if (ro_lang) ReactPixel.init('481809935606098');
		else ReactPixel.init('582159962479535');
		ReactPixel.pageView();
	};

	useEffect(() => {
		if (selectedCategories[1]?.isSelected === true) sendPageView(appLocation);
		if (selectedCategories[2]?.isSelected === true) sendFbPixel();
		// eslint-disable-next-line
	}, [appLocation, selectedCategories]);

	const getScriptsToSelectedCategories = (): IInsertCodes[] => {
		return cookie_data.insert_codes.filter((code) =>
			savedCategoryIds.includes(code.category)
		);
	};
	return (
		<>
			{isSetted && (
				<Helmet>
					{getScriptsToSelectedCategories().map(
						(script: IInsertCodes, idx: number) =>
							script.tag === 'script' ? (
								<script key={`scriptidx-${idx}`}>{script.value}</script>
							) : script.tag === 'noscript' ? (
								<noscript key={`noscriptidx-${idx}`}>{script.value}</noscript>
							) : (
								''
							)
					)}
				</Helmet>
			)}
			<Button
				variant='primary'
				onClick={() => setModalShow(!modalShow)}
				style={{
					height: '50px',
					width: '50px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '50%',
					position: 'fixed',
					bottom: '10px',
					left: '10px',
					zIndex: 999999,
				}}
			>
				<FontAwesomeIcon icon={faCog} style={{ fontSize: '24px' }} />
			</Button>
			<Modal
				show={isSetted ? modalShow : true}
				onHide={() => setModalShow(false)}
				size='xl'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				backdropClassName='cookie-backdrop'
				onExited={() => setViewType('Start')}
			>
				<CookieView
					cookie_data={cookie_data}
					selectedCategories={selectedCategories}
					selectCategoryHandler={selectCategoryHandler}
					saveCookieHandler={saveCookieHandler}
					enableAllCookieHandler={enableAllCookieHandler}
					enableNecessaryCookieHandler={enableNecessaryCookieHandler}
					viewType={viewType}
					setViewType={setViewType}
					prevViewType={prevViewType}
				/>
			</Modal>
		</>
	);
};

export default CookieController;

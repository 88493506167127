import { IExportFinancingForm } from './../Interfaces/IExportFinancing.interface';

export const ExportFinancingForm: IExportFinancingForm = {
	model_price: {
		id: 'model_price',
		value: '',
		required: true,
		labelText: 'Model price',
		disabled: true,
		type: 'text',
		textType: true,
	},
	first_name: {
		id: 'first_name',
		value: '',
		required: true,
		labelText: 'First name',
		type: 'text',
		textType: true,
	},
	last_name: {
		id: 'last_name',
		value: '',
		required: true,
		labelText: 'Last name',
		type: 'text',
		textType: true,
	},
	email: {
		id: 'email',
		value: '',
		required: true,
		labelText: 'Email',
		type: 'email',
		textType: true,
	},
	tel: {
		id: 'tel',
		value: '',
		required: false,
		pattern: '^[0-9-+s()]*$',
		labelText: 'Phone number',
		type: 'tel',
		textType: true,
	},
	city: {
		id: 'city',
		value: '',
		required: false,
		labelText: 'City',
		type: 'text',
		textType: true,
	},
	postal_code: {
		id: 'postal_code',
		value: '',
		required: false,
		labelText: 'Postal code',
		type: 'text',
		textType: true,
	},
	address: {
		id: 'address',
		value: '',
		required: false,
		labelText: 'Address',
		type: 'text',
		textType: true,
	},
	bike_category: {
		id: 'bike_category',
		value: null,
		required: true,
		options: [],
		labelText: 'Bike category',
		selectType: true,
	},
	bike_model: {
		id: 'bike_model',
		value: null,
		required: true,
		options: [],
		labelText: 'Bike model',
		selectType: true,
	},
	self_suff: {
		id: 'self_suff',
		value: null,
		required: true,
		options: [],
		labelText: 'Self-sufficiency',
		selectType: true,
	},
	term: {
		id: 'term',
		value: null,
		required: true,
		options: [],
		labelText: 'Term',
		selectType: true,
	},
	reseller: {
		id: 'reseller',
		value: null,
		required: true,
		options: [],
		labelText: 'Reseller',
		selectType: true,
	},
	legal_form: {
		id: 'legal_form',
		value: null,
		required: true,
		options: [],
		labelText: 'Legal form',
		selectType: true,
	},
	message: {
		id: 'message',
		value: '',
		textAreaType: true,
		labelText: '',
		required: false,
		className: 'text-area',
	},
	acceptance: {
		id: 'acceptance',
		value: false,
		href: '/adatkezeles',
		hrefText: 'adatvédelmi',
		labelTextBefore: 'Elfogadom az',
		labelTextAfter: 'nyilatkozatot.',
		booleanType: true,
	},
};

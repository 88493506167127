import React from "react";
import { BlockElementType, PromotionBlockElement } from "../Model/Bike.model";
import "./Less/PromotionSection.style.css";

export interface PromotionSectionProps {
    elements: BlockElementType[];
    hookID: number;
}

const PromotionSection: React.FC<PromotionSectionProps> = (props): React.ReactElement => {
    return (
        <div className="promotion-section-component" id={`BLOCK_${props.hookID}`}>
            {props.elements
                .sort((element1, element2) => element1.order - element2.order)
                .map((element, index) => {
                    if (element instanceof PromotionBlockElement)
                        return (
                            <div
                                className="promotion-section-item"
                                key={`${index}-promo-block`}
                                style={{ backgroundImage: `url(${element.background})` }}
                            >
                                <div className="promotion-section-item__left">
                                    <div className="promotion-section-item__title">
                                        {element.name}
                                    </div>
                                    <div className="promotion-section-item__desc">
                                        {element.description}
                                    </div>
                                </div>
                                <div className="promotion-section-item__right">
                                    <a
                                        className="promotion-section-item__button"
                                        href={element.url}
                                    >
                                        {element.btnText}
                                    </a>
                                </div>
                            </div>
                        );
                    else return null;
                })}
        </div>
    );
};

export default PromotionSection;

import React, { useContext, useEffect, useState } from 'react';

import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import ContactROView from '../View/Contact_ro.view';
import { GetLocaleText } from '../../../I18n';
import { IContactROForm } from '../Interfaces/IContact_ro.interface';
import { ContactROForm } from '../Static/Contact_ro.form';
import { cloneDeep } from 'lodash';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { changeHandler, setOptions } from '../../../Utils/InputChange.util';
import { validateFormData } from '../../../Utils/ValidateForm.util';
import ContactRODAO from '../DAO/Contact_ro.DAO';
import ResellersDAO from '../../Resellers/DAO/Resellers.DAO';
import { ResellersModel } from '../../Resellers/Model/Resellers.model';

export interface IContactROControllerProps
	extends RouteChildrenProps<{ slug: string }> {}

const ContactROController: React.FC<IContactROControllerProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);

	const initForm = (): IContactROForm => {
		const initialForm: IContactROForm = cloneDeep(ContactROForm);
		return initialForm;
	};
 
	const [form, set_form] = useState<IContactROForm>(initForm());
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [form_sent, set_form_sent] = useState<boolean>(false);
	const [error, set_error] = useState<any>(null);

	const errorMessages: Array<string> = [
		GetLocaleText('ex_fin__form_err_pp'),
		GetLocaleText('ex_fin__form_err_fields'),
		GetLocaleText('ex_fin__form_err_length'),
		GetLocaleText('ex_fin__form_err_select'),
		GetLocaleText('error_ro_contact_checkbox'),
	];

	useEffect(
		() => {
			fillResellers();
			return () => {
				set_form_sent(false);
			};
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	const fillResellers = () => {
		let new_form = initForm();
		ResellersDAO.getResellers().then((resellers: ResellersModel[]) => {
			const res_op = resellers.map((reseller) => ({
				id: `${reseller.reseller_id}`,
				name: `${reseller.name} / ${reseller.city}`,
				email: `${reseller.email}`,
				address: `${reseller.address}`,
				city: `${reseller.name}`,
			}));
			res_op.push({ id: '99', name: 'Magyar Suzuki ZRT / ESZTERGOM SUCURSALA', email: 'contact@suzuki.ro', address: 'ESZTERGOM', city: 'ESZTERGOM' });
			new_form = setOptions(new_form, 'reseller', res_op);
			set_form(new_form);
		});
	};

	const onChange = (args: IInputChange): void => {
		set_form(changeHandler(form, args.fieldName, args.value));
	};

	const parseFormData = (): FormData | null => {
		const data: FormData = new FormData();
		for (const field of Object.values(form)) {
			if ('booleanType' in field) {
				if (field.id === 'preferred_mail' && field.value === true) {
					data.append(field.id, `true`);
				}
				if (field.id === 'preferred_phone' && field.value === true) {
					data.append(field.id, `true`);
				}
				continue;
			}

			if ('textType' in field) {
				data.append(field.id, field.value);
				continue;
			}

			if ('selectType' in field) {
				if (field.value) {
					if (
						field.value['email'] &&
						field.value['address'] &&
						field.value['city']
					) {
						data.append(field.id, `${field.value['email']}`);
						data.append('reseller-address', `${field.value['address']}`);
						data.append('reseller-name', `${field.value['city']}`);
					} else data.append(field.id, `${field.value['name']}`);
				}
				continue;
			}

			data.append(field.id, `${field.value}` || 'null');
		}

		return data;
	};

	const onSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		set_error('');
		const isFormOk = validateFormData(form, errorMessages);
		if (isFormOk === null) {
			const data: FormData | null = parseFormData();
			if (!data) return;
			set_loading(
				ContactRODAO.create(data, routerContext.lang.value.name.toLowerCase())
					.then((data) => {
						set_form_sent(true);
					})
					.catch((e) => set_error(e))
					.finally(() => {
						set_loading(null);
					})
			);
		} else {
			set_error(isFormOk.join('<br/>'));
		}
	};

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>
					{GetLocaleText('contact_page__header_title')}
				</div>
			</div>
			<ContactROView
				form={form}
				form_sent={form_sent}
				onChange={onChange}
				loading={loading}
				onSubmit={onSubmit}
				error={error}
			/>
		</div>
	);
};

export default ContactROController;

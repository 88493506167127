import { ModelConstructor } from "../../../../Utils/DataSetter.util";
import { ICookieDetails } from "../Interfaces/ICookieDetails.interface";

export class CookieDetailsModel {
  constructor(CookieDetailsModel?: ICookieDetails) {
    if (CookieDetailsModel) {
      ModelConstructor(CookieDetailsModel, this);
    }
  }
  page_id: number | null = null;
  lang_id: number | null = null;
  name: string = "";
  slug: string = "";
  content: string = "";
}

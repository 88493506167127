import React from 'react';
import BikeModel, { IBlock } from '../Model/Bike.model';
import './Less/BikeAnchorNavigation.style.css';
import { GetLocaleText } from '../../../I18n';

export interface IBikeAnchorNavigationProps {
	blocks: IBlock[];
	bikeName: string;
	accessories: [];
	bike: BikeModel;
}

const BikeAnchorNavigation: React.FC<IBikeAnchorNavigationProps> = (
	props
): React.ReactElement => {
	const bike_page_anchor_other_bikes = GetLocaleText(
		'bike_page_anchor_other_bikes'
	);
	const bike_anchor_feature_label = GetLocaleText('bike_anchor_feature_label');
	const bike_page_anchor_tech_details = GetLocaleText(
		'bike_page_anchor_tech_details'
	);
	//const bike_anchor_accessories = GetLocaleText('bike_anchor_accessories');
	const bike_page_anchor_intro = GetLocaleText('bike_page_anchor_intro');
	const [isVisible, setisVisible] = React.useState<boolean>(
		window.scrollY > 0 ? true : false
	);

	const scrollToHash = (hash: string): void => {
		const element: HTMLElement | null = document.getElementById(hash);
		if (!element) return;

		element.scrollIntoView({
			behavior: 'smooth',
			inline: 'center',
			block: 'center',
		});
	};

	const setVisibility = (): void => {
		if (window.scrollY > 0) {
			setisVisible(true);
		}
		if (window.scrollY === 0) {
			setisVisible(false);
		}
	};

	React.useEffect(
		() => {
			document.addEventListener('scroll', setVisibility);
			return () => {
				document.removeEventListener('scroll', setVisibility);
			};
		},
		//eslint-disable-next-line
		[]
	);
	return (
		<React.Fragment>
			{isVisible && (
				<div className='anchor-nav'>
					<div
						className='anchor-nav__logo'
						onClick={() => scrollToHash('BIKE_NAME')}
					>
						{props.bikeName}
					</div>
					<div className='anchor-nav__hashes'>
						<div
							className='anchor-nav__hash'
							onClick={() => scrollToHash('BIKE_DETAILS')}
						>
							<div className='text'>{bike_page_anchor_intro}</div>
						</div>
						{props.bike.technologies.length > 0 ? (
							<div
								className='anchor-nav__hash'
								onClick={() => scrollToHash('BIKE_TECH')}
							>
								<div className='text'>{bike_page_anchor_tech_details}</div>
							</div>
						) : (
							''
						)}

						{props.blocks.length !== 0 ? (
							<div
								className='anchor-nav__hash'
								onClick={() => scrollToHash(`BLOCK_${props.blocks[0].blockId}`)}
							>
								<div className='text'>{bike_anchor_feature_label}</div>
							</div>
						) : null}

						{/*props.accessories.length !== 0 ? (
							<div
								className='anchor-nav__hash'
								onClick={() => scrollToHash('BIKE_ACCESSORIES')}
							>
								<div className='text'>{bike_anchor_accessories}</div>
							</div>
						) : null*/}
						<div
							className='anchor-nav__hash'
							onClick={() => scrollToHash('OTHER_BIKES')}
						>
							<div className='text'>{bike_page_anchor_other_bikes}</div>
						</div>
					</div>
				</div>
			)}
			{null}
		</React.Fragment>
	);
};

export default BikeAnchorNavigation;

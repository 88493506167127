import React from 'react';
import Popup from 'reactjs-popup';
import Img from 'react-image';
import { VideoModel } from '../Model/Video.model';
import { cutLink } from '../../../Utils/CutYTLinks.util';
import { formatDateString } from '../../../Utils/FormatDateString.util';
import PlayButton from '../../../Assets/play_button.png';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';

export interface IVideoElementViewProps {
  videos: VideoModel;
}

const VideoElementView: React.FC<IVideoElementViewProps> = (
  props
): React.ReactElement => {
  const { videos } = props;
  const routerContext: IRouterContext = React.useContext(RouterContext);
  const cuttedVideoLink: string = cutLink(videos.video);
  const formattedDate: string = formatDateString(
    videos.published_at,
    '.',
    routerContext.lang.value.name,
    true
  );
  const video_title = videos.title.split(' - ')[0];
  const video_short = videos.title.split(' - ')[1];

  return (
    <div className='video col-lg-4 col-md-6'>
      <div className='videos__card-header'>
        <Popup
          trigger={
            <div className='videos__card-header--image'>
              <div className='videos__card-header--image__logo'>
                <Img src={PlayButton} alt='' />
              </div>
              <Img
                style={{ maxWidth: '360px' }}
                src={`https://img.youtube.com/vi/${cuttedVideoLink}/hqdefault.jpg`}
                alt={videos.title}
                className={`videos__card-header--image-${videos.video_id}`}
              />
            </div>
          }
          modal
        >
          <div>
            <iframe
              title={videos.video_id}
              width='100%'
              height='500'
              allowFullScreen
              src={'https://www.youtube.com/embed/' + cuttedVideoLink}
            ></iframe>
          </div>
        </Popup>
      </div>
      <div className='videos__card-body'>
        <div className='videos__card-body__container'>
          <div className='videos__card-body--category'>
            {videos.video_type_name}
            <span></span>
          </div>
          <div className='videos__card-body--date'>
            <span></span>
            {formattedDate}
          </div>
        </div>
        <div className='videos__card-body--title'>{video_title}</div>
        <div className='videos__card-body--short'>{video_short}</div>
      </div>
    </div>
  );
};

export default VideoElementView;

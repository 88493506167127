import React from 'react';
import { FinancingPricesModel } from '../Model/FinancingPrices.model';
import { FinancingBikePriceModel } from '../Model/FinancingBikePrice.model';
import { IError } from '../../../Interfaces/IError.interface';
import BG from '../../../Assets/financing_bg.jpg';
import { GetLocaleText } from '../../../I18n';
import './Less/Financing.css';
import Img from 'react-image';
import Slider from 'react-rangeslider';
import CalculatedView from './Calculated.view';
import { formatPrice } from '../../../Utils/FormatStringToCurrency.util';
import { ResellersModel } from '../../Resellers/Model/Resellers.model';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { BlueToRedButton } from '../../UI/BlueToRedButton/BlueToRedButton';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IFinancingViewProps {
	loading: Promise<any> | null;
	prices: FinancingPricesModel[];
	bike_price: FinancingBikePriceModel;
	error: IError;
	calculate: (
		slug: string,
		own_part: string,
		duration: string,
		casco: string
	) => void;
	active_bike: FinancingPricesModel;
	bike_change: (slug: string) => void;
	resellers: ResellersModel[];
	finish: (
		slug: string,
		price_own_part: number,
		duration: number,
		casco: number,
		name: string,
		city: string,
		email: string,
		tel: string,
		reseller_id: number | null
	) => void;
	form_sent: boolean;
}

const FinancingView: React.FC<IFinancingViewProps> = (
	props
): React.ReactElement => {
	const financing_status_one = GetLocaleText('financing_status_one');
	const financing_status_two = GetLocaleText('financing_status_two');
	const financing_status_three = GetLocaleText('financing_status_three');
	const testdrive_bike = GetLocaleText('testdrive_bike');
	const financing_own_part_before = GetLocaleText('financing_own-part-before');
	const financing_financing_amount = GetLocaleText(
		'financing_financing-amount'
	);
	const financing_duration_value = GetLocaleText('financing_duration-value');
	const financing_duration = GetLocaleText('financing_duration');
	const with_casco = GetLocaleText('with_casco');
	const without_casco = GetLocaleText('without_casco');
	const financing_calculate_button = GetLocaleText(
		'financing_calculate_button'
	);
	const financing_legal = GetLocaleText('financing_legal');
	const calculateMinimumOwnPart = (): number => {
		let minPrice: number = 0;
		(+props.active_bike.calculator_price / 100) * 0.8 > 4000000 ?
			minPrice = (+props.active_bike.calculator_price / 100) - 4000000 :
			minPrice = (+props.active_bike.calculator_price / 100) * 0.2
		return minPrice;
	};
	const calculateMaximumOwnPart = (): number => {
		let maxPrice: number = 0;
		(+props.active_bike.calculator_price / 100) * 0.2 < 400000 ?
			maxPrice = (+props.active_bike.calculator_price / 100) - 400000 :
			maxPrice = (+props.active_bike.calculator_price / 100) * 0.8
		return maxPrice;
	};
	const [own_part_value, set_own_part_value] = React.useState(0);
	const [duration, set_duration] = React.useState(24);
	const [selectedCasco, set_selectedCasco] = React.useState(true);
	React.useEffect(() => {
		if (props.loading !== null) {
			ControlSplash(true);
		} else ControlSplash(false);
		set_own_part_value(calculateMinimumOwnPart());
		set_duration(36);
		//eslint-disable-next-line
	}, [props.active_bike]);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		if (props.loading !== null) {
			ControlSplash(true);
		} else ControlSplash(false);
		//eslint-disable-next-line
	}, []);
	const handleBikeChange = (event: any) => {
		props.bike_change(event.target.value);
	};
	React.useEffect(() => {
		if (props.loading !== null) {
			ControlSplash(true);
		} else ControlSplash(false);
		//eslint-disable-next-line
	}, [props.finish]);
	return (
		<div
			className='financing-view'
			style={{ background: `url(${BG})`, minHeight: '100vh', height: 'auto' }}
		>
			{props.bike_price.amount_financed !== 0 ? (
				<div>
					<CalculatedView
						form_sent={props.form_sent}
						bike_price={props.bike_price}
						bike_props={props.active_bike}
						resellers={props.resellers}
						finish={props.finish}
					></CalculatedView>
				</div>
			) : (
				<div className='financing-view__content container'>
					<div className='status-bar'>
						<div className='item active'>
							<div className='counter'>1.</div>
							<div className='text'>{financing_status_one}</div>
						</div>
						<div className='item'>
							<div className='counter'>2.</div>
							<div className='text'>{financing_status_two}</div>
						</div>
						<div className='item'>
							<div className='counter'>3.</div>
							<div className='text'>{financing_status_three}</div>
						</div>
					</div>
					<div className='financing-view__content--top-row row'>
						<div className='bike-select'>
							<select
								name='bike'
								id='bike-select'
								onChange={handleBikeChange}
								value={props.active_bike.slug}
								required
								aria-required='true'
							>
								<option aria-selected value=''>
									{testdrive_bike}
								</option>
								{props.prices.map((bike) => {
									return (
										<option key={`bike-id-${bike.bike_id}`} value={bike.slug}>
											{bike.name} {bike.year}
										</option>
									);
								})}
							</select>
						</div>
						{props.active_bike.bike_id !== '' ? (
							<div className='bike-logo'>
								<Img
									src={props.active_bike.logo}
									alt={generateImgAlt(props.active_bike.logo)}
								></Img>
							</div>
						) : (
							''
						)}
					</div>
					{props.active_bike.bike_id !== '' ? (
						<div className='financing-view__content--mid-row row'>
							<div className='bike-image'>
								<Img
									src={props.active_bike.image}
									alt={generateImgAlt(props.active_bike.image)}
								></Img>
							</div>
							<div className='bike-price'>
								{formatPrice(+props.active_bike.calculator_price / 100 + '')}
							</div>
							<div className='financing-view__content--mid-row__ranges row'>
								{props.active_bike.apr === '0' ? (
									<div className='bike-own-part'>
										<div className='bike-own-part-child before-rangeslider'>
											{financing_own_part_before}
										</div>
										<Slider
											value={own_part_value}
											orientation='horizontal'
											min={calculateMinimumOwnPart()}
											max={calculateMaximumOwnPart()}
											step={10000}
											tooltip={false}
											onChange={(value: number) => {
												set_own_part_value(value);
											}}
										/>
										<div className='bike-own-part-child after-rangeslider'>
											{formatPrice(own_part_value + '')}
										</div>
									</div>
								) : (
									<div className='bike-own-part'>
										<div className='bike-own-part-child before-rangeslider'>
											{financing_own_part_before}
										</div>
										<Slider
											value={own_part_value}
											orientation='horizontal'
											min={calculateMinimumOwnPart()}
											max={calculateMaximumOwnPart()}
											step={10000}
											tooltip={false}
											onChange={(value: number) => {
												set_own_part_value(value);
											}}
										/>
										<div className='bike-own-part-child after-rangeslider'>
											{formatPrice(own_part_value + '')}
										</div>
									</div>
								)}
								<div className='financed-amount'>
									<div className='financed-amount__title'>
										{financing_financing_amount}
									</div>
									<div className='financed-amount__price'>
										{formatPrice(
											+props.active_bike.calculator_price / 100 -
												own_part_value +
												''
										)}
									</div>
								</div>
								{!props.active_bike.name.toLowerCase().includes('address') ? (
									<div className='bike-duration'>
										<div className='bike-duration-child before-rangeslider'>
											{financing_duration}
										</div>
										<Slider
											value={duration}
											orientation='horizontal'
											min={12}
											max={60}
											step={12}
											tooltip={false}
											onChange={(value: number) => {
												set_duration(value);
											}}
										/>
										<div className='bike-duration-child after-rangeslider'>
											{duration} {financing_duration_value}
										</div>
									</div>
								) : (
									<div className='bike-duration'>
										<div className='bike-duration-child before-rangeslider'>
											{financing_duration}
										</div>
										<Slider
											value={duration}
											orientation='horizontal'
											min={12}
											max={60}
											step={12}
											tooltip={false}
											onChange={(value: number) => {
												set_duration(value);
											}}
										/>
										<div className='bike-duration-child after-rangeslider'>
											{duration} {financing_duration_value}
										</div>
									</div>
								)}
							</div>
							<div className='casco'>
								<label className='casco__with'>
									<input
										name='casco'
										value='true'
										onChange={() => set_selectedCasco(!selectedCasco)}
										type='radio'
										checked={selectedCasco === true}
									/>
									{with_casco}
								</label>
								<label className='casco__without'>
									<input
										name='casco'
										value='false'
										onChange={() => set_selectedCasco(!selectedCasco)}
										type='radio'
										checked={selectedCasco === false}
									/>
									{without_casco}
								</label>
							</div>

							<BlueToRedButton
								isSingle
								onClick={() =>
									props.calculate(
										props.active_bike.slug,
										'' + own_part_value,
										'' + duration,
										selectedCasco + ''
									)
								}
							>
								{financing_calculate_button}
							</BlueToRedButton>
						</div>
					) : (
						''
					)}
				</div>
			)}
			{props.form_sent === false ? (
				<div className='container'>
					<div className='legal-text'>{financing_legal}</div>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default FinancingView;

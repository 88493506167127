import React from "react";
import "./Less/TechnologyBlockMobile.style.css";
import BikeModel from "../Model/Bike.model";
import ArrowPrev from "../../../Assets/BikePage/tech_arrow_mobile_right.png";
import ArrowNext from "../../../Assets/BikePage/tech_arrow_mobile_left.png";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Swipeable } from "react-swipeable";

export interface ITechnologyBlockMobileViewProps {
    model: BikeModel;
}

export interface ITechnologyBlockMobileViewState {
    cursor: number;
    elements: SliderElement[];
    indicatorNumber: number;
    forward: boolean;
}

class TechnologyBlockMobileView extends React.Component<
    ITechnologyBlockMobileViewProps,
    ITechnologyBlockMobileViewState
> {
    state: ITechnologyBlockMobileViewState = {
        cursor: 0,
        elements: [],
        indicatorNumber: 3,
        forward: false
    };

    componentDidMount() {
        const sliderItems: SliderElement[] = [];

        for (let i = 0; i < this.props.model.technologies.length; i++) {
            sliderItems.push({
                imgUrl: this.props.model.technologies[i].icon,
                id: this.props.model.technologies[i].technologyId,
                desc: this.props.model.technologies[i].description,
                order: this.props.model.technologies[i].order,
                name: this.props.model.technologies[i].name
            });
        }

        this.setState({
            elements: sliderItems,
            indicatorNumber: 3
        });
    }

    next = () => {
        const { elements, cursor, indicatorNumber } = this.state;

        let newElements = [ ...elements ];

        if (cursor < indicatorNumber - 1) {
            this.setState({ cursor: cursor + 1, forward: true });
        } else {
            const firstElement = newElements.shift();

            if (firstElement) newElements.push(firstElement);

            this.setState({ elements: newElements, forward: true });
        }
    };

    prev = () => {
        const { elements, cursor } = this.state;

        let newElements = [ ...elements ];

        if (cursor !== 0) {
            this.setState({ cursor: cursor - 1, forward: false });
        } else {
            const lastElement = newElements.pop();

            if (lastElement) {
                newElements.unshift(lastElement);
            }

            this.setState({ elements: newElements, forward: false });
        }
    };

    onSwipeRight = (event: any) => {
        this.prev();
    };

    onSwipeLeft = (event: any) => {
        this.next();
    };

    render() {
        if (this.props.model.technologies.length === 0) {
            return <div />;
        }

        const { elements, cursor, indicatorNumber } = this.state;

        return (
            <div className="technology-section-mobile-component" id="BIKE_TECH">
                <div className="tech-mobile-indicator-container">
                    {elements.slice(0, indicatorNumber).map((elem: SliderElement, index) => (
                        <div
                            key={`${index}-tech-block`}
                            className={[
                                "tech-mobile-indicator-container__image-wrapper",
                                cursor === index
                                    ? "tech-mobile-indicator-container__image-wrapper--active"
                                    : null
                            ].join(" ")}
                        >
                            <img
                                className="tech-mobile-indicator-container__image"
                                src={elem.imgUrl}
                                alt=""
                            />
                        </div>
                    ))}
                    <div className="tech-mobile-indicator-container__arrows">
                        <img
                            className="tech-mobile-indicator-container__arrows--left"
                            onClick={this.prev}
                            src={ArrowNext}
                            alt="prev"
                        />
                        <img
                            className="tech-mobile-indicator-container__arrows--right"
                            onClick={this.next}
                            src={ArrowPrev}
                            alt="next"
                        />
                    </div>
                </div>
                <div className="tech-mobile-content-container">
                    <Swipeable
                        trackMouse={true}
                        onSwipedLeft={this.onSwipeLeft}
                        onSwipedRight={this.onSwipeRight}
                    >
                        <TransitionGroup
                            className={[
                                "technology-content-mobile-slider",
                                this.state.forward
                                    ? "technology-mobile-slider-forward"
                                    : "technology-mobile-slider-backward"
                            ].join(" ")}
                        >
                            <CSSTransition
                                key={
                                    elements[cursor] ? (
                                        elements[cursor].id
                                    ) : (
                                        `${Math.random().toString(16).substring(2)}`
                                    )
                                }
                                timeout={300}
                                classNames={"fade"}
                            >
                                <div className="technology-content-mobile-slider__item">
                                    {elements[cursor] ? elements[cursor].desc : null}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </Swipeable>
                </div>
            </div>
        );
    }
}

interface SliderElement {
    imgUrl: string;
    id: number;
    desc: string;
    order: number;
    name: string;
}

export default TechnologyBlockMobileView;

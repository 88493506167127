import { ISingleOffer } from "./../Interfaces/IOffer.interface";
import { OfferModel } from "./Offer.model";
import { setString } from "./../../../Utils/DataSetter.util";

export class SingleOfferModel extends OfferModel {
    constructor(Offer?: ISingleOffer) {
        super(Offer);
        if (Offer) {
            this.content = setString(Offer.content);
        }
    }
    content: string = "";
}

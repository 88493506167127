import React, { useContext, useEffect, useState } from 'react';

import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import NewDealerView from '../View/NewDealer.view';
import { GetLocaleText } from '../../../I18n';
import { INewDealerForm } from '../Interfaces/INewDealer.interface';
import { NewDealerForm } from '../Static/NewDealer.form';
import { cloneDeep } from 'lodash';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { changeHandler } from '../../../Utils/InputChange.util';
import { validateFormData } from '../../../Utils/ValidateForm.util';
import NewDealerDAO from '../DAO/NewDealer.DAO';

export interface INewDealerControllerProps
	extends RouteChildrenProps<{ slug: string }> {}

const NewDealerController: React.FC<INewDealerControllerProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);

	const initForm = (): INewDealerForm => {
		const initialForm: INewDealerForm = cloneDeep(NewDealerForm);
		return initialForm;
	};

	const [form, set_form] = useState<INewDealerForm>(initForm());
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [form_sent, set_form_sent] = useState<boolean>(false);
	const [error, set_error] = useState<any>(null);
	const [file_list, set_file_list] = useState<File[]>([]);

	const errorMessages: Array<string> = [
		GetLocaleText('ex_fin__form_err_pp'),
		GetLocaleText('ex_fin__form_err_fields'),
		GetLocaleText('ex_fin__form_err_length'),
	];

	useEffect(
		() => {
			return () => {
				set_form_sent(false);
			};
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	const onChange = (args: IInputChange): void => {
		set_form(changeHandler(form, args.fieldName, args.value));
	};

	const parseFormData = (): FormData | null => {
		const data: FormData = new FormData();
		for (const field of Object.values(form)) {
			if ('booleanType' in field) {
				continue;
			}

			if ('textType' in field) {
				data.append(field.id, field.value);
				continue;
			}

			data.append(field.id, `${field.value}` || 'null');
		}

		return data;
	};

	const onSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		set_error('');
		const isFormOk = validateFormData(form, errorMessages, file_list);
		if (isFormOk === null) {
			const data: FormData | null = parseFormData();
			if (!data) return;
			set_loading(
				NewDealerDAO.create(data)
					.then((data) => {
						set_form_sent(true);
					})
					.catch((e) => set_error(e))
					.finally(() => {
						set_loading(null);
					})
			);
		} else {
			set_error(isFormOk.join('<br/>'));
		}
	};

	const onDelete = (i: number) => {
		set_file_list(file_list.filter((_, idx) => idx !== i));
	};

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>
					{GetLocaleText('partnership__header')}
				</div>
			</div>
			<NewDealerView
				form={form}
				form_sent={form_sent}
				onChange={onChange}
				loading={loading}
				onSubmit={onSubmit}
				error={error}
				onDelete={onDelete}
			/>
		</div>
	);
};

export default NewDealerController;

import React, { useState } from 'react';
import './Less/DefaultPropertiesSection.style.css';
import BikeModel from '../Model/Bike.model';
import engineCapacityImage from '../../../Assets/BikePage/CmIcon.png';
import fuelImage from '../../../Assets/BikePage/ConsumingIcon.png';
import performanceImage from '../../../Assets/BikePage/PerformanceIcon.png';
import torqueImage from '../../../Assets/BikePage/powerIcon.png';
import weightImage from '../../../Assets/BikePage/weightIcon.png';
import seatHeightImage from '../../../Assets/BikePage/seatHeightIcon.png';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export interface IDefaultPropertiesSectionProps {
	model: BikeModel;
}

const DefaultPropertiesSection: React.FC<IDefaultPropertiesSectionProps> = (
	props
): React.ReactElement => {
	const [visibleOnce, setvisibleOnce] = useState<boolean>(false);
	const bikeTechnicalProperties = [
		{
			propName: GetLocaleText('bike_page_default_prop_1'),
			unit: (
				<React.Fragment>
					CM<sup>3</sup>
				</React.Fragment>
			),
			value: props.model.bike.engineCapacity,
			image: engineCapacityImage,
		},
		{
			propName: GetLocaleText('bike_page_default_prop_2'),
			unit: 'L',
			value: props.model.bike.fuelCapacity,
			image: fuelImage,
		},
		{
			propName: GetLocaleText('bike_page_default_prop_3'),
			unit: GetLocaleText('bike_general_performance'),
			value: props.model.bike.performance,
			image: performanceImage,
		},
		{
			propName: GetLocaleText('bike_page_default_prop_4'),
			unit: 'Nm',
			value: props.model.bike.torque,
			image: torqueImage,
		},
		{
			propName: GetLocaleText('bike_page_default_prop_5'),
			unit: 'KG',
			value: props.model.bike.curbWeight,
			image: weightImage,
		},
		{
			propName: GetLocaleText('bike_page_default_prop_6'),
			unit: 'MM',
			value: props.model.bike.seatHeight,
			image: seatHeightImage,
		},
	];

	const bgText: string = GetLocaleText('bike_page_default_prop_bg_text');

	return (
		<VisibilitySensor
			partialVisibility={window.innerWidth < 1100 ? true : false}
		>
			{({ isVisible }) => {
				if (!visibleOnce && isVisible) setvisibleOnce(true);

				return (
					<div className='default-properties-section-component'>
						<div className='divider-default-props' />
						<div className='bike-prop-container'>
							<span className='bike-prop-container__background-text'>
								<div className='bike-prop-container__title-mobile'>
									{bgText}
								</div>
								{bgText.split('').map((char, index) => (
									<span
										className='bike-prop-container__background-text-char'
										key={`${index}-char-block`}
									>
										{char}
									</span>
								))}
							</span>
							{bikeTechnicalProperties.map((data, index) => (
								<span
									className='bike-prop-block'
									key={`${index}-biketech-block`}
								>
									<div className='bike-prop-block__image-container'>
										<img
											src={data.image}
											alt={generateImgAlt(data.image)}
											className='bike-prop-block__image'
										/>
									</div>
									<div className='bike-prop-block__value-container'>
										{visibleOnce ? (
											data.value % 1 === 0 ? (
												<CountUp
													className='bike-prop-block__value'
													end={data.value}
													duration={1}
													useEasing={false}
												/>
											) : (
												<CountUp
													className='bike-prop-block__value'
													end={data.value}
													decimals={1}
													decimal='.'
													duration={1}
													useEasing={false}
												/>
											)
										) : (
											<span className='bike-prop-block__value'>0</span>
										)}
										<span className='bike-prop-block__unit'>{data.unit}</span>
									</div>
									<div className='bike-prop-block__prop-name'>
										{data.propName}
									</div>
								</span>
							))}
						</div>
					</div>
				);
			}}
		</VisibilitySensor>
	);
};

export default DefaultPropertiesSection;

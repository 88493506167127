import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { ICatalogTypes } from '../Interfaces/ICatalogTypes.interface';

export class CatalogTypesModel {
  constructor(CatalogType?: ICatalogTypes) {
    if (CatalogType) {
      ModelConstructor(CatalogType, this);
    }
  }

  type_id: string = '';
  lang: string = '';
  name: string = '';
}

import { IBikeResponse } from './../../Bike/Interfaces/IBike.interface';
import { axios } from '../../../Api/axios';
import { BikesModel } from '../Model/Bikes.model';
import { IBikes } from './../Interfaces/IBikes.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { BikeTypesModel } from '../Model/BikeTypes.model';
import { IBikeTypes } from '../Interfaces/IBikeTypes.interface';
import BikeModel from '../../Bike/Model/Bike.model';

export default abstract class BikesDAO {
	private static readonly base: string = '/api/bikes';
	private static readonly types: string = '/api/bikes/types';

	public static async getBikes(params?: object): Promise<BikesModel[]> {
		const request: AxiosPromise<IBikes[]> = axios
			.getInstance()
			.get(this.base, { params: params });
		const response: AxiosResponse<IBikes[]> = await request;
		return response.data.map((bikes: IBikes) => new BikesModel(bikes));
	}

	public static async getBike(slug: string): Promise<BikeModel> {
		const request: AxiosPromise<IBikeResponse> = axios
			.getInstance()
			.get(this.base + '/' + slug);
		const response: AxiosResponse<IBikeResponse> = await request;
		return new BikeModel(response.data);
	}

	public static async getScooters(params?: object): Promise<BikesModel[]> {
		const request: AxiosPromise<IBikes[]> = axios
			.getInstance()
			.get(this.base, { params: params });
		const response: AxiosResponse<IBikes[]> = await request;
		return response.data.map((bikes: IBikes) => new BikesModel(bikes));
	}

	public static async getBikeTypes(): Promise<BikeTypesModel[]> {
		const request: AxiosPromise<IBikeTypes[]> = axios
			.getInstance()
			.get(this.types);
		const response: AxiosResponse<IBikeTypes[]> = await request;
		return response.data.map(
			(bike_types: IBikeTypes) => new BikeTypesModel(bike_types)
		);
	}
}

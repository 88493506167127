import React from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { OfferModel } from '../Model/Offer.model';
import { sortBy } from 'lodash';
import './Less/Offer.css';
import OfferElementView from './OfferElement.view';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';

export interface IOffersViewProps {
  loading: Promise<any> | null;
  offers: OfferModel[];
  error: IError;
  lang: string;
}

const getImagePositions = (offer: OfferModel[]): Array<string> => {
  let returner: Array<string> = [];
  let indexer: number = 1;

  //eslint-disable-next-line
  offer.map((o) => {
    if (o.image !== '') {
      indexer++;
    }
    o.image === ''
      ? returner.push('')
      : indexer % 2 === 0
      ? returner.push('left')
      : returner.push('right');
  });
  return returner;
};

const OffersView: React.FC<IOffersViewProps> = (props): React.ReactElement => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    props.loading !== null ? ControlSplash(true) : ControlSplash(false);
    return () => {};
  }, [props.loading]);
  //TODO: create error pages
  if (props.error !== null) return <SplashScreen opacity='1' />;
  const sortedList: OfferModel[] = sortBy(props.offers, 'order');

  const imageProps = getImagePositions(sortedList);
  return (
    <div id='offers'>
      {sortedList.map((offer: OfferModel, index: number) => (
        <OfferElementView
          key={`${index}-offer-element`}
          index={index}
          offer={offer}
          offer_length={props.offers.length}
          lang={props.lang}
          imageProps={imageProps}
        />
      ))}
    </div>
  );
};

export default OffersView;

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IResellers } from '../Interfaces/IResellers.interface';

export class ResellersModel {
  constructor(Resellers?: IResellers) {
    if (Resellers) {
      ModelConstructor(Resellers, this);
    }
  }
  reseller_id: string = '';
  site_id: string = '';
  coord_lat: string = '';
  coord_lng: string = '';
  city: string = '';
  name: string = '';
  address: string = '';
  tel: string = '';
  email: string = '';
  site_url: string = '';
  is_draft: string = '';
  is_car_seller: string = '';
  is_marine_seller: string = '';
  is_bike_seller: string = '';
  is_scooter_seller: string = '';
  is_service: string = '';
  logo: string = '';
  lang_id: string = '';
  opening_hours: string = '';
}

import React, { useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { ResellersModel } from '../Model/Resellers.model';
import './Less/Resellers.css';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import Img from 'react-image';
import { GetLocaleText } from '../../../I18n';
import BG from '../../../Assets/resellers_bg.png';
import TableImage from '../../../Assets/resellers_table.png';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import Popup from 'reactjs-popup';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';

export interface IResellersViewProps {
	loading: Promise<any> | null;
	resellers: ResellersModel[];
	error: IError;
	lang: string;
	active: boolean;
	set_active: (bike_or_scooter: boolean) => void;
}

const ResellersView: React.FC<IResellersViewProps> = (
	props
): React.ReactElement => {
	const resellers_opening_hours = GetLocaleText('resellers_opening-hours');
	const resellers_website = GetLocaleText('resellers_website');
	const resellers_map = GetLocaleText('resellers_map');
	const resellers_map_filter = GetLocaleText('resellers_map_filter');
	const resellers_filter_1 = GetLocaleText('resellers_filter_1');
	const resellers_filter_2 = GetLocaleText('resellers_filter_2');
	const routerContext: IRouterContext = useContext(RouterContext);
	const map_links = [
		{
			lang: {
				key: 'hu',
				link:
					'https://www.google.com/maps/d/u/3/embed?mid=1RgJrK2vsWNxoXpvK638ylqHmG3XgGZWo',
			},
		},
		{
			lang: {
				key: 'ro',
				link:
					'https://www.google.com/maps/d/u/3/embed?mid=1RU-cevxQhbNlZIm_L1Wj4JMcCp0PHlAE',
			},
		},
		{
			lang: {
				key: 'sk',
				link:
					'https://www.google.com/maps/d/u/3/embed?mid=1esWM4t6JKwOgnvSYJzw296st3wIG7AHG',
			},
		},
		{
			lang: {
				key: 'si',
				link:
					'https://www.google.com/maps/d/u/3/embed?mid=1sj331xBKtVVPt_D-FYU36AJTOTQNbnEt',
			},
		},
		{
			lang: {
				key: 'cz',
				link:
					'https://www.google.com/maps/d/u/3/embed?mid=1oqRlRJ7YTxhw4K0dAEGPiUsZ1UeDHqne',
			},
		},
		{
			lang: {
				key: 'hr',
				link:
					'https://www.google.com/maps/d/u/3/embed?mid=1rkwxS01n0AJhM9hO4OglgOznpzCep-pz',
			},
		},
	];
	const found = routerContext.lang.options.find((e) => e.id !== 'en');
	let goo_map;
	if (found) goo_map = map_links.find((e) => e.lang.key === found?.id);

	if (goo_map) goo_map = goo_map?.lang.link;

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null) return <SplashScreen opacity='1' />;
	const disableFilter = () => {
		const filter: HTMLDivElement | null = document.querySelector(
			'.resellers-view .filter'
		);
		if (filter instanceof HTMLDivElement) filter.style.display = 'none';
	};
	return (
		<div
			className={`resellers-view ${found?.name === 'HU' ? 'both' : 'single'}`}
		>
			<div className='map'>
				<iframe
					src={goo_map}
					style={{ border: '0', marginTop: '-50px', marginBottom: '-100px' }}
					width='100%'
					height='630'
					title='Resellers'
				></iframe>
				<div
					className='filter'
					onClick={() => {
						disableFilter();
					}}
				>
					{resellers_map_filter}
				</div>
			</div>
			<div
				className='content'
				style={{
					backgroundImage: `url(${BG})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'bottom center',
				}}
			>
				<div className='table'>
					<Img src={TableImage} alt=''></Img>
				</div>
				<div className='type-selector container'>
					<div
						className={`bike ${props.active === false ? 'active' : ''}`}
						onClick={() => {
							props.set_active(false);
						}}
					>
						<span>{resellers_filter_1}</span>
					</div>
					<div
						className={`scooter-and-bike ${
							props.active === true ? 'active' : ''
						}`}
						onClick={() => {
							props.set_active(true);
						}}
					>
						<span>{resellers_filter_2}</span>
					</div>
					<div className='type-selector__placeholder'></div>
				</div>
				{props.lang === 'CZ' ? <div className='cz-text-addon'>
					Upozornění:
					<br/>
					<br/>
					Upozorňujeme naše zákazníky, že společnost <strong>Westmoto s.r.o.</strong> v Horní Bříze, firma <strong>Moto Lhotský</strong> v Kynšperku nad Ohří a firma <strong>Aleš Dvořák – Motocykly Dvořák</strong> v Benátkách nad Jizerou nejsou autorizovanými partnery Suzuki v oblasti prodeje a servisu motocyklů a neposkytují tak služby na úrovni autorizovaných partnerů. Společnost Magyar Suzuki Corporation se distancuje od jakéhokoliv konání těchto subjektů.
				</div> : <></>}
				<div className='resellers-item-wrapper'>
					{props.resellers.map((reseller, index) => {
						return (
							<Popup
								key={'popup__' + index}
								trigger={
									<div className='reseller-item'>
										<div className='reseller-item__name'>{reseller.name}</div>
										<div className='reseller-item__city'>{reseller.city}</div>
										<div className='reseller-item__info'>
											<div className='reseller-item__info--border'></div>
											<i className='icon'></i>
											<div className='reseller-item__info--border'></div>
										</div>
									</div>
								}
								modal
							>
								{(close) => (
									<div className='resellers-popup-border'>
										<div className='resellers-popup-x' onClick={close}>
											X
										</div>
										<div className='resellers-popup-border__top'></div>
										<div className='resellers-popup-content'>
											<div className='resellers-popup-content__head'>
												{resellers_opening_hours}
											</div>
											<div className='resellers-popup-content__opening-hours'>
												{reseller.opening_hours.split('\n').map((e, i) => (
													<span key={'i__' + i}>{e}</span>
												))}
											</div>
											<div className='resellers-popup-content__links'>
												<div className='website'>
													<a
														href={reseller.site_url}
														target='_blank'
														rel='noopener noreferrer'
													>
														{resellers_website}
													</a>
												</div>
												|
												<div className='maplink'>
													<a
														href={` https://www.google.com/maps/search/?api=1&query=
                        ${reseller.name},${reseller.city}`}
														target='_blank'
														rel='noopener noreferrer'
													>
														{resellers_map}
													</a>
												</div>
											</div>
											<div className='resellers-popup-content__border'></div>
											<div className='resellers-popup-content__info'>
												<div className='location'>{reseller.address}</div>
												<div className='tel'>
													{reseller.tel.split('/')[0]}
													<br />
													{reseller.tel.split('/')[1]}
												</div>
												<div className='email'>{reseller.email}</div>
											</div>
										</div>
										<div className='resellers-popup-border__bottom'></div>
									</div>
								)}
							</Popup>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ResellersView;

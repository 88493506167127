import React, { useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { AboutUsModel } from '../Model/AboutUs.model';
import HTMLParser from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import { orderBy } from 'lodash';
import Img from 'react-image';
import './Less/AboutUs.css';
import './Less/Timeline.css';
import PressIcon from '../../../Assets/press-icon.png';
import CustomerIcon from '../../../Assets/customer-support-icon.png';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';

export interface IAboutUsViewProps {
	error: IError;
	loading: Promise<any> | null;
	data: AboutUsModel;
}

const AboutUsView: React.FC<IAboutUsViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	//TODO: ERROR PAGE MISSING

	React.useEffect(() => {
		props.loading !== null ? ControlSplash(true) : ControlSplash(false);
		return () => {};
	}, [props.loading]);
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	const disableFilter = () => {
		const filter: HTMLDivElement | null = document.querySelector(
			'.timeline-filter'
		);
		if (filter instanceof HTMLDivElement) filter.style.display = 'none';
	};
	window.scrollTo(0, 0);
	const ordered_slider = orderBy(props.data.sliders, ['order']);
	const found = routerContext.lang.options.find((e) => e.id !== 'en');
	return (
		<div className='about-us-view'>
			<div
				className='big-image-lead'
				style={{ backgroundImage: `url(${props.data.data.background})` }}
			>
				<div className='filter'></div>
				<div className='big-image-lead__container container'>
					<div className='big-image-lead__container--row row'>
						<div className='col-lg-6 col-md-7 col-xs-8'>
							<div className='title'>{props.data.data.title}</div>
							<div className='description'>
								{HTMLParser(props.data.data.description)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='carousel'>
				<div className='slider'>
					<div className='row'>
						<div className='content'>
							<div className='text'>
								<div>{HTMLParser(ordered_slider[0].content)}</div>
							</div>
						</div>
					</div>
					<Carousel
						responsive={responsive}
						swipeable={true}
						draggable={false}
						showDots={false}
						ssr={true}
						infinite={true}
					>
						{ordered_slider.map((slider, index) => {
							return (
								<div className='image' key={index}>
									<Img
										src={slider.image}
										alt={generateImgAlt(slider.image)}
										className='side-image'
									/>
								</div>
							);
						})}
					</Carousel>
				</div>
			</div>
			<div
				className='history'
				style={{
					backgroundImage: `url(${props.data.history.data.background})`,
				}}
			>
				<div className='title'>{props.data.history.data.title}</div>
				<div className='subtitle'>{props.data.history.data.subtitle}</div>
				<div
					className='timeline-filter'
					onClick={() => {
						disableFilter();
					}}
				>
					{GetLocaleText('about-us_filter')}
				</div>
				<div className='timeline-wrapper'>
					<div className='timeline'>
						{props.data.history.elements.map((element, index) => {
							return (
								<div
									key={`aboutus__${element.history_id}`}
									className={`timeline-container ${
										index % 2 === 0 ? 'left' : 'right'
									}`}
									data-year={element.year}
								>
									<div className='item'>
										<Img
											src={element.image}
											alt={generateImgAlt(element.image)}
										/>
										<p>{element.description}</p>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className={`information ${found?.id !== 'hu' && 'display-none'}`}>
				<div
					className='customer-service'
					style={{
						backgroundImage: `url(${props.data.info.customer_service_background})`,
					}}
				>
					<div className='title'>
						<div className='icon'>
							<Img src={CustomerIcon} alt=''></Img>
						</div>
						{props.data.info.customer_service_title}
					</div>
					<div className='content'>
						{HTMLParser(props.data.info.customer_service_content)}
					</div>
				</div>
				<div
					className='press-contact'
					id='press-contact'
					style={{
						backgroundImage: `url(${props.data.info.press_contact_background})`,
					}}
				>
					<div className='title'>
						<div className='icon'>
							<Img src={PressIcon} alt=''></Img>
						</div>
						{props.data.info.press_contact_title}
					</div>
					<div className='content'>
						{HTMLParser(props.data.info.press_contact_content)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUsView;

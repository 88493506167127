import { IContactForm } from './../Interfaces/IContact.interface';

export const ContactForm: IContactForm = {
	recipient: {
		id: 'recipient',
		value: null,
		required: true,
		options: [],
		labelText: 'Recipient',
		selectType: true,
	},
	name: {
		id: 'name',
		value: '',
		required: true,
		labelText: 'Name',
		type: 'text',
		textType: true,
	},
	email: {
		id: 'email',
		value: '',
		required: true,
		labelText: 'Email',
		type: 'email',
		textType: true,
	},
	phone: {
		id: 'phone',
		value: '',
		required: false,
		labelText: 'Phone',
		type: 'tel',
		textType: true,
	},
	vin: {
		id: 'vin',
		value: '',
		required: false,
		labelText: 'VIN',
		type: 'text',
		textType: true,
	},
	message: {
		id: 'message',
		value: '',
		textAreaType: true,
		labelText: 'Message',
		required: true,
		className: 'text-area',
	},
	attachment: {
		id: 'attachment',
		value: null,
		fileInputType: true,
	},
	acceptance: {
		id: 'acceptance',
		value: false,
		required: true,
		href: '/adatkezeles',
		hrefText: 'adatvédelmi',
		labelTextBefore: 'Elfogadom az',
		labelTextAfter: 'nyilatkozatot.',
		booleanType: true,
	},
};

import { axios } from '../../../Api/axios';
import { MainSlidersModel } from '../Model/MainSliders.model';
import { MainNewsModel } from '../Model/MainNews.model';
import {
  IMainSliders,
  IMainNews,
  IMainNewsTypes,
  IMainOffers
} from '../Interfaces/IMainPage.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { MainNewsTypesModel } from '../Model/MainNewsType.model';
import { MainOffersModel } from '../Model/MainOffers.model';

export default abstract class MainSlidersDAO {
  private static readonly sliders: string = '/api/index/sliders';
  private static readonly news: string = '/api/news/articles';
  private static readonly types: string = '/api/news/types';
  private static readonly offers: string = '/api/index/offers';

  public static async getSliders(): Promise<MainSlidersModel[]> {
    const request: AxiosPromise<IMainSliders[]> = axios
      .getInstance()
      .get(this.sliders);
    const response: AxiosResponse<IMainSliders[]> = await request;
    return response.data.map(
      (slider: IMainSliders) => new MainSlidersModel(slider)
    );
  }

  public static async getOffers(): Promise<MainOffersModel[]> {
    const request: AxiosPromise<IMainOffers[]> = axios
      .getInstance()
      .get(this.offers);
    const response: AxiosResponse<IMainOffers[]> = await request;
    return response.data.map(
      (slider: IMainOffers) => new MainOffersModel(slider)
    );
  }

  public static async getNews(params?: object): Promise<MainNewsModel[]> {
    const request: AxiosPromise<IMainNews[]> = axios
      .getInstance()
      .get(this.news, { params: params });
    const response: AxiosResponse<IMainNews[]> = await request;
    return response.data.map((news: IMainNews) => new MainNewsModel(news));
  }

  public static async getNewsTypes(): Promise<MainNewsTypesModel[]> {
    const request: AxiosPromise<IMainNewsTypes[]> = axios
      .getInstance()
      .get(this.types);
    const response: AxiosResponse<IMainNewsTypes[]> = await request;
    return response.data.map(
      (news_types: IMainNewsTypes) => new MainNewsTypesModel(news_types)
    );
  }
}

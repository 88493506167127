import React, { useState, useEffect } from 'react';
import BikeModel from '../Model/Bike.model';
import './Less/IntroSection.style.css';
import Img from 'react-image';
import HTMLReactParser from 'html-react-parser';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IIntroSectionProps {
	model: BikeModel;
}

const IntroSection: React.FC<IIntroSectionProps> = (
	props
): React.ReactElement => {
	const getActiveColorById = (id: number) =>
		props.model.colors.find((color) => {
			return color.colorId === id;
		});

	const [color, setColor] = useState(
		getActiveColorById(props.model.bike.defaultColorId)
	);

	useEffect(() => {
		const getActiveColorById = (id: number) =>
			props.model.colors.find((color) => {
				return color.colorId === id;
			});

		setColor(getActiveColorById(props.model.bike.defaultColorId));
	}, [props.model]);

	const setDeafultColor = (colorId: number) => {
		setColor(getActiveColorById(colorId));
	};
	return (
		<div className='intro-section-component' id='BIKE_DETAILS'>
			<div className='intro-section-logo-container'>
				<Img
					draggable={false}
					className='intro-section-logo-container__logo'
					src={props.model.bike.logo}
					alt={generateImgAlt(props.model.bike.logo) + '-logo'}
				/>
			</div>
			<div
				className='bike-inner-background'
				style={{ backgroundImage: `url(${props.model.bike.innerBackground})` }}
			>
				<div className='intro-section-main-bike-container'>
					<div className='intro-section-main-bike-container__left'>
						<div className='intro-section-main-bike-container__main-bike-image-container'>
							<img
								draggable={false}
								className='intro-section-main-bike-container__main-bike-image'
								src={color && color.bikeImage}
								alt={color && color.color01 + '-main-bike-image'}
							/>
						</div>
						{props.model.colors.length !== 0 ? (
							<div className='bike-color-picker'>
								<span className='bike-color-picker__title'>
									{GetLocaleText('color_scheme')}
								</span>
								{props.model.colors.map((color, index) => (
									<div
										className='bike-color-picker__inner-wrapper'
										key={`${index}-color-block`}
									>
										<span
											className='bike-color-picker__dot'
											onClick={() => setDeafultColor(color.colorId)}
											style={{
												background: `linear-gradient(90deg, #${color.color01} 0%, #${color.color02} 100%)`,
											}}
										/>
										<span>{color.colorName}</span>
									</div>
								))}
							</div>
						) : null}
					</div>
					<div className='intro-section-main-bike-container__right'>
						<h2 className='intro-section-main-bike-container__bike-slogan'>
							{props.model.lang.slogan}
						</h2>
						<div className='intro-section-main-bike-container__bike-introduction'>
							{HTMLReactParser(props.model.lang.description)}
						</div>
						{props.model.bike.license[0] === 'none' ||
						props.model.bike.license[0] === undefined ? null : (
							<>
								<div className='intro-section-licence-category__title'>
									{GetLocaleText('licence_categories')}
								</div>
								<div className='intro-section-licence-category__container'>
									{props.model.bike.license.length ? (
										props.model.bike.license.map(
											(licence: string, index: number) => (
												<span
													className='intro-section-licence-category__item-wrapper'
													key={`${index}-intro-block`}
												>
													<span
														className={[
															'intro-section-licence-category__item',
															licence.length > 3
																? 'intro-section-licence-category__item--extra-small'
																: licence.length > 2
																? 'intro-section-licence-category__item--small'
																: null,
														].join(' ')}
													>
														{licence}
													</span>
												</span>
											)
										)
									) : (
										<span
											className={'intro-section-licence-category__no-licence'}
										>
											{GetLocaleText('bike_no_license')}
										</span>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntroSection;

import * as React from 'react';
import './Less/Footer.css';
import footerLogo from '../../../Assets/footer-suzuki-logo.png';
import Img from 'react-image';
import FooterDAO from '../DAO/Footer.DAO';
import { IFooterLinks, IFooterLogos } from '../Interfaces/IFooter.interface';
import { useState } from 'react';
import { FooterLinksModel } from '../Model/FooterLinks.model';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { GetLocaleText } from '../../../I18n';

export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = (): React.ReactElement => {
	const routerContext: IRouterContext = React.useContext(RouterContext);
	const service_text = GetLocaleText('footer-service-portal-text');
	const [links, set_links] = useState<IFooterLinks>(new FooterLinksModel());
	const [logos, set_logos] = useState<IFooterLogos[]>([]);
	const [show_to_top, set_show_to_top] = useState(false);
	React.useEffect(() => {
		FooterDAO.getLogos().then((logo) => set_logos(logo));
		FooterDAO.getLinks().then((links) => {
			set_links(links);
		});
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', () => handleScroll);
		};
	}, []);
	const handleScroll = () => {
		window.scrollY > 500 ? set_show_to_top(true) : set_show_to_top(false);
	};
	const links_object = [
		{
			lang: {
				key: 'hu',
				link: {
					facebook: 'https://www.facebook.com/SuzukiMotorcyclesMagyarorszag',
					instagram: 'https://www.instagram.com/suzukimotorcyclesmagyarorszag/',
					youtube: 'https://www.youtube.com/SuzukiMotorcyclesMagyarorszag',
				},
			},
		},
		{
			lang: {
				key: 'ro',
				link: {
					facebook: 'https://www.facebook.com/suzukirom',
					instagram: 'https://www.instagram.com/suzuki.ro/?hl=ro',
					youtube: 'https://www.youtube.com/user/RomaniaSuzuki',
				},
			},
		},
		{
			lang: {
				key: 'sk',
				link: {
					facebook: 'https://www.facebook.com/Suzuki.Motocykle/',
					instagram: 'https://www.instagram.com/suzuki_slovakia/',
					youtube: 'https://www.youtube.com/user/SuzukiSlovakia',
				},
			},
		},
		{
			lang: {
				key: 'si',
				link: {
					facebook: 'https://www.facebook.com/suzukislo',
					instagram: '',
					youtube: '',
				},
			},
		},
		{
			lang: {
				key: 'hr',
				link: {
					facebook: 'https://www.facebook.com/suzuki.hr/',
					instagram: '',
					youtube: 'https://www.youtube.com/channel/UCAiGlfgURhBMlYKR__FtUVg',
				},
			},
		},
		{
			lang: {
				key: 'cz',
				link: {
					facebook: 'https://www.facebook.com/suzukimotoczech',
					instagram: 'https://www.instagram.com/suzuki_czech/',
					youtube: '',
				},
			},
		},
	];
	const found = routerContext.lang.options.find((e) => e.id !== 'en');
	let goo_map;
	if (found) goo_map = links_object.find((e) => e.lang.key === found?.id);
	let fb_link = '',
		ig_link = '',
		yt_link = '';
	if (found && goo_map) {
		fb_link = goo_map.lang.link.facebook;
		ig_link = goo_map.lang.link.instagram;
		yt_link = goo_map.lang.link.youtube;
	}
	return (
		<div className='footer'>
			<div className='proud'>
				<div className='logos'>
					{logos.map((logo, index) => {
						return (
							<a
								href={logo.url}
								key={`logos-${index}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								<Img src={logo.logo} alt={generateImgAlt(logo.logo)} />
							</a>
						);
					})}
				</div>
			</div>
			{}
			<div className='big-footer row'>
				<div className='socials'>
					<div
						className={`to_top item ${show_to_top}`}
						onClick={() => window.scrollTo(0, 0)}
					>
						<span>Scroll to top</span>
					</div>
					{fb_link.startsWith('http') ? (
						<a href={fb_link} target='_blank' rel='noopener noreferrer'>
							<div className='facebook item'></div>
						</a>
					) : null}

					{ig_link.startsWith('http') ? (
						<a href={ig_link} target='_blank' rel='noopener noreferrer'>
							<div className='instagram item'></div>
						</a>
					) : null}
					{yt_link.startsWith('http') ? (
						<a href={yt_link} target='_blank' rel='noopener noreferrer'>
							<div className='youtube item'></div>
						</a>
					) : null}
				</div>
				<div className='container'>
					<div className='col-lg-6 col-md-6 col-xs-12 left'>
						<div className='logo'>
							<Img src={footerLogo} alt={''} className='logo-image'></Img>
							<div className='logo-naming'>© Magyar Suzuki Zrt.</div>
						</div>
					</div>
					<div className='col-lg-6 col-md-6 col-xs-12 right'>
						<div className='links'>
							{links !== null
								? links.TOP?.map((link, index) => {
										return (
											<div
												className={`col-lg-6 links__item links__item--${index}`}
												key={`links-${index}`}
											>
												<a
													href={`${link.link}`}
													target='_blank'
													rel='noopener noreferrer'
												>
													{link.name}
												</a>
											</div>
										);
								  })
								: ''}
						</div>
						<div className='links-bottom'>
							{links !== undefined
								? links.BOTTOM?.map((link, index) => {
										return (
											<div className='link' key={`link-${index}`}>
												<a
													href={`${link.link}`}
													target='_blank'
													rel='noopener noreferrer'
												>
													{link.name}
												</a>
											</div>
										);
								  })
								: ''}
						</div>
					</div>
				</div>
			</div>
			<div className='hungary-service-text'>{service_text}</div>
		</div>
	);
};

export default Footer;

import React from 'react';
import { BlockElementType, TechSliderBlockElement } from '../Model/Bike.model';
import './Less/TechSlider.style.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import HTMLReactParser from 'html-react-parser';
import { getLocaleText } from '../../../I18n';
import { RouterContext } from '../../Router/Context/Router.context';
import prevArrow from '../../../Assets/BikePage/techSliderArrowBack.png';
import nextArrow from '../../../Assets/BikePage/techSliderArrowNext.png';
import { Swipeable } from 'react-swipeable';
import { scroller } from 'react-scroll';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface ITechSliderSectionProps {
	elements: BlockElementType[];
	hookID: number;
}

export interface ITechSliderSectionState {
	index: number;
	forward: boolean;
}

class TechSliderSection extends React.Component<
	ITechSliderSectionProps,
	ITechSliderSectionState
> {
	state = {
		index: 0,
		forward: false,
	};

	goTo = (index: number) => {
		if (index < this.state.index) this.setState({ index, forward: false });
		else this.setState({ index, forward: true });
	};

	goNext = () => {
		this.scrollToTitle();
		if (this.state.index < this.props.elements.length - 1)
			this.setState({ index: this.state.index + 1, forward: true });
		else this.setState({ index: 0, forward: true });
	};

	goBack = () => {
		this.scrollToTitle();
		if (this.state.index !== 0)
			this.setState({ index: this.state.index - 1, forward: false });
		else
			this.setState({ index: this.props.elements.length - 1, forward: false });
	};

	scrollToTitle() {
		if (window.innerWidth < 1000) {
			scroller.scrollTo('tech-slider-top', {
				duration: 100,
				offset: -32,
				delay: 300,
				smooth: 'easeInOut',
			});
		}
	}
	onSwipeLeft = (event: any) => {
		this.goNext();
	};

	onSwipeRight = (event: any) => {
		this.goBack();
	};

	render() {
		const { index } = this.state;
		const activeElement = this.props.elements[index];

		if (activeElement instanceof TechSliderBlockElement) {
			return (
				<div
					className='tech-slider-section-compoenent'
					id={`BLOCK_${this.props.hookID}`}
				>
					<Swipeable
						trackMouse={true}
						onSwipedLeft={this.onSwipeLeft}
						onSwipedRight={this.onSwipeRight}
					>
						<div className='tech-slider-left-side' id='tech-slider-top'>
							<div className='tech-slider-left-side__content-container'>
								<TransitionGroup
									className={[
										'tech-slider-left-side-transition-group',
										this.state.forward
											? 'tech-slider-forward'
											: 'tech-slider-backward',
									].join(' ')}
								>
									<CSSTransition
										key={activeElement.sliderId}
										timeout={300}
										classNames={'slide'}
									>
										<div className='tech-slider-left-side-transition-group__item'>
											<div className='tech-slider-title'>
												{HTMLReactParser(activeElement.title)}
											</div>
											<img
												src={activeElement.image}
												alt={generateImgAlt(activeElement.image)}
												className='tech-illustartion-mobile'
											/>
											<div className='tech-slider-desc'>
												{HTMLReactParser(activeElement.content)}
											</div>
										</div>
									</CSSTransition>
								</TransitionGroup>
								<div className='tech-slider-section-navigation-container'>
									<div
										className='tech-slider-section-navigation-left-button'
										onClick={() => this.goBack()}
									>
										<img
											src={prevArrow}
											alt='<'
											className='tech-slider-section-navigation-left-button__image'
										/>
										<p className='tech-slider-section-navigation-left-button__text'>
											{getLocaleText(
												this.context.lang.value.name,
												'bike_prev_tech',
												this.context
											)}
										</p>
									</div>
									<div className='tech-slider-section-navigation-indicators'>
										{this.props.elements.map((element, elementIndex) => {
											const minWidth = `calc(${
												100 / this.props.elements.length
											}% - 6px)`;

											return (
												<span
													key={`${elementIndex}-tech-slider-block`}
													className={[
														'tech-slider-section-navigation-indicators__item',
														elementIndex === this.state.index
															? 'tech-slider-section-navigation-indicators__item--active'
															: null,
													].join(' ')}
													onClick={() => this.goTo(elementIndex)}
													style={{
														minWidth,
													}}
												/>
											);
										})}
									</div>
									<div
										className='tech-slider-section-navigation-right-button'
										onClick={() => this.goNext()}
									>
										<p className='tech-slider-section-navigation-right-button__text'>
											{getLocaleText(
												this.context.lang.value.name,
												'bike_next_tech',
												this.context
											)}
										</p>
										<img
											src={nextArrow}
											alt='<'
											className='tech-slider-section-navigation-right-button__image'
										/>
									</div>
								</div>
							</div>
						</div>
					</Swipeable>
					<div className='tech-slider-right-side'>
						<TransitionGroup
							className={[
								'tech-slider-right-side-transition-group',
								this.state.forward
									? 'tech-slider-forward'
									: 'tech-slider-backward',
							].join(' ')}
						>
							<CSSTransition
								key={activeElement.sliderId}
								timeout={300}
								classNames={'fade'}
							>
								<img
									src={activeElement.image}
									alt={generateImgAlt(activeElement.image)}
									className='tech-illustartion'
								/>
							</CSSTransition>
						</TransitionGroup>
					</div>
				</div>
			);
		}

		return null;
	}
}

TechSliderSection.contextType = RouterContext;

export default TechSliderSection;

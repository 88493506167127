import { RouterContext } from './../Components/Router/Context/Router.context';
import { IDictionary } from './../Interfaces/IDictionary.interface';
import { IRouterContext } from '../Components/Router/Interfaces/IRouterContext.interface';
import { useContext } from 'react';

export const GetLocaleText = (text: string): string => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const data = require('./i18n.json');
	if (routerContext) {
		const found: IDictionary | undefined = routerContext.dictionary.find(
			(v) =>
				v.key === text && v.lang === routerContext.lang.value.name.toLowerCase()
		);
		if (found) return found.value;
		else return data['default']['EN'];
	}
	return '';
};

export const getLocaleText = (
	lang: string,
	text: string,
	context: IRouterContext
): string => {
	const data = require('./i18n.json');
	if (context) {
		const found: IDictionary | undefined = context.dictionary.find(
			(v) => v.key === text && v.lang === context.lang.value.name.toLowerCase()
		);
		if (found) return found.value;
		else return data['default']['EN'];
	}
	if (text === '' || text === null) text = 'default';
	if (data[text][lang.toUpperCase()]) return data[text][lang.toUpperCase()];
	else return data[text]['EN'];
};

export const hu = {
	page_id: 0,
	lang_id: 1,
	name: 'Cookie Details Page',
	slug: 'cookie',
	content: `<div class="custompage-view-cookie">
  <div class="custompage-view-cookie__content">
  <h2 class="mainhead__title">
  <span>COOKIE KEZEL&Eacute;SI SZAB&Aacute;LYZAT</span
  >
</h2>
<h2><span>Mik azok a cookie-k?</span></h2>
<p>
  A s&uuml;tik (cookie) olyan kis, sz&ouml;veges f&aacute;jlok, amelyeket egy
  weboldal haszn&aacute;l arra, hogy m&eacute;g hat&eacute;konyabb&aacute; tegye
  a felhaszn&aacute;l&oacute;i &eacute;lm&eacute;nyt. A s&uuml;tik egy
  t&iacute;pusa t&aacute;rolja a felhaszn&aacute;l&oacute; webes
  aktivit&aacute;sait, amelynek k&ouml;sz&ouml;nhetően a weboldal
  szem&eacute;lyre szabhat&oacute;. A s&uuml;tik m&aacute;sik elterjedt
  t&iacute;pusa a &ldquo;session cookie&rdquo;. Ezek a s&uuml;tik
  inform&aacute;ci&oacute;kat k&uuml;ldenek a weboldal szerver&eacute;nek. A
  session cookiekat nem t&aacute;rolja a b&ouml;ng&eacute;szője.
</p>
<p>
  Tov&aacute;bbi inform&aacute;ci&oacute;k&eacute;rt k&eacute;rj&uuml;k,
  l&aacute;togasson el az al&aacute;bbi oldalakra:
</p>
<p>
  <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener"
    >www.allaboutcookies.org&nbsp;</a
  >
</p>
<p>
  <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener"
    >www.youronlinechoices.eu</a
  >
</p>
<p>
  <a
    title="www.google.co.uk/policies/technologies/cookies"
    href="http://www.google.co.uk/policies/technologies/cookies"
    target="_blank"
    rel="noopener"
    >www.google.co.uk/policies/technologies/cookies</a
  >
</p>
<h2>
  <span>Mi&eacute;rt j&oacute; a felhaszn&aacute;l&oacute;nak?</span
  >
</h2>
<p>
  Sz&aacute;mtalan internetes szolg&aacute;ltat&aacute;s haszn&aacute;lata
  &eacute;s &eacute;lm&eacute;nye m&uacute;lik a s&uuml;tiken. Ennek
  seg&iacute;ts&eacute;g&eacute;vel tud p&eacute;ld&aacute;ul k&ouml;nnyen
  bejelentkezni egy jelsz&oacute;val v&eacute;dett fel&uuml;letre vagy
  &eacute;ppen szem&eacute;lyre szabott tartalmat el&eacute;rni a neten.
</p>
<h2>
  <span>S&uuml;tik (cookie-k) a Suzuki Magyarorsz&aacute;g weboldalain &eacute;s
    online szolg&aacute;ltat&aacute;saiban.</span
  >
</h2>
<p>
  Oldalaink min&eacute;l magasabb sz&iacute;nvonal&uacute;
  műk&ouml;d&eacute;s&eacute;nek biztos&iacute;t&aacute;sa &eacute;s a
  felhaszn&aacute;l&oacute;i &eacute;lm&eacute;ny n&ouml;vel&eacute;se
  &eacute;rdek&eacute;ben a Suzuki Magyarorsz&aacute;g weboldalain &eacute;s
  szolg&aacute;ltat&aacute;saiban s&uuml;tiket (&bdquo;cookie&rdquo;)
  haszn&aacute;lunk. A cookie-t
  sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;ről b&aacute;rmikor
  t&ouml;r&ouml;lheti, illetve be&aacute;ll&iacute;thatja
  b&ouml;ng&eacute;szőj&eacute;t &uacute;gy, hogy a cookiek
  alkalmaz&aacute;s&aacute;t tiltsa. A cookiek alkalmaz&aacute;s&aacute;nak
  tilt&aacute;s&aacute;val azonban tudom&aacute;sul veszi, hogy cookie
  n&eacute;lk&uuml;l az oldal műk&ouml;d&eacute;se nem lesz teljes
  &eacute;rt&eacute;kű. A cookiek alkalmaz&aacute;s&aacute;r&oacute;l
  t&aacute;j&eacute;koz&oacute;dhat az adatkezel&eacute;si
  szab&aacute;lyzataiban, amit&nbsp;<a
    href="https://www.suzuki.hu/corporate/hu/tartalom/adatvedelem"
    >ide</a>&nbsp;kattintva &eacute;rhet el.
</p>
<h2>
  <span>Harmadik szem&eacute;lyek &aacute;ltal elhelyezett cookie-k.</span
  >
</h2>
<p>
  Oldalaink tartalmazhatnak olyan inform&aacute;ci&oacute;kat, amelyek olyan
  harmadik szem&eacute;lyektől,
  rekl&aacute;mszolg&aacute;ltat&oacute;kt&oacute;l sz&aacute;rmaznak, akik nem
  &aacute;llnak kapcsolatban az Adatkezelővel. Előfordulhat, hogy ezen harmadik
  szem&eacute;lyek is elhelyeznek cookie-kat, web beacon-okat a
  felhaszn&aacute;l&oacute; sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;n,
  vagy hasonl&oacute; technol&oacute;gi&aacute;kat alkalmazva gyűjtenek adatokat
  annak &eacute;rdek&eacute;ben, hogy a felhaszn&aacute;l&oacute;nak a
  saj&aacute;t szolg&aacute;ltat&aacute;saikkal &ouml;sszef&uuml;gg&eacute;sben
  c&iacute;mzett rekl&aacute;m&uuml;zenetet k&uuml;ldjenek. Ilyen esetekben az
  adatkezel&eacute;sre az ezen harmadik szem&eacute;lyek &aacute;ltal
  meghat&aacute;rozott adatv&eacute;delmi elő&iacute;r&aacute;sok az
  ir&aacute;nyad&oacute;ak.
</p>
<h2>
  <span>A s&uuml;tikkel (cookie-k) kapcsolatos be&aacute;ll&iacute;t&aacute;sok a
    b&ouml;ng&eacute;szőkben.</span>
</h2>
<p>
  A s&uuml;tik (coockie-k) haszn&aacute;lat&aacute;t manu&aacute;lisan is
  be&aacute;ll&iacute;thatja minden felhaszn&aacute;l&oacute;. Minden
  felhelyezett s&uuml;tit t&ouml;r&ouml;lni lehet illetve
  felhelyez&eacute;s&uuml;ket megakad&aacute;lyozhatja a megfelelő
  be&aacute;ll&iacute;t&aacute;sokkal. Ezeket a
  be&aacute;ll&iacute;t&aacute;sokat az al&aacute;bbi b&ouml;ng&eacute;szők
  eset&eacute;ben a megadott linken tal&aacute;lhat&oacute;
  &uacute;tmutat&aacute;s alapj&aacute;n &aacute;ll&iacute;thatja be.
</p>
<p>
  <strong>Microsoft Internet Explorer</strong><br /><a
    href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies"
    target="_blank"
    rel="noopener"
    >https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies</a
  ><br /><strong>Microsoft Egde</strong><br /><a
    href="https://privacy.microsoft.com/hu-hu/windows-10-microsoft-edge-and-privacy"
    target="_blank"
    rel="noopener"
    >https://privacy.microsoft.com/hu-hu/windows-10-microsoft-edge-and-privacy</a
  ><br /><strong>Fire Fox</strong><br /><a
    href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn"
    target="_blank"
    rel="noopener"
    >https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn</a
  ><br /><strong>Chrome</strong><br /><a
    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=hu"
    target="_blank"
    rel="noopener"
    >https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=hu</a
  ><br /><strong>Safari</strong><br /><a
    href="https://support.apple.com/hu-hu/guide/safari/sfri35610/mac"
    >https://support.apple.com/hu-hu/guide/safari/sfri35610/mac</a
  ><br /><strong>IOS Safari</strong><br /><a
    href="https://support.apple.com/hu-hu/HT201265"
    target="_blank"
    rel="noopener"
    >https://support.apple.com/hu-hu/HT201265</a
  >
</p>
<h2>
  <span lang="hu-HU">Alapvető s&uuml;tik:</span>
</h2>
<p lang="en-GB" align="justify">
  <span lang="hu-HU"
    >Ezek n&eacute;lk&uuml;l a weboldal nem műk&ouml;dik rendesen. Ezeket nem
    lehet kikapcsolni &eacute;s rendszerint a weboldalon v&eacute;grehajtott
    tevek&eacute;nys&eacute;geknek megfelelően l&eacute;pnek
    műk&ouml;d&eacute;sbe, p&eacute;ld&aacute;ul: adatv&eacute;delem
    be&aacute;ll&iacute;t&aacute;sa, bejelentkez&eacute;s vagy űrlapok
    kit&ouml;lt&eacute;se.</span
  >
</p>
<div style="width: 100%; overflow-x: auto">
  <table
    style="
      border-collapse: collapse;
      width: 100.335%;
      height: 257px;
      margin-left: auto;
      margin-right: auto;
    "
    border="1px"
    cellspacing="33.33%"
  >
    <thead>
      <tr style="height: 52px">
        <td
          style="
            width: 11.6537%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p><br />S&uuml;ti neve</p>
        </td>
        <td
          style="
            width: 68.4795%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p><br />Le&iacute;r&aacute;sa</p>
        </td>
        <td
          style="
            width: 52.9026%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p><br />T&aacute;rol&aacute;s időtartama</p>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 52px">
        <td
          style="
            width: 11.6537%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p><br />lang</p>
        </td>
        <td
          style="
            width: 68.4795%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p class="gtx-body">
            <br />Az oldal nyelv v&aacute;lt&aacute;s&aacute;hoz
            sz&uuml;ks&eacute;ges s&uuml;ti.
          </p>
        </td>
        <td
          style="
            width: 52.9026%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p>
            &nbsp;<br />A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor&nbsp;
          </p>
        </td>
      </tr>
      <tr style="height: 52px">
        <td
          style="
            width: 11.6537%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p>CookieConsent</p>
        </td>
        <td
          style="
            width: 68.4795%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p>
            Azt figyeli, hogy a felhaszn&aacute;l&oacute; elfogadta-e m&aacute;r
            a s&uuml;tik haszn&aacute;lat&aacute;t az adott a weboldalon.
          </p>
        </td>
        <td
          style="
            width: 52.9026%;
            text-align: center;
            line-height: 10px;
            height: 52px;
          "
        >
          <p><br />1 &eacute;v</p>
        </td>
      </tr>
      <tr style="height: 59px">
        <td
          style="
            width: 11.6537%;
            text-align: center;
            line-height: 10px;
            height: 59px;
          "
        >
          <p>rc::a</p>
        </td>
        <td>
          <p style="text-align: center">
            Ez a s&uuml;ti az emberek &eacute;s a botok
            megk&uuml;l&ouml;nb&ouml;ztet&eacute;s&eacute;re szolg&aacute;l. Ez
            a webhely sz&aacute;m&aacute;ra előny&ouml;s, mivel
            &eacute;rv&eacute;nyes jelent&eacute;seketk&eacute;sz&iacute;thet a
            webhely haszn&aacute;lat&aacute;r&oacute;l.
          </p>
        </td>
        <td
          style="
            width: 52.9026%;
            text-align: center;
            line-height: 10px;
            height: 59px;
          "
        >
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor&nbsp;</p>
        </td>
      </tr>
      <tr style="height: 42px">
        <td
          style="
            width: 11.6537%;
            text-align: center;
            line-height: 10px;
            height: 42px;
          "
        >
          <p>rc::c</p>
        </td>
        <td>
          <p style="text-align: center">
            Ez a s&uuml;ti az emberek &eacute;s a botok
            megk&uuml;l&ouml;nb&ouml;ztet&eacute;s&eacute;re szolg&aacute;l.
          </p>
        </td>
        <td
          style="
            width: 52.9026%;
            text-align: center;
            line-height: 10px;
            height: 42px;
          "
        >
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor&nbsp;</p>
        </td>
      </tr>
      <tr>
        <td style="width: 11.6537%; text-align: center; line-height: 10px">
          <span style="font-size: 16px">secondLevelRoute</span>
        </td>
        <td>
          <p style="text-align: center">
            Nyelv v&aacute;lt&aacute;sakor t&aacute;rolja a pillanatnyi link
            c&iacute;m&eacute;t.
          </p>
        </td>
        <td style="width: 52.9026%; text-align: center; line-height: 10px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor&nbsp;</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<h2>
  <span><br />Teljes&iacute;tm&eacute;ny s&uuml;tik:</span
  >
</h2>
<p lang="en-GB" align="justify">
  <span lang="hu-HU"
    ><br />Feladata, hogy a b&ouml;ng&eacute;sző műk&ouml;d&eacute;s&eacute;t
    figyelje, ez&aacute;ltal seg&iacute;tse az inform&aacute;ci&oacute;k
    &eacute;s szolg&aacute;ltat&aacute;sok fejleszt&eacute;s&eacute;t.</span
  >
</p>
<div style="width: 100%; overflow-x: auto">
  <table style="width: 100%; height: 197px" border="1px" cellspacing="33.33%">
    <tbody>
      <tr style="height: 70px">
        <td
          style="
            text-align: center;
            width: 11.3192%;
            height: 70px;
            line-height: 18px;
          "
        >
          <p><br />S&uuml;ti neve</p>
        </td>
        <td
          style="
            text-align: center;
            width: 68.241%;
            height: 70px;
            line-height: 18px;
          "
        >
          <p><br />Le&iacute;r&aacute;sa</p>
        </td>
        <td
          style="
            text-align: center;
            width: 19.6254%;
            height: 70px;
            line-height: 18px;
          "
        >
          <p><br />T&aacute;rol&aacute;s időtartama</p>
        </td>
      </tr>
      <tr style="height: 57px">
        <td
          style="
            text-align: center;
            width: 11.3192%;
            height: 57px;
            line-height: 18px;
          "
        >
          <p>.ga</p>
        </td>
        <td
          style="
            text-align: center;
            width: 68.241%;
            height: 57px;
            line-height: 18px;
          "
        >
          <p>
            A s&uuml;ti a Googly Analytics műk&ouml;d&eacute;s&eacute;t
            seg&iacute;ti. Felhaszn&aacute;l&oacute;k
            megk&uuml;l&ouml;nb&ouml;ztet&eacute;se a c&eacute;lja
            az&aacute;ltal, hogy v&eacute;letlenszerűen rendel a
            felhaszn&aacute;l&oacute;khoz azonos&iacute;t&oacute;
            sz&aacute;mokat. Ez a weboldal minden oldalk&eacute;relm&eacute;ben
            szerepel, ennek seg&iacute;ts&eacute;g&eacute;vel
            t&ouml;rt&eacute;nik a l&aacute;togat&oacute;k, a munkamenet,
            &eacute;s a kamp&aacute;ny adatok sz&aacute;ml&aacute;l&aacute;sa a
            webhelyelemz&eacute;sekhez.
          </p>
        </td>
        <td
          style="
            text-align: center;
            width: 19.6254%;
            height: 57px;
            line-height: 18px;
          "
        >
          <p>2 &eacute;v</p>
        </td>
      </tr>
      <tr style="height: 51px">
        <td
          style="
            text-align: center;
            width: 11.3192%;
            height: 51px;
            line-height: 18px;
          "
        >
          <p>_gid</p>
        </td>
        <td
          style="
            text-align: center;
            width: 68.241%;
            height: 51px;
            line-height: 18px;
          "
        >
          <p>
            Ez a s&uuml;ti a Google Analytics műk&ouml;d&eacute;s&eacute;t
            seg&iacute;ti. A felhaszn&aacute;l&oacute;k
            megk&uuml;l&ouml;nb&ouml;ztet&eacute;s&eacute;re szolg&aacute;l.
          </p>
        </td>
        <td
          style="
            text-align: center;
            width: 19.6254%;
            height: 51px;
            line-height: 18px;
          "
        >
          <p>24 &oacute;ra</p>
        </td>
      </tr>
      <tr style="height: 19px">
        <td
          style="
            text-align: center;
            width: 11.3192%;
            height: 19px;
            line-height: 18px;
          "
        >
          <p>_gat</p>
        </td>
        <td
          style="
            text-align: center;
            width: 68.241%;
            height: 19px;
            line-height: 18px;
          "
        >
          <p>
            A Googly Analytics műk&ouml;d&eacute;s&eacute;t seg&iacute;ti. Ez
            cs&ouml;kkenti a k&eacute;r&eacute;sek ar&aacute;ny&aacute;t &ndash;
            azaz korl&aacute;tozza az adatgyűjt&eacute;st a magas
            forgalm&uacute; webhelyeken.
          </p>
        </td>
        <td
          style="
            text-align: center;
            width: 19.6254%;
            height: 19px;
            line-height: 18px;
          "
        >
          <p>1 perc</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 11.3192%; line-height: 18px">
          <span style="font-size: 16px">collect</span>
        </td>
        <td style="text-align: center; width: 68.241%; line-height: 18px">
          <p>
            Adatok k&uuml;ld&eacute;se a Google Analytics-hez a
            l&aacute;togat&oacute; eszk&ouml;z&eacute;ről &eacute;s
            viselked&eacute;s&eacute;ről. K&ouml;veti a l&aacute;togat&oacute;t
            az eszk&ouml;z&ouml;k&ouml;n &eacute;s a marketing
            csatorn&aacute;kon kereszt&uuml;l.
          </p>
        </td>
        <td style="text-align: center; width: 19.6254%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor&nbsp;</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<h2>
  <span lang="hu-HU"
    ><br />K&uuml;lső partnerek s&uuml;tijei:</span
  >
</h2>
<p>
  <span lang="hu-HU"
    >A weboldalon be&aacute;gyazott tartalmak haszn&aacute;lj&aacute;k,
    p&eacute;ld&aacute;ul a k&ouml;z&ouml;ss&eacute;gi m&eacute;di&aacute;ban,
    t&eacute;rk&eacute;peken vagy vide&oacute;kban.</span
  >
</p>
<div style="width: 100%; overflow-x: auto">
  <table style="width: 100%" border="1px" cellspacing="33.33%">
    <tbody>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <p><br />S&uuml;ti neve</p>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p><br />Le&iacute;r&aacute;sa</p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p><br />T&aacute;rol&aacute;s időtartama</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <p>NID</p>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A NID cookie egy egyedi azonos&iacute;t&oacute;t tartalmaz, amelyet
            a Google az &Ouml;n preferenci&aacute;inak &ndash;
            p&eacute;ld&aacute;ul az előnyben r&eacute;szes&iacute;tett nyelv
            (pl. a magyar), a keres&eacute;si tal&aacute;latok
            megjelen&iacute;teni k&iacute;v&aacute;nt sz&aacute;ma (pl. 10 vagy
            20), vagy, hogy bekapcsolja-e a Google Biztons&aacute;gos
            keres&eacute;s szűrőt &ndash; &eacute;s egy&eacute;b adatainak
            t&aacute;rol&aacute;s&aacute;ra haszn&aacute;l.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>6 h&oacute;nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <p>IDE</p>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            Ezt a cookie-t az online hirdet&eacute;sek &uacute;jb&oacute;li
            c&eacute;lz&aacute;s&aacute;ra,
            optimaliz&aacute;l&aacute;s&aacute;ra, jelent&eacute;s&eacute;re
            &eacute;s hozz&aacute;rendel&eacute;s&eacute;re
            haszn&aacute;lj&aacute;k.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>2 h&oacute;nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">test_cookie</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            Annak ellenőrz&eacute;s&eacute;re szolg&aacute;l, hogy a
            felhaszn&aacute;l&oacute; b&ouml;ng&eacute;szője t&aacute;mogatja-e
            a s&uuml;tik haszn&aacute;lat&aacute;t.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>1 nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">fr</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A Facebook a hirdet&eacute;si term&eacute;kek sorozat&aacute;nak
            sz&aacute;ll&iacute;t&aacute;s&aacute;ra haszn&aacute;lja,
            p&eacute;ld&aacute;ul val&oacute;s idejű licit&aacute;l&aacute;st
            harmadik f&eacute;l hirdetőitől.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>3 h&oacute;nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">tr</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A Facebook a hirdet&eacute;si term&eacute;kek sorozat&aacute;nak
            sz&aacute;ll&iacute;t&aacute;s&aacute;ra haszn&aacute;lja,
            p&eacute;ld&aacute;ul val&oacute;s idejű licit&aacute;l&aacute;st
            harmadik f&eacute;l hirdetőitől.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">_fbp</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A Facebook a hirdet&eacute;si term&eacute;kek sorozat&aacute;nak
            sz&aacute;ll&iacute;t&aacute;s&aacute;ra haszn&aacute;lja,
            p&eacute;ld&aacute;ul val&oacute;s idejű licit&aacute;l&aacute;st
            harmadik f&eacute;l hirdetőitől.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>3 h&oacute;nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">GPS</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            Egyedi azonos&iacute;t&oacute;t regisztr&aacute;l a mobil
            eszk&ouml;z&ouml;k&ouml;n, hogy lehetőv&eacute; tegye a nyomon
            k&ouml;vet&eacute;st a f&ouml;ldrajzi GPS-hely alapj&aacute;n.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>1 nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">VISITOR_INFO1_LIVE</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            Ez a cookie megpr&oacute;b&aacute;lja megbecs&uuml;lni a
            felhaszn&aacute;l&oacute;k
            s&aacute;vsz&eacute;less&eacute;g&eacute;t az integr&aacute;lt
            YouTube-vide&oacute;kat tartalmaz&oacute; oldalakon.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>179 nap</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">YSC</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            Az egyedi azonos&iacute;t&oacute;t regisztr&aacute;lja, hogy
            statisztik&aacute;t t&aacute;roljon arr&oacute;l, hogy a YouTube-on
            milyen vide&oacute;kat l&aacute;tott a felhaszn&aacute;l&oacute;.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">yt-remote-cast-installed</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A felhaszn&aacute;l&oacute; vide&oacute;
            lej&aacute;tsz&oacute;j&aacute;nak be&aacute;ll&iacute;t&aacute;sait
            a be&aacute;gyazott YouTube-vide&oacute;val t&aacute;rolja.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">yt-remote-connected-devices</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A felhaszn&aacute;l&oacute; vide&oacute;
            lej&aacute;tsz&oacute;j&aacute;nak be&aacute;ll&iacute;t&aacute;sait
            a be&aacute;gyazott YouTube-vide&oacute;val t&aacute;rolja.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>T&ouml;rl&eacute;sig</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">yt-remote-device-id</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A felhaszn&aacute;l&oacute; vide&oacute;
            lej&aacute;tsz&oacute;j&aacute;nak be&aacute;ll&iacute;t&aacute;sait
            a be&aacute;gyazott YouTube-vide&oacute;val t&aacute;rolja.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>T&ouml;rl&eacute;sig</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">yt-remote-fast-check-period</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A felhaszn&aacute;l&oacute; vide&oacute;
            lej&aacute;tsz&oacute;j&aacute;nak be&aacute;ll&iacute;t&aacute;sait
            a be&aacute;gyazott YouTube-vide&oacute;val t&aacute;rolja.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">yt-remote-session-app</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A felhaszn&aacute;l&oacute; vide&oacute;
            lej&aacute;tsz&oacute;j&aacute;nak be&aacute;ll&iacute;t&aacute;sait
            a be&aacute;gyazott YouTube-vide&oacute;val t&aacute;rolja.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: center; width: 10.9121%; line-height: 18px">
          <span style="font-size: 16px">yt-remote-session-name</span>
        </td>
        <td style="text-align: center; width: 68.7297%; line-height: 18px">
          <p>
            A felhaszn&aacute;l&oacute; vide&oacute;
            lej&aacute;tsz&oacute;j&aacute;nak be&aacute;ll&iacute;t&aacute;sait
            a be&aacute;gyazott YouTube-vide&oacute;val t&aacute;rolja.
          </p>
        </td>
        <td style="text-align: center; width: 19.5439%; line-height: 18px">
          <p>A b&ouml;ng&eacute;sző bez&aacute;r&aacute;sakor</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<p>
  <br />Folyamatosan dolgozunk azon, hogy fejlessz&uuml;k weboldalainkat
  &eacute;s digit&aacute;lis szolg&aacute;ltat&aacute;sainkat. Ennek
  &eacute;rdek&eacute;ben a Google Analytics seg&iacute;ts&eacute;g&eacute;vel
  elemezz&uuml;k felhaszn&aacute;l&oacute;ink anonim, &ouml;sszes&iacute;tett
  magatart&aacute;sform&aacute;it.<br />Tov&aacute;bb&aacute; azt is
  enged&eacute;lyezz&uuml;k webelemző eszk&ouml;z&uuml;nknek, a Goole
  Analytics-nek, hogy a cookie-kat kezelje, illetve cookie-kat helyezzen el ezen
  a weboldalon. Az ebből k&eacute;sz&uuml;lt statisztik&aacute;k
  seg&iacute;ts&eacute;g&eacute;vel fejlesztj&uuml;k a
  felhaszn&aacute;l&oacute;i &eacute;lm&eacute;nyeket.<br /><br />Tov&aacute;bbi
  inform&aacute;ci&oacute;&eacute;rt k&eacute;rj&uuml;k, keresse
  adatv&eacute;delmi munkat&aacute;rsunkat a&nbsp;
  <a title="privacy@suzuki.hu" href="mailto:privacy@suzuki.hu"
    >privacy@suzuki.hu</a
  >
  &nbsp;email c&iacute;men.
</p>
</div>
</div>`,
};

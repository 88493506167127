import React from 'react';
import { RouteChildrenProps } from 'react-router';
import PDFView from '../View/PDF.view';

export interface IPDFControllerProps extends RouteChildrenProps {
	url: string;
}

const PDFController: React.FC<IPDFControllerProps> = (
	props
): React.ReactElement => {
	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>Használati útmutató</div>
			</div>
			<PDFView />
		</div>
	);
};

export default PDFController;

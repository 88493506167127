import { map } from "lodash";
import { NewsModel } from "../Components/News/Model/News.model";
import { NewsTypesModel } from "../Components/News/Model/NewsTypes.model";

export const changeNewsIdToName = (
    news: NewsModel[],
    news_type_array: NewsTypesModel[]
): NewsModel[] => {
    if(news && news_type_array){
        if (news.length !== 0) {
            map(news, (article) => {
                if(article)
                map(news_type_array, (news_type, index) => {
                    if (article.news_type_id === news_type.news_type_id) {
                        article.news_type_name = String(news_type_array[index].name);
                    }
                });
            });
        }
        return news;
    }
    return [new NewsModel()];
};

import { AxiosPromise, AxiosResponse } from 'axios';
import { axios } from '../../../Api/axios';
import { AboutUsModel } from '../Model/AboutUs.model';

export default abstract class AboutUsDAO {
  private static readonly base: string = '/api/about-us';

  public static async getAboutUsData(): Promise<AboutUsModel> {
    const request: AxiosPromise<AboutUsModel> = axios
      .getInstance()
      .get(this.base);
    const response: AxiosResponse<AboutUsModel> = await request;
    return response.data;
  }
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IOwners } from '../Interfaces/IOwners.interface';

export class OwnersModel {
  constructor(Owners?: IOwners) {
    if (Owners) {
      ModelConstructor(Owners, this);
    }
  }
  element_id: string = '';
  lang_id: string = '';
  title: string = '';
  description: string = '';
  btn_text: string = '';
  url: string = '';
  background: string = '';
  order: string = '';
}

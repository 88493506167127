import { axios } from '../../../Api/axios';
import { CatalogModel } from '../Model/Catalog.model';
import { ICatalog } from '../Interfaces/ICatalog.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ICatalogTypes } from '../Interfaces/ICatalogTypes.interface';
import { CatalogTypesModel } from '../Model/CatalogTypes.model';

export default abstract class CatalogDAO {
  private static readonly base: string = '/api/catalogs';
  private static readonly types: string = '/api/catalogs/types';
  private static readonly type_filter: string = '/api/catalogs/types';

  public static async getCatalog(params?: object): Promise<CatalogModel[]> {
    const request: AxiosPromise<ICatalog[]> = axios
      .getInstance()
      .get(this.base, { params: params });
    const response: AxiosResponse<ICatalog[]> = await request;
    return response.data.map(
      (catalogs: ICatalog) => new CatalogModel(catalogs)
    );
  }

  public static async getCatalogByType(
    type_id: string,
    params?: object
  ): Promise<CatalogModel[]> {
    const request: AxiosPromise<ICatalog[]> = axios
      .getInstance()
      .get(`${this.type_filter}/${type_id}/catalogs`, { params: params });
    const response: AxiosResponse<ICatalog[]> = await request;
    return response.data.map(
      (catalogs: ICatalog) => new CatalogModel(catalogs)
    );
  }

  public static async getCatalogTypes(): Promise<CatalogTypesModel[]> {
    const request: AxiosPromise<ICatalogTypes[]> = axios
      .getInstance()
      .get(this.types);
    const response: AxiosResponse<ICatalogTypes[]> = await request;
    return response.data.map(
      (catalog_types: ICatalogTypes) => new CatalogTypesModel(catalog_types)
    );
  }
}

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { BikesModel } from '../Model/Bikes.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import BikesView from '../View/Bikes.view';
import BikesDAO from '../DAO/Bikes.DAO';
import { GetLocaleText } from '../../../I18n';
import { BikeTypesModel } from '../Model/BikeTypes.model';
import { hayaOrder } from '../../../Utils/HayaOrder.util';

export interface IBikesProps extends RouteChildrenProps {
	filterBikeTypesById?: number;
	label: string;
}

const Bikes: React.FC<IBikesProps> = (props): React.ReactElement => {
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [bikes, set_bikes] = useState<BikesModel[]>([]);
	const [error, set_error] = useState<IError>(null);
	const [bike_types, set_bike_types] = useState<BikeTypesModel[]>([]);

	const routerContext: IRouterContext = useContext(RouterContext);

	const loadBikes = (): void => {
		const type = props.label === 'BIKES' ? 'BIKE' : 'SCOOTER';
		set_loading(
			BikesDAO.getBikes({ type })
				.then((bikes: BikesModel[]) => {
					const filteredBikes = filterBikes(bikes);
					loadBikeTypes(filteredBikes);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => set_loading(null))
		);
	};

	const filterBikes = (full_list: BikesModel[]): BikesModel[] => {
		let removedDrafts: BikesModel[] = [];
		full_list.map((item) => {
			if (item.is_draft === '0' && item.is_emphasized === '1')
				return removedDrafts.push(item);
			return '';
		});
		set_bikes(removedDrafts);
		return removedDrafts;
	};

	const loadBikeTypes = (filteredBikes: BikesModel[]): void => {
		BikesDAO.getBikeTypes().then((bike_types: BikeTypesModel[]) => {
			filterTypes(filteredBikes, bike_types);
		});
	};

	const filterTypes = (
		bikes: BikesModel[],
		all_types: BikeTypesModel[]
	): void => {
		let filteredTypes: BikeTypesModel[] = [];
		let bikeTypes: string[] = [];

		if (props.filterBikeTypesById) {
			bikeTypes.push(props.filterBikeTypesById.toString());
		} else {
			bikes.map((bike) => {
				return bikeTypes.push(bike.type_id);
			});
		}

		all_types.map((type) => {
			if (bikeTypes.includes(type.type_id)) return filteredTypes.push(type);
			return '';
		});
		set_bike_types(hayaOrder(filteredTypes));
	};

	useEffect(
		() => {
			loadBikes();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			{!props.filterBikeTypesById ? (
				<div className='menu-title'>
					<div className='menu-title__text'>
						{props.label === 'BIKES'
							? GetLocaleText('bikes_menu')
							: GetLocaleText('scooters_menu')}
					</div>
				</div>
			) : null}
			<BikesView
				loading={loading}
				bikes={bikes}
				error={error}
				lang={routerContext.lang.value.name}
				bike_types={bike_types}
			/>
		</div>
	);
};

export default Bikes;

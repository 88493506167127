import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IPricelistText } from '../Interfaces/IPricelist.interface';

export class PricelistTextModel {
  constructor(PricelistText?: IPricelistText) {
    if (PricelistText) {
      ModelConstructor(PricelistText, this);
    }
  }

  lang_id: string = '';
  content: string = '';
  pdf: string = '';
}

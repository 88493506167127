import React, { useContext, useEffect, useState } from 'react';

import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import ContactView from '../View/Contact.view';
import { GetLocaleText } from '../../../I18n';
import { IContactForm } from '../Interfaces/IContact.interface';
import { ContactForm } from '../Static/Contact.form';
import { cloneDeep } from 'lodash';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { changeHandler, setOptions } from '../../../Utils/InputChange.util';
import { validateFormData } from '../../../Utils/ValidateForm.util';
import ContactDAO from '../DAO/Contact.DAO';

export interface IContactControllerProps
	extends RouteChildrenProps<{ slug: string }> {}

const ContactController: React.FC<IContactControllerProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);

	const initForm = (): IContactForm => {
		const initialForm: IContactForm = cloneDeep(ContactForm);
		return initialForm;
	};

	const [form, set_form] = useState<IContactForm>(initForm());
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [form_sent, set_form_sent] = useState<boolean>(false);
	const [error, set_error] = useState<any>(null);
	const [file_list, set_file_list] = useState<File[]>([]);

	const errorMessages: Array<string> = [
		GetLocaleText('ex_fin__form_err_pp'),
		GetLocaleText('ex_fin__form_err_fields'),
		GetLocaleText('ex_fin__form_err_length'),
		GetLocaleText('ex_fin__form_err_select'),
		GetLocaleText('ex_fin__form_err_file'),
	];

	useEffect(
		() => {
			fillRecipients();
			return () => {
				set_form_sent(false);
			};
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	const fillRecipients = () => {
		let new_form = initForm();
		const recipient_options = [
			{ id: '1', name: 'Prodej moto', addr: 'suzuki@uamk.cz' },
			{
				id: '2',
				name: 'Servis + náhradní díly',
				addr: 'suzuki@uamk.cz',
			},
		];
		new_form = setOptions(new_form, 'recipient', recipient_options);
		new_form = changeHandler(new_form, 'recipient', recipient_options[0]);

		set_form(new_form);
	};

	const onChange = (args: IInputChange): void => {
		if (args.fieldName !== 'attachment') {
			set_form(changeHandler(form, args.fieldName, args.value));
		} else {
			const new_list = cloneDeep(file_list);
			new_list.push(args.value);
			set_file_list(new_list);
			set_form(changeHandler(form, args.fieldName, args.value));
		}
	};

	const parseFormData = (): FormData | null => {
		const data: FormData = new FormData();
		for (const field of Object.values(form)) {
			if ('booleanType' in field) {
				continue;
			}

			if ('textType' in field) {
				data.append(field.id, field.value);
				continue;
			}

			if ('selectType' in field) {
				data.append(field.id, `${field.value['addr']}`);
				data.append(`${field.id}-option`, `${field.value['name']}`);
				continue;
			}

			data.append(field.id, `${field.value}` || 'null');
		}
		file_list.forEach((file) => data.append('attachment', file));

		return data;
	};

	const onSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		set_error('');
		const isFormOk = validateFormData(form, errorMessages, file_list);
		if (isFormOk === null) {
			const data: FormData | null = parseFormData();
			if (!data) return;
			set_loading(
				ContactDAO.create(data, routerContext.lang.value.name.toLowerCase())
					.then((data) => {
						set_form_sent(true);
					})
					.catch((e) => set_error(e))
					.finally(() => {
						set_loading(null);
					})
			);
		} else {
			set_error(isFormOk.join('<br/>'));
		}
	};

	const onDelete = (i: number) => {
		set_file_list(file_list.filter((_, idx) => idx !== i));
	};

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>
					{GetLocaleText('contact_page__header_title')}
				</div>
			</div>
			<ContactView
				form={form}
				form_sent={form_sent}
				onChange={onChange}
				loading={loading}
				onSubmit={onSubmit}
				error={error}
				onDelete={onDelete}
			/>
		</div>
	);
};

export default ContactController;

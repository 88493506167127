export const ro = {
	categories: [
		{
			id: '1',
			name: 'Necesare',
			description:
				'Cookie-urile necesare ajută la a face un site utilizabil prin activarea funcţiilor de bază, precum navigarea în pagină şi accesul la zonele securizate de pe site. Site-ul nu poate funcţiona corespunzător fără aceste cookie-uri.',
		},
		{
			id: '2',
			name: 'Statistici',
			description:
				'Cookie-urile de statistică îi ajută pe proprietarii unui site să înţeleagă modul în care vizitatorii interacţionează cu site-urile prin colectarea şi raportarea informaţiilor în mod anonim.',
		},
		{
			id: '3',
			name: 'Marketing',
			description:
				'Cookie-urile de marketing sunt utilizate pentru a-i urmări pe utilizatori de la un site la altul. Intenţia este de a afişa anunţuri relevante şi antrenante pentru utilizatorii individuali, aşadar ele sunt mai valoroase pentru agenţiile de puiblicitate şi părţile terţe care se ocupă de publicitate.',
		},
	],
	insert_codes: [
		{
			category: '2',
			tag: 'script',
			value:
				'(function (i, s, o, g, r, a, m) {i["GoogleAnalyticsObject"] = r; (i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }), (i[r].l = 1 * new Date());(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "UA-656346-10", "auto");ga("send", "pageview");',
		},
		{
			category: '3',
			tag: 'script',
			value:
				'!(function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "582159962479535");fbq("track", "PageView");',
		},
		{
			category: '3',
			tag: 'noscript',
			value:
				'<img height="1" width="1" src="https://www.facebook.com/tr?id=582159962479535&ev=PageView&noscript=1"/>',
		},
	],
	cookies: [
		{
			Name: 'iTechCookie',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Folosit pentru a monitoriza dacă utilizatorul a acceptat deja utilizarea cookie-urilor de pe site.',
		},
		{
			Name: 'lang',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Cookie-uri necesare pentru a schimba limba paginii.',
		},
		{
			Name: 'secondLevelRoute',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Când schimbați limba, stocheaza adresa linkului curent.',
		},
		{
			Name: '_ga',
			Provider: 'suzuki.hu',
			ExpireDescription: '2 years',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Acest cookie asistă Google Analytics în funcționarea sa. Folosit pentru a distinge utilizatorii prin atribuirea unui număr de identificare aleatoriu fiecărui utilizator. Acest cookie este prezent în toate solicitările de pagină pentru site-ul web și este utilizat pentru a număra vizitatorii, sesiunile și datele campaniei pentru analiza site-ului.',
		},
		{
			Name: '_gat',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Acest cookie asistă Google Analytics în funcționarea sa. Folosit pentru a limita rata solicitării, adică limitează colectarea datelor pe site-urile cu trafic mare.',
		},
		{
			Name: '_gid',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Acest cookie asistă Google Analytics în funcționarea sa. Folosit pentru a distinge utilizatorii.',
		},
		{
			Name: 'fr',
			Provider: 'facebook.com',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Folosit de Facebook pentru a livra o serie de produse publicitare, cum ar fi licitarea în timp real de la agenții de publicitate terți.',
		},
		{
			Name: 'tr',
			Provider: 'facebook.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'Pixel',
			Category: '3',
			PurposeDescription:
				'Folosit de Facebook pentru a livra o serie de produse publicitare, cum ar fi licitarea în timp real de la agenții de publicitate terți.',
		},
		{
			Name: 'NID',
			Provider: 'google.com',
			ExpireDescription: '6 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Cookie-ul NID conține un ID unic pe care Google îl folosește pentru a vă aminti preferințele - cum ar fi limba preferată (de exemplu, romană), câte rezultate de căutare doriți să afiseze (de exemplu, 10 sau 20) și dacă doriți sau nu să aveți Google Filtrul Sigur de Căutare   activat și, de asemenea, pentru a stoca celelalte informații.',
		},
		{
			Name: '_fbp',
			Provider: 'suzuki.hu',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Folosit de Facebook pentru a livra o serie de produse publicitare, cum ar fi licitarea în timp real de la agenții de publicitate terți.',
		},
		{
			Name: 'x-bni-fpc',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
		{
			Name: 'x-bni-rncf',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
	],
};

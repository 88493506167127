import { IRouteCmp } from '../Interfaces/IRouteAsComponent.interface';
import { IDynamicObject } from '../Interfaces/IDynamicObjcet.interface';
import SingleOffer from '../Components/Offers/Controller/SingleOffer.controller';
import Offers from '../Components/Offers/Controller/Offers.controller';
import Video from '../Components/Video/Controller/Video.controller';
import News from '../Components/News/Controller/News.controller';
import SingleArticle from '../Components/News/Controller/SingleArticle.controller';
import CustomPage from '../Components/CustomPage/Controller/CustomPage.controller';
import Catalog from '../Components/Catalog/Controller/Catalog.controller';
import AboutUsController from '../Components/AboutUs/Controller/AboutUs.controller';
import Bikes from '../Components/Bikes/Controller/Bikes.controller';
import PricelistController from '../Components/Pricelist/Controller/Pricelist.controller';
import Owners from '../Components/Owners/Controller/Owners.controller';
import Resellers from '../Components/Resellers/Controller/Resellers.controller';
import TestDriveController from '../Components/TestDrive/Controller/TestDrive.controller';
import Bike from '../Components/Bike/Controller/Bike.controller';
import Financing from '../Components/Financing/Controller/Financing.controller';
import ContactController from '../Components/Contact/Controller/Contact.controller';
import ExportFinancingController from '../Components/ExportFinancing/Controller/ExportFinancing.controller';
import NewDealerController from '../Components/NewDealer/Controller/NewDealer.controller';
import ContactROController from '../Components/Contact_RO/Controller/Contact_ro.controller';

export const RouteAsComponent: IDynamicObject<IRouteCmp> = {
	OFFERS: {
		component: Offers,
		sub_url: '/:offer_id',
		sub_cmp: SingleOffer,
	},
	NEWS: {
		component: News,
		sub_url: '/:slug',
		sub_cmp: SingleArticle,
	},
	VIDEOS: {
		component: Video,
	},
	PAGES: {
		component: CustomPage,
		sub_url: '/:slug',
		sub_cmp: CustomPage,
	},
	CATALOGS: {
		component: Catalog,
	},
	ABOUTUS: {
		component: AboutUsController,
	},
	BIKES: {
		component: Bikes,
		sub_url: '/:slug',
		sub_cmp: Bike,
	},
	PRICELIST: {
		component: PricelistController,
	},
	OWNERS: {
		component: Owners,
	},
	RESELLERS: {
		component: Resellers,
	},
	SCOOTER: {
		component: Bikes,
		sub_url: '/:slug',
		sub_cmp: Bike,
	},
	TESTDRIVE: {
		component: TestDriveController,
	},
	CONTACT: {
		component: ContactController,
	},
	CONTACTRO: {
		component: ContactROController,
	},
	EXPORTFINANCING: {
		component: ExportFinancingController,
	},
	NEWDEALER: {
		component: NewDealerController,
	},
	CALCULATOR: {
		component: Financing,
		sub_url: '/:slug',
		sub_cmp: Financing,
	},
};

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { dateStringToDate } from '../../../Utils/DateStringToDate.util';
import { IMainNews } from '../Interfaces/IMainPage.interface';
import { ISingleNewsRelations } from '../../News/Interfaces/INews.interface';

export class MainNewsModel {
  constructor(News?: IMainNews) {
    if (News) {
      ModelConstructor(News, this);

      this.public_at_as_date = dateStringToDate(News.published_at);
    }
  }
  news_id: string = '';
  news_type_id: string = '';
  lang_id: string = '';
  name: string = '';
  short_desc: string = '';
  image: string = '';
  published_at: string = '';
  is_draft: string = '';
  slug: string = '';
  image_ratio: string = '';
  text_position_x: string = '';
  inner_image: string = '';
  text_position_y: string = '';
  //Added properties
  news_type_name: string = '';
  og_desc: string = '';
  og_image: string = '';
  og_title: string = '';

  relations: ISingleNewsRelations[] = [
    {
      lang: '',
      name: '',
      news_id: '',
      slug: '',
    },
  ];
  public_at_as_date: Date = new Date();
}

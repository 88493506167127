import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { TestDriveBikesModel } from '../Model/TestDriveBikes.model';
import { TestDriveResellersModel } from '../Model/TestDriveResellers.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import TestDriveView from '../View/TestDrive.view';
import TestDriveDAO from '../DAO/TestDrive.DAO';
import { GetLocaleText } from '../../../I18n';

export interface ITestDriveProps extends RouteChildrenProps {}

const TestDriveController: React.FC<ITestDriveProps> = (
	props
): React.ReactElement => {
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [bikes, set_bikes] = useState<TestDriveBikesModel[]>([]);
	const [error, set_error] = useState<IError>(null);
	const [bike_selected, set_bike_selected] = useState(0);
	const [reseller_selected, set_reseller_selected] = useState(0);
	const [resellers, set_resellers] = useState<TestDriveResellersModel[]>([]);
	const [name, set_name] = useState('');
	const [place, set_place] = useState('');
	const [email, set_email] = useState('');
	const [tel, set_tel] = useState('');
	const [form_sent, set_form_sent] = useState(false);

	const routerContext: IRouterContext = useContext(RouterContext);

	const handleBikeChange = (bike_id: number): void => {
		set_bike_selected(bike_id);
	};
	const handleResellerChange = (reseller_id: number): void => {
		set_reseller_selected(reseller_id);
	};

	const handleNameChange = (e: any): void => {
		set_name(e.target.value);
	};
	const handlePlaceChange = (e: any): void => {
		set_place(e.target.value);
	};
	const handleEmailChange = (e: any): void => {
		set_email(e.target.value);
	};
	const handleTelChange = (e: any): void => {
		set_tel(e.target.value);
	};

	const loadBikes = (): void => {
		set_loading(
			TestDriveDAO.getTestBikes()
				.then((bikes: TestDriveBikesModel[]) => {
					set_bikes(bikes);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => set_loading(null))
		);
	};

	const loadResellers = (bike_id: number): void => {
		set_loading(
			TestDriveDAO.getResellers(bike_id)
				.then((resellers: TestDriveResellersModel[]) => {
					set_resellers(resellers);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => set_loading(null))
		);
	};
	useEffect(() => {
		if (+bike_selected !== 0) loadResellers(bike_selected);
	}, [bike_selected]);

	useEffect(
		() => {
			loadBikes();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	const onSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		const data: FormData = new FormData();
		data.append('name', name);
		data.append('city', place);
		data.append('email', email);
		data.append('tel', tel);
		set_loading(
			TestDriveDAO.create(bike_selected, reseller_selected, data)
				.catch((error: IError) => {
					set_error(error);
				})
				.finally(() => {
					set_loading(null);
					set_form_sent(true);
				})
		);
	};

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>
					{GetLocaleText('test_drive_menu')}
				</div>
			</div>
			<TestDriveView
				loading={loading}
				bikes={bikes}
				error={error}
				onSubmit={onSubmit}
				resellers={resellers}
				bike_selected={bike_selected}
				reseller_change={handleResellerChange}
				bike_change={handleBikeChange}
				name_change={handleNameChange}
				place_change={handlePlaceChange}
				email_change={handleEmailChange}
				tel_change={handleTelChange}
				form_sent={form_sent}
			/>
		</div>
	);
};

export default TestDriveController;

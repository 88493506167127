import React from 'react';
import './Less/VideoSelector.css';
import { IVideoFilterProps } from './Video.view';
import { VideoTypesModel } from '../Model/VideoTypes.model';

export interface IVideoTypesViewProps extends IVideoFilterProps {
	types: VideoTypesModel[];
	active: number;
}

const VideoTypesView: React.FC<IVideoTypesViewProps> = (
	props
): React.ReactElement => {
	const { types } = props;

	return (
		<div className='row'>
			<div className='video-view__selector'>
				{types.map((type, index) => {
					return (
						<div
							className={`col-lg-2 video-view__selector--menu-item-container ${
								props.active === +type.video_type_id ? 'active' : ''
							}`}
							onClick={() => {
								props.filter(type.video_type_id);
							}}
							key={index}
						>
							<div className='video-view__selector--menu-item'>{type.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default VideoTypesView;

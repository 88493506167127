import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ServiceWorker from './serviceWorker';
import Router from './Components/Router/View/Router.view';
import DefaultAxios from 'axios';
import { AxiosResponse, AxiosError } from 'axios';
import { getLanguageFromDomain } from './Utils/GetLanguageFromDomain.util';
import { RouteContextWrapper } from './Components/Router/Context/Router.context';
import { IDynamicObject } from './Interfaces/IDynamicObjcet.interface';
import { BrowserRouter } from 'react-router-dom';
import { axios } from './Api/axios';
import CookieController from './Components/Cookie/CookieMain/Controller/Cookie.controller';
import './Css/Index.css';

DefaultAxios.get('/api/rest')
	.then((response: AxiosResponse<IDynamicObject>) => response.data)
	.then((ENV_KEYS: IDynamicObject) => {
		const lang: string = getLanguageFromDomain();
		axios.initAxios(ENV_KEYS.REST_API_URL, lang);
		const AppRoot = (
			<BrowserRouter>
				<RouteContextWrapper>
					<CookieController />
					<Router />
				</RouteContextWrapper>
			</BrowserRouter>
		);

		ReactDOM.hydrate(AppRoot, document.getElementById('root'));

		// My note about ServiceWorker. If you register it, the updates won't apply to the page
		// unless they open it once, close the page, and open it again.

		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		ServiceWorker.unregister();
	})
	.catch((error: AxiosError) => {
		ReactDOM.hydrate(
			<div>
				<h2>We're sorry but something has gone wrong.</h2>
				<p>Error:</p>
				<p>{error.message}</p>
			</div>,
			document.getElementById('root')
		);
	});

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { PricelistModel } from '../Model/Pricelist.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import Pricelist from '../View/Pricelist.view';
import PricelistDAO from '../DAO/Pricelist.DAO';
import { PricelistTextModel } from '../Model/PricelistText.model';
import BikesDAO from '../../Bikes/DAO/Bikes.DAO';
import { BikeTypesModel } from '../../Bikes/Model/BikeTypes.model';
import { GetLocaleText } from '../../../I18n';
import { hayaOrder } from '../../../Utils/HayaOrder.util';

export interface IPricelistControllerProps extends RouteChildrenProps {}

const PricelistController: React.FC<IPricelistControllerProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const menu_title_text = GetLocaleText('pricelist_menu');
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [pricelist, set_pricelist] = useState<PricelistModel[]>([]);
	const [content, set_content] = useState<PricelistTextModel>(
		new PricelistTextModel()
	);
	const [bike_types, set_bike_types] = useState<BikeTypesModel[]>([]);
	const [error, set_error] = useState<IError>(null);

	const loadPrices = (): void => {
		set_loading(
			PricelistDAO.getBikesPrices()
				.then((prices: PricelistModel[]) => {
					set_pricelist(prices);
					loadContent();
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					loadBikeTypes();
				})
		);
	};

	const loadBikeTypes = (): void => {
		set_loading(
			BikesDAO.getBikeTypes()
				.then((bike_types: BikeTypesModel[]) => {
					set_bike_types(hayaOrder(bike_types));
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const loadContent = (): void => {
		set_loading(
			PricelistDAO.getPricelistText()
				.then((content: PricelistTextModel) => {
					set_content(content);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	useEffect(() => {
		return () => {
			set_loading(null);
			set_pricelist([]);
			set_bike_types([]);
			set_error(null);
		};
	}, []);

	useEffect(
		() => {
			loadPrices();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{menu_title_text}</div>
			</div>
			<div className='pricelist-layout'>
				<Pricelist
					loading={loading}
					error={error}
					bikes={pricelist}
					content={content}
					bike_types={bike_types}
				/>
			</div>
		</div>
	);
};

export default PricelistController;

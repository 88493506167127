import React, { useContext } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import '../../../../node_modules/react-owl-carousel2/lib/styles.css';
import { OfferModel } from '../Model/Offer.model';
import HTMLParser from 'html-react-parser';
import { GetLocaleText } from '../../../I18n';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import MoreOfferFilter from '../../../Assets/more_offers_filter.png';
import './Less/MoreOffers.css';
import StaticImage from '../../../Assets/more_offers_logo.png';
import StaticImageEN from '../../../Assets/main_offers_en.png';
import StaticImageHR from '../../../Assets/Offer---HR.png';
import StaticImageRO from '../../../Assets/Offer---RO.png';
import StaticImageSI from '../../../Assets/Offer---SI.png';
import StaticImageSK from '../../../Assets/Offer---SK.png';
import StaticImageCZ from '../../../Assets/Offer---CZ.png';
import BlueToRedButton from '../../UI/BlueToRedButton/BlueToRedButton';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { Link } from 'react-router-dom';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface IMoreOffersViewProps {
	offers: OfferModel[];
	imageProps: Array<string>;
	loading: Promise<any> | null;
}

const MoreOffersView: React.FC<IMoreOffersViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	if (props.loading !== null && props.offers.length > 0) return <div></div>;
	const options = {
		items: 1,
		nav: true,
		rewind: true,
		autoplay: false,
		URLhashListener: false,
		navText: ['', ''],
		dotsData: true,
		dotData: false,
		dots: true,
		loop: true,
		pagination: true,
	};
	return (
		<div className='more-offers-view'>
			<OwlCarousel options={options} className={`owl-carousel`}>
				{props.offers.map((slide, index) => {
					return (
						<div
							className={`more-offers-view__single item ${
								props.imageProps[index] === '' ? 'no-image' : ''
							}`}
							key={index}
							data-dot={`<button role='button' owl-dot></button>`}
						>
							<div
								className='more-offers-view__single--container'
								style={{ backgroundImage: `url(${slide.background})` }}
							>
								<img alt='' src={MoreOfferFilter} className='filter'></img>
								<div className={`more-offers-view__single--container__row row`}>
									<div
										className={`more-offers-view__single--container__row--image col-xs-12${
											props.imageProps[index] === 'right'
												? ' col-sm-push-6 col-md-6 col-lg-6'
												: props.imageProps[index] === 'left'
												? ' col-md-6 col-lg-6'
												: ''
										}`}
									>
										{slide.image !== '' ? (
											<div className='static-image'>
												{routerContext.lang.value.name === 'HU' ? (
													<img src={StaticImage} alt='' className='img' />
												) : routerContext.lang.value.name === 'EN' ? (
													<img src={StaticImageEN} alt='' className='img' />
												) : routerContext.lang.value.name === 'RO' ? (
													<img src={StaticImageRO} alt='' className='img' />
												) : routerContext.lang.value.name === 'SK' ? (
													<img src={StaticImageSK} alt='' className='img' />
												) : routerContext.lang.value.name === 'HR' ? (
													<img src={StaticImageHR} alt='' className='img' />
												) : routerContext.lang.value.name === 'SI' ? (
													<img src={StaticImageSI} alt='' className='img' />
												) : routerContext.lang.value.name === 'CZ' ? (
													<img src={StaticImageCZ} alt='' className='img' />
												) : null}
												<Link
													to={`/${getRouteLink('OFFERS', routerContext)}/${
														slide.slug
													}`}
												>
													<img
														src={slide.image}
														alt={generateImgAlt(slide.image)}
														className='image'
													/>
												</Link>
											</div>
										) : (
											''
										)}
									</div>
									<div
										className={`more-offers-view__single--container__row--desc  col-xs-12${
											props.imageProps[index] === 'right'
												? ' col-md-6 col-lg-6 col-sm-pull-6'
												: props.imageProps[index] === 'left'
												? ' col-md-6 col-lg-6'
												: ''
										}`}
									>
										<div className='more-offers-view__single--container__row--desc__logo'>
											{slide.logo !== '' ? (
												<img
													src={slide.logo}
													alt={generateImgAlt(slide.logo)}
													className='img'
												/>
											) : (
												''
											)}
										</div>
										<Link
											className='more-offers-view__single--container__row--desc__title'
											to={`/${getRouteLink('OFFERS', routerContext)}/${
												slide.slug
											}`}
										>
											{props.imageProps[index] !== '' ? slide.name : ''}
										</Link>
										<div className='more-offers-view__single--container__row--desc__short'>
											{HTMLParser(slide.short_desc)}
										</div>
										<BlueToRedButton
											link={`/${getRouteLink('OFFERS', routerContext)}/${
												slide.slug
											}`}
										>
											{GetLocaleText('read_more')}
										</BlueToRedButton>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</OwlCarousel>
		</div>
	);
};

export default MoreOffersView;

import React, { useContext, useEffect } from 'react';
import HTMLParser from 'html-react-parser';
import { SingleOfferModel } from '../Model/SingleOffer.model';
import { IError } from '../../../Interfaces/IError.interface';
import './Less/SingleOffer.css';
import { formatDateString } from '../../../Utils/FormatDateString.util';
import Placeholder from '../../../Assets/offer_placeholder.png';
import { OfferModel } from '../Model/Offer.model';
import 'react-multi-carousel/lib/styles.css';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { Helmet } from 'react-helmet';
import Cookie from 'js-cookie';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import MoreOffersView from './MoreOffers.view';

export interface ISingleOfferProps {
  loadOffer: (offer_id: string) => void;
}

export interface ISingleOfferViewProps extends ISingleOfferProps {
  loading: Promise<any> | null;
  single_offer: SingleOfferModel;
  offers: OfferModel[];
  error: IError;
}

const getImagePositions = (offer: OfferModel[]): Array<string> => {
  let returner: Array<string> = [];
  let indexer: number = 1;
  //eslint-disable-next-line
  offer.map((o) => {
    if (o.image !== '') {
      indexer++;
    }
    o.image === ''
      ? returner.push('')
      : indexer % 2 === 0
      ? returner.push('left')
      : returner.push('right');
  });
  return returner;
};

const SingleOfferView: React.FC<ISingleOfferViewProps> = (
  props
): React.ReactElement => {
  const { single_offer: offer } = props;
  const routerContext: IRouterContext = useContext(RouterContext);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const imageProps = getImagePositions(props.offers);
  const listener = (): void => {
    if (offer.relations.length !== 0)
      Cookie.set('secondLevelRoute', offer.relations[0].slug);
  };
  useEffect(() => {
    const lang_selector: HTMLDivElement | null = document.querySelector(
      '.normal-lang-selector'
    );
    if (lang_selector instanceof HTMLDivElement) {
      lang_selector.addEventListener('click', listener);
    }
    const sticky_lang_selector: HTMLDivElement | null = document.querySelector(
      '.sticky-lang-selector'
    );
    if (sticky_lang_selector instanceof HTMLDivElement) {
      sticky_lang_selector.addEventListener('click', listener);
    }
    return () => {
      sticky_lang_selector?.removeEventListener('click', listener);
      lang_selector?.removeEventListener('click', listener);
    };
  });

  React.useEffect(() => {
    props.loading !== null ? ControlSplash(true) : ControlSplash(false);
    return () => {};
  }, [props.loading]);
  return (
    <div className='single-offer-view'>
      <Helmet>
        <meta property='og:title' content={offer.og_title} />
        <meta property='og:type' content='article' />
        <meta property='og:description' content={offer.og_desc} />
        <meta property='og:url' content={window.location.href} />
        <meta property='og:image' content={offer.og_image} />
      </Helmet>
      <div
        className='head-img'
        style={{
          backgroundImage: `url(${
            offer.inner_image !== '' ? offer.inner_image : Placeholder
          }`,
        }}
      >
        <div className='lead'>{offer.name}</div>
        <div className='date'>
          <span className='before'></span>
          {formatDateString(
            offer.public_at,
            '.',
            routerContext.lang.value.name,
            true
          )}
          <span className='after'></span>
        </div>
      </div>

      <div className='content'>{HTMLParser(offer.content)}</div>
      {props.loading === null && props.offers.length > 0 ? (
        <MoreOffersView
          loading={props.loading}
          imageProps={imageProps}
          offers={props.offers}
        ></MoreOffersView>
      ) : (
        ''
      )}
    </div>
  );
};

export default SingleOfferView;

import { axios } from "../../../Api/axios";
import { FinancingPricesModel } from "../Model/FinancingPrices.model";
import { FinancingBikePriceModel } from "../Model/FinancingBikePrice.model";
import { IFinancingBikePrice, IFinancingPrices } from "../Interfaces/IFinancing.interface";
import { AxiosPromise, AxiosResponse } from "axios";

export default abstract class FinancingDAO {
    private static readonly prices: string = "/api/bikes/calculator/prices";

    public static async getCalculatorPrices(): Promise<FinancingPricesModel[]> {
        const request: AxiosPromise<IFinancingPrices[]> = axios.getInstance().get(this.prices);
        const response: AxiosResponse<IFinancingPrices[]> = await request;
        return response.data.map((data: IFinancingPrices) => new FinancingPricesModel(data));
    }

    public static async getCalculatorBikePrice(
        slug: string,
        params: object
    ): Promise<FinancingBikePriceModel> {
        const request: AxiosPromise<IFinancingBikePrice> = axios
            .getInstance()
            .get("/api/bikes/" + slug + "/calculator", { params: params });
        const response: AxiosResponse<IFinancingBikePrice> = await request;
        return new FinancingBikePriceModel(response.data);
    }

    public static async create(
        slug: string,
        params: FormData,
        calculator_id: number
    ): Promise<void> {
        const request: AxiosPromise<void> = axios
            .getInstance()
            .post("/api/bikes/" + slug + "/calculator/" + calculator_id, params);
        const response: AxiosResponse<void> = await request;
        return response.data;
    }
}

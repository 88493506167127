import React, { useState, useContext, useEffect } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { SingleOfferModel } from '../Model/SingleOffer.model';
import { RouteChildrenProps } from 'react-router';
import SingleOfferView from '../View/SingleOffer.view';
import OfferDAO from '../DAO/Offer.dao';
import { OfferModel } from '../Model/Offer.model';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { GetLocaleText } from '../../../I18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import Slash from '../../../Assets/third_level_slash.png';
import Img from 'react-image';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface ISingleOfferProps
	extends RouteChildrenProps<{ offer_id: string }> {}

const SingleOffer: React.FC<ISingleOfferProps> = (
	props
): React.ReactElement => {
	const [error, set_error] = useState<IError>(null);
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [wait_for_push_link, set_wait_for_push_link] = useState(false);
	const [offers, set_offers] = useState<OfferModel[]>([]);
	const [single_offer, set_single_offer] = useState<SingleOfferModel>(
		new SingleOfferModel()
	);

	const routerContext: IRouterContext = useContext(RouterContext);
	const history = useHistory();

	const loadOffer = (): void => {
		if (props.match && props.match.params.offer_id) {
			set_loading(
				OfferDAO.getOfferById(props.match.params.offer_id)
					.then((offer: SingleOfferModel) => set_single_offer(offer))
					.catch((error: IError) => set_error(error))
					.finally(() => {
						set_loading(null);
					})
			);
			set_loading(
				OfferDAO.getOffers()
					.then((offers: OfferModel[]) => filterDrafts(offers))
					.catch((error: IError) => set_error(error))
					.finally(() => {})
			);
		} else {
			props.history.goBack();
		}
	};

	const filterDrafts = (full_list: OfferModel[]): void => {
		let removedDraftsOffers: OfferModel[] = [];
		full_list.map((item) => {
			if (item.is_draft === '0') {
				return removedDraftsOffers.push(item);
			} else return '';
		});
		set_offers(removedDraftsOffers);
	};
	/* LOADING SINGLE OFFER FROM MORE OFFERS */
	useEffect(() => {
		loadOffer();
		return () => {
			set_wait_for_push_link(false);
		};
		//eslint-disable-next-line
	}, [wait_for_push_link]);

	useEffect(
		() => {
			if (single_offer) {
				loadOffer();
			}
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper '>
			<div className='menu-title single-offer'>
				<div className='menu-title__text inner'>
					<div
						className='back-button'
						onClick={() =>
							history.push(`/${getRouteLink('OFFERS', routerContext)}`)
						}
					>
						<FontAwesomeIcon icon={faLongArrowAltLeft} />
						<div>{GetLocaleText('back')}</div>
						<div className='slash'>
							<Img src={Slash} alt='' />
						</div>
					</div>
					{GetLocaleText('offers_menu')}
				</div>
			</div>
			<SingleOfferView
				loadOffer={(offer_slug: string) => {
					history.push(cleanLink(`${offer_slug}`));
					set_wait_for_push_link(true);
				}}
				single_offer={single_offer}
				loading={loading}
				error={error}
				offers={offers}
			/>
		</div>
	);
};

export default SingleOffer;

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { NewsModel } from '../Model/News.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import NewsView from '../View/News.view';
import NewsDAO from '../DAO/News.dao';
import { NewsTypesModel } from '../Model/NewsTypes.model';
import { GetLocaleText } from '../../../I18n';

export interface INewsProps extends RouteChildrenProps {}

const News: React.FC<INewsProps> = (props): React.ReactElement => {
	const news_menu_text = GetLocaleText('news_menu');
	const all_text_text = GetLocaleText('all_text');
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [news, set_news] = useState<NewsModel[]>([]);
	const [error, set_error] = useState<IError>(null);
	const [filter_state, set_filter_state] = useState('0');
	const [page_state, set_page_state] = useState(16);
	const [active_selector, set_active_selector] = useState(0);
	const [news_types, set_news_types] = useState<NewsTypesModel[]>([]);
	const [load_more, set_load_more] = useState(true);
	const [loading_load_more, set_loading_load_more] = useState(false);
	const filter = (id: string): void => {
		set_filter_state(id);
	};
	const page = (): void => {
		set_page_state(page_state + 16);
		set_loading_load_more(true);
	};
	const routerContext: IRouterContext = useContext(RouterContext);
	const loadNews = (page: number): void => {
		set_loading(
			NewsDAO.getNews({ qty: page })
				.then((news: NewsModel[]) => {
					filterDrafts(news);
					if (page !== news.length) {
						set_load_more(false);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
					set_loading_load_more(false);
				})
		);
	};

	const loadNewsByType = (type_id: string, page: number): void => {
		set_loading(
			NewsDAO.getNewsByType(type_id, { qty: 16 })
				.then((news: NewsModel[]) => {
					filterDrafts(news);
					if (page !== news.length) {
						set_load_more(false);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const filterDrafts = (full_list: NewsModel[]): void => {
		let removedDraftsNews: NewsModel[] = [];
		full_list.map((item) => {
			if (item.is_draft === '0') {
				return removedDraftsNews.push(item);
			} else return '';
		});
		set_news(removedDraftsNews);
	};

	const loadNewsTypes = (): void => {
		set_loading(
			NewsDAO.getNewsTypes()
				.then((news_types: NewsTypesModel[]) => {
					const all_types: NewsTypesModel = {
						news_type_id: '0',
						lang: routerContext.lang.value.name,
						name: all_text_text,
					};
					news_types.unshift(all_types);
					set_news_types(news_types);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {})
		);
	};

	useEffect(
		() => {
			set_active_selector(0);
			set_load_more(true);
			loadNewsTypes();
			set_page_state(16);
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	useEffect(
		() => {
			set_active_selector(+filter_state);
			set_load_more(true);
			set_page_state(16);
			if (+filter_state !== 0) {
				loadNewsByType(filter_state, 16);
			} else if (+filter_state === 0) {
				loadNews(16);
			} else {
				set_page_state(16);
			}
		},
		//eslint-disable-next-line
		[filter_state]
	);

	useEffect(
		() => {
			if (page_state === 16) return;
			if (filter_state === '0' || filter_state === '') {
				loadNews(page_state);
			} else {
				loadNewsByType(filter_state, page_state);
			}
		},
		//eslint-disable-next-line
		[page_state]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{news_menu_text}</div>
			</div>
			<NewsView
				loading={loading}
				news={news}
				filter={filter}
				news_types={news_types}
				loading_load_more={loading_load_more}
				page={page}
				error={error}
				load_more={load_more}
				active={active_selector}
				lang={routerContext.lang.value.name}
			/>
		</div>
	);
};

export default News;

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { dateStringToDate } from '../../../Utils/DateStringToDate.util';
import { IVideo } from '../Interfaces/IVideo.interface';

export class VideoModel {
  constructor(Video?: IVideo) {
    if (Video) {
      ModelConstructor(Video, this);

      this.public_at_as_date = dateStringToDate(Video.published_at);
    }
  }

  video_id: string = '';
  video_type_id: string = '';
  title: string = '';
  video: string = '';
  published_at: string = '';
  //Added properties
  public_at_as_date: Date = new Date();
  video_type_name: string = '';
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IPricelist, Color } from '../Interfaces/IPricelist.interface';

export class PricelistModel {
  constructor(Pricelist?: IPricelist) {
    if (Pricelist) {
      ModelConstructor(Pricelist, this);
    }
  }
  bike_id: string = '';
  type_id: string = '';
  name: string = '';
  slug: string = '';
  year: string = '';
  logo: string = '';
  image: string = '';
  type: string = '';
  default_color_id: string = '';
  price: string = '';
  discount_price: string = '';
  apr: string = '';
  slogan: string = '';
  is_draft: string = '';
  is_new: string = '';
  colors: Color[] = [
    {
      color_id: '',
      color_01: '',
      color_02: '',
      bike_image: ''
    }
  ];
}

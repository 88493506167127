import { ModelConstructor } from '../../../../Utils/DataSetter.util';
import {
	ICookie,
	ICookieCategory,
	ICookieData,
	IInsertCodes,
} from '../Interfaces/ICookie.interface';

export class CookieModel {
	constructor(CookieModel?: ICookie) {
		if (CookieModel) {
			ModelConstructor(CookieModel, this);
		}
	}
	categories: ICookieCategory[] = [
		{
			id: '',
			name: '',
			description: '',
		},
	];
	insert_codes: IInsertCodes[] = [
		{
			category: '',
			tag: '',
			value: '',
		},
	];
	cookies: ICookieData[] = [
		{
			Name: '',
			Provider: '',
			ExpireDescription: '',
			TrackerTypeAbbr: '',
			Category: '',
			PurposeDescription: '',
		},
	];
}

export const hu = {
	categories: [
		{
			id: '1',
			name: 'Elengedhetetlen',
			description:
				'Az elengedhetetlen sütik segítenek használhatóvá tenni a weboldalunkat azáltal, hogy engedélyeznek olyan alapvető funkciókat, mint az oldalon való navigáció és a weboldal biztonságos területeihez való hozzáférés. A weboldal ezen sütik nélkül nem tud megfelelően működni.',
		},
		{
			id: '2',
			name: 'Statisztika',
			description:
				'Az adatok névtelen formában való gyűjtésén és jelentésén keresztül a statisztikai sütik segítenek a weboldal tulajdonosának abban, hogy megértse, hogyan lépnek interakcióba a látogatók a weboldallal.',
		},
		{
			id: '3',
			name: 'Marketing',
			description:
				'A marketingsütiket a látogatók weboldal-tevékenységének nyomon követésére használjuk. A cél az, hogy releváns hirdetéseket tegyünk közzé az egyéni felhasználók számára, valamint aktivitásra buzdítsuk őket, ez pedig még értékesebbé teszi weboldalunkat a tartalmakat közzétevő és a harmadik fél hirdetők számára.',
		},
	],
	insert_codes: [
		{
			category: '2',
			tag: 'script',
			value:
				'(function (i, s, o, g, r, a, m) {i["GoogleAnalyticsObject"] = r; (i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }), (i[r].l = 1 * new Date());(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "UA-656346-10", "auto");ga("send", "pageview");',
		},
		{
			category: '3',
			tag: 'script',
			value:
				'!(function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "582159962479535");fbq("track", "PageView");',
		},
		{
			category: '3',
			tag: 'noscript',
			value:
				'<img height="1" width="1" src="https://www.facebook.com/tr?id=582159962479535&ev=PageView&noscript=1"/>',
		},
	],
	cookies: [
		{
			Name: 'iTechCookie',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Tárolja a felhasználó cookie-hozzájárulási állapotát az aktuális domainhez.',
		},
		{
			Name: 'lang',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Az oldal nyelv váltásához szükséges süti.',
		},
		{
			Name: 'secondLevelRoute',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Nyelv váltásakor tárolja a pillanatnyi link címét.',
		},
		{
			Name: '_ga',
			Provider: 'suzuki.hu',
			ExpireDescription: '2 years',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Egyedi azonosítót regisztrál, amelyet statisztikai adatok generálására használnak arról, hogy a látogató hogyan használja a weboldalt.',
		},
		{
			Name: '_gat',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription: 'A Google Analytics használja a kérési arányokhoz.',
		},
		{
			Name: '_gid',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Egyedi azonosítót regisztrál, amelyet statisztikai adatok generálására használnak arról, hogy a látogató hogyan használja a weboldalt.',
		},
		{
			Name: 'IDE',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'A Google DoubleClick arra használja, hogy regisztrálja és jelentse a weboldal felhasználói tevékenységeit, miután megtekintette vagy rákattintott egy hirdető hirdetésére, egy hirdetés hatékonyságának mérése és a célzott hirdetések bemutatása céljából.',
		},
		{
			Name: 'test_cookie',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Annak ellenőrzésére szolgál, hogy a felhasználó böngészője támogatja-e a sütik használatát.',
		},
		{
			Name: 'fr',
			Provider: 'facebook.com',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'A Facebook a hirdetési termékek sorozatának szállítására használja, például valós idejű licitálást harmadik fél hirdetőitől.',
		},
		{
			Name: 'tr',
			Provider: 'facebook.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'Pixel',
			Category: '3',
			PurposeDescription:
				'A Facebook a hirdetési termékek sorozatának szállítására használja, például valós idejű licitálást harmadik fél hirdetőitől.',
		},
		{
			Name: 'NID',
			Provider: 'google.com',
			ExpireDescription: '6 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Egyedi azonosítót regisztrál, amely azonosítja a visszatérő felhasználó eszközét. Ezt az azonosítót a célzott hirdetésekhez használjuk.',
		},
		{
			Name: '_fbp',
			Provider: 'suzuki.hu',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'A Facebook a hirdetési termékek sorozatának szállítására használja, például valós idejű licitálást harmadik fél hirdetőitől.',
		},
		{
			Name: 'VISITOR_INFO1_LIVE',
			Provider: 'youtube.com',
			ExpireDescription: '179 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Ez a cookie megpróbálja megbecsülni a felhasználók sávszélességét az integrált YouTube-videókat tartalmazó oldalakon.',
		},
		{
			Name: 'YSC',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Az egyedi azonosítót regisztrálja, hogy statisztikát tároljon arról, hogy a YouTube-on milyen videókat látott a felhasználó.',
		},
		{
			Name: 'yt-remote-cast-installed',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'A felhasználó videolejátszójának beállításait a beágyazott YouTube-videóval tárolja.',
		},
		{
			Name: 'yt-remote-connected-devices',
			Provider: 'youtube.com',
			ExpireDescription: 'Persistent',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'A felhasználó videolejátszójának beállításait a beágyazott YouTube-videóval tárolja.',
		},
		{
			Name: 'yt-remote-device-id',
			Provider: 'youtube.com',
			ExpireDescription: 'Persistent',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'A felhasználó videolejátszójának beállításait a beágyazott YouTube-videóval tárolja.',
		},
		{
			Name: 'yt-remote-fast-check-period',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'A felhasználó videolejátszójának beállításait a beágyazott YouTube-videóval tárolja.',
		},
		{
			Name: 'yt-remote-session-app',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'A felhasználó videolejátszójának beállításait a beágyazott YouTube-videóval tárolja.',
		},
		{
			Name: 'yt-remote-session-name',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'A felhasználó videolejátszójának beállításait a beágyazott YouTube-videóval tárolja.',
		},
		{
			Name: 'x-bni-fpc',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
		{
			Name: 'x-bni-rncf',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
	],
};

import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import './Less/Sliders.css';
import '../../../../node_modules/react-owl-carousel2/lib/styles.css';
import { MainSlidersModel } from '../Model/MainSliders.model';
import Img from 'react-image';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IMainSliderCarouselViewProps {
	slides: MainSlidersModel[];
}

const MainSliderCarouselView: React.FC<IMainSliderCarouselViewProps> = (
	props
): React.ReactElement => {
	const options = {
		items: 1,
		nav: true,
		rewind: true,
		autoplay: true,
		URLhashListener: false,
		navText: ['', ''],
		dotsData: true,
		dotData: false,
		dots: true,
		video: true,
		loop: false,
		pagination: true,
	};
	React.useEffect(() => {
		if (window.innerWidth > 1200) {
			watchVideoPlay();
			window.addEventListener('scroll', removeDisplayNoneClasses);

			const element: HTMLElement | null = document.querySelector(
				'.owl-carousel .owl-dots'
			);
			if (!element) return;
			element.addEventListener('click', removeDisplayNoneClasses);
			const nav: HTMLElement | null = document.querySelector(
				'.owl-carousel .owl-nav'
			);
			if (!nav) return;
			nav.addEventListener('click', removeDisplayNoneClasses);
		} else return;
		return () => {
			if (window.innerWidth > 1200) {
				window.removeEventListener('click', listener);
				removeDisplayNoneClasses();
			} else return;
		};
		//eslint-disable-next-line
	}, []);
	const removeDisplayNoneClasses = (): void => {
		const navbar: HTMLElement | null = document.querySelector('.navbar');
		if (!navbar) return;
		navbar.classList.remove('display-none');
		const footer: HTMLElement | null = document.querySelector(
			'.footer .socials'
		);
		if (!footer) return;
		footer.classList.remove('display-none');
	};
	const watchVideoPlay = (): void => {
		const element: NodeListOf<Element> | null = document.querySelectorAll(
			'.owl-carousel .owl-video-play-icon'
		);
		if (!element) return;
		element.forEach((e) => {
			e.addEventListener('click', listener);
		});
	};
	const listener = (e: any): void => {
		const navbar: HTMLElement | null = document.querySelector('.navbar');
		if (!navbar) return;
		navbar.classList.add('display-none');
		const footer: HTMLElement | null = document.querySelector(
			'.footer .socials'
		);
		if (!footer) return;
		footer.classList.add('display-none');
	};
	const addVideo = (slide: MainSlidersModel): JSX.Element => {
		return (
			<div className='item-video'>
				<div className='filter'></div>
				<a className='owl-video' href={slide.content}>
					{' '}
				</a>
				<div className='content'>
					<a href={slide.link}>
						<div className='title'>{slide.title}</div>
						<div className='desc'>{slide.description}</div>
					</a>
				</div>
			</div>
		);
	};

	const addImage = (slide: MainSlidersModel): JSX.Element => {
		return (
			<div className='item'>
				<a href={slide.link}>
					<div className='filter'></div>
					<Img src={slide.content} alt={generateImgAlt(slide.content)} />
					<div className='content'>
						<div className='title'>{slide.title}</div>
						<div className='desc'>{slide.description}</div>
					</div>
				</a>
			</div>
		);
	};

	return (
		<OwlCarousel options={options} className={`owl-carousel`}>
			{props.slides.map((slide, index) => {
				return (
					<div
						className={`slide`}
						key={`mainslider-${index}`}
						data-dot={`<button role='button' owl-dot></button>`}
					>
						{slide.type === 'VIDEO' ? addVideo(slide) : addImage(slide)}
					</div>
				);
			})}
		</OwlCarousel>
	);
};

export default MainSliderCarouselView;

export const cz = {
	categories: [
		{
			id: '1',
			name: 'Nutné',
			description:
				'Nutné cookies pomáhají, aby byla webová stránka použitelná tak, že umožní základní funkce jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže správně fungovat bez těchto cookies.',
		},
		{
			id: '2',
			name: 'Statistické',
			description:
				'Statistické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají webové stránky. Anonymně sbírají a sdělují informace.',
		},
		{
			id: '3',
			name: 'Marketingové',
			description:
				'Marketingové cookies jsou používány pro sledování návštěvníků na webových stránkách. Záměrem je zobrazit reklamu, která je relevantní a zajímavá pro jednotlivého uživatele a tímto hodnotnější pro vydavatele a inzerenty třetích stran.',
		},
	],
	insert_codes: [
		{
			category: '2',
			tag: 'script',
			value:
				'(function (i, s, o, g, r, a, m) {i["GoogleAnalyticsObject"] = r; (i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }), (i[r].l = 1 * new Date());(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "UA-656346-10", "auto");ga("send", "pageview");',
		},
		{
			category: '3',
			tag: 'script',
			value:
				'!(function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "582159962479535");fbq("track", "PageView");',
		},
		{
			category: '3',
			tag: 'noscript',
			value:
				'<img height="1" width="1" src="https://www.facebook.com/tr?id=582159962479535&ev=PageView&noscript=1"/>',
		},
	],
	cookies: [
		{
			Name: 'iTechCookie',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '1 rok',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'Ukládá stav souhlasu uživatele se soubory cookie pro aktuální doménu',
		},
		{
			Name: 'lang',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Soubory cookie potřebné ke změně jazyka stránky.',
		},
		{
			Name: 'secondLevelRoute',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: 'Při změně jazyka uložte adresu aktuálního odkazu.',
		},
		{
			Name: '_ga',
			Provider: 'suzuki.hu',
			ExpireDescription: '2 years',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
		},
		{
			Name: '_gat',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 den',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Tento soubor cookie pomáhá službě Google Analytics při jejím provozu. Slouží k rozlišování uživatelů přiřazením náhodného identifikačního čísla každému uživateli. Tento soubor cookie je přítomen ve všech požadavcích na stránky pro web a používá se k počítání návštěvníků, relací a údajů o kampani pro analýzu webové stránky.',
		},
		{
			Name: '_gid',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 den',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Tento soubor cookie pomáhá službě Google Analytics při jejím provozu. Používá se k rozlišování uživatelů.',
		},
		{
			Name: 'IDE',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 rok',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Tento soubor cookie se používá k opětovnému cílení, optimalizaci, vykazování a přiřazování on-line reklam.',
		},
		{
			Name: 'test_cookie',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 den',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Slouží ke kontrole, zda prohlížeč uživatele podporuje soubory cookie.',
		},
		{
			Name: 'fr',
			Provider: 'facebook.com',
			ExpireDescription: '3 měsíce',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Facebook využívá soubor cookie k zobrazování různých reklamních produktů, například nabídek inzerentů třetích stran v reálném čase.',
		},
		{
			Name: 'tr',
			Provider: 'facebook.com',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'Pixel',
			Category: '3',
			PurposeDescription:
				'Facebook využívá soubor cookie k zobrazování různých reklamních produktů, například nabídek inzerentů třetích stran v reálném čase.',
		},
		{
			Name: 'NID',
			Provider: 'google.com',
			ExpireDescription: '6 měsíce',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Soubor cookie NID obsahuje jedinečné ID, které Google používá k ukládání vašich preferencí – například vašeho preferovaného jazyka (třeba maďarštiny), kolik výsledků vyhledávání chcete zobrazit (např. 10 nebo 20) a zda si přejete mít zapnutý filtr Google pro bezpečné vyhledávání – a také pro ukládání vašich dalších informací.',
		},
		{
			Name: '_fbp',
			Provider: 'suzuki.hu',
			ExpireDescription: '3 měsíce',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Facebook využívá soubor cookie k zobrazování různých reklamních produktů, například nabídek inzerentů třetích stran v reálném čase.',
		},
		{
			Name: 'VISITOR_INFO1_LIVE',
			Provider: 'youtube.com',
			ExpireDescription: '179 dnů',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				"Tries to estimate the user's bandwidth on pages with integrated YouTube videos.",
		},
		{
			Name: 'YSC',
			Provider: 'youtube.com',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Registruje jedinečné ID k vedení statistik o tom, jaká videa z YouTube uživatel zhlédl.',
		},
		{
			Name: 'yt-remote-cast-installed',
			Provider: 'youtube.com',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Ukládá preference videopřehrávače uživatele pomocí zabudované aplikace YouTube',
		},
		{
			Name: 'yt-remote-connected-devices',
			Provider: 'youtube.com',
			ExpireDescription: 'Trvalý',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Ukládá preference videopřehrávače uživatele pomocí zabudované aplikace YouTube',
		},
		{
			Name: 'yt-remote-device-id',
			Provider: 'youtube.com',
			ExpireDescription: 'Trvalý',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Ukládá preference videopřehrávače uživatele pomocí zabudované aplikace YouTube',
		},
		{
			Name: 'yt-remote-fast-check-period',
			Provider: 'youtube.com',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Ukládá preference videopřehrávače uživatele pomocí zabudované aplikace YouTube',
		},
		{
			Name: 'yt-remote-session-app',
			Provider: 'youtube.com',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Ukládá preference videopřehrávače uživatele pomocí zabudované aplikace YouTube',
		},
		{
			Name: 'yt-remote-session-name',
			Provider: 'youtube.com',
			ExpireDescription: 'Relace',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				'Ukládá preference videopřehrávače uživatele pomocí zabudované aplikace YouTube',
		},
		{
			Name: 'x-bni-fpc',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 dnů',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
		{
			Name: 'x-bni-rncf',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 dnů',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
	],
};

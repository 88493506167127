import React, { useEffect, useState, useContext } from 'react';
import CookieDetailsView from '../View/CookieDetails.view';
import { hu } from '../DAO/Details_hu';
import { en } from '../DAO/Details_en';
import { hr } from '../DAO/Details_hr';
import { ro } from '../DAO/Details_ro';
import { si } from '../DAO/Details_si';
import { sk } from '../DAO/Details_sk';
import { cz } from '../DAO/Details_cz';
import { CookieDetailsModel } from '../Model/CookieDetails.model';
import { RouterContext } from '../../../Router/Context/Router.context';
import { IRouterContext } from '../../../Router/Interfaces/IRouterContext.interface';

export interface CookieDetailsControllerProps {}

const CookieDetailsController: React.FC<CookieDetailsControllerProps> = () => {
	const [cookieDetails, setCookieDetails] = useState<CookieDetailsModel>(
		new CookieDetailsModel()
	);

	const routerContext: IRouterContext = useContext(RouterContext);

	useEffect(() => {
		if (routerContext.lang.value.id === 'hu') setCookieDetails(hu);
		if (routerContext.lang.value.id === 'en') setCookieDetails(en);
		if (routerContext.lang.value.id === 'hr') setCookieDetails(hr);
		if (routerContext.lang.value.id === 'ro') setCookieDetails(ro);
		if (routerContext.lang.value.id === 'si') setCookieDetails(si);
		if (routerContext.lang.value.id === 'sk') setCookieDetails(sk);
		if (routerContext.lang.value.id === 'cz') setCookieDetails(cz);
		//eslint-disable-next-line
	}, []);

	return <CookieDetailsView cookieDetails={cookieDetails} />;
};

export default CookieDetailsController;

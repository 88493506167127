import React from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import DAO from '../DAO/Bike.DAO';
import { RouteChildrenProps } from 'react-router';
import BikeModel from '../Model/Bike.model';
import BikeView from '../View/Bike.view';
import { Accessories } from '../Model/Accessories.model';
import { RouterContext } from '../../Router/Context/Router.context';

export interface IBikeControllerProps
	extends RouteChildrenProps<{ slug: string }> {
	label: string;
}

export interface IBikeControllerState {
	loading: boolean;
	error: IError;
	bike: BikeModel;
	accessories: [];
}
export interface IBikeAccessoriesInside {
	id: string;
	name: string;
	items: {};
	sequenceNumber: null;
	icon: string;
}

class BikeController extends React.Component<
	IBikeControllerProps,
	IBikeControllerState
> {
	state: IBikeControllerState = {
		loading: true,
		error: null,
		bike: new BikeModel(),
		accessories: [],
	};

	componentDidUpdate(
		prevProps: IBikeControllerProps,
		prevState: IBikeControllerState
	) {
		if (prevProps.match && this.props.match) {
			if (prevProps.match.params.slug !== this.props.match.params.slug)
				this.loadBike();
		}
	}
	static contextType = RouterContext;
	loadBike = () => {
		this.setState({ loading: true }, () => {
			window.scroll(0, 0);

			if (this.props.match && this.props.match.params.slug) {
				DAO.getBike(this.props.match.params.slug)
					.then((bike: BikeModel) => {
						this.setState({
							bike,
						});
						const accessories_json: Accessories[] = require('../../../accessories.json');
						const found_accessory:
							| Accessories
							| undefined = accessories_json.find((e: Accessories) => {
							return e.bike_id === bike.bike.bikeId;
						});

						const { lang } = this.context;
						if (found_accessory && lang.value.id === 'hu')
							DAO.getBikeAccessories(found_accessory.category)
								.then((accessories: any) => {
									const filteredAccessories = accessories.items.parts.filter(
										(e: any) => e.identifier !== null && e.price !== null
									);
									this.setState({
										loading: false,
										accessories: filteredAccessories,
									});
								})
								.catch((error: IError) => {
									this.setState({ loading: false, error });
								});
						else {
							this.setState({
								loading: false,
							});
						}
					})
					.catch((error: IError) => {
						this.setState({ loading: false, error });
					});
			}
		});
	};

	componentDidMount() {
		this.loadBike();
	}

	render() {
		return <BikeView {...this.state} {...this.props} />;
	}
}

export default BikeController;

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IVideoTypes } from '../Interfaces/IVideoTypes.interface';

export class VideoTypesModel {
  constructor(VideoType?: IVideoTypes) {
    if (VideoType) {
      ModelConstructor(VideoType, this);
    }
  }

  video_type_id: string = '';
  lang: string = '';
  name: string = '';
}

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { OfferModel } from '../Model/Offer.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import OffersView from '../View/Offers.view';
import OfferDAO from '../DAO/Offer.dao';
import { GetLocaleText } from '../../../I18n';

export interface IOffersProps extends RouteChildrenProps {}

const Offers: React.FC<IOffersProps> = (props): React.ReactElement => {
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [offers, set_offers] = useState<OfferModel[]>([]);
	const [error, set_error] = useState<IError>(null);

	const routerContext: IRouterContext = useContext(RouterContext);

	const loadOffers = (): void => {
		set_loading(
			OfferDAO.getOffers()
				.then((offers: OfferModel[]) => filterDrafts(offers))
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};
	const filterDrafts = (full_list: OfferModel[]): void => {
		let removedDraftsOffers: OfferModel[] = [];
		full_list.map((item) => {
			if (item.is_draft === '0') {
				return removedDraftsOffers.push(item);
			}
			return '';
		});
		set_offers(removedDraftsOffers);
	};

	useEffect(
		() => {
			loadOffers();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{GetLocaleText('offers_menu')}</div>
			</div>
			<OffersView
				loading={loading}
				offers={offers}
				error={error}
				lang={routerContext.lang.value.name}
			/>
		</div>
	);
};

export default Offers;

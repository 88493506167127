import React, { FormEvent } from 'react';
import { FinancingPricesModel } from '../Model/FinancingPrices.model';
import Img from 'react-image';
import { formatPrice } from '../../../Utils/FormatStringToCurrency.util';
import './Less/FinishingForm.css';
import { GetLocaleText } from '../../../I18n';
import HTMLParser from 'html-react-parser';
import { ResellersModel } from '../../Resellers/Model/Resellers.model';
import { FinancingBikePriceModel } from '../Model/FinancingBikePrice.model';
import FormSent from './FormSent.view';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface IFinishingFormViewProps {
	bike_props: FinancingPricesModel;
	resellers: ResellersModel[];
	bike_price: FinancingBikePriceModel;
	finish: (
		slug: string,
		price_own_part: number,
		duration: number,
		casco: number,
		name: string,
		city: string,
		email: string,
		tel: string,
		reseller_id: number | null
	) => void;
	form_sent: boolean;
}

const FinishingForm: React.FC<IFinishingFormViewProps> = (
	props
): React.ReactElement => {
	const [name, set_name] = React.useState('');
	const [email, set_email] = React.useState('');
	const [tel, set_tel] = React.useState('');
	const [city, set_city] = React.useState('');
	const [reseller_id, set_reseller_id] = React.useState<number | null>(null);
	const handleNameChange = (e: any) => {
		set_name(e.target.value);
	};
	const handleEmailChange = (e: any) => {
		set_email(e.target.value);
	};
	const handleTelChange = (e: any) => {
		set_tel(e.target.value);
	};
	const handleCityChange = (e: any) => {
		set_city(e.target.value);
	};
	const handleResellerChange = (e: any) => {
		set_reseller_id(e.target.value);
	};
	const handleSubmit = (e: FormEvent): void => {
		e.preventDefault();
		props.finish(
			props.bike_props.slug,
			+props.bike_props.calculator_price - props.bike_price.amount_financed,
			props.bike_price.duration,
			props.bike_price.casco ? 1 : 0,
			name,
			city,
			email,
			tel,
			reseller_id
		);
	};
	return props.form_sent === false ? (
		<div className='finishing-form-view container'>
			<div className='status-bar'>
				<div className='item active'>
					<div className='counter'>1.</div>
					<div className='text'>{GetLocaleText('financing_status_one')}</div>
				</div>
				<div className='item active'>
					<div className='counter'>2.</div>
					<div className='text'>{GetLocaleText('financing_status_two')}</div>
				</div>
				<div className='item active'>
					<div className='counter'>3.</div>
					<div className='text'>{GetLocaleText('financing_status_three')}</div>
				</div>
			</div>

			<div className='bike-data'>
				<div className='bike-data-logo'>
					<Img
						src={props.bike_props.logo}
						alt={generateImgAlt(props.bike_props.logo)}
					/>
				</div>
				<div className='bike-data-image-and-price'>
					<div className='bike-image'>
						<Img
							src={props.bike_props.image}
							alt={generateImgAlt(props.bike_props.image)}
						/>
					</div>
					<div className='bike-price'>
						{formatPrice(+props.bike_props.calculator_price / 100 + '')}
					</div>
				</div>
			</div>
			<div className='form'>
				<form onSubmit={handleSubmit}>
					<div className='separator'>
						{GetLocaleText('financing_view_finishing_form_separator')}
					</div>
					<div className='top-text'>
						{GetLocaleText('financing_view_finishing_form_top-text')}
					</div>
					<div className='row'>
						<div className='col-lg-6 col-xs-12 col-md-6 '>
							<input
								type='text'
								name='name'
								id='name'
								onChange={handleNameChange}
								required
								placeholder={GetLocaleText('testdrive_name')}
							/>
						</div>
						<div className='col-lg-6 col-xs-12 col-md-6'>
							<input
								type='text'
								name='place'
								onChange={handleCityChange}
								id='place'
								required
								placeholder={GetLocaleText('testdrive_place')}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-xs-12 col-md-6 '>
							<input
								type='email'
								name='email'
								onChange={handleEmailChange}
								id='email'
								required
								placeholder={GetLocaleText('testdrive_email')}
							/>
						</div>
						<div className='col-lg-6 col-xs-12 col-md-6'>
							<input
								type='tel'
								name='tel'
								onChange={handleTelChange}
								id='tel'
								required
								placeholder={GetLocaleText('testdrive_tel')}
							/>
						</div>
						<div className='col-lg-12 col-xs-12 col-md-12 select-option select-option__financing'>
							<select
								name='financing'
								id='bike-resellers-financing'
								onChange={handleResellerChange}
								aria-required='true'
							>
								<option aria-selected value=''>
									{GetLocaleText('financing_reseller')}
								</option>
								{props.resellers.map((reseller) => {
									return reseller ? (
										<option
											key={`reseller_${reseller.reseller_id}`}
											value={reseller.reseller_id}
										>
											{reseller.name}
										</option>
									) : (
										''
									);
								})}
							</select>
						</div>
						<div className='col-lg-12 col-xs-12 col-md-12 checkbox'>
							<label>
								{HTMLParser(GetLocaleText('testdrive_acceptance'))}
								<input name='acceptance' type='checkbox' required />
							</label>
						</div>
						<div className='buttons'>
							<button
								className='button'
								onClick={() => {
									return window.location.reload(false);
								}}
							>
								{GetLocaleText('financing_calculate_button_new')}
							</button>
							<a
								className='button'
								href={`https://api-motor.suzuki.hu/api/bikes/${props.bike_props.slug}/calculator/${props.bike_price.calculator_id}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								{GetLocaleText('financing_calculate_button_print')}
							</a>
							<button className='button inverse' type='submit'>
								{GetLocaleText('financing_calculate_button_finisher')}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	) : (
		<FormSent />
	);
};

export default FinishingForm;

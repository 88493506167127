import React, { useState } from 'react';
import BikeModel from '../Model/Bike.model';
import './Less/BikePropertiesTabSectionMobile.style.css';
import ArrowIcon from '../../../Assets/BikePage/properties_arrow_mobile.png';
import defaultDownloadIcon from '../../../Assets/BikePage/downloadIcon.png';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import HTMLParser from 'html-react-parser';
import BikeModalView from './BikeModal.view';

export interface IBikePropertiesTabSectionMobileViewProps {
	model: BikeModel;
	manual: string;
}

const BikePropertiesTabSectionMobileView: React.FC<IBikePropertiesTabSectionMobileViewProps> = (
	props
): React.ReactElement => {
	const [detailsIsOpen, toggleDetails] = useState<boolean>(false);
	const [downloadIsOpen, toggleDownload] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const docs = props.model.documents.filter((d) => !d.file.match(/manual_/g));
	return (
		<div className='bike-prop-tab-section-mobile-component'>
			{props.model.properties.length !== 0 ? (
				<React.Fragment>
					<div
						className='bike-prop-drop-down-container'
						onClick={() => toggleDetails(!detailsIsOpen)}
					>
						<span className='bike-prop-drop-down-container__text'>
							{GetLocaleText('bike_page_tabs_more_tech_details')}
						</span>
						<div className='bike-prop-drop-down-container__divider' />
						<div className='bike-prop-drop-down-container__arrow-container'>
							<img
								src={ArrowIcon}
								alt=''
								className={[
									'bike-prop-drop-down-container__arrow',
									detailsIsOpen
										? 'bike-prop-drop-down-container__arrow--spin'
										: null,
								].join(' ')}
							/>
						</div>
					</div>
					<div
						className={[
							'technical-details-content',
							detailsIsOpen ? 'technical-details-content--open' : null,
						].join(' ')}
					>
						{props.model.properties.map((property, index) => (
							<div
								className='technical-details-row'
								key={`${index}-bikeprops-block`}
							>
								<div className='technical-details-row__image-wrapper'>
									{property.icon ? (
										<img
											className='technical-details-row__image'
											src={property.icon}
											alt={generateImgAlt(property.icon)}
										/>
									) : (
										<div className='icon-cube' />
									)}
								</div>
								<span className='technical-details-row__label'>
									{HTMLParser(property.name)}
								</span>
								<span className='technical-details-row__value'>
									{HTMLParser(property.value)}
								</span>
							</div>
						))}
					</div>
				</React.Fragment>
			) : null}

			{props.model.documents.length !== 0 ? (
				<React.Fragment>
					<div
						className='bike-prop-drop-down-container'
						onClick={() => toggleDownload(!downloadIsOpen)}
					>
						<span className='bike-prop-drop-down-container__text'>
							{GetLocaleText('bike_page_tabs_documents')}
						</span>
						<div className='bike-prop-drop-down-container__divider' />
						<div className='bike-prop-drop-down-container__arrow-container'>
							<img
								src={ArrowIcon}
								alt=''
								className={[
									'bike-prop-drop-down-container__arrow',
									downloadIsOpen
										? 'bike-prop-drop-down-container__arrow--spin'
										: null,
								].join(' ')}
							/>
						</div>
					</div>
					<div
						className={[
							'download-content',
							downloadIsOpen ? 'download-content--open' : null,
						].join(' ')}
					>
						{docs.map((document, index) => (
							<a
								href={document.file}
								target='__blank'
								key={`${index}-doc-block`}
								className='download-content-row'
							>
								<div className='download-content-row__image-container'>
									<img
										src={defaultDownloadIcon}
										alt='download'
										className='download-content-row__image'
									/>
								</div>
								<div className='download-content-row__name'>
									{document.name}{' '}
									{GetLocaleText('bike_page_tabs_documents_donwload_text')}
								</div>
							</a>
						))}
					</div>
				</React.Fragment>
			) : null}
			{props.manual ? (
				<>
					{showModal && (
						<BikeModalView setShowModal={setShowModal} manual={props.manual} />
					)}
					<div onClick={() => setShowModal(true)} style={{ marginTop: '1rem' }}>
						<div className='fin-calc-mobile-button'>Használati útmutató</div>
					</div>
				</>
			) : (
				''
			)}
		</div>
	);
};

export default BikePropertiesTabSectionMobileView;

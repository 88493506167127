import { axios } from '../../../Api/axios';
import { VideoModel } from '../Model/Video.model';
import { IVideo } from '../Interfaces/IVideo.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { IVideoTypes } from '../Interfaces/IVideoTypes.interface';
import { VideoTypesModel } from '../Model/VideoTypes.model';

export default abstract class VideoDAO {
  private static readonly base: string = '/api/video/videos';
  private static readonly types: string = '/api/video/types';
  private static readonly type_filter: string = '/api/video/types';

  public static async getVideo(params?: object): Promise<VideoModel[]> {
    const request: AxiosPromise<IVideo[]> = axios
      .getInstance()
      .get(this.base, { params: params });
    const response: AxiosResponse<IVideo[]> = await request;
    return response.data.map((videos: IVideo) => new VideoModel(videos));
  }

  public static async getVideoByType(
    video_type_id: string,
    params?: object
  ): Promise<VideoModel[]> {
    const request: AxiosPromise<IVideo[]> = axios
      .getInstance()
      .get(`${this.type_filter}/${video_type_id}/videos`, { params: params });
    const response: AxiosResponse<IVideo[]> = await request;
    return response.data.map((videos: IVideo) => new VideoModel(videos));
  }

  public static async getVideoTypes(): Promise<VideoTypesModel[]> {
    const request: AxiosPromise<IVideoTypes[]> = axios
      .getInstance()
      .get(this.types);
    const response: AxiosResponse<IVideoTypes[]> = await request;
    return response.data.map(
      (video_types: IVideoTypes) => new VideoTypesModel(video_types)
    );
  }
}

import * as React from 'react';
import { useHistory, RouteComponentProps } from 'react-router';
import { GetLocaleText } from '../../../I18n';
import './Less/WarnPages.css';
import Background from '../../../Assets/404_page_motor_bg.jpg';
import Image_404 from '../../../Assets/404.png';

export interface IE404PageNotFoundProps extends RouteComponentProps {}

const E404PageNotFound: React.FC<IE404PageNotFoundProps> = (
	props
): React.ReactElement => {
	const history = useHistory();
	return (
		<div
			className='warn-page e404'
			style={{ backgroundImage: `url(${Background})` }}
		>
			<h2 className='warn-page__title'>
				<span>
					<img src={Image_404} alt='' />
				</span>{' '}
				<br /> {GetLocaleText('404_upper')} <br />
				{GetLocaleText('404_lower')}
			</h2>
			<div className='warn-page__container'>
				<div className='warn-page__container--buttons'>
					<button
						className='back_to_main_page'
						onClick={() => history.push('/')}
					>
						{GetLocaleText('404_button')}
					</button>
					<p>{GetLocaleText('404_back')}</p>
				</div>
			</div>
		</div>
	);
};

export default E404PageNotFound;

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IFooterLinks, TOP, BOTTOM } from './../Interfaces/IFooter.interface';

export class FooterLinksModel {
  constructor(FooterLinks?: IFooterLinks) {
    if (FooterLinks) {
      ModelConstructor(FooterLinks, this);
    }
  }
  TOP: TOP[] | null = [
    {
      link_id: '',
      name: '',
      link: '',
      icon: '',
      label: ''
    }
  ];
  BOTTOM: BOTTOM[] | null = [
    {
      link_id: '',
      name: '',
      link: '',
      icon: '',
      label: ''
    }
  ];
}

import { IDynamicObject } from '../Interfaces/IDynamicObjcet.interface';
import { IOption } from '../Interfaces/ILangSwitch.interface';

const DomainLangPair: IDynamicObject = {
	'http://localhost:3000': 'hu',
	'https://bikes.suzuki.hu': 'hu',
	'https://motor.suzuki.hu': 'hu',
	'https://bikes.suzuki.ro': 'ro',
	'https://bikes.suzuki.sk': 'sk',
	'https://bikes.suzuki.cz': 'cz',
	'https://bikes.suzuki.hr': 'hr',
	'https://bikes.suzuki.si': 'si',
};

/**
 * ## Get the default language from the domain name
 *
 */
export function getLanguageFromDomain(): string {
	const origin: string = document.location.origin;
	if (origin in DomainLangPair) return DomainLangPair[origin];

	return 'hu';
}

export const getLangOptions = (): IOption[] => {
	const current: string = getLanguageFromDomain();

	const option1: IOption = {
		id: current,
		name: current.toUpperCase(),
	};
	const option2: IOption = {
		id: 'en',
		name: 'EN',
	};

	return [option1, option2];
};

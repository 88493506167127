export const formatDateString = (
	dateString: string,
	delimeter: string,
	lang: string,
	fullMonth?: boolean
): string => {
	//Split the date into an array, the API will always return dates like: 2020-02-10 12:30:00
	const dateArr: string[] = dateString.split(/[.]|[\s]|[-]|[:]|[/]|[T]/g);
	const y: string = dateArr[0];
	let m: string = dateArr[1];
	const d: string = dateArr[2];
	if (fullMonth) {
		m = monthNames(lang)[+m - 1];
		if (lang === 'EN') return `${m} ${d} ${y}`;
		if (lang === 'CZ') return `${d}. ${m} ${y}`;
		else return `${y}. ${m} ${d}.`;
	}
	return `${y}${delimeter}${m}${delimeter}${d}`;
};
//TODO: i18n
const monthNames = (lang: string): string[] => {
	let i18Array: string[] = [];
	lang === 'HU'
		? (i18Array = [
				'Január',
				'Február',
				'Március',
				'Április',
				'Május',
				'Június',
				'Július',
				'Augusztus',
				'Szeptember',
				'Október',
				'November',
				'December',
		  ])
		: lang === 'EN'
		? (i18Array = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
		  ])
		: lang === 'RO'
		? (i18Array = [
				'Ianuarie',
				'Februarie',
				'Martie',
				'Aprilie',
				'Mai',
				'Iunie',
				'Iulie',
				'August',
				'Septembrie',
				'Octombrie',
				'Noiembrie',
				'Decembrie',
		  ])
		: lang === 'SI'
		? (i18Array = [
				'Januarja',
				'Februarja',
				'Marec',
				'April',
				'Maj',
				'Junij',
				'Julija',
				'Avgusta',
				'Septembra',
				'Oktober',
				'Novembra',
				'December',
		  ])
		: lang === 'SK'
		? (i18Array = [
				'Január',
				'Február',
				'Marec',
				'April',
				'Smieť',
				'Jún',
				'Júl',
				'August',
				'Septembra',
				'Október',
				'November',
				'December',
		  ])
		: lang === 'HR'
		? (i18Array = [
				'Siječanj',
				'Veljača',
				'Ožujak',
				'Travanj',
				'Svibanj',
				'Lipanj',
				'Srpanj',
				'Kolovoz',
				'Rujan',
				'Listopad',
				'Studeni',
				'Prosinac',
		  ])
		: lang === 'CZ'
		? (i18Array = [
				'Leden',
				'Únor',
				'Březen',
				'Duben',
				'Smět',
				'červen',
				'červenec',
				'Srpen',
				'Září',
				'říjen',
				'Listopad',
				'Prosinac',
		  ])
		: (i18Array = []);
	return i18Array;
};

import {
  IInputBoolean,
  IInputSelect,
  IInputText,
  IInputTextArea,
} from "../Interfaces/IInput.interface";

export const validateFormData = <T>(
  form: T,
  messages: Array<string>,
  file_list?: File[]
): null | Array<string> => {
  const error_message: Array<string> = [];
  let mail_and_phone = false;
  let hasMailAndPhone = false;
  for (const T of Object.values(form)) {
    const field: IInputTextArea | IInputText | IInputSelect | IInputBoolean = T;

    if ("booleanType" in field) {
      if (field.id !== "preferred_mail" && field.id !== "preferred_phone") {
        field.value !== true && error_message.push(messages[0]);
      } else {
        if (field.id === "preferred_mail" && field.value) {
          hasMailAndPhone = true;
          mail_and_phone = true;
        }
        if (field.id === "preferred_phone" && field.value) {
          hasMailAndPhone = true;
          mail_and_phone = true;
        }
      }
    }

    if ("textAreaType" in field) {
      if (field.required !== true) continue;
      field.value === "" && error_message.push(messages[1]);
    }

    if ("textType" in field) {
      if (field.required === true) {
        if (field.disabled !== true && field.hidden !== true) {
          field.value === "" && error_message.push(messages[1]);
          field.value.length < 3 && error_message.push(messages[2]);
        }
      }
    }

    if ("selectType" in field) {
      !field.value && error_message.push(messages[3]);
    }
  }
  if (!mail_and_phone && hasMailAndPhone) {
    error_message.push(messages[4]);
  }
  if (file_list?.length && file_list.length > 0) {
    const files_size = file_list.map((f) => f.size).reduce((a, c) => a + c, 0);
    if (files_size >= 15 * 1000 * 1000) error_message.push(messages[4]);
  }
  if (error_message.length === 0) return null;
  else return error_message.filter((v, i, s) => s.indexOf(v) === i);
};

export const cz = {
	page_id: 0,
	lang_id: 1,
	name: 'Cookie Details Page',
	slug: 'cookie',
	content: `<div class="custompage-view-cookie">
  <div class="custompage-view-cookie__content">
  <h1 class="mainhead__title"><span style="font-family: SuzukiBold;">Z&Aacute;SADY POUŽ&Iacute;V&Aacute;N&Iacute; SOUBORŮ COOKIE</span></h1>
<h2><span style="font-family: SuzukiBold;">Co je soubor cookie?</span></h2>
<p>Soubor cookie je mal&yacute; datov&yacute; bal&iacute;ček, kter&yacute; webov&eacute; služby ukl&aacute;daj&iacute; ve va&scaron;em webov&eacute;m prohl&iacute;žeči. Tato technologie je nezbytn&aacute; pro spr&aacute;vn&eacute; fungov&aacute;n&iacute; mnoha webov&yacute;ch služeb a od roku 1995 ji podporuj&iacute; v&scaron;echny hlavn&iacute; prohl&iacute;žeče.</p>
<h2><span style="font-family: SuzukiBold;">V čem spoč&iacute;v&aacute; př&iacute;nos pro uživatele?</span></h2>
<p>Mnoho webov&yacute;ch služeb z&aacute;vis&iacute; na souborech cookie pro zaji&scaron;těn&iacute; jejich spr&aacute;vn&eacute; funkce a pohodl&iacute; uživatele. Soubory cookie v&aacute;m např&iacute;klad umožňuj&iacute; snadn&eacute; přihl&aacute;&scaron;en&iacute; k webům chr&aacute;něn&yacute;m heslem nebo př&iacute;stup k jejich přizpůsoben&eacute;mu obsahu.</p>
<h2><span style="font-family: SuzukiBold;">Soubory cookie na webov&yacute;ch str&aacute;nk&aacute;ch a v on-line služb&aacute;ch společnosti Magyar Suzuki Corporation.</span></h2>
<p>Pro zaji&scaron;těn&iacute; nejvy&scaron;&scaron;&iacute; kvality fungov&aacute;n&iacute; na&scaron;ich webov&yacute;ch str&aacute;nek a nejlep&scaron;&iacute;ho možn&eacute;ho uživatelsk&eacute;ho komfortu použ&iacute;v&aacute;me soubory cookie na webov&yacute;ch str&aacute;nk&aacute;ch a jako souč&aacute;st služeb společnosti Magyar Suzuki Corporation. Soubory cookie můžete ve sv&eacute;m poč&iacute;tači kdykoli smazat a svůj prohl&iacute;žeč nakonfigurovat tak, aby je zak&aacute;zal. Nicm&eacute;ně mějte na paměti, že pokud zak&aacute;žete soubory cookie, nebude webov&aacute; str&aacute;nka plně funkčn&iacute;. Dal&scaron;&iacute; informace o souborech cookie naleznete v z&aacute;sad&aacute;ch ochrany osobn&iacute;ch &uacute;dajů, ke kter&yacute;m z&iacute;sk&aacute;te př&iacute;stup kliknut&iacute;m <a href="https://www.suzuki.hu/corporate/en/content/privacy-notice">zde</a>.</p>
<h2><span style="font-family: SuzukiBold;">Soubory cookie instalovan&eacute; třet&iacute;mi stranami.</span></h2>
<p>Na&scaron;e webov&eacute; str&aacute;nky mohou obsahovat takov&eacute; informace, jež poch&aacute;zej&iacute; od třet&iacute;ch stran nebo poskytovatelů reklamy, kteř&iacute; nijak nesouvisej&iacute; se spr&aacute;vcem. Tyto třet&iacute; strany mohou tak&eacute; ukl&aacute;dat soubory cookie a webov&eacute; maj&aacute;ky do poč&iacute;tače uživatele nebo mohou použ&iacute;vat podobn&eacute; technologie ke shromažďov&aacute;n&iacute; &uacute;dajů za &uacute;čelem zas&iacute;l&aacute;n&iacute; c&iacute;len&yacute;ch reklam uživateli v souvislosti se sv&yacute;mi vlastn&iacute;mi službami. V takov&yacute;ch př&iacute;padech se zpracov&aacute;n&iacute; &uacute;dajů ř&iacute;d&iacute; z&aacute;sadami ochrany osobn&iacute;ch &uacute;dajů stanoven&yacute;mi těmito třet&iacute;mi stranami.</p>
<h2><span style="font-family: SuzukiBold;">Nastaven&iacute; souborů cookie v prohl&iacute;žeč&iacute;ch.</span></h2>
<p>Soubory cookie může uživatel tak&eacute; konfigurovat ručně. V&scaron;echny uložen&eacute; soubory cookie lze odstranit a jejich ukl&aacute;d&aacute;n&iacute; lze zak&aacute;zat spr&aacute;vn&yacute;m nastaven&iacute;m. Tato nastaven&iacute; můžete nakonfigurovat podle pokynů v odkazech uveden&yacute;ch n&iacute;že podle typu prohl&iacute;žeče.</p>
<p><strong>Microsoft Internet Explorer</strong><br /><a href="hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener">hhttps://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a><br /><strong>Microsoft Egde</strong><br /><a href="https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener">https://privacy.microsoft.com/en-US/windows-10-microsoft-edge-and-privacy</a><br /><strong>Firefox</strong><br /><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a><br /><strong>Chrome</strong><br /><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en" target="_blank" rel="noopener">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%%203DDesktop&amp;hl=en</a><br /><strong>Safari</strong><br /><a href="https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac">https://support.apple.com/guide/safari/privacy-preferences-sfri35610/mac</a><br /><strong>IOS Safari</strong><br /><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener">https://support.apple.com/en-us/HT201265</a></p>
<h2><span style="font-family: SuzukiBold;">Funkčn&iacute; soubory cookie:</span></h2>
<p lang="en-GB" align="justify">Bez těchto souborů cookie nebude webov&aacute; str&aacute;nka spr&aacute;vně fungovat. Nelze je vypnout a obvykle se spou&scaron;těj&iacute; na z&aacute;kladě va&scaron;&iacute; interakce s webovou str&aacute;nkou, jako je nastaven&iacute; možnost&iacute; ochrany osobn&iacute;ch &uacute;dajů, přihl&aacute;&scaron;en&iacute; nebo vyplňov&aacute;n&iacute; formul&aacute;řů.</p>
<table style="width: 99.9318%;" border="1px" cellspacing="33.33%">
<thead>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p>N&aacute;zev</p>
</td>
<td style="width: 76.8186%; text-align: center;">
<p>Popis</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p>Doba uložen&iacute;</p>
</td>
</tr>
</thead>
<tbody>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">lang</span></p>
</td>
<td style="width: 76.8186%; text-align: center;">
<p class="gtx-body">Soubory cookie potřebn&eacute; ke změně jazyka str&aacute;nky.</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p>Při uzavřen&iacute; prohl&iacute;žeče</p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">
<p><span style="font-size: 16px;">CookieConsent</span></p>
</td>
<td style="width: 76.8186%; text-align: center;">
<p>Použ&iacute;vaj&iacute; se ke sledov&aacute;n&iacute; toho, zda uživatel již souhlasil s použit&iacute;m souborů cookie na webov&yacute;ch str&aacute;nk&aacute;ch.</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p><span style="font-size: 16px;">1 roků<br /></span></p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">rc::a</td>
<td style="width: 76.8186%; text-align: center;">
<p>Tento soubor cookie se použ&iacute;v&aacute; k rozli&scaron;ov&aacute;n&iacute; mezi lidmi a roboty. To je pro webovou str&aacute;nku př&iacute;nosn&eacute;, aby bylo možn&eacute; vyd&aacute;vat platn&eacute; zpr&aacute;vy o použ&iacute;v&aacute;n&iacute; webov&eacute; str&aacute;nky.</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p>Při uzavřen&iacute; prohl&iacute;žeče</p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">rc::c</td>
<td style="width: 76.8186%; text-align: center;">
<p>Tento soubor cookie se použ&iacute;v&aacute; k rozli&scaron;ov&aacute;n&iacute; mezi lidmi a roboty.&nbsp;</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p>Při uzavřen&iacute; prohl&iacute;žeče</p>
</td>
</tr>
<tr>
<td style="width: 11.6537%; text-align: center;">secondLevelRoute</td>
<td style="width: 76.8186%; text-align: center;">
<p>Při změně jazyka uložte adresu aktu&aacute;ln&iacute;ho odkazu.</p>
</td>
<td style="width: 71.2561%; text-align: center;">
<p>Při uzavřen&iacute; prohl&iacute;žeče</p>
</td>
</tr>
</tbody>
</table>
<h2 lang="en-GB" align="justify"><span lang="hu-HU"><span style="font-family: SuzukiBold;"><br />Soubory cookie zaměřen&eacute; na v&yacute;kon:<br /></span></span></h2>
<p lang="en-GB" align="justify"><span lang="hu-HU">They are used to monitor a browser&rsquo;s operation to provide help in improving information and services.</span></p>
<table style="width: 100%;" border="1px" cellspacing="33.33%">
<tbody>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p>N&aacute;zev</p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p>Popis</p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p>Doba uložen&iacute;</p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">.ga</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p>Tento soubor cookie pom&aacute;h&aacute; službě Google Analytics při jej&iacute;m provozu. Slouž&iacute; k rozli&scaron;ov&aacute;n&iacute; uživatelů přiřazen&iacute;m n&aacute;hodn&eacute;ho identifikačn&iacute;ho č&iacute;sla každ&eacute;mu uživateli. Tento soubor cookie je př&iacute;tomen ve v&scaron;ech požadavc&iacute;ch na str&aacute;nky pro web a použ&iacute;v&aacute; se k poč&iacute;t&aacute;n&iacute; n&aacute;v&scaron;těvn&iacute;ků, relac&iacute; a &uacute;dajů o kampani pro anal&yacute;zu webov&eacute; str&aacute;nky.</p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">2 roků<br /></span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">_gid</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p>Tento soubor cookie pom&aacute;h&aacute; službě Google Analytics při jej&iacute;m provozu. Použ&iacute;v&aacute; se k rozli&scaron;ov&aacute;n&iacute; uživatelů.</p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">24 hodin<br /></span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">
<p><span style="font-size: 16px;">_gat</span></p>
</td>
<td style="text-align: center; width: 76.8596%;">
<p>Tento soubor cookie pom&aacute;h&aacute; službě Google Analytics při jej&iacute;m provozu. Použ&iacute;v&aacute; se k omezov&aacute;n&iacute; rozsahu požadavků, tj. omezuje sběr dat na webov&yacute;ch str&aacute;nk&aacute;ch s intenzivn&iacute;m provozem</p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p><span style="font-size: 16px;">1 minut<br /></span></p>
</td>
</tr>
<tr>
<td style="width: 11.8457%; text-align: center;">collect</td>
<td style="text-align: center; width: 76.8596%;">
<p>Použ&iacute;v&aacute; se k odes&iacute;l&aacute;n&iacute; &uacute;dajů o zař&iacute;zen&iacute; a chov&aacute;n&iacute; n&aacute;v&scaron;těvn&iacute;ka službě Google Analytics. Sleduje n&aacute;v&scaron;těvn&iacute;ka např&iacute;č zař&iacute;zen&iacute;mi a marketingov&yacute;mi kan&aacute;ly.</p>
</td>
<td style="width: 11.3636%; text-align: center;">
<p>Při uzavřen&iacute; prohl&iacute;žeče<br />&nbsp;</p>
</td>
</tr>
</tbody>
</table>
<h2><span lang="hu-HU" style="font-family: SuzukiBold;"><br />Soubory cookie třet&iacute;ch stran:<br /></span></h2>
<p>Tyto soubory cookie použ&iacute;v&aacute; obsah (např&iacute;klad př&iacute;spěvky na soci&aacute;ln&iacute;ch m&eacute;di&iacute;ch, mapy nebo videa) vložen&yacute; do webov&eacute; str&aacute;nky.</p>
<table style="width: 100%;" border="1px" cellspacing="33.33%">
<tbody>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p>N&aacute;zev</p>
</td>
<td style="text-align: center;">
<p>Popis</p>
</td>
<td style="width: 10.8127%; text-align: center;">
<p>Doba uložen&iacute;</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">NID</span></p>
</td>
<td style="text-align: center;">Soubor cookie NID obsahuje jedinečn&eacute; ID, kter&eacute; Google použ&iacute;v&aacute; k ukl&aacute;d&aacute;n&iacute; va&scaron;ich preferenc&iacute; &ndash; např&iacute;klad va&scaron;eho preferovan&eacute;ho jazyka (třeba maďar&scaron;tiny), kolik v&yacute;sledků vyhled&aacute;v&aacute;n&iacute; chcete zobrazit (např. 10 nebo 20) a zda si přejete m&iacute;t zapnut&yacute; filtr Google pro bezpečn&eacute; vyhled&aacute;v&aacute;n&iacute; &ndash; a tak&eacute; pro ukl&aacute;d&aacute;n&iacute; va&scaron;ich dal&scaron;&iacute;ch informac&iacute;.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">6 měs&iacute;ce</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">
<p><span style="font-size: 16px;">IDE</span></p>
</td>
<td style="text-align: center;">Tento soubor cookie se použ&iacute;v&aacute; k opětovn&eacute;mu c&iacute;len&iacute;, optimalizaci, vykazov&aacute;n&iacute; a přiřazov&aacute;n&iacute; on-line reklam.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">2 měs&iacute;ce</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">test_cookie</td>
<td style="text-align: center;">Slouž&iacute; ke kontrole, zda prohl&iacute;žeč uživatele podporuje soubory cookie.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">1 den</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">fr</td>
<td style="text-align: center;">Facebook využ&iacute;v&aacute; soubor cookie k zobrazov&aacute;n&iacute; různ&yacute;ch reklamn&iacute;ch produktů, např&iacute;klad nab&iacute;dek inzerentů třet&iacute;ch stran v re&aacute;ln&eacute;m čase.&nbsp; &nbsp;</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">3 měs&iacute;ce</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">tr</td>
<td style="text-align: center;">Facebook využ&iacute;v&aacute; soubor cookie k zobrazov&aacute;n&iacute; různ&yacute;ch reklamn&iacute;ch produktů, např&iacute;klad nab&iacute;dek inzerentů třet&iacute;ch stran v re&aacute;ln&eacute;m čase.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">Session</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">_fbp</td>
<td style="text-align: center;">Facebook využ&iacute;v&aacute; soubor cookie k zobrazov&aacute;n&iacute; různ&yacute;ch reklamn&iacute;ch produktů, např&iacute;klad nab&iacute;dek inzerentů třet&iacute;ch stran v re&aacute;ln&eacute;m čase.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">3 měs&iacute;ce</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">GPS</td>
<td style="text-align: center;">Na mobiln&iacute;ch zař&iacute;zen&iacute;ch registruje jedinečn&eacute; ID umožňuj&iacute;c&iacute; sledov&aacute;n&iacute; na z&aacute;kladě zeměpisn&eacute; polohy z GPS.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">1 den</span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">VISITOR_INFO1_LIVE</td>
<td style="text-align: center;">Pokou&scaron;&iacute; se odhadnout &scaron;&iacute;řku p&aacute;sma uživatelů na str&aacute;nk&aacute;ch s integrovan&yacute;mi videy YouTube.</td>
<td style="text-align: center; width: 10.8127%;">
<p><span style="font-size: 16px;">179 dnů<br /></span></p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">YSC</td>
<td style="text-align: center;">Registruje jedinečn&eacute; ID k veden&iacute; statistik o tom, jak&aacute; videa z YouTube uživatel zhl&eacute;dl.</td>
<td style="text-align: center; width: 10.8127%;">
<p>Relace</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-cast-installed</td>
<td style="text-align: center;">Ukl&aacute;d&aacute; preference videopřehr&aacute;vače uživatele pomoc&iacute; zabudovan&eacute; aplikace YouTube</td>
<td style="text-align: center; width: 10.8127%;">
<p>Relace</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-connected-devices</td>
<td style="text-align: center;">Ukl&aacute;d&aacute; preference videopřehr&aacute;vače uživatele pomoc&iacute; zabudovan&eacute; aplikace YouTube</td>
<td style="text-align: center; width: 10.8127%;">
<p>Trval&yacute;</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-device-id</td>
<td style="text-align: center;">Ukl&aacute;d&aacute; preference videopřehr&aacute;vače uživatele pomoc&iacute; zabudovan&eacute; aplikace YouTube</td>
<td style="text-align: center; width: 10.8127%;">
<p>Trval&yacute;</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-fast-check-period</td>
<td style="text-align: center;">Ukl&aacute;d&aacute; preference videopřehr&aacute;vače uživatele pomoc&iacute; zabudovan&eacute; aplikace YouTube</td>
<td style="text-align: center; width: 10.8127%;">
<p>Relace</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-session-app</td>
<td style="text-align: center;">Ukl&aacute;d&aacute; preference videopřehr&aacute;vače uživatele pomoc&iacute; zabudovan&eacute; aplikace YouTube</td>
<td style="text-align: center; width: 10.8127%;">
<p>Relace</p>
</td>
</tr>
<tr>
<td style="text-align: center; width: 12.0523%;">yt-remote-session-name</td>
<td style="text-align: center;">Ukl&aacute;d&aacute; preference videopřehr&aacute;vače uživatele pomoc&iacute; zabudovan&eacute; aplikace YouTube</td>
<td style="text-align: center; width: 10.8127%;">
<p>Relace</p>
</td>
</tr>
</tbody>
</table>
<p style="text-align: center;"><br />Neust&aacute;le pracujeme na zlep&scaron;ov&aacute;n&iacute; sv&yacute;ch webov&yacute;ch str&aacute;nek a digit&aacute;ln&iacute;ch služeb. Za t&iacute;mto &uacute;čelem použ&iacute;v&aacute;me službu Google Analytics k anal&yacute;ze agregovan&yacute;ch a anonymizovan&yacute;ch &uacute;dajů o chov&aacute;n&iacute; uživatelů.<br />D&aacute;le tak&eacute; umožňujeme sv&eacute;mu n&aacute;stroji pro webovou anal&yacute;zu Google Analytics spravovat soubory cookie a umisťovat je na tuto webovou str&aacute;nku. Statistick&eacute; &uacute;daje z&iacute;skan&eacute; t&iacute;mto způsobem použ&iacute;v&aacute;me ke zlep&scaron;en&iacute; spokojenosti uživatelů.<br />Dal&scaron;&iacute; informace z&iacute;sk&aacute;te od na&scaron;eho pracovn&iacute;ka pro ochranu osobn&iacute;ch &uacute;dajů na adrese privacy@suzuki.hu<br /><br /></p>
</div>
</div>`,
};

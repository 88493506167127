import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import BikeImg from "../../../Assets/contact_img.png";
import { GetLocaleText } from "../../../I18n";
import { IInputChange } from "../../../Interfaces/IInputChange.interface";
import { ControlSplash } from "../../../Utils/ControlSplashScreen.util";
import { IContactForm } from "../Interfaces/IContact.interface";
import ContactFormView from "./ContactForm.view";
import "./Less/Contact.css";

export interface IContactViewProps {
  form: IContactForm;
  onChange: (args: IInputChange) => void;
  loading: Promise<any> | null;
  error: string | null;
  form_sent: boolean;
  onSubmit: (e: React.FormEvent) => void;
  onDelete: (i: number) => void;
}

const ContactView: React.FC<IContactViewProps> = (
  props
): React.ReactElement => {
  React.useEffect(() => {
    props.loading !== null ? ControlSplash(true) : ControlSplash(false);
    window.scrollTo(0, 0);
    return () => {};
    //eslint-disable-next-line
  }, [props.loading]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-view container">
      <div className="contact-wrapper row">
        <div
          className={`contact-wrapper--right ${
            props.form_sent ? "form_sent" : "col-lg-6"
          } col-12`}
        >
          <div className="contact-text">
            {!props.form_sent ? (
              <>
                <h3 className="contact-text--title">
                  {GetLocaleText("contact_page__text_title")}
                </h3>
                <h2 className="contact-text--highlight">
                  {GetLocaleText("contact_page__text_title_big")}
                </h2>
                <p className="contact-text--long">
                  {GetLocaleText("contact_page__text_long")}
                </p>
              </>
            ) : (
              <>
                <h2 className="contact-text--highlight">
                  {GetLocaleText("contact_page__success_lead")}
                </h2>
                <p className="contact-text--long">
                  {GetLocaleText("contact_page__success_text")}
                </p>
                <p className="contact-text--long">
                  {GetLocaleText("contact_page__success_text_2")}
                </p>
              </>
            )}
          </div>
          <div className="contact-image">
            <img src={BikeImg} alt="" />
          </div>
        </div>
        <div className="contact-wrapper--left col-lg-6 col-12">
          {!props.form_sent && (
            <ContactFormView
              form={props.form}
              onChange={props.onChange}
              onSubmit={props.onSubmit}
              onDelete={props.onDelete}
              error={props.error}
            />
          )}
        </div>
        <div className="contact-image--mobile">
          <img src={BikeImg} alt="" />
        </div>
      </div>

      <div className="recaptcha">
        <ReCAPTCHA
          size="invisible"
          sitekey="6LdObeYUAAAAAIrBvZXWm25JKNB9af7sEGMPXnIe"
        />
      </div>
    </div>
  );
};

export default ContactView;

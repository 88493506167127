import { axios } from '../../../Api/axios';
import { PricelistModel } from '../Model/Pricelist.model';
import { IPricelist, IPricelistText } from '../Interfaces/IPricelist.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { PricelistTextModel } from '../Model/PricelistText.model';

export default abstract class PricelistDAO {
  private static readonly bikes_prices: string = '/api/bikes/prices';
  private static readonly pricelist: string = '/api/pricelist';

  public static async getBikesPrices(): Promise<PricelistModel[]> {
    const request: AxiosPromise<IPricelist[]> = axios
      .getInstance()
      .get(this.bikes_prices);
    const response: AxiosResponse<IPricelist[]> = await request;
    return response.data.map(
      (bikes_prices: IPricelist) => new PricelistModel(bikes_prices)
    );
  }

  public static async getPricelistText(): Promise<PricelistTextModel> {
    const request: AxiosPromise<IPricelistText> = axios
      .getInstance()
      .get(this.pricelist);
    const response: AxiosResponse<IPricelistText> = await request;
    return new PricelistTextModel(response.data);
  }
}

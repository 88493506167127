import {
	IBikeResponse,
	IFeatureBlockResponseElement,
	IBlockResponse,
	ITextSliderBlockResponseElement,
	ITechSliderBlockResponseElement,
	IPromotionBlockResponseElement,
} from './../Interfaces/IBike.interface';
import {
	setNumber,
	setString,
	setBoolean,
} from '../../../Utils/DataSetter.util';

export default class BikeModel {
	bike: IBikeDetails = {
		bikeId: 10000,
		typeId: 1,
		name: '',
		slug: 'slug',
		year: 2020,
		logo: '',
		image: '',
		background: '',
		defaultColorId: 1,
		engineCapacity: 100,
		fuelCapacity: 100,
		performance: 100,
		torque: 100,
		curbWeight: 100,
		seatHeight: 100,
		license: ['A'],
		type: 'bike',
		isDraft: false,
		isNew: true,
		innerBackground: '',
	};
	simpleSlider: ISimpleSliderItem[] = [];
	offers: IOfferItem[] = [];
	realtions: IRelationItem[] = [];
	properties: IPropertiItem[] = [];
	documents: IDocumentItem[] = [];
	colors: IColorItem[] = [];
	technologies: ITechnologyItem[] = [];
	site: ISite = {
		bikeId: 1,
		langId: 1,
		price: '<p>1 700 000 Ft</p>',
		discountPrice: '<p>Most csak 1 399 000 Ft</p>',
		calculatorPrice: 1399000,
		is_test_drive_available: '0',
		apr: 549,
	};
	lang: ILang = {
		bikeId: 1,
		lang: '',
		description: '',
		slogan: '',
		og_title: '',
		og_desc: '',
		og_image: '',
	};
	blocks: IBlock[] = [];
	resellers: IConnectedResellerItem[] = [];

	constructor(responseData?: IBikeResponse) {
		if (responseData) {
			this.bike = {
				bikeId: setNumber(responseData.bike.bike_id),
				typeId: setNumber(responseData.bike.type_id),
				name: setString(responseData.bike.name),
				slug: setString(responseData.bike.slug),
				year: setNumber(responseData.bike.year),
				logo: setString(responseData.bike.logo),
				image: setString(responseData.bike.image),
				background: setString(responseData.bike.background),
				defaultColorId: setNumber(responseData.bike.default_color_id),
				engineCapacity: setNumber(responseData.bike.engine_capacity),
				fuelCapacity: setNumber(responseData.bike.fuel_capacity),
				performance: setNumber(responseData.bike.performance),
				torque: setNumber(responseData.bike.torque),
				curbWeight: setNumber(responseData.bike.curb_weight),
				seatHeight: setNumber(responseData.bike.seat_height),
				license: responseData.bike.license.map((licence) => licence),
				type: setString(responseData.bike.type),
				isDraft: setBoolean(responseData.bike.is_draft),
				isNew: setBoolean(responseData.bike.is_new),
				innerBackground: setString(responseData.bike.inner_background),
			};
			this.simpleSlider = responseData['simple-slider'].map(
				(simpleSliderItem) => {
					return {
						sliderId: setNumber(simpleSliderItem.slider_id),
						bikeId: setNumber(simpleSliderItem.bike_id),
						lang: setString(simpleSliderItem.lang),
						type: setString(simpleSliderItem.type),
						content: setString(simpleSliderItem.content),
						order: setNumber(simpleSliderItem.order),
					};
				}
			);
			this.offers = responseData.offers.map((offerItem) => {
				return {
					bikeId: setNumber(offerItem.bike_id),
					offerId: setNumber(offerItem.offer_id),
					image: setString(offerItem.image),
					description: setString(offerItem.description),
					name: setString(offerItem.name),
					slug: setString(offerItem.slug),
				};
			});
			this.realtions = responseData.relations.map((relationItem) => {
				return {
					bikeId: setNumber(relationItem.bike_id),
					year: setNumber(relationItem.year),
					name: setString(relationItem.name),
					slug: setString(relationItem.slug),
				};
			});
			this.properties = responseData.properties.map((propertieItem) => {
				return {
					bikeId: setNumber(propertieItem.bike_id),
					value: setString(propertieItem.value),
					propertyId: setNumber(propertieItem.property_id),
					lang: setString(propertieItem.lang),
					icon: setString(propertieItem.icon),
					name: setString(propertieItem.name),
				};
			});
			this.documents = responseData.documents.map((documentItem) => {
				return {
					documentId: setNumber(documentItem.document_id),
					bikeId: setNumber(documentItem.bike_id),
					lang: setString(documentItem.lang),
					name: setString(documentItem.name),
					icon: setString(documentItem.icon),
					file: setString(documentItem.file),
				};
			});
			this.colors = responseData.colors.map((colorItem) => {
				return {
					colorId: setNumber(colorItem.color_id),
					bikeId: setNumber(colorItem.bike_id),
					color01: setString(colorItem.color_01),
					color02: setString(colorItem.color_02),
					colorName: setString(colorItem.color_name),
					bikeImage: setString(colorItem.bike_image),
				};
			});
			this.technologies = responseData.technologies.map((technologyItem) => {
				return {
					bikeId: setNumber(technologyItem.bike_id),
					technologyId: setNumber(technologyItem.technology_id),
					lang: setString(technologyItem.lang),
					icon: setString(technologyItem.icon),
					name: setString(technologyItem.name),
					description: setString(technologyItem.description),
					order: setNumber(technologyItem.order),
				};
			});
			this.site = {
				bikeId: setNumber(responseData.site.bike_id),
				langId: setNumber(responseData.site.lang_id),
				price: setString(responseData.site.price),
				discountPrice: setString(responseData.site.discount_price),
				calculatorPrice: setNumber(responseData.site.calculator_price),
				is_test_drive_available: setString(
					responseData.site.is_test_drive_available
				),
				apr: setNumber(responseData.site.apr),
			};
			this.lang = {
				bikeId: setNumber(responseData.lang.bike_id),
				lang: setString(responseData.lang.lang),
				description: setString(responseData.lang.description),
				slogan: setString(responseData.lang.slogan),
				og_title: setString(responseData.lang.og_title),
				og_desc: setString(responseData.lang.og_desc),
				og_image: setString(responseData.lang.og_image),
			};

			this.blocks = responseData.blocks.map((block) => {
				let typedBlock;

				let blockElements: BlockElementType[] = [];

				switch (block.type) {
					case 'FEATURE':
						typedBlock = block as IBlockResponse<'FEATURE'>;
						blockElements = typedBlock.elements.map(
							(element) => new FeatureBlockElement(element)
						);
						break;
					case 'TEXT_SLIDER':
						typedBlock = block as IBlockResponse<'TEXT_SLIDER'>;
						blockElements = typedBlock.elements.map(
							(element) => new TextSliderBlockElement(element)
						);
						break;
					case 'TECH_SLIDER':
						typedBlock = block as IBlockResponse<'TECH_SLIDER'>;
						blockElements = typedBlock.elements.map(
							(element) => new TechSliderBlockElement(element)
						);
						break;
					case 'PROMOTION':
						typedBlock = block as IBlockResponse<'PROMOTION'>;
						blockElements = typedBlock.elements.map(
							(element) => new PromotionBlockElement(element)
						);
						break;
				}

				return {
					blockId: setNumber(block.block_id),
					bikeId: setNumber(block.bike_id),
					lang: setString(block.lang),
					name: setString(block.name),
					type: setString(block.type),
					order: setNumber(block.order),
					elements: blockElements,
				};
			});

			this.resellers = responseData.resellers.map((reseller) => {
				return {
					resellerId: setNumber(reseller.reseller_id),
					siteId: setNumber(reseller.site_id),
					coordLat: setNumber(reseller.coord_lat),
					coordLng: setNumber(reseller.coord_lng),
					city: setString(reseller.city),
					name: setString(reseller.name),
					address: setString(reseller.address),
					tel: setString(reseller.tel),
					email: setString(reseller.email),
					siteUrl: setString(reseller.site_url),
					isDraft: setBoolean(reseller.is_draft),
					isCarSeller: setBoolean(reseller.is_car_seller),
					isMarineSeller: setBoolean(reseller.is_marine_seller),
					isBikeSeller: setBoolean(reseller.is_bike_seller),
					isScooterSeller: setBoolean(reseller.is_scooter_seller),
					isService: setBoolean(reseller.is_service),
					logo: setString(reseller.logo),
					langId: setNumber(reseller.lang_id),
					openingHours: setString(reseller.opening_hours),
					isTestDriveAvailable: setNumber(reseller.is_test_drive_available),
				};
			});
		}
	}
}

export interface IBikeDetails {
	bikeId: number;
	typeId: number;
	name: string;
	slug: string;
	year: number;
	logo: string;
	image: string;
	background: string;
	defaultColorId: number;
	engineCapacity: number;
	fuelCapacity: number;
	performance: number;
	torque: number;
	curbWeight: number;
	seatHeight: number;
	license: string[];
	type: string;
	isDraft: boolean;
	isNew: boolean;
	innerBackground: string;
}

export interface ISimpleSliderItem {
	sliderId: number;
	bikeId: number;
	lang: string;
	type: string;
	content: string;
	order: number;
}

export interface IOfferItem {
	slug: string;
	bikeId: number;
	offerId: number;
	image: string;
	description: string;
	name: string;
}

export interface IRelationItem {
	bikeId: number;
	year: number;
	name: string;
	slug: string;
}

export interface IPropertiItem {
	bikeId: number;
	value: string;
	propertyId: number;
	lang: string;
	icon: string;
	name: string;
}

export interface IDocumentItem {
	documentId: number;
	bikeId: number;
	lang: string;
	name: string;
	icon: string;
	file: string;
}

export interface IColorItem {
	colorId: number;
	bikeId: number;
	color01: string;
	color02: string;
	colorName: string;
	bikeImage: string;
}

export interface ITechnologyItem {
	bikeId: number;
	technologyId: number;
	lang: string;
	icon: string;
	name: string;
	description: string;
	order: number;
}

export interface ISite {
	bikeId: number;
	langId: number;
	price: string;
	discountPrice: string;
	calculatorPrice: number;
	is_test_drive_available: string;
	apr: number;
}

export interface ILang {
	bikeId: number;
	lang: string;
	description: string;
	slogan: string;
	og_title: string;
	og_image: string;
	og_desc: string;
}

export interface IBlock {
	blockId: number;
	bikeId: number;
	lang: string;
	name: string;
	type: string;
	order: number;
	elements: BlockElementType[];
}

export class FeatureBlockElement {
	featureId: number = 0;
	blockId: number = 0;
	smallImage: string = '';
	bigImage: string = '';
	textPositionX: string = '';
	textPositionY: string = '';
	title: string = '';
	content: string = '';
	order: number = 0;

	constructor(element?: IFeatureBlockResponseElement) {
		if (element) {
			this.featureId = setNumber(element.feature_id);
			this.blockId = setNumber(element.block_id);
			this.smallImage = setString(element.small_image);
			this.bigImage = setString(element.big_image);
			this.textPositionX = setString(element.text_position_x);
			this.textPositionY = setString(element.text_position_y);
			this.title = setString(element.title);
			this.content = setString(element.content);
			this.order = setNumber(element.order);
		}
	}
}

export class TextSliderBlockElement {
	sliderId: number = 0;
	blockId: number = 0;
	background: string = '';
	title: string = '';
	content: string = '';
	textPositionX: string = '';
	textPositionY: string = '';
	order: number = 0;
	btn_text: string = '';
	btn_link: string = '';
	btn_bg_color: string = '';
	btn_text_color: string = '';

	constructor(element?: ITextSliderBlockResponseElement) {
		if (element) {
			this.sliderId = setNumber(element.slider_id);
			this.blockId = setNumber(element.block_id);
			this.background = setString(element.background);
			this.title = setString(element.title);
			this.content = setString(element.content);
			this.textPositionX = setString(element.text_position_x);
			this.textPositionY = setString(element.text_position_y);
			this.btn_text = setString(element.btn_text);
			this.btn_link = setString(element.btn_link);
			this.btn_bg_color = setString(element.btn_bg_color);
			this.btn_text_color = setString(element.btn_text_color);

			this.order = setNumber(element.order);
		}
	}

	public isButton(): boolean {
		if (this.btn_text && this.btn_link) return true;
		else return false;
	}
}

export class TechSliderBlockElement {
	sliderId: number = 0;
	blockId: number = 0;
	image: string = '';
	title: string = '';
	content: string = '';
	order: number = 0;

	constructor(element?: ITechSliderBlockResponseElement) {
		if (element) {
			this.sliderId = setNumber(element.slider_id);
			this.blockId = setNumber(element.block_id);
			this.image = setString(element.image);
			this.title = setString(element.title);
			this.content = setString(element.content);
			this.order = setNumber(element.order);
		}
	}
}

export class PromotionBlockElement {
	promotionId: number = 0;
	blockId: number = 0;
	background: string = '';
	name: string = '';
	description: string = '';
	btnText: string = '';
	url: string = '';
	order: number = 0;

	constructor(element?: IPromotionBlockResponseElement) {
		if (element) {
			this.blockId = setNumber(element.block_id);
			this.promotionId = setNumber(element.promotion_id);
			this.background = setString(element.background);
			this.name = setString(element.name);
			this.description = setString(element.description);
			this.btnText = setString(element.btn_text);
			this.url = setString(element.url);
			this.order = setNumber(element.order);
		}
	}
}

export type BlockElementType =
	| FeatureBlockElement
	| TextSliderBlockElement
	| TechSliderBlockElement
	| PromotionBlockElement;

export interface IConnectedResellerItem {
	resellerId: number;
	siteId: number;
	coordLat: number;
	coordLng: number;
	city: string;
	name: string;
	address: string;
	tel: string;
	email: string;
	siteUrl: string;
	isDraft: boolean;
	isCarSeller: boolean;
	isMarineSeller: boolean;
	isBikeSeller: boolean;
	isScooterSeller: boolean;
	isService: boolean;
	logo: string;
	langId: number;
	openingHours: string;
	isTestDriveAvailable: number;
}

import React, { useContext, useEffect } from 'react';
import HTMLParser from 'html-react-parser';
import { SingleNewsModel } from '../Model/SingleNews.model';
import { IError } from '../../../Interfaces/IError.interface';
import './Less/SingleNews.css';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { formatDateString } from '../../../Utils/FormatDateString.util';
import Placeholder from '../../../Assets/offer_placeholder.png';
import { Helmet } from 'react-helmet';
import Cookie from 'js-cookie';
import { map } from 'lodash';
import RelatedNewsView from './RelatedNews.view';
import Masonry from 'react-masonry-css';
import { NewsModel } from '../Model/News.model';
import { changeNewsIdToName } from '../../../Utils/ChangeNewsIdToName.util';
import { NewsTypesModel } from '../Model/NewsTypes.model';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';

export interface ISingleNewsViewProps {
  loading: Promise<any> | null;
  single_news: SingleNewsModel;
  news: NewsModel[];
  news_types: NewsTypesModel[];
  error: IError;
  loadRelatedNews: (offer_id: string) => void;
}

const SingleNewsView: React.FC<ISingleNewsViewProps> = (
  props
): React.ReactElement => {
  const routerContext: IRouterContext = useContext(RouterContext);
  const { single_news: news } = props;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getNewsSlug = (): string => {
    let news: string = '';
    routerContext.menuData.MAIN.map((menudata) => {
      return menudata.label === 'NEWS' ? (news = menudata.link) : '';
    });
    routerContext.menuData.SIDE.map((menudata) => {
      return menudata.label === 'NEWS' ? (news = menudata.link) : '';
    });
    routerContext.menuData.SUB.map((menudata) => {
      return menudata.label === 'NEWS' ? (news = menudata.link) : '';
    });
    return news;
  };

  const breakpointColumnsObj = {
    default: 2,
    700: 1,
  };

  const changed_type_news: NewsModel[] = changeNewsIdToName(
    props.news,
    props.news_types
  );

  const listener = (): void => {
    if (news.relations.length !== 0)
      Cookie.set('secondLevelRoute', news.relations[0].slug);
  };
  useEffect(() => {
    const lang_selector: HTMLDivElement | null = document.querySelector(
      '.normal-lang-selector'
    );
    if (lang_selector instanceof HTMLDivElement) {
      lang_selector.addEventListener('click', listener);
    }
    const sticky_lang_selector: HTMLDivElement | null = document.querySelector(
      '.sticky-lang-selector'
    );
    if (sticky_lang_selector instanceof HTMLDivElement) {
      sticky_lang_selector.addEventListener('click', listener);
    }
    return () => {
      sticky_lang_selector?.removeEventListener('click', listener);
      lang_selector?.removeEventListener('click', listener);
    };
  });
  React.useEffect(() => {
    props.loading !== null ? ControlSplash(true) : ControlSplash(false);
    return () => {};
  }, [props.loading]);
  return (
    <div className='single-news-view'>
      <Helmet>
        <meta property='og:title' content={news.og_title} />
        <meta property='og:type' content='article' />
        <meta property='og:description' content={news.og_desc} />
        <meta property='og:url' content={window.location.href} />
        <meta property='og:image' content={news.og_image} />
      </Helmet>
      <div
        className='head-img'
        style={{
          backgroundImage: `url(${
            news.inner_image !== '' ? news.inner_image : Placeholder
          }`,
        }}
      >
        <div className='lead'>{news.name}</div>
        <div className='date'>
          <span className='before'></span>
          {formatDateString(
            news.published_at,
            '.',
            routerContext.lang.value.name,
            true
          )}
          <span className='after'></span>
        </div>
      </div>

      <div className='content'>{HTMLParser(news.content)}</div>
      <div className='related-news'>
        {
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className='my-masonry-grid'
            columnClassName='my-masonry-grid_column'
          >
            {map(changed_type_news, (article: NewsModel, index: number) => (
              <RelatedNewsView
                key={`main-news-${index}`}
                article={article}
                news_slug={getNewsSlug()}
                loadRelatedNews={props.loadRelatedNews}
              ></RelatedNewsView>
            ))}
          </Masonry>
        }
      </div>
    </div>
  );
};

export default SingleNewsView;

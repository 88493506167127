import React from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { VideoModel } from '../Model/Video.model';
import { map, orderBy } from 'lodash';
import VideoElementView from './VideoElement.view';
import './Less/Video.css';
import { changeVideoIdToName } from '../../../Utils/ChangeVideoIdToName.util';
import VideoTypesView from './VideoTypes.view';
import { GetLocaleText } from '../../../I18n';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';
import { VideoTypesModel } from '../Model/VideoTypes.model';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import FancyButton from '../../UI/FancyButton/FancyButton';

export interface IVideoFilterProps {
	filter: (type_id: string) => void;
	active: number;
}
export interface IVideoPageProps extends IVideoFilterProps {
	page: () => void;
}
export interface IVideoViewProps extends IVideoPageProps {
	loading: Promise<any> | null;
	videos: VideoModel[];
	video_types: VideoTypesModel[];
	error: IError;
	load_more: boolean;
	loading_load_more: boolean;
}

const VideoView: React.FC<IVideoViewProps> = (props): React.ReactElement => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		props.loading !== null && props.loading_load_more === false
			? ControlSplash(true)
			: ControlSplash(false);
		return () => {};
		//eslint-disable-next-line
	}, [props.loading]);
	//TODO: create error pages
	if (props.error !== null) {
		console.log(props.error);
		return <SplashScreen opacity='1' isAbsolute={false} />;
	}
	const changed_type_videos: VideoModel[] = changeVideoIdToName(
		props.videos,
		props.video_types
	);
	const sortedList: VideoModel[] = orderBy(
		changed_type_videos,
		'published_at',
		'desc'
	);
	const split_locale_0 = GetLocaleText('load_more').split('-')[0];
	const split_locale_1 = GetLocaleText('load_more').split('-')[1];

	return (
		<div className='video-view'>
			<VideoTypesView
				types={props.video_types}
				filter={props.filter}
				active={props.active}
			></VideoTypesView>
			<div className='row video'>
				{map(sortedList, (videos: VideoModel, index: number) => (
					<VideoElementView key={`${index}-video-element`} videos={videos} />
				))}
			</div>
			{props.load_more === true ? (
				<FancyButton onClick={() => props.page()}>
					{split_locale_0} {split_locale_1}
				</FancyButton>
			) : (
				''
			)}
		</div>
	);
};

export default VideoView;

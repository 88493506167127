import React, { useContext, useState } from 'react';
import { RouterContext } from '../../Router/Context/Router.context';
import { GetLocaleText } from '../../../I18n';
import { ControlSplash } from '../../../Utils/ControlSplashScreen.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { IBikeControllerState } from '../Controller/Bike.controller';
import { useHistory, RouteChildrenProps } from 'react-router';
import BikePropertiesTabSectionMobileView from './BikePropertiesTabSectionMobile.view';
import BikePropertiesTabSectionLarge from './BikePropertiesTabSectionLarge.view';
import TechnologyBlockMobileView from './TechnologyBlockMobile.view';
import TechnologyBlockLargeView from './TechnologyBlockLarge.view';
import DefaultPropertiesSection from './DefaultPropertiesSection.view';
import BikeAnchorNavigation from './BikeAnchorNavigation.view';
import ResellerPromotion from './ResellerPromotion.view';
import MainSliderSection from './MainSliderSection.view';
import TextSliderSection from './TextSliderSection.view';
import TechSliderSection from './TechSliderSection.view';
import PromotionSection from './PromotionSection.view';
import FeatureSection from './FeatureSection.view';
import IntroSection from './IntroSection.view';
import MainInfoView from './MainInfo.view';
import Bikes from '../../Bikes/Controller/Bikes.controller';
import Slash from '../../../Assets/third_level_slash.png';
import Img from 'react-image';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';
import { Helmet } from 'react-helmet';
import './Less/Bike.style.css';
import BikeAccessories from './BikeAccessories.view';

export interface IBikeViewProps
	extends IBikeControllerState,
		RouteChildrenProps {
	label: string;
}

const BikeView: React.FC<IBikeViewProps> = (props): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);
	const back_text = GetLocaleText('back');
	const [width, setWidth] = useState<number>(window.innerWidth);

	const history = useHistory();

	React.useEffect(
		() => {
			const resizeListener = (): void => {
				setWidth(window.innerWidth);
			};
			window.addEventListener('resize', resizeListener);
			return () => {
				window.removeEventListener('resize', resizeListener);
			};
		},
		//eslint-disable-next-line
		[]
	);

	if (props.loading) {
		ControlSplash(true);

		return <div />;
	}
	if (props.error) return <div>Error</div>;

	const manual = props.bike.documents
		.filter((d) => d.file.match(/manual_/g))[0]
		?.file?.split('https://api-motor.suzuki.hu/files/')[1]
		?.split('.pdf')[0];

	return (
		<div className='component-wrapper'>
			<Helmet>
				<meta property='og:url' content={window.location.href} />
				<meta property='og:type' content='article' />
				<meta property='og:title' content={props.bike.lang.og_title} />
				<meta property='og:description' content={props.bike.lang.og_desc} />
				<meta property='og:image' content={props.bike.lang.og_image} />
			</Helmet>
			<div className='menu-title single-news' id='BIKE_NAME'>
				<div className='menu-title__text inner'>
					<div
						className='back-button'
						onClick={() => {
							history.push(
								cleanLink(`/${getRouteLink('BIKES', routerContext)}`)
							);
						}}
					>
						<FontAwesomeIcon icon={faLongArrowAltLeft} />
						<div>{back_text}</div>
						<div className='slash'>
							<Img src={Slash} alt='' />
						</div>
					</div>
					{props.bike.bike.name}
				</div>
			</div>
			<BikeAnchorNavigation
				bike={props.bike}
				bikeName={props.bike.bike.name}
				blocks={props.bike.blocks}
				accessories={props.accessories}
			/>
			<div>
				<MainSliderSection bike={props.bike} />
				<IntroSection model={props.bike} />
				<MainInfoView model={props.bike} />
				<DefaultPropertiesSection model={props.bike} />
				{width > 1100 ? (
					<React.Fragment>
						<BikePropertiesTabSectionLarge model={props.bike} manual={manual} />
						<TechnologyBlockLargeView model={props.bike} />
					</React.Fragment>
				) : (
					<React.Fragment>
						<BikePropertiesTabSectionMobileView
							model={props.bike}
							manual={manual}
						/>
						<TechnologyBlockMobileView model={props.bike} />
					</React.Fragment>
				)}
				<ResellerPromotion />
				{props.bike.blocks
					.sort((block1, block2) => block1.order - block2.order)
					.map((block, index) => {
						if (block.type === 'PROMOTION')
							return (
								<PromotionSection
									elements={block.elements}
									hookID={block.blockId}
									key={`${index}-block`}
								/>
							);
						if (block.type === 'TEXT_SLIDER')
							return (
								<TextSliderSection
									elements={block.elements}
									hookID={block.blockId}
									key={`${index}-block`}
								/>
							);
						if (block.type === 'TECH_SLIDER')
							return (
								<TechSliderSection
									elements={block.elements}
									hookID={block.blockId}
									key={`${index}-block`}
								/>
							);
						if (block.type === 'FEATURE')
							return (
								<FeatureSection
									hookID={block.blockId}
									elements={block.elements}
									key={`${index}-block`}
								/>
							);
						return null;
					})}
				{
					/* <BikeResellersView model={props.bike} /> */
					<div id='BIKE_ACCESSORIES'>
						<BikeAccessories accessories={props.accessories} />
					</div>
				}
				<div id='OTHER_BIKES'>
					<Bikes
						history={props.history}
						match={props.match}
						location={props.location}
						filterBikeTypesById={props.bike.bike.typeId}
						label={props.label}
					/>
				</div>
			</div>
		</div>
	);
};

export default BikeView;

import React, { useState, useContext, useEffect } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { SingleNewsModel } from '../Model/SingleNews.model';
import { RouteChildrenProps } from 'react-router';
import SingleNewsView from '../View/SingleNews.view';
import NewsDAO from '../DAO/News.dao';
import { GetLocaleText } from '../../../I18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import Slash from '../../../Assets/third_level_slash.png';
import Img from 'react-image';
import { NewsTypesModel } from '../Model/NewsTypes.model';
import { NewsModel } from '../Model/News.model';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface ISingleNewsProps
	extends RouteChildrenProps<{ slug: string }> {}

const SingleArticle: React.FC<ISingleNewsProps> = (
	props
): React.ReactElement => {
	const [error, set_error] = useState<IError>(null);
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [news_types, set_news_types] = useState<NewsTypesModel[]>([]);
	const [wait_for_push_link, set_wait_for_push_link] = useState(false);
	const [news, set_news] = useState<NewsModel[]>([]);
	const [single_news, set_single_news] = useState<SingleNewsModel>(
		new SingleNewsModel()
	);

	const routerContext: IRouterContext = useContext(RouterContext);

	const history = useHistory();

	const loadSingleNews = (): void => {
		if (props.match && props.match.params.slug) {
			set_loading(
				NewsDAO.getNewsById(props.match.params.slug)
					.then((news: SingleNewsModel) => {
						set_single_news(news);
					})
					.catch((error: IError) => set_error(error))
					.finally(() => set_loading(null))
			);
		} else {
			props.history.goBack();
		}
	};

	/* LOADING SINGLE OFFER FROM MORE OFFERS */
	useEffect(() => {
		loadNewsTypes();
		loadSingleNews();
		return () => {
			set_wait_for_push_link(false);
		};
		//eslint-disable-next-line
	}, [wait_for_push_link]);

	const loadNews = (): void => {
		set_loading(
			NewsDAO.getNews()
				.then((news: NewsModel[]) => {
					filterDrafts(news);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const filterDrafts = (full_list: NewsModel[]): void => {
		let removedDraftsNews: NewsModel[] = [];
		full_list.map((item) => {
			if (item.is_draft === '0') {
				return removedDraftsNews.push(item);
			} else return '';
		});
		getCompatibleSizeNews(removedDraftsNews);
	};

	const getCompatibleSizeNews = (removedDraftsNews: NewsModel[]): void => {
		const compatibleSizeWide: NewsModel[] = [];
		const compatibleSizes: NewsModel[] = [];
		let compatibleSizeNormal: NewsModel = new NewsModel();
		let wideCounter = 0;
		let normalCounter = 0;
		removedDraftsNews.map((item) => {
			if (item.image_ratio === 'WIDE' && wideCounter < 2) {
				compatibleSizeWide.push(item);
				wideCounter++;
			} else if (item.image_ratio === 'NORMAL' && normalCounter < 1) {
				compatibleSizeNormal = item;
				normalCounter++;
			}
			return '';
		});
		if (
			compatibleSizeWide[0] &&
			compatibleSizeWide[1] &&
			compatibleSizeNormal
		) {
			compatibleSizes.push(compatibleSizeWide[0]);
			compatibleSizes.push(compatibleSizeNormal);
			compatibleSizes.push(compatibleSizeWide[1]);
			set_news(compatibleSizes);
		}
	};

	const loadNewsTypes = (): void => {
		set_loading(
			NewsDAO.getNewsTypes()
				.then((news_types: NewsTypesModel[]) => {
					set_news_types(news_types);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					loadNews();
				})
		);
	};

	useEffect(
		() => {
			if (single_news) {
				loadNewsTypes();
				loadSingleNews();
			}
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title single-news'>
				<div className='menu-title__text inner'>
					<div
						className='back-button'
						onClick={() =>
							history.push(`/${getRouteLink('NEWS', routerContext)}`)
						}
					>
						<FontAwesomeIcon icon={faLongArrowAltLeft} />
						<div>{GetLocaleText('back')}</div>
						<div className='slash'>
							<Img src={Slash} alt='' />
						</div>
					</div>
					{GetLocaleText('news_menu')}
				</div>
			</div>
			<SingleNewsView
				single_news={single_news}
				loading={loading}
				news={news}
				news_types={news_types}
				error={error}
				loadRelatedNews={(news_slug: string) => {
					history.push(cleanLink(`${news_slug}`));
					set_wait_for_push_link(true);
				}}
			/>
		</div>
	);
};

export default SingleArticle;

import React from 'react';
import './Less/MainPageAnchorNav.css';
import { GetLocaleText } from '../../../I18n';

export interface IMainPageAnchorNavProps {}

const MainPageAnchorNav: React.FC<IMainPageAnchorNavProps> = (
	props
): React.ReactElement => {
	const totop_text = GetLocaleText('main_anchor_totop');
	const offer_text = GetLocaleText('main_anchor_offers');
	const resellers_text = GetLocaleText('main_anchor_resellers');
	const proposition_text = GetLocaleText('main_anchor_proposition');
	const news_text = GetLocaleText('main_anchor_news');

	const [isVisible, setisVisible] = React.useState<boolean>(
		window.scrollY > 0 ? true : false
	);

	const scrollToHash = (hash: string): void => {
		const element: HTMLElement | null = document.getElementById(hash);
		if (!element) return;
		const yOffset = -60;
		const y =
			element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });
	};

	const setVisibility = (): void => {
		if (window.scrollY > 0) {
			setisVisible(true);
		}
		if (window.scrollY === 0) {
			setisVisible(false);
		}
	};

	React.useEffect(
		() => {
			document.addEventListener('scroll', setVisibility);
			return () => {
				document.removeEventListener('scroll', setVisibility);
			};
		},
		//eslint-disable-next-line
		[]
	);
	return (
		<React.Fragment>
			{isVisible && (
				<div className='anchor-nav'>
					<div
						className='anchor-nav__logo'
						onClick={() => scrollToHash('SLIDER')}
					>
						{totop_text}
					</div>
					<div className='anchor-nav__hashes'>
						<div
							className='anchor-nav__hash'
							onClick={() => scrollToHash('FIRST_OFFERS')}
						>
							<div className='text'>{offer_text}</div>
						</div>
						<div
							className='anchor-nav__hash'
							onClick={() => scrollToHash('RESELLERS')}
						>
							<div className='text'>{resellers_text}</div>
						</div>
						<div
							className='anchor-nav__hash'
							onClick={() => scrollToHash('SECOND_OFFERS')}
						>
							<div className='text'>{proposition_text}</div>
						</div>
						<div
							className='anchor-nav__hash'
							onClick={() => scrollToHash('NEWS')}
						>
							<div className='text'>{news_text}</div>
						</div>
					</div>
				</div>
			)}
			{null}
		</React.Fragment>
	);
};

export default MainPageAnchorNav;

import React from 'react';
import HTMLParser from 'html-react-parser';
import { GetLocaleText } from '../../../I18n';
import { OfferModel } from '../Model/Offer.model';
import BlueToRedButton from '../../UI/BlueToRedButton/BlueToRedButton';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
let Flip = require('react-reveal/Flip');

interface IOfferLeftOrRightElementProps {
	offer: OfferModel;
	index: number;
	lang: string;
}
export const OfferNoImgElementView: React.FC<IOfferLeftOrRightElementProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = React.useContext(RouterContext);
	const { offer } = props;
	return (
		<div className='offer__container--row row no-image'>
			<Flip left>
				<div className='offer__container--row__desc col-lg-12 col-12'>
					<div className='offer__container--row__desc--quote'>
						<span>,,</span>
					</div>
					<div className='offer__container--row__desc--short'>
						{HTMLParser(offer.short_desc)}
					</div>

					<BlueToRedButton
						link={cleanLink(
							`/${getRouteLink('OFFERS', routerContext)}/${offer.slug}`
						)}
					>
						{GetLocaleText('read_more')}
					</BlueToRedButton>
				</div>
			</Flip>
		</div>
	);
};

import React, { useContext, useEffect, useState } from 'react';

import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import { GetLocaleText } from '../../../I18n';
import ExportFinancingView from '../View/ExportFinancing.view';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { IExportFinancingForm } from '../Interfaces/IExportFinancing.interface';
import { changeHandler, setOptions } from '../../../Utils/InputChange.util';
import { cloneDeep } from 'lodash';
import { ExportFinancingForm } from '../Static/ExportFinancing.form';
import {
	IInputBoolean,
	IInputSelect,
	IInputText,
	IInputTextArea,
} from '../../../Interfaces/IInput.interface';
import BikesDAO from '../../Bikes/DAO/Bikes.DAO';
import { BikeTypesModel } from '../../Bikes/Model/BikeTypes.model';
import { ISelectOption } from '../../../Interfaces/ISelectOption.type';
import ResellersDAO from '../../Resellers/DAO/Resellers.DAO';
import { ResellersModel } from '../../Resellers/Model/Resellers.model';
import ExportFinancingDAO from '../DAO/ExportFinancing.DAO';
import { validateFormData } from '../../../Utils/ValidateForm.util';
import ExportFinancingMailDAO from '../DAO/ExportFinancingMail.DAO';

export interface IContactControllerProps
	extends RouteChildrenProps<{ slug: string }> {}

const ExportFinancingController: React.FC<IContactControllerProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = useContext(RouterContext);

	const initForm = (): IExportFinancingForm => {
		const initialForm: IExportFinancingForm = cloneDeep(ExportFinancingForm);
		return initialForm;
	};

	const [form, set_form] = useState<IExportFinancingForm>(initForm());
	const [starting_form, set_starting_form] = useState<IExportFinancingForm>(
		initForm()
	);
	const [form_sent, set_form_sent] = useState<boolean>(false);
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [comp_loading, set_comp_loading] = useState<Promise<any> | null>(null);
	const [error, set_error] = useState<any>(null);
	const legal1: string = GetLocaleText('ex_fin__form_legal_option_1');
	const legal2: string = GetLocaleText('ex_fin__form_legal_option_2');
	const legal3: string = GetLocaleText('ex_fin__form_legal_option_3');
	const legal4: string = GetLocaleText('ex_fin__form_legal_option_4');
	const legal5: string = GetLocaleText('ex_fin__form_legal_option_5');
	const monthName: string = GetLocaleText('ex_fin__form_term_month');
	const errorMessages: Array<string> = [
		GetLocaleText('ex_fin__form_err_pp'),
		GetLocaleText('ex_fin__form_err_fields'),
		GetLocaleText('ex_fin__form_err_length'),
		GetLocaleText('ex_fin__form_err_select'),
	];

	useEffect(
		() => {
			fillFields();
			return () => {
				set_form_sent(false);
			};
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	const fillFields = () => {
		set_loading(
			BikesDAO.getBikeTypes()
				.then((types: BikeTypesModel[]) => {
					//append bike types to its selector
					const append_bike_types = types.map((type) => ({
						id: type.type_id,
						name: type.name,
					}));
					let new_form: IExportFinancingForm = cloneDeep(form);
					new_form = setOptions(new_form, 'bike_category', append_bike_types);
					new_form = changeHandler(
						new_form,
						'bike_category',
						append_bike_types[0]
					);

					//append self-sufficiency values to its selector
					const self_suff_options: Array<ISelectOption> = [];
					for (let i = 10; i <= 70; i += 5) {
						self_suff_options.push({ id: `${i}`, name: `${i}%` });
					}
					new_form = setOptions(new_form, 'self_suff', self_suff_options);
					new_form = changeHandler(new_form, 'self_suff', self_suff_options[0]);
					//append term values to its selector
					const term_options: Array<ISelectOption> = [];
					for (let i = 36; i <= 72; i += 12) {
						term_options.push({ id: `${i}`, name: `${i} ${monthName}` });
					}
					new_form = setOptions(new_form, 'term', term_options);
					new_form = changeHandler(new_form, 'term', term_options[0]);

					//append legal form values to its selector
					const legal_form_options: Array<ISelectOption> = [
						{ id: 'private', name: legal1 },
						{ id: 'entre', name: legal2 },
						{ id: 'entrevat', name: legal3 },
						{ id: 'legal', name: legal4 },
						{ id: 'legalvat', name: legal5 },
					];
					new_form = setOptions(new_form, 'legal_form', legal_form_options);
					new_form = changeHandler(
						new_form,
						'legal_form',
						legal_form_options[0]
					);

					//set new form
					return new_form;
				})
				.then((new_form) => {
					ResellersDAO.getResellers()
						.then((resellers: ResellersModel[]) => {
							const resellers_option = resellers.map((reseller) => ({
								id: `${reseller.reseller_id}`,
								name: `${reseller.name} / ${reseller.city}`,
								email: `${reseller.email}`,
								address: `${reseller.address}`,
								city: `${reseller.name}`,
							}));
							new_form = setOptions(new_form, 'reseller', resellers_option);
							return new_form;
						})
						.then((new_form) => {
							firstLoadModel(
								new_form.bike_category.options[0].id,
								new_form
							).then((full_form) => {
								set_form(full_form);
								set_starting_form(full_form);
							});
						});
				})
				.finally(() => set_loading(null))
		);
	};

	const firstLoadModel = (id: string, form: IExportFinancingForm) => {
		return ExportFinancingDAO.getBikeByType(id).then((bikes) => {
			const bike_model_options = bikes.map((bike) => ({
				id: bike.slug,
				name: `${bike.name} ${bike.year}`,
			}));
			form = setOptions(form, 'bike_model', bike_model_options);
			return form;
		});
	};

	const changeModels = (args: IInputChange): void => {
		set_comp_loading(
			ExportFinancingDAO.getBikeByType(args.value.id)
				.then((bike) => {
					let new_form = cloneDeep(form);
					const bike_model_options = bike.map((bike) => ({
						id: bike.slug,
						name: `${bike.name} ${bike.year}`,
					}));
					new_form = setOptions(new_form, 'bike_model', bike_model_options);
					new_form = changeHandler(new_form, 'bike_model', '');
					new_form = changeHandler(new_form, 'model_price', '');
					set_form(changeHandler(new_form, args.fieldName, args.value));
				})
				.finally(() => set_comp_loading(null))
		);
	};

	const changePrice = (args: IInputChange): void => {
		set_comp_loading(
			BikesDAO.getBike(args.value.id)
				.then((bike) => {
					let new_form = cloneDeep(form);
					let plainPrice = bike.site.price.replace(/<[^>]+>/g, '');
					new_form = changeHandler(form, args.fieldName, args.value);
					set_form(changeHandler(new_form, 'model_price', plainPrice));
				})
				.finally(() => set_comp_loading(null))
		);
	};

	const onChange = (args: IInputChange): void => {
		if (args.fieldName === 'bike_category') changeModels(args);
		if (args.fieldName === 'bike_model') changePrice(args);
		else set_form(changeHandler(form, args.fieldName, args.value));
	};

	const parseFormData = (): FormData | null => {
		const data: FormData = new FormData();
		for (const T of Object.values(form)) {
			const field:
				| IInputTextArea
				| IInputText
				| IInputSelect<ResellersModel>
				| IInputBoolean = T;

			if ('booleanType' in field) {
				continue;
			}
			if ('textType' in field) {
				data.append(field.id, field.value);
				continue;
			}
			if ('selectType' in field) {
				if (field.value) {
					if (
						field.value['email'] &&
						field.value['address'] &&
						field.value['city']
					) {
						data.append(field.id, `${field.value['email']}`);
						data.append('reseller-address', `${field.value['address']}`);
						data.append('reseller-name', `${field.value['city']}`);
					} else data.append(field.id, `${field.value['name']}`);
					continue;
				}
			}

			data.append(field.id, `${field.value}` || 'null');
		}
		return data;
	};

	const onSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		set_error('');
		const isFormOk = validateFormData(form, errorMessages);
		if (isFormOk === null) {
			const data: FormData | null = parseFormData();
			if (!data) return;
			set_loading(
				ExportFinancingMailDAO.create(
					data,
					routerContext.lang.value.name.toLowerCase()
				)
					.then((data) => {
						set_form_sent(true);
						set_form(starting_form);
					})
					.catch((e) => set_error(e))
					.finally(() => set_loading(null))
			);
		} else {
			set_error(isFormOk.join('<br/>'));
		}
	};

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>
					{GetLocaleText('ex_fin__header_title')}
				</div>
			</div>
			<ExportFinancingView
				onChange={onChange}
				form={form}
				form_sent={form_sent}
				set_form_sent={set_form_sent}
				onSubmit={onSubmit}
				loading={loading}
				comp_loading={comp_loading}
				error={error}
			/>
		</div>
	);
};

export default ExportFinancingController;

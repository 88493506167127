import * as React from 'react';
//Prevents laggy loading of images and you can set a loading component for it with the 'loader' prop
import Img from 'react-image';
import { useState, useEffect, useRef } from 'react';
import { IMenuList, IRoute } from '../../../Interfaces/IRoute.interface';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { useHistory } from 'react-router';
import { map } from 'lodash';
import SuzukiLogo from '../../../Assets/suzukilogo.png';
import Cookie from 'js-cookie';
import Insta from '../../../Assets/insta_icon.png';
import FB from '../../../Assets/facebook_icon.png';
import YT from '../../../Assets/yt_icon.png';
import './Less/Navbar.css';
import { Link } from 'react-router-dom';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { GetLocaleText } from '../../../I18n';

export interface IMainNavbarProps {}

const MainNavbar: React.FC<IMainNavbarProps> = (props): React.ReactElement => {
	const [isOpened, set_isOpened] = React.useState<boolean>(false);
	const routerContext: IRouterContext = React.useContext(RouterContext);
	const routes: IMenuList = routerContext.menuData;
	const history = useHistory();
	const navbarClasses: string[] = ['navbar'];
	const [isSticky, setSticky] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		window.scrollY > 0 ? setSticky(true) : setSticky(false);
	};

	const links_object = [
		{
			lang: {
				key: 'hu',
				link: {
					facebook: 'https://www.facebook.com/SuzukiMotorcyclesMagyarorszag',
					instagram: 'https://www.instagram.com/suzukimotorcyclesmagyarorszag/',
					youtube: 'https://www.youtube.com/SuzukiMotorcyclesMagyarorszag',
				},
			},
		},
		{
			lang: {
				key: 'ro',
				link: {
					facebook: 'https://www.facebook.com/suzukirom',
					instagram: 'https://www.instagram.com/suzuki.ro/?hl=ro',
					youtube: 'https://www.youtube.com/user/RomaniaSuzuki',
				},
			},
		},
		{
			lang: {
				key: 'sk',
				link: {
					facebook: 'https://www.facebook.com/Suzuki.Motocykle/',
					instagram: 'https://www.instagram.com/suzuki_slovakia/',
					youtube: 'https://www.youtube.com/user/SuzukiSlovakia',
				},
			},
		},
		{
			lang: {
				key: 'si',
				link: {
					facebook: 'https://www.facebook.com/suzukislo',
					instagram: '',
					youtube: '',
				},
			},
		},
		{
			lang: {
				key: 'hr',
				link: {
					facebook: 'https://www.facebook.com/suzuki.hr/',
					instagram: '',
					youtube: 'https://www.youtube.com/channel/UCAiGlfgURhBMlYKR__FtUVg',
				},
			},
		},
		{
			lang: {
				key: 'cz',
				link: {
					facebook: 'https://www.facebook.com/suzukimotoczech',
					instagram: 'https://www.instagram.com/suzuki_czech/',
					youtube: '',
				},
			},
		},
	];
	const found = routerContext.lang.options.find((e) => e.id !== 'en');
	let goo_map;
	if (found) goo_map = links_object.find((e) => e.lang.key === found?.id);
	let fb_link = '',
		ig_link = '',
		yt_link = '';
	if (found && goo_map) {
		fb_link = goo_map.lang.link.facebook;
		ig_link = goo_map.lang.link.instagram;
		yt_link = goo_map.lang.link.youtube;
	}

	if (isOpened) navbarClasses.push('navbar-opened');

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', () => handleScroll);
		};
		// eslint-disable-next-line
	}, []);

	const handleLinkClick = (linkWOSlash: string, name: string): void => {
		const link: string = `/${linkWOSlash}`;
		//re-direct and re-render only if the route is different
		if (history.location.pathname !== link) {
			history.push(link);
		}
		set_isOpened(false);
	};

	const returnRoute = (
		route: IRoute,
		className: string,
		isSticky?: boolean
	): JSX.Element => {
		const classes: string[] = ['link', className];

		if (route.icon) classes.push('link--with-icon');
		if (history.location.pathname.split('/')[1] === route.link)
			classes.push('active');

		if (route.link.startsWith('http'))
			return (
				<div
					className={classes.join(' ')}
					key={`${route.menu_id}-link-key`}
					style={{
						backgroundImage: route.icon ? `url(${route.icon})` : 'none',
					}}
				>
					<a
						href={route.link}
						className={classes.join(' ')}
						target='_blank'
						rel='noopener noreferrer'
					>
						<span style={{ display: 'none' }}>
							<Img src={route.icon} alt='' />
						</span>
						{route.name}
					</a>
				</div>
			);
		if (isSticky && route.icon)
			return (
				<div
					className={classes.join(' ')}
					key={`${route.menu_id}-link-key`}
					style={{
						backgroundImage: route.icon ? `url(${route.icon})` : 'none',
					}}
					onClick={() => {
						set_isOpened(!isOpened);
					}}
				>
					<Link to={`/${cleanLink(route.link)}`} className={classes.join(' ')}>
						<Img src={route.icon} alt='' style={{ maxHeight: '25px' }} />
					</Link>
				</div>
			);
		return (
			<div
				className={classes.join(' ')}
				key={`${route.menu_id}-link-key`}
				style={{
					backgroundImage: route.icon ? `url(${route.icon})` : 'none',
				}}
				onClick={() => {
					set_isOpened(false);
				}}
			>
				<Link to={`/${cleanLink(route.link)}`} className={classes.join(' ')}>
					<span style={{ display: 'none' }}>
						<Img src={route.icon} alt='' style={{ maxHeight: '25px' }} />
					</span>
					{route.name}
				</Link>
			</div>
		);
	};

	const switchLang = (): void => {
		const path: string = history.location.pathname;
		for (const route of routerContext.routes) {
			if (
				(route.label === 'BIKES' || route.label === 'SCOOTER') &&
				path.includes(route.link)
			) {
				// In case of a link like /bikes/slug, the 0th element of the array would be "",
				// the second would be bikes, motorok, etc... and the 2nd would be the bike/scooter slug.
				const slug: string[] = path.split('/');
				if (slug[2]) {
					Cookie.set('secondLevelRoute', slug[2]);
				}
				break;
			}
		}

		routerContext.switchLanguage();
	};

	return (
		<div>
			<div
				className={`sticky-wrapper${isSticky ? ' sticky' : ''} ${
					isOpened ? 'opened' : 'closed'
				}`}
			>
				<div className='sticky-inner' style={{ color: 'black' }}>
					<div className='navbar__logo'>
						<Img
							onClick={() => handleLinkClick('', '')}
							src={SuzukiLogo}
							alt='Suzuki logo'
							className='navbar__logo--image'
						/>
					</div>
					<div className='navbar__wrapper'>
						<div className='navbar__wrapper--routes'>
							{map(routes.SUB, (route: IRoute) =>
								returnRoute(route, 'sub-link')
							)}
							<div
								className='navbar__wrapper--routes__side'
								onClick={() => {
									set_isOpened(false);
								}}
							>
								{map(routes.SIDE, (route: IRoute) =>
									returnRoute(route, 'side-link', true)
								)}
							</div>
							<div
								className='lang-selector sticky-lang-selector'
								onClick={switchLang}
							>
								{routerContext.lang.value.name ===
								routerContext.lang.options[1].name ? (
									<div className='current'>
										{routerContext.lang.options[0].name}
									</div>
								) : (
									<div className='current'>
										{routerContext.lang.options[1].name}
									</div>
								)}
							</div>

							<div
								className={`navbar__threads`}
								onClick={() => set_isOpened(!isOpened)}
							>
								<div className='thread' />
								<div className='thread' />
								<div className='thread' />
							</div>
						</div>
					</div>
					<div
						className={`navbar__wrapper--routes__main ${
							isOpened ? 'opened' : 'closed'
						}`}
					>
						<div className='container'>
							{map(routes.MAIN, (route: IRoute) =>
								returnRoute(route, 'main-link', true)
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={navbarClasses.join(' ')} ref={ref}>
				<div className='navbar__logo'>
					<Img
						onClick={() => handleLinkClick('', '')}
						src={SuzukiLogo}
						alt='SUZUKI logo'
						className='navbar__logo--image'
					/>
				</div>
				<div className={`navbar__mobile ${!isOpened ? 'display-none' : ''}`}>
					<div className={`navbar__mobile--scroll`}>
						{map(routes.SUB, (route: IRoute) => returnRoute(route, ''))}
						{map(routes.MAIN, (route: IRoute) => returnRoute(route, ''))}
					</div>
					<div className='navbar__mobile--side'>
						{map(routes.SIDE, (route: IRoute) => returnRoute(route, ''))}
					</div>
					<div
						className={`navbar__mobile--socials ${
							!isOpened ? 'display-none' : ''
						}`}
					>
						<div className='navbar__mobile--socials left'>
							{GetLocaleText('navbar_social_text')}
						</div>
						<div className='navbar__mobile--socials right'>
							{fb_link.startsWith('http') ? (
								<div className='facebook item'>
									<a href={fb_link} target='_blank' rel='noopener noreferrer'>
										<Img src={FB} alt='' />
									</a>
								</div>
							) : null}
							{ig_link.startsWith('http') ? (
								<div className='instagram item'>
									<a href={ig_link} target='_blank' rel='noopener noreferrer'>
										<Img src={Insta} alt='' />
									</a>
								</div>
							) : null}
							{yt_link.startsWith('http') ? (
								<div className='youtube item'>
									<a href={yt_link} target='_blank' rel='noopener noreferrer'>
										<Img src={YT} alt='' />
									</a>
								</div>
							) : null}
						</div>
					</div>
				</div>
				<div className='navbar__routes'>
					<div className={`navbar__routes--top navbar-route-line`}>
						{map(routes.MAIN, (route: IRoute) =>
							returnRoute(route, 'main-link')
						)}
					</div>
					<div className='navbar__routes--row-wrapper'>
						<div className='navbar__routes--bottom navbar-route-line'>
							{map(routes.SUB, (route: IRoute) =>
								returnRoute(route, 'sub-link')
							)}
						</div>
						<div className='navbar__routes--side navbar-route-line'>
							{map(routes.SIDE, (route: IRoute) =>
								returnRoute(route, 'side-link')
							)}
						</div>
					</div>
					<div
						className={`navbar__routes--socials navbar-route-line ${
							!isOpened ? 'display-none' : ''
						}`}
					>
						{fb_link.startsWith('http') ? (
							<div className='facebook item'>
								<a href={fb_link} target='_blank' rel='noopener noreferrer'>
									<Img src={FB} alt='' />
								</a>
							</div>
						) : null}
						{ig_link.startsWith('http') ? (
							<div className='instagram item'>
								<a href={ig_link} target='_blank' rel='noopener noreferrer'>
									<Img src={Insta} alt='' />
								</a>
							</div>
						) : null}
						{yt_link.startsWith('http') ? (
							<div className='youtube item'>
								<a href={yt_link} target='_blank' rel='noopener noreferrer'>
									<Img src={YT} alt='' />
								</a>
							</div>
						) : null}
					</div>
				</div>
				<div
					className='lang-selector normal-lang-selector'
					onClick={switchLang}
				>
					{routerContext.lang.value.name ===
					routerContext.lang.options[1].name ? (
						<div className='current'>{routerContext.lang.options[0].name}</div>
					) : (
						<div className='current'>{routerContext.lang.options[1].name}</div>
					)}
				</div>
				<div
					className={`navbar__threads`}
					onClick={() => set_isOpened(!isOpened)}
				>
					<div className='thread' />
					<div className='thread' />
					<div className='thread' />
				</div>
			</div>
		</div>
	);
};

export default MainNavbar;

import * as React from 'react';
import GetInputLabel from '../Common-Input-Files/GetInputLabel';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import { IInputFile } from '../../../Interfaces/IInput.interface';
import '../Common-Input-Files/CommonInput.css';
import './FileUpload.css';
import { cloneDeep } from 'lodash';

export interface IFileUploadProps extends Omit<IInputFile, 'value'> {
	onChange: (e: IInputChange) => void;
	fileType?: string;
	labelText?: string;
	required?: boolean;
	loading?: boolean;
	value: Blob | File | string | null;
	id: string;
	onDelete?: (id: number) => void;
}

const FileUpload: React.FC<IFileUploadProps> = (props): React.ReactElement => {
	const label = GetInputLabel(props);
	const [name, set_name] = React.useState<Array<string | JSX.Element>>([]);
	const removeItem = (id: number) => {
		set_name(name.filter((_, i) => i !== id));
		if (props.onDelete) props.onDelete(id);
	};
	return (
		<div className='wrapper'>
			<span>{label}</span>
			<div className='file_list'>
				{props.value &&
					name.map((n, i) => (
						<p className='file_list_item m-0 p-0' key={`file_list_item-${i}`}>
							{n} <span onClick={() => removeItem(i)}>X</span>
						</p>
					))}
			</div>
			<div className={'file-upload'}>
				<label htmlFor={props.id} className='file-upload__label'>
					{props.children}
				</label>
				<input
					type='file'
					id={props.id}
					name='file-upload'
					className='file-upload__input'
					required={props.required}
					onChange={(e) => {
						if (e.currentTarget.files && e.currentTarget.files.length > 0) {
							const new_name = cloneDeep(name);
							new_name.push(e.currentTarget.files[0].name);
							set_name(new_name);
							props.onChange({
								fieldName: props.id,
								value: e.currentTarget.files[0],
							});
						}
					}}
				/>
			</div>
		</div>
	);
};

export default FileUpload;

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import {
	IFinancingBikePrice,
	Resellers,
} from '../Interfaces/IFinancing.interface';

export class FinancingBikePriceModel {
	constructor(FinancingBikePrice?: IFinancingBikePrice) {
		if (FinancingBikePrice) {
			ModelConstructor(FinancingBikePrice, this);
		}
	}

	monthly_price: number = 0; // multiplied with 100
	duration: number = 0;
	casco: boolean = false;
	amount_financed: number = 0; // multiplied with 100
	total_amount_refunded: number = 0; // multiplied with 100
	total_interest_amount: number = 0;
	total_lease_fee: number = 0;
	apr: number = 0; // multiplied with 100
	public_rate: number = 0; // multiplied with 100
	calculator_id: number = 0;
	resellers: Resellers[] = [
		{
			address: '',
			city: '',
			coord_lat: '',
			coord_lng: '',
			email: '',
			is_bike_seller: '',
			is_car_seller: '',
			is_draft: '',
			is_marine_seller: '',
			is_scooter_seller: '',
			is_service: '',
			is_test_drive_available: '',
			lang_id: '',
			logo: '',
			name: '',
			opening_hours: '',
			reseller_id: '',
			site_id: '',
			site_url: '',
			tel: '',
		},
	];
}

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { OwnersModel } from '../Model/Owners.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import OwnersView from '../View/Owners.view';
import OwnersDAO from '../DAO/Owners.DAO';
import { GetLocaleText } from '../../../I18n';

export interface IOwnersProps extends RouteChildrenProps {}

const Owners: React.FC<IOwnersProps> = (props): React.ReactElement => {
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [owners, set_owners] = useState<OwnersModel[]>([]);
	const [error, set_error] = useState<IError>(null);

	const routerContext: IRouterContext = useContext(RouterContext);

	const loadOwners = (): void => {
		set_loading(
			OwnersDAO.getOwners()
				.then((owners: OwnersModel[]) => set_owners(owners))
				.catch((error: IError) => set_error(error))
				.finally(() => set_loading(null))
		);
	};

	useEffect(
		() => {
			loadOwners();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{GetLocaleText('owners_menu')}</div>
			</div>
			<OwnersView loading={loading} owners={owners} error={error} />
		</div>
	);
};

export default Owners;

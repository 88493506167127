import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Less/CookieStart.css';
import { ViewType } from '../../CookieMain/Interfaces/IViewType.interface';
import { GetLocaleText } from '../../../../I18n';

export interface StartViewProps {
	setViewType: (viewType: ViewType) => void;
	enableAllCookieHandler: () => void;
	enableNecessaryCookieHandler: () => void;
}

const StartView: React.FC<StartViewProps> = (props) => {
	const {
		setViewType,
		enableNecessaryCookieHandler,
		enableAllCookieHandler,
	} = props;
	return (
		<>
			<div className='cookie-spacer'>
				<Row>
					<Col xs='12'>
						<div className='cookie-content'>
							<p className='cookie-content-text'>
								{GetLocaleText('cookie_content_long_desc')}
							</p>
						</div>
					</Col>
				</Row>
			</div>
			<div className='cookie-spacer'>
				<Row>
					<Col xs='12' lg='6' xl='4'>
						<div
							className='cookie-button'
							onClick={() => enableNecessaryCookieHandler()}
						>
							<button className='simple-cookie-button'>
								{GetLocaleText('cookie_content_only_necessary')}
							</button>
						</div>
					</Col>
					<Col xs='12' lg='12' xl='4'>
						<div className='cookie-button'>
							<button
								className='filled-cookie-button'
								onClick={() => enableAllCookieHandler()}
							>
								{GetLocaleText('cookie_content_accept_all')}
							</button>
						</div>
					</Col>
					<Col xs='12' lg='6' xl='4'>
						<div className='cookie-button'>
							<button
								className='simple-cookie-button'
								onClick={() => setViewType('Settings')}
							>
								{GetLocaleText('cookie_content_settings_button')}
							</button>
						</div>
					</Col>
				</Row>
			</div>
			<Row>
				<Col xs='12'>
					<div className='cookie-footer'>
						<p className='cookie-footer-text'>
							{GetLocaleText('cookie_content_footer_text').split('-')[0]}
							<button onClick={() => setViewType('Details')}>
								{GetLocaleText('cookie_content_footer_text').split('-')[1]}
							</button>{' '}
							{GetLocaleText('cookie_content_footer_text').split('-')[2]}
						</p>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default StartView;

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IMainOffers } from '../Interfaces/IMainPage.interface';

export class MainOffersModel {
  constructor(MainOffers?: IMainOffers) {
    if (MainOffers) {
      ModelConstructor(MainOffers, this);
    }
  }

  element_id: string = '';
  lang_id: string = '';
  title: string = '';
  url: string = '';
  background: string = '';
  order: string = '';
}

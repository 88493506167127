import React, { useState } from 'react';
import './Less/BikePropertiesTabSectionLarge.style.css';
import BikeModel from '../Model/Bike.model';
import { GetLocaleText } from '../../../I18n';
import defaultDownloadIcon from '../../../Assets/BikePage/downloadIcon.png';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import HTMLParser from 'html-react-parser';
import BikeModalView from './BikeModal.view';

export interface IBikePropertiesTabSectionLargeProps {
	model: BikeModel;
	manual: string;
}

const BikePropertiesTabSectionLarge: React.FC<IBikePropertiesTabSectionLargeProps> = (
	props
): React.ReactElement => {
	const [technicalDetailsIsOpen, toggleTechnicalDetail] = useState(false);
	const [documentsIsOpen, toggleDocuments] = useState(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const docs = props.model.documents.filter((d) => !d.file.match(/manual_/g));
	return (
		<div className='bike-properties-tab-section-component'>
			<div className='bike-properties-tab-section-component__content'>
				<div className='bike-page-tabs-container'>
					{props.model.properties.length > 0 ? (
						<div
							className={[
								'bike-page-tabs-container__tab',
								technicalDetailsIsOpen ? 'active' : null,
							].join(' ')}
							onClick={() => {
								toggleTechnicalDetail(!technicalDetailsIsOpen);
								toggleDocuments(false);
							}}
						>
							<span className='bike-page-tabs-container__tab--inside'>
								{GetLocaleText('bike_page_tabs_more_tech_details')}
							</span>
						</div>
					) : null}

					{props.model.documents.length > 0 ? (
						<div
							className={[
								'bike-page-tabs-container__tab',
								documentsIsOpen ? 'active' : null,
							].join(' ')}
							onClick={() => {
								toggleTechnicalDetail(false);
								toggleDocuments(!documentsIsOpen);
							}}
						>
							<span className='bike-page-tabs-container__tab--inside'>
								{GetLocaleText('bike_page_tabs_documents')}
							</span>
						</div>
					) : null}
					{props.manual ? (
						<>
							{showModal && (
								<BikeModalView
									setShowModal={setShowModal}
									manual={props.manual}
								/>
							)}
							<div onClick={() => setShowModal(true)}>
								<div className='bike-page-tabs-container__tab'>
									<span className='bike-page-tabs-container__tab--inside'>
										{GetLocaleText('manual_text')}
									</span>
								</div>
							</div>
						</>
					) : (
						''
					)}


					<div className='bike-page-tabs-container__placeholder' />
				</div>
				<div
					className={[
						'info-toggle-container',
						technicalDetailsIsOpen ? 'open' : null,
					].join(' ')}
				>
					<div className='row bike-additional-properties-container'>
						{props.model.properties.map((property, index) => (
							<div
								className='bike-additional-prop-row col-12'
								key={`${index}-prop-lg-block`}
							>
								{property.icon && (
									<img
										className='bike-additional-prop-row__image'
										src={property.icon}
										alt={generateImgAlt(property.icon)}
									/>
								)}
								<span className='bike-additional-prop-row__left'>
									{HTMLParser(property.name)}:
								</span>
								<span
									className={[
										'bike-additional-prop-row__right',
										property.name === '*'
											? 'bike-additional-prop-row__right--comment'
											: null,
									].join(' ')}
								>
									{HTMLParser(property.value)}
								</span>
							</div>
						))}
					</div>
				</div>
				<div
					className={[
						'info-toggle-container',
						documentsIsOpen ? 'open' : null,
					].join(' ')}
				>
					<div className='row bike-additional-properties-container bike-additional-properties-container--download'>
						{docs.map((doc, index) => (
							<div className='col-12 col-md-4' key={`${index}-doc-lg-block`}>
								<a
									className='download-document'
									target='_blank'
									rel='noreferrer noopener'
									href={doc.file}
								>
									<img
										className='download-document__image'
										src={defaultDownloadIcon}
										alt={generateImgAlt(defaultDownloadIcon)}
									/>
									<div className='download-document__name'>
										{doc.name}{' '}
										{GetLocaleText('bike_page_tabs_documents_donwload_text')}
									</div>
								</a>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BikePropertiesTabSectionLarge;

import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import ResellersView from '../View/Resellers.view';
import ResellersDAO from '../DAO/Resellers.DAO';
import { GetLocaleText } from '../../../I18n';
import { ResellersModel } from '../Model/Resellers.model';

export interface IOwnersProps extends RouteChildrenProps {}

const Owners: React.FC<IOwnersProps> = (props): React.ReactElement => {
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [resellers, set_resellers] = useState<ResellersModel[]>([]);
	const [all_resellers, set_all_resellers] = useState<ResellersModel[]>([]);
	const [error, set_error] = useState<IError>(null);
	const [active, set_active] = useState(false);

	const routerContext: IRouterContext = useContext(RouterContext);

	const setActive = (bike_or_scooter: boolean): void => {
		if (active !== bike_or_scooter) set_active(bike_or_scooter);
	};

	const loadResellers = (): void => {
		set_loading(
			ResellersDAO.getResellers()
				.then((resellers: ResellersModel[]) => {
					set_all_resellers(resellers);
					filterResellers(resellers);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => set_loading(null))
		);
	};

	const filterResellers = (bike_or_scooter: ResellersModel[]): void => {
		let filteredResellers: ResellersModel[] = [];
		bike_or_scooter.map((item) => {
			if (!active)
				if (item.is_bike_seller === '1') return filteredResellers.push(item);
				else return '';
			else if (item.is_scooter_seller === '1')
				return filteredResellers.push(item);
			else return '';
		});
		set_resellers(filteredResellers);
	};

	useEffect(() => {
		filterResellers(all_resellers);
		return () => {};
		//eslint-disable-next-line
	}, [active]);

	useEffect(
		() => {
			loadResellers();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>
					{GetLocaleText('resellers_menu')}
				</div>
			</div>
			<ResellersView
				loading={loading}
				resellers={resellers}
				error={error}
				active={active}
				set_active={setActive}
				lang={routerContext.lang.value.name}
			/>
		</div>
	);
};

export default Owners;

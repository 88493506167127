import React, { useState } from "react";
import CookieDetailRowView from "../View/CookieDetailRow.view";

export interface CookieDetailRowControllerProps {
  name: string;
  description: string;
}

const CookieDetailRowController: React.FC<CookieDetailRowControllerProps> = (
  props
) => {
  const { description, name } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <CookieDetailRowView
      open={open}
      openHandler={setOpen}
      description={description}
      name={name}
    />
  );
};

export default CookieDetailRowController;

import React from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import './Less/PDF.css';

export interface IPDFViewProps {}

const PdfView: React.FC<IPDFViewProps> = (props): React.ReactElement => {
	const history = useHistory();
	React.useEffect(() => {
		if (!Cookies.get('manual_ok')) history.push('/');
		window.scrollTo(0, 0);
		//eslint-disable-next-line
	}, []);
	const pdfName = window.location.pathname.split('/manual/')[1];
	return (
		<>
			<iframe
				src={`https://api-motor.suzuki.hu/manual/${pdfName}`}
				width='100%'
				height='1000'
				title='PDF Viewer'
			></iframe>
		</>
	);
};

export default PdfView;

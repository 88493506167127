import React from 'react';
import Img from 'react-image';
import HTMLParser from 'html-react-parser';
import { GetLocaleText } from '../../../I18n';
import { OfferModel } from '../Model/Offer.model';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import BlueToRedButton from '../../UI/BlueToRedButton/BlueToRedButton';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { Link } from 'react-router-dom';
let Fade = require('react-reveal/Fade');
let Zoom = require('react-reveal/Zoom');

interface IOfferLeftOrRightElementProps {
	offer: OfferModel;
	index: number;
	lang: string;
	direction: string;
	swap: boolean;
}
export const OfferImgElementView: React.FC<IOfferLeftOrRightElementProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = React.useContext(RouterContext);
	const { offer, direction, swap } = props;
	return (
		<div className={`offer__container--row row ${direction}`}>
			<div
				className={`offer__container--row__image col-md-6 col-xs-12 col-lg-6 ${
					swap ? ' col-sm-push-6' : ''
				}`}
			>
				<Fade {...direction}>
					<Link
						className='offer__container--row__desc--title'
						to={cleanLink(
							`/${getRouteLink('OFFERS', routerContext)}/${offer.slug}`
						)}
					>
						<Img
							src={offer.image}
							alt={generateImgAlt(offer.image)}
							className='img'
						/>
					</Link>
				</Fade>
			</div>
			<div
				className={`offer__container--row__desc col-md-6 col-xs-12 col-lg-6 ${
					swap ? 'col-sm-pull-6' : ''
				}`}
			>
				<div className='offer__container--row__desc--logo'>
					<Zoom bottom>
						<Img
							src={offer.logo}
							alt={generateImgAlt(offer.logo)}
							className='img'
						/>
					</Zoom>
				</div>
				<Fade bottom>
					<Link
						className='offer__container--row__desc--title'
						to={cleanLink(
							`/${getRouteLink('OFFERS', routerContext)}/${offer.slug}`
						)}
					>
						{offer.name}
					</Link>
					<div className='offer__container--row__desc--short'>
						{HTMLParser(offer.short_desc)}
					</div>
					<BlueToRedButton
						link={cleanLink(
							`/${getRouteLink('OFFERS', routerContext)}/${offer.slug}`
						)}
					>
						{GetLocaleText('read_more')}
					</BlueToRedButton>
				</Fade>
			</div>
		</div>
	);
};

import React, { useState, useContext, useEffect } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import AboutUsDAO from '../DAO/AboutUs.DAO';
import { GetLocaleText } from '../../../I18n';
import AboutUsView from '../View/AboutUs.view';
import { AboutUsModel } from '../Model/AboutUs.model';

export interface ISingleOfferProps
	extends RouteChildrenProps<{ offer_id: string }> {}

const AboutUsController: React.FC<ISingleOfferProps> = (
	props
): React.ReactElement => {
	const [error, set_error] = useState<IError>(null);
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [about_us, set_about_us] = useState<AboutUsModel>(new AboutUsModel());

	const routerContext: IRouterContext = useContext(RouterContext);

	const loadAboutUs = (): void => {
		if (props.match) {
			set_loading(
				AboutUsDAO.getAboutUsData()
					.then((about_us: AboutUsModel) => set_about_us(about_us))
					.catch((error: IError) => set_error(error))
					.finally(() => set_loading(null))
			);
		} else {
			props.history.goBack();
		}
	};

	useEffect(() => {
		return () => {
			set_loading(null);
			set_error(null);
		};
	}, []);

	useEffect(
		() => {
			loadAboutUs();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{GetLocaleText('about_us_menu')}</div>
			</div>
			<AboutUsView data={about_us} loading={loading} error={error} />
		</div>
	);
};

export default AboutUsController;

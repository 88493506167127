import React, { useState, useEffect, useContext } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { CatalogModel } from '../Model/Catalog.model';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouteChildrenProps } from 'react-router';
import CatalogView from '../View/Catalog.view';
import CatalogDAO from '../DAO/Catalog.DAO';
import { GetLocaleText } from '../../../I18n';
import { CatalogTypesModel } from '../Model/CatalogTypes.model';

export interface ICatalogProps extends RouteChildrenProps {}

const Catalog: React.FC<ICatalogProps> = (props): React.ReactElement => {
	const all_text_text = GetLocaleText('all_text');
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [catalogs, set_catalogs] = useState<CatalogModel[]>([]);
	const [filter_state, set_filter_state] = useState('0');
	const [page_state, set_page_state] = useState(10);
	const [active_selector, set_active_selector] = useState(0);
	const [load_more, set_load_more] = useState(true);
	const [catalog_types, set_catalog_types] = useState<CatalogTypesModel[]>([]);
	const [error, set_error] = useState<IError>(null);
	const routerContext: IRouterContext = useContext(RouterContext);

	const filter = (id: string): void => {
		set_filter_state(id);
	};
	const page = (): void => {
		set_page_state(page_state + 10);
	};

	const loadCatalog = (page: number): void => {
		set_loading(
			CatalogDAO.getCatalog({ qty: page })
				.then((catalogs: CatalogModel[]) => {
					set_catalogs(catalogs);
					if (page !== catalogs.length) {
						set_load_more(false);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};
	const loadCatalogByType = (type_id: string, page: number): void => {
		set_loading(
			CatalogDAO.getCatalogByType(type_id, { qty: page })
				.then((catalogs: CatalogModel[]) => {
					set_catalogs(catalogs);
					if (page !== catalogs.length) {
						set_load_more(false);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const loadCatalogTypes = (): void => {
		set_loading(
			CatalogDAO.getCatalogTypes()
				.then((catalog_types: CatalogTypesModel[]) => {
					const all_types: CatalogTypesModel = {
						type_id: '0',
						lang: routerContext.lang.value.name,
						name: all_text_text,
					};
					catalog_types.unshift(all_types);
					set_catalog_types(catalog_types);
					if (props.location.hash === '#accessories') {
						const highest_value = catalog_types.reduce((prev, current) =>
							prev.type_id > current.type_id ? prev : current
						);
						filter(highest_value.type_id);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	useEffect(
		() => {
			set_active_selector(0);
			set_load_more(true);
			loadCatalogTypes();
			set_page_state(10);
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);
	useEffect(
		() => {
			set_active_selector(+filter_state);
			set_load_more(true);
			set_page_state(10);
			if (+filter_state !== 0) {
				loadCatalogByType(filter_state, 10);
			} else if (page_state === 10 && +filter_state === 0) {
				loadCatalog(10);
			} else {
				set_page_state(10);
			}
		},
		//eslint-disable-next-line
		[filter_state]
	);

	useEffect(
		() => {
			if (page_state === 10) return;
			if (filter_state === '0' || filter_state === '') {
				loadCatalog(page_state);
			} else {
				loadCatalogByType(filter_state, page_state);
			}
		},
		//eslint-disable-next-line
		[page_state]
	);

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{GetLocaleText('catalog_menu')}</div>
			</div>
			<div className='catalog-wrapper'>
				<CatalogView
					loading={loading}
					catalogs={catalogs}
					filter={filter}
					catalog_types={catalog_types}
					page={page}
					error={error}
					load_more={load_more}
					active={active_selector}
					lang={routerContext.lang.value.name}
				/>
			</div>
		</div>
	);
};

export default Catalog;

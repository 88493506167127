import React from 'react';
import './Less/Bikes.css';
import OwlCarousel from 'react-owl-carousel2';
import '../../../../node_modules/react-owl-carousel2/lib/styles.css';
import { BikesModel } from '../Model/Bikes.model';
import Slash from '../../../Assets/data-slash.png';
import PerformanceIcon from '../../../Assets/teljesitmeny.png';
import EngineCapacity from '../../../Assets/hengerurtartalom.png';
import CurbWeight from '../../../Assets/tomeg.png';
import Tank from '../../../Assets/tank.png';
import SeatHeight from '../../../Assets/magassag.png';
import License from '../../../Assets/jogositvany.png';
import { BikeTypesModel } from '../Model/BikeTypes.model';
import { useRouteMatch } from 'react-router';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { Link } from 'react-router-dom';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';

export interface ICarouselViewProps {
	bikes: BikesModel[];
	custom_class: string;
	type: BikeTypesModel;
}

export interface IOwlActiveState {
	class: string;
	index: number;
}

const BikesCarouselView: React.FC<ICarouselViewProps> = (
	props
): React.ReactElement => {
	const custom_class = props.custom_class.replace(/\s+/g, '').split('/')[0];
	const options = {
		items: 1,
		nav: true,
		autoplay: false,
		URLhashListener: false,
		navText: [<span className='left' />, <span className='right' />],
		dotsData: true,
		dotData: true,
		loop: true,
		pagination: false,
	};
	const onlyOneSlideOptions = {
		items: 1,
		nav: true,
		autoplay: false,
		URLhashListener: false,
		navText: [<span className='left' />, <span className='right' />],
		dotsData: true,
		dotData: true,
		loop: false,
		pagination: false,
	};
	const match = useRouteMatch();
	let bikeCount = 0;
	props.bikes.map((bike) => {
		if (bike.type_id === props.type.type_id) {
			bikeCount++;
		}
		return '';
	});

	return (
		<OwlCarousel
			options={bikeCount > 1 ? options : onlyOneSlideOptions}
			className={`${custom_class} owl-carousel`}
			id={`${custom_class}`}
		>
			<React.Fragment>
				{props.bikes.map((bike, index) => {
					if (bike.type_id === props.type.type_id) {
						return (
							<Link
								to={`${cleanLink(`${match.path.split(':')[0]}/${bike.slug}`)}`}
								key={`${index}-bike-carousel-link-item`}
							>
								<div
									key={`bike__${bike.bike_id}`}
									className={`${custom_class} ${custom_class}-${index} motortype`}
									data-dot={`<button role='button' class='${custom_class}' owl-dot>${bike.name}</button>`}
								>
									<div className='tipus'>{props.custom_class}</div>
									<div className='motor-datas'>
										<div className='performance motor-data'>
											<div className='data'>
												<img src={PerformanceIcon} alt='' />
												{bike.performance}{' '}
												<div>{GetLocaleText('bike_general_performance')}</div>
											</div>
											<div className='desc'>
												{GetLocaleText('bike_performance')}
											</div>
										</div>
										<img src={Slash} className='slash' alt='' />
										<div className='engine motor-data'>
											<div className='data'>
												<img src={EngineCapacity} alt='' />
												{bike.engine_capacity}{' '}
												<div>
													CM<div className='sup'>3</div>
												</div>
											</div>
											<div className='desc'>{GetLocaleText('bike_engine')}</div>
										</div>
										<img src={Slash} className='slash' alt='' />
										<div className='weight motor-data'>
											<div className='data'>
												<img src={CurbWeight} alt='' />
												{bike.curb_weight} <div>KG</div>
											</div>
											<div className='desc'>{GetLocaleText('bike_mass')}</div>
										</div>
										<img src={Slash} className='slash' alt='' />
										<div className='fuel motor-data'>
											<div className='data'>
												<img src={Tank} alt='' />
												{bike.fuel_capacity} <div>L</div>
											</div>
											<div className='desc'>
												{GetLocaleText('bike_fuel_capacity')}
											</div>
										</div>
										<img src={Slash} className='slash' alt='' />
										<div className='seat_height motor-data'>
											<div className='data'>
												<img src={SeatHeight} alt='' />
												{bike.seat_height} <div>MM</div>
											</div>
											<div className='desc'>
												{GetLocaleText('bike_seat_height')}
											</div>
										</div>
										{bike.license[0] === 'none' ||
										bike.license[0] === undefined ? null : (
											<>
												<img src={Slash} className='slash' alt='' />
												<div className='lincense motor-data'>
													<div className='data'>
														<img src={License} alt='' />
														{bike.license[0]}
													</div>
													<div className='desc'>
														{GetLocaleText('bike_license')}
													</div>
												</div>
											</>
										)}
									</div>
									<div className='bg-image'>
										<img
											src={bike.background}
											alt={generateImgAlt(bike.background)}
											className='bg'
										/>
										<div className='filter' />
									</div>
									<div className='motor-image'>
										<img src={bike.image} alt={generateImgAlt(bike.image)} />
									</div>
								</div>
							</Link>
						);
					}
					return null;
				})}
			</React.Fragment>
		</OwlCarousel>
	);
};

export default BikesCarouselView;

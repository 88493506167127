import React, { useState, useContext, useEffect } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { FinancingPricesModel } from '../Model/FinancingPrices.model';
import { FinancingBikePriceModel } from '../Model/FinancingBikePrice.model';
import { RouteChildrenProps } from 'react-router';
import FinancingView from '../View/Financing.view';
import FinancingDAO from '../DAO/Financing.DAO';
import { GetLocaleText } from '../../../I18n';
import { ResellersModel } from '../../Resellers/Model/Resellers.model';

export interface IFinancingProps extends RouteChildrenProps<{ slug: string }> {}

const Financing: React.FC<IFinancingProps> = (props): React.ReactElement => {
	const financing_bc_text = GetLocaleText('financing_bc');
	const [error, set_error] = useState<IError>(null);
	const [loading, set_loading] = useState<Promise<any> | null>(null);
	const [prices, set_prices] = useState<FinancingPricesModel[]>([]);
	const [form_sent, set_form_sent] = useState(false);
	const [resellers, set_resellers] = useState<ResellersModel[]>([]);
	const [bike_price, set_bike_price] = useState<FinancingBikePriceModel>(
		new FinancingBikePriceModel()
	);
	const [active_bike, set_active_bike] = useState<FinancingPricesModel>(
		new FinancingPricesModel()
	);

	const routerContext: IRouterContext = useContext(RouterContext);

	const loadPrices = (): void => {
		set_loading(
			FinancingDAO.getCalculatorPrices()
				.then((prices: FinancingPricesModel[]) => {
					filterPrices(prices);
					if (props.match?.params.slug) {
						setActiveBike(props.match?.params.slug, prices);
					}
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const filterPrices = (prices: FinancingPricesModel[]): void => {
		let new_prices: FinancingPricesModel[] = [];
		prices.map((price) => {
			if (price.calculator_price !== '0') {
				return new_prices.push(price);
			}
			return '';
		});
		set_prices(new_prices);
	};

	const loadBikePrice = (
		slug: string,
		own_part: string,
		duration: string,
		casco: string
	): void => {
		set_loading(
			FinancingDAO.getCalculatorBikePrice(slug, {
				price_own_part: +own_part * 100,
				duration: +duration,
				casco: casco === 'true' ? 1 : 0,
			})
				.then((bike_price: FinancingBikePriceModel) => {
					set_bike_price(bike_price);
					loadResellers(bike_price);
				})
				.catch((error: IError) => set_error(error))
				.finally(() => {
					set_loading(null);
				})
		);
	};

	const loadResellers = (bike_price: FinancingBikePriceModel): void => {
		set_resellers(
			bike_price.resellers.filter(
				(reseller: ResellersModel) => reseller.lang_id === '1'
			)
		);
	};

	const setActiveBike = (
		slug: string,
		prices: FinancingPricesModel[]
	): void => {
		prices.map((bike) => {
			if (bike.slug === slug) set_active_bike(bike);
			return '';
		});
	};

	useEffect(
		() => {
			loadPrices();
		},
		//eslint-disable-next-line
		[routerContext.lang]
	);

	const calculate = (
		slug: string,
		own_part: string,
		duration: string,
		casco: string
	): void => {
		loadBikePrice(slug, own_part, duration, casco);
	};

	const handleBikeChange = (slug: string): void => {
		prices.map((bike) => {
			if (bike.slug === slug) set_active_bike(bike);
			return '';
		});
	};

	const finish = (
		slug: string,
		price_own_part: number,
		duration: number,
		casco: number,
		name: string,
		city: string,
		email: string,
		tel: string,
		reseller_id: number | null
	) => {
		const data: FormData = new FormData();
		data.append('price_own_part', price_own_part + '');
		data.append('duration', duration + '');
		data.append('casco', casco + '');
		data.append('name', name);
		data.append('city', city);
		data.append('email', email);
		data.append('tel', tel);
		data.append(
			'reseller_id',
			reseller_id !== null ? `${reseller_id}` : 'null'
		);
		set_loading(
			FinancingDAO.create(slug, data, bike_price.calculator_id)
				.catch((error: IError) => {
					set_error(error);
				})
				.finally(() => {
					set_loading(null);
					set_form_sent(true);
				})
		);
	};

	return (
		<div className='component-wrapper'>
			<div className='menu-title'>
				<div className='menu-title__text'>{financing_bc_text}</div>
			</div>
			<FinancingView
				prices={prices}
				bike_price={bike_price}
				calculate={calculate}
				active_bike={active_bike}
				loading={loading}
				error={error}
				bike_change={handleBikeChange}
				resellers={resellers}
				finish={finish}
				form_sent={form_sent}
			/>
		</div>
	);
};

export default Financing;

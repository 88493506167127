import React from 'react';
import { IInputBoolean } from '../../../Interfaces/IInput.interface';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import './Checkbox.css';

type ILocalProps = IInputBoolean & {
	onChange: (e: IInputChange) => void;
	title?: string;
	labelStyle?: string;
};

export const Checkbox = (props: ILocalProps) => {
	let wrapperStyle: string[] = ['il-checkbox-wrapper wrapper-sm'];
	if (props.className) wrapperStyle.push(props.className);
	if (props.hidden) wrapperStyle.push('wrapper-hidden');

	return (
		<div className={wrapperStyle.join(' ')}>
			<label
				className={`checkbox-container ${props.labelStyle}`}
				htmlFor={props.id}
			>
				{props.labelTextBefore}
				<a href={props.href} target='_blank' rel='noopener noreferrer'>
					{props.hrefText}
				</a>
				{props.labelTextAfter}
				{props.children}
				<input
					type={'checkbox'}
					checked={props.value}
					required={props.required}
					onChange={(e) =>
						props.onChange({
							fieldName: props.id,
							value: e.target.checked,
						})
					}
					className='checkbox-input'
					name={props.name}
					id={props.id}
				/>
				<span className='check-mark' title={props.title} />
			</label>
		</div>
	);
};

import React from 'react';
import { ICatalogFilterProps } from './Catalog.view';
import { CatalogTypesModel } from '../Model/CatalogTypes.model';

export interface ICatalogTypesViewProps extends ICatalogFilterProps {
	types: CatalogTypesModel[];
	active: number;
}

const CatalogTypesView: React.FC<ICatalogTypesViewProps> = (
	props
): React.ReactElement => {
	const { types } = props;

	return (
		<div className='row'>
			<div className='video-view__selector'>
				{types.map((type, index) => {
					return (
						<div
							className={`col-lg-3 video-view__selector--menu-item-container ${
								props.active === +type.type_id ? 'active' : ''
							}`}
							onClick={() => {
								props.filter(type.type_id);
							}}
							key={index}
						>
							<div className='video-view__selector--menu-item'>{type.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default CatalogTypesView;

import * as React from "react";
import StartView from "../View/CookieStart.view";
import { ViewType } from "../../CookieMain/Interfaces/IViewType.interface";

export interface StartControllerProps {
  setViewType: (viewType: ViewType) => void;
  enableAllCookieHandler: () => void;
  enableNecessaryCookieHandler: () => void;
}

const StartController: React.FC<StartControllerProps> = (props) => {
  return <StartView {...props} />;
};

export default StartController;

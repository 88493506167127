import React, { useState, useContext, useEffect } from 'react';
import { IError } from '../../../Interfaces/IError.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { CustomPageModel } from '../Model/CustomPage.model';
import { RouteChildrenProps } from 'react-router';
import CustomPageView from '../View/CustomPage.view';
import CustomPageDAO from '../DAO/CustomPage.dao';

export interface ICustomPageProps
  extends RouteChildrenProps<{ slug: string }> {}

const CustomPage: React.FC<ICustomPageProps> = (props): React.ReactElement => {
  const [error, set_error] = useState<IError>(null);
  const [loading, set_loading] = useState<Promise<any> | null>(null);
  const [custom_page, set_custom_page] = useState<CustomPageModel>(
    new CustomPageModel()
  );

  const routerContext: IRouterContext = useContext(RouterContext);

  const loadCustomPage = (): void => {
    if (props.match) {
      set_loading(
        CustomPageDAO.getPage(props.match.path)
          .then((page: CustomPageModel) => {
            set_custom_page(page);
          })
          .catch((error: IError) => set_error(error))
          .finally(() => set_loading(null))
      );
    }
  };

  useEffect(
    () => {
      if (custom_page) {
        loadCustomPage();
      }
    },
    //eslint-disable-next-line
    [routerContext.lang]
  );

  return (
    <div className='component-wrapper'>
      <div className='menu-title'>
        <div className='menu-title__text'>{custom_page.name}</div>
      </div>
      <CustomPageView
        custom_page={custom_page}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default CustomPage;

import * as React from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import './Less/SplashScreen.css';

export interface ISplashScreenProps {
  opacity?: string;
  isAbsolute?: boolean;
}

const SplashScreen: React.FC<ISplashScreenProps> = (
  props
): React.ReactElement => {
  const opacity: string = props.opacity || '0.4';

  return (
    <div
      className='splash-screen'
      style={{
        backgroundColor: `rgba(255,255,255,${opacity})`,
        position: props.isAbsolute ? 'absolute' : 'fixed'
      }}
    >
      <Spinner isLarge />
    </div>
  );
};

export default SplashScreen;

import { IRouterContext } from '../Components/Router/Interfaces/IRouterContext.interface';

export const getRouteLink = (
  label: string,
  routerContext: IRouterContext
): string => {
  for (const route of routerContext.routes) {
    if (route.label === label) {
      return route.link;
    }
  }
  return '';
};

import React from "react";
import { IInputChange } from "../../../Interfaces/IInputChange.interface";
import Select from "../../UI/Select/Select";
import { TextArea } from "../../UI/TextArea/TextArea";
import TextInput from "../../UI/TextInput/TextInput";
import { IExportFinancingForm } from "../Interfaces/IExportFinancing.interface";
import SideImg from "../../../Assets/export-financing.png";
import "./Less/ExportFinancing.css";
import { Checkbox } from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import { ControlSplash } from "../../../Utils/ControlSplashScreen.util";
import Spinner from "../../UI/Spinner/Spinner";
import HTMLParser from "html-react-parser";
import { GetLocaleText } from "../../../I18n";
import ReCAPTCHA from "react-google-recaptcha";

export interface IExportFinancingViewProps {
  onSubmit: (e: React.FormEvent) => void;
  onChange: (args: IInputChange) => void;
  form: IExportFinancingForm;
  loading: Promise<any> | null;
  comp_loading: Promise<any> | null;
  error: string | null;
  form_sent: boolean;
  set_form_sent: (form_sent: boolean) => void;
}

const ExportFinancingView: React.FC<IExportFinancingViewProps> = (
  props
): React.ReactElement => {
  React.useEffect(() => {
    props.loading !== null ? ControlSplash(true) : ControlSplash(false);
    window.scrollTo(0, 0);
    return () => {};
    //eslint-disable-next-line
  }, [props.loading]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const noOp = GetLocaleText("ex_fin__form_select_noop");
  const placeholder = GetLocaleText("ex_fin__form_select_placeholder");

  const bikeCategory = GetLocaleText("ex_fin__form_bc");
  const bikeModel = GetLocaleText("ex_fin__form_bm");
  const modelPrice = GetLocaleText("ex_fin__form_mp");
  const ownPart = GetLocaleText("ex_fin__form_op");
  const term = GetLocaleText("ex_fin__content_term");

  const firstName = GetLocaleText("ex_fin__form_fn");
  const lastName = GetLocaleText("ex_fin__form_ln");
  const legalForm = GetLocaleText("ex_fin__form_lf");
  const email = GetLocaleText("ex_fin__form_email");
  const tel = GetLocaleText("ex_fin__form_pn");
  const city = GetLocaleText("ex_fin__form_city");
  const postalCode = GetLocaleText("ex_fin__form_pc");
  const addr = GetLocaleText("ex_fin__form_addr");

  const reseller = GetLocaleText("ex_fin__form_reseller");

  const accBefore = GetLocaleText("ex_fin__form_acc_before");
  const accHref = GetLocaleText("ex_fin__form_acc_href");
  const accHrefText = GetLocaleText("ex_fin__form_acc_href_text");
  const accAfter = GetLocaleText("ex_fin__form_acc_after");

  return (
    <div className="export-financing-view container">
      <div className="recaptcha">
        <ReCAPTCHA
          size="invisible"
          sitekey="6LdObeYUAAAAAIrBvZXWm25JKNB9af7sEGMPXnIe"
        />
      </div>
      <div className="row export-financing-view__top container">
        <div className="col-lg-6">
          {props.form_sent ? (
            <div className="d-flex align-items-center text-center flex-column">
              <h2 className="title">{GetLocaleText("ex_fin__success_lead")}</h2>
              <p className="content">{GetLocaleText("ex_fin__success_text")}</p>
              <p className="content">
                {GetLocaleText("ex_fin__success_text_2")}
              </p>
              <p className="content">
                <Button
                  isDark
                  isSmall
                  style={{ fontSize: "20px !important" }}
                  onClick={() => props.set_form_sent(false)}
                >
                  {GetLocaleText("ex_fin__success_button")}
                </Button>
              </p>
            </div>
          ) : (
            <>
              <h2 className="title">
                {GetLocaleText("ex_fin__content_title")}
              </h2>
              <p className="content">{GetLocaleText("ex_fin__content_long")}</p>
            </>
          )}
        </div>
        <div className="col-lg-6">
          <img src={SideImg} alt="" />
        </div>
      </div>
      {!props.form_sent && (
        <form
          onSubmit={props.onSubmit}
          className="export-financing-view__form-wrapper"
        >
          <div className="headline">
            <h3>{GetLocaleText("ex_fin__form_block_1")}</h3>
          </div>
          <div className="row">
            {props.comp_loading && <Spinner isAbsolute />}
            <Select
              {...props.form.bike_category}
              noOptionsMessage={noOp}
              placeholder={placeholder}
              labelText={bikeCategory}
              onChange={props.onChange}
              wrapperClass="col-lg-4"
            />
            <Select
              {...props.form.bike_model}
              noOptionsMessage={noOp}
              placeholder={placeholder}
              labelText={bikeModel}
              onChange={props.onChange}
              wrapperClass="col-lg-4"
            />
            <TextInput
              {...props.form.model_price}
              labelText={modelPrice}
              onChange={props.onChange}
              className="col-lg-4"
            />
            <Select
              {...props.form.self_suff}
              noOptionsMessage={noOp}
              placeholder={placeholder}
              labelText={ownPart}
              onChange={props.onChange}
              wrapperClass="col-lg-4"
            />
            <Select
              {...props.form.term}
              noOptionsMessage={noOp}
              placeholder={placeholder}
              labelText={term}
              onChange={props.onChange}
              wrapperClass="col-lg-4"
            />
          </div>
          <div style={{ padding: "2rem" }}></div>
          <div className="headline">
            <h3>{GetLocaleText("ex_fin__form_block_2")}</h3>
          </div>
          <div className="row">
            <TextInput
              {...props.form.first_name}
              onChange={props.onChange}
              labelText={firstName}
              className="col-lg-4"
            />
            <TextInput
              {...props.form.last_name}
              labelText={lastName}
              onChange={props.onChange}
              className="col-lg-4"
            />
            <Select
              {...props.form.legal_form}
              noOptionsMessage={noOp}
              placeholder={placeholder}
              labelText={legalForm}
              onChange={props.onChange}
              wrapperClass="col-lg-4"
            />
            <TextInput
              {...props.form.email}
              labelText={email}
              onChange={props.onChange}
              className="col-lg-4"
            />
            <TextInput
              {...props.form.tel}
              labelText={tel}
              onChange={props.onChange}
              className="col-lg-4"
            />
            <TextInput
              {...props.form.city}
              labelText={city}
              onChange={props.onChange}
              className="col-lg-4"
            />
            <TextInput
              {...props.form.postal_code}
              labelText={postalCode}
              onChange={props.onChange}
              className="col-lg-4"
            />
            <TextInput
              {...props.form.address}
              labelText={addr}
              onChange={props.onChange}
              className="col-lg-8"
            />
          </div>
          <div style={{ padding: "2rem" }}></div>
          <div className="headline">
            <h3>{GetLocaleText("ex_fin__form_block_3")}</h3>
          </div>
          <div className="row d-lg-flex align-items-center">
            <div className="col-lg-6">
              <p>{GetLocaleText("ex_fin__form_reseller_text")}</p>
            </div>
            <Select
              {...props.form.reseller}
              noOptionsMessage={noOp}
              placeholder={placeholder}
              labelText={reseller}
              onChange={props.onChange}
              wrapperClass="col-lg-6"
            />
          </div>
          <div style={{ padding: "2rem" }}></div>
          <div className="headline">
            <h3>{GetLocaleText("ex_fin__form_block_4")}</h3>
          </div>
          <div className="row">
            <TextArea
              {...props.form.message}
              onChange={props.onChange}
              className="col-lg-12"
            />
          </div>
          {props.error && (
            <>
              <div style={{ padding: "2rem" }}></div>
              <div className="error-message">{HTMLParser(props.error)}</div>
            </>
          )}
          <div style={{ padding: "2rem" }}></div>
          <div className="row d-lg-flex align-items-center">
            <div className="col-lg-9">
              <Checkbox
                {...props.form.acceptance}
                labelTextBefore={accBefore}
                labelTextAfter={accAfter}
                href={accHref}
                hrefText={accHrefText}
                onChange={props.onChange}
              />
            </div>
            <div className="col-lg-3 justify-content-lg-end d-flex mt-lg-0 mt-4 justify-content-center">
              <Button
                isDark
                isSmall
                style={{ fontSize: "20px !important" }}
                type="submit"
              >
                {GetLocaleText("ex_fin__form_button")}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ExportFinancingView;

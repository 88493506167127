import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { INewsTypes } from '../Interfaces/INewsTypes.interface';

export class NewsTypesModel {
  constructor(NewsType?: INewsTypes) {
    if (NewsType) {
      ModelConstructor(NewsType, this);
    }
  }

  news_type_id: string = '';
  lang: string = '';
  name: string = '';
}

import { map } from 'lodash';
import { CatalogModel } from '../Components/Catalog/Model/Catalog.model';
import { CatalogTypesModel } from '../Components/Catalog/Model/CatalogTypes.model';

export const changeCatalogIdToName = (
  catalog: CatalogModel[],
  catalog_type_array: CatalogTypesModel[]
): CatalogModel[] => {
  if (catalog.length !== 0) {
    map(catalog, catalog_item => {
      map(catalog_type_array, (catalog_type, index) => {
        if (catalog_item.type_id === catalog_type.type_id) {
          catalog_item.catalog_type_name = String(
            catalog_type_array[index].name
          );
        }
      });
    });
  }
  return catalog;
};

import { axios } from '../../../Api/axios';
import { CustomPageModel } from '../Model/CustomPage.model';
import { ICustomPage } from '../Interfaces/ICustomPage.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class CustomPageDAO {
  private static readonly base: string = '/api/pages';

  public static async getPage(slug: string): Promise<CustomPageModel> {
    const request: AxiosPromise<ICustomPage> = axios
      .getInstance()
      .get(`${this.base}/${slug}`);
    const response: AxiosResponse<ICustomPage> = await request;
    return new CustomPageModel(response.data);
  }
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IMainNewsTypes } from '../Interfaces/IMainPage.interface';

export class MainNewsTypesModel {
  constructor(NewsType?: IMainNewsTypes) {
    if (NewsType) {
      ModelConstructor(NewsType, this);
    }
  }

  news_type_id: string = '';
  lang: string = '';
  name: string = '';
}

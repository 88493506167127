import * as React from 'react';
import MainNavbar from '../../Navbar/View/MainNavbar.view';
import './Less/Layout.css';
import MenuBG from '../../../Assets/menubg.jpg';
import Img from 'react-image';
import Footer from '../../Footer/View/Footer';
import SplashScreen from '../../SplashScreen/View/SplashScreen.view';

export interface ILayoutProps {}

const Layout: React.FC<ILayoutProps> = (props): React.ReactElement => {
	return (
		<div className='layout'>
			<SplashScreen opacity='1'></SplashScreen>
			<div className='navImage'>
				<Img src={MenuBG} alt='Menu Image' style={{ width: '100%' }} />
			</div>
			<MainNavbar />
			{props.children}
			<Footer />
		</div>
	);
};

export default Layout;

import React, { useContext } from 'react';
import { RouterContext } from '../../Router/Context/Router.context';
import { useHistory } from 'react-router';
import Button from '../../UI/Button/Button';
import Img from 'react-image';
import BG from '../../../Assets/BikePage/Kereskedesek-bg.jpg';
import './Less/ResellerPromotion.css';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { GetLocaleText } from '../../../I18n';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface IResellerPromotionProps {}

const ResellerPromotion: React.FC<IResellerPromotionProps> = (
	props
): React.ReactElement => {
	const history = useHistory();
	const router: IRouterContext = useContext(RouterContext);

	return (
		<div className='reseller-promo'>
			<div className='reseller-promo__background'>
				<Img src={BG} alt='' className='reseller-promo__background--image' />
			</div>
			<div className='content-container'>
				<div className='reseller-promo__border' />
				<h1 className='reseller-promo__title'>
					{GetLocaleText('bike_page_resellers_lead')}
				</h1>
				<h4 className='reseller-promo__sub-title'>
					{GetLocaleText('bike_page_resellers_desc')}
				</h4>
				<div className='reseller-promo__button-container'>
					<span className='reseller-promo__border' />
					<Button
						onClick={() =>
							history.push(cleanLink(`/${getRouteLink('RESELLERS', router)}`))
						}
						altColor
					>
						{GetLocaleText('bike_page_resellers_cta_text')}
					</Button>
					<span className='reseller-promo__border' />
				</div>
			</div>
		</div>
	);
};

export default ResellerPromotion;

import React, { useState } from 'react';
import BikeModel from '../Model/Bike.model';
import './Less/TechnologyBlockLarge.style.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { GetLocaleText } from '../../../I18n';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';

export interface ITechnologyBlockLargeViewProps {
	model: BikeModel;
}

const TechnologyBlockLargeView: React.FC<ITechnologyBlockLargeViewProps> = (
	props
): React.ReactElement => {
	const [index, setIndex] = useState(0);

	if (props.model.technologies.length === 0) return <div />;

	return (
		<div className='technology-block-component' id='BIKE_TECH'>
			<div className='technology-block-component__content-container'>
				<div className='technology-block-component__background-text-container'>
					<span className='technology-block-component__background-text'>
						{GetLocaleText('bike_page_tech_section_bg_text')}
					</span>
					<TransitionGroup className={'technology-content-wrapper'}>
						<CSSTransition
							key={props.model.technologies[index].name}
							timeout={300}
							classNames={'fade'}
						>
							<div className='technology-content-slider-item'>
								<div className='technology-title'>
									{props.model.technologies[index].name}
								</div>
								<div className='technology-desc'>
									{props.model.technologies[index].description}
								</div>
							</div>
						</CSSTransition>
					</TransitionGroup>
				</div>
			</div>
			<div className='technology-block-component__right-side'>
				<div className='tech-container'>
					{props.model.technologies.map((tech, techIndex) => (
						<div
							className={[
								'tech-icon-container',
								techIndex === index ? 'tech-icon-container--active' : null,
							].join(' ')}
							key={`${techIndex}-tech-lg-block`}
							onClick={() => setIndex(techIndex)}
						>
							<img
								src={tech.icon}
								alt={generateImgAlt(tech.icon)}
								className='tech-icon-container__tech-icon'
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TechnologyBlockLargeView;

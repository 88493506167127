import React from 'react';
import { IInputText } from '../../../Interfaces/IInput.interface';
import { IInputChange } from '../../../Interfaces/IInputChange.interface';
import './TextInput.css';

type ITextInputProps = Omit<IInputText, 'type'> & {
	onChange: (e: IInputChange) => void;
	type?: string;
	style?: React.CSSProperties;
};

const TextInput = (props: ITextInputProps): React.ReactElement => {
	const wrapperStyle: string[] = ['il-text-input-wrapper'];

	if (props.hidden) wrapperStyle.push('hidden');
	if (props.className) wrapperStyle.push(props.className);

	const inputStyle: string[] = ['il-text-input'];

	return (
		<div className={wrapperStyle.join(' ')} style={props.style}>
			<label htmlFor={props.id} className='input-label'>
				{props.labelText}
			</label>
			<input
				className={inputStyle.join(' ')}
				type={props.type}
				id={props.id}
				value={props.value}
				required={props.required}
				disabled={props.disabled}
				pattern={props.pattern}
				onChange={(e) => {
					props.onChange({
						fieldName: props.id,
						value: e.target.value,
					});
				}}
			/>
		</div>
	);
};

export default TextInput;

export const en = {
	categories: [
		{
			id: '1',
			name: 'Necessary',
			description:
				'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
		},
		{
			id: '2',
			name: 'Statistics',
			description:
				'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
		},
		{
			id: '3',
			name: 'Marketing',
			description:
				'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
		},
	],
	insert_codes: [
		{
			category: '2',
			tag: 'script',
			value:
				'(function (i, s, o, g, r, a, m) {i["GoogleAnalyticsObject"] = r; (i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }), (i[r].l = 1 * new Date());(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "UA-656346-10", "auto");ga("send", "pageview");',
		},
		{
			category: '3',
			tag: 'script',
			value:
				'!(function (f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function () {n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "582159962479535");fbq("track", "PageView");',
		},
		{
			category: '3',
			tag: 'noscript',
			value:
				'<img height="1" width="1" src="https://www.facebook.com/tr?id=582159962479535&ev=PageView&noscript=1"/>',
		},
	],
	cookies: [
		{
			Name: 'iTechCookie',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				"Stores the user's cookie consent state for the current domain",
		},
		{
			Name: 'lang',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				"Remembers the user's selected language version of a website",
		},
		{
			Name: 'secondLevelRoute',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription:
				'When changing the language, store the address of the current link.',
		},
		{
			Name: '_ga',
			Provider: 'suzuki.hu',
			ExpireDescription: '2 years',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
		},
		{
			Name: '_gat',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription: 'Used by Google Analytics to throttle request rate',
		},
		{
			Name: '_gid',
			Provider: 'suzuki.hu',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '2',
			PurposeDescription:
				'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
		},
		{
			Name: 'IDE',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 year',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				"Used by Google DoubleClick to register and report the website user's actions after viewing or clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.",
		},
		{
			Name: 'test_cookie',
			Provider: 'doubleclick.net',
			ExpireDescription: '1 day',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				"Used to check if the user's browser supports cookies.",
		},
		{
			Name: 'fr',
			Provider: 'facebook.com',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
		},
		{
			Name: 'tr',
			Provider: 'facebook.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'Pixel',
			Category: '3',
			PurposeDescription:
				'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
		},
		{
			Name: 'NID',
			Provider: 'google.com',
			ExpireDescription: '6 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				"Registers a unique ID that identifies a returning user's device. The ID is used for targeted ads.",
		},
		{
			Name: '_fbp',
			Provider: 'suzuki.hu',
			ExpireDescription: '3 months',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
		},
		{
			Name: 'VISITOR_INFO1_LIVE',
			Provider: 'youtube.com',
			ExpireDescription: '179 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				"Tries to estimate the user's bandwidth on pages with integrated YouTube videos.",
		},
		{
			Name: 'YSC',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTTP',
			Category: '3',
			PurposeDescription:
				'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.',
		},
		{
			Name: 'yt-remote-cast-installed',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				"Stores the user's video player preferences using embedded YouTube video",
		},
		{
			Name: 'yt-remote-connected-devices',
			Provider: 'youtube.com',
			ExpireDescription: 'Persistent',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				"Stores the user's video player preferences using embedded YouTube video",
		},
		{
			Name: 'yt-remote-device-id',
			Provider: 'youtube.com',
			ExpireDescription: 'Persistent',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				"Stores the user's video player preferences using embedded YouTube video",
		},
		{
			Name: 'yt-remote-fast-check-period',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				"Stores the user's video player preferences using embedded YouTube video",
		},
		{
			Name: 'yt-remote-session-app',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				"Stores the user's video player preferences using embedded YouTube video",
		},
		{
			Name: 'yt-remote-session-name',
			Provider: 'youtube.com',
			ExpireDescription: 'Session',
			TrackerTypeAbbr: 'HTML',
			Category: '3',
			PurposeDescription:
				"Stores the user's video player preferences using embedded YouTube video",
		},
		{
			Name: 'x-bni-fpc',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
		{
			Name: 'x-bni-rncf',
			Provider: 'motor.suzuki.hu',
			ExpireDescription: '5895 days',
			TrackerTypeAbbr: 'HTTP',
			Category: '1',
			PurposeDescription: '',
		},
	],
};

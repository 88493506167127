import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { ITestDriveBikes } from '../Interfaces/ITestDrive.interface';

export class TestDriveBikesModel {
  constructor(Bikes: ITestDriveBikes) {
    if (Bikes) {
      ModelConstructor(Bikes, this);
    }
  }

  bike_id: string = '';
  type_id: string = '';
  name: string = '';
  slug: string = '';
  year: string = '';
  logo: string = '';
  image: string = '';
  background: string = '';
  default_color_id: string = '';
  engine_capacity: string = '';
  fuel_consumption: string = '';
  performance: string = '';
  torque: string = '';
  curb_weight: string = '';
  seat_height: string = '';
  license: string[] = [];
  type: string = '';
  is_draft: string = '';
  is_new: string = '';
  is_test_drive_available: string = '';
}

import { ModelConstructor } from '../../../Utils/DataSetter.util';
import { IFooterLogos } from './../Interfaces/IFooter.interface';

export class FooterLogosModel {
  constructor(FooterLogos?: IFooterLogos) {
    if (FooterLogos) {
      ModelConstructor(FooterLogos, this);
    }
  }

  logo_id: string = '';
  lang_id: string = '';
  url: string = '';
  logo: string = '';
  order: string = '';
}

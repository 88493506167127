import React from 'react';
import Img from 'react-image';
import { NewsModel } from '../Model/News.model';
import { formatDateString } from '../../../Utils/FormatDateString.util';
import HTMLParser from 'html-react-parser';
import '../../../Css/Positions.css';
import { RouterContext } from '../../Router/Context/Router.context';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { generateImgAlt } from '../../../Utils/GenerateImageAlt.util';
import { Link } from 'react-router-dom';
import { cleanLink } from '../../../Utils/LinkRouteCleaner.util';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface INewsElementViewProps {
  news: NewsModel;
}

const NewsElementView: React.FC<INewsElementViewProps> = (
  props
): React.ReactElement => {
  const routerContext: IRouterContext = React.useContext(RouterContext);
  const { news } = props;
  const [hover, set_hover] = React.useState(false);
  const formattedDate: string = formatDateString(
    news.published_at,
    '.',
    routerContext.lang.value.name,
    true
  );
  return (
    <div
      className={`newsimage ${news.image_ratio}`}
      style={{ cursor: 'pointer' }}
    >
    <Link to={cleanLink(`/${getRouteLink('NEWS', routerContext)}/${news.slug}`)}>
      <div className={`image-container ${hover ? 'hovering' : ''}`}>
        <Img
          src={news.image}
          alt={generateImgAlt(news.image)}
          className='img'
        />
      </div>
      <div
        className={`news__overimage position-${news.text_position_x}-${news.text_position_y} ${news.image_ratio}`}
        onMouseEnter={() => set_hover(true)}
        onMouseLeave={() => set_hover(false)}
      >
        <div className='typeAndDate'>
          <div className='type'>
            {news.news_type_name}
            <span></span>
          </div>
          <div className='date'>
            <span></span>
            {formattedDate}
          </div>
        </div>
        <div className='title'>{news.name}</div>
        <div className='desc'>{HTMLParser(news.short_desc)}</div>
      </div>
      </Link>
    </div>
  );
};

export default NewsElementView;

import * as React from 'react';

const GetInputLabel = (props: any): React.ReactNode => {
	if (props.labelText)
		return (
			<label htmlFor={props.id} className='input-label'>
				{props.labelText}
			</label>
		);
	return null;
};

export default GetInputLabel;

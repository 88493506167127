import React from 'react';
import HTMLParser from 'html-react-parser';
import { GetLocaleText } from '../../../I18n';
import { IRouterContext } from '../../Router/Interfaces/IRouterContext.interface';
import { RouterContext } from '../../Router/Context/Router.context';
import BlueToRedButton from '../../UI/BlueToRedButton/BlueToRedButton';
import { getRouteLink } from '../../../Utils/GetRouteLink.util';

export interface IFormSentViewProps {}
const FormSentView: React.FC<IFormSentViewProps> = (
	props
): React.ReactElement => {
	const routerContext: IRouterContext = React.useContext(RouterContext);
	window.scrollTo(0, 0);
	return (
		<div className='container test-drive-form-sent-view'>
			{HTMLParser(GetLocaleText('testdrive_finish_text'))}
			<div className='test-drive-form-sent-view__buttons'>
				<div className='button'>
					<BlueToRedButton onClick={() => window.location.reload(false)}>
						{GetLocaleText('testdrive_finish_button_1')}
					</BlueToRedButton>
				</div>
				<div className='button'>
					<BlueToRedButton inverse link={getRouteLink('BIKES', routerContext)}>
						{GetLocaleText('testdrive_finish_button_2')}
					</BlueToRedButton>
				</div>
			</div>
		</div>
	);
};
export default FormSentView;

import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { ILinkRoute } from '../../../Interfaces/IRouteAsComponent.interface';
import { RouterContext } from '../Context/Router.context';
import { RouteAsComponent } from '../../../Static/RouteAsComponent.static';
import E404PageNotFound from '../../WarnPages/View/E404';
import Layout from '../../Layout/View/Layout.view';
import MainPageController from '../../MainPage/Controller/MainPage.controller';
import PDFController from '../../PDF/Controller/PDF.controller';

const Router: React.FC = () => {
	const routes: ILinkRoute[] = React.useContext(RouterContext).routes;
	const getRoutes = (): any => {
		const elements: (JSX.Element | null)[] = [];

		routes.forEach((route: ILinkRoute, index: number) => {
			if (route.label in RouteAsComponent) {
				const Component = RouteAsComponent[route.label].component;
				elements.push(
					<Route
						key={`${index}-route-element`}
						component={(props: any) => (
							<Component label={route.label} {...props} />
						)}
						path={`/${route.link}`}
						exact
					/>
				);

				if (
					RouteAsComponent[route.label].sub_url &&
					RouteAsComponent[route.label].sub_cmp
				) {
					const Component = RouteAsComponent[route.label].sub_cmp;
					elements.push(
						<Route
							key={`${index}-route-element-sub`}
							component={(props: any) => (
								<Component label={route.label} {...props} />
							)}
							path={`/${route.link}${RouteAsComponent[route.label].sub_url}`}
							exact
						/>
					);
				}
			}
		});
		elements.push(
			<Route key={'mainpage'} component={MainPageController} path={'/'} exact />
		);
		elements.push(
			<Route
				key={'manualViewer'}
				component={PDFController}
				path={'/manual/:pdf'}
			/>
		);
		elements.push(
			<Route
				key={'404-route-element'}
				component={E404PageNotFound}
				path={'*'}
				exact
			/>
		);
		return elements;
	};
	return (
		<Layout>
			<Switch>{getRoutes()}</Switch>
		</Layout>
	);
};

export default withRouter(Router);

/**
 * ## Clean link
 * 
 * This function should be called every time you want to history.push the
 * user to an inner view. In case the route ends with a slash (/), the function
 * will remove the trailing slash and return the link in a pure form.
 * 
 * For exmaple, if you want to push "/:offer_id" to an "/offers/" page, it would
 * be like "/offers//:offer_id"
 * 
 * If you call this function, it'll remove the extra slash and return "/offers/:offer_id"
 */
export const cleanLink = (link: string): string => {
    const new_link: string = link.replace(/\/\//g, "/");
    return new_link;
};

import React from 'react';
import { ICookieDetails } from '../Interfaces/ICookieDetails.interface';
import HTMLParser from 'html-react-parser';
import { Container, Col, Row } from 'react-bootstrap';
import './Less/CookieDetails.css';

export interface CookieDetailsViewProps {
	cookieDetails: ICookieDetails;
}

const CookieDetailsView: React.FC<CookieDetailsViewProps> = (props) => {
	return (
		<Container>
			<Row>
				<Col xs='12'>{HTMLParser(props.cookieDetails.content)}</Col>
			</Row>
		</Container>
	);
};

export default CookieDetailsView;

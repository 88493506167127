import * as React from 'react';
import './BlueToRedButton.css';
import { Link } from 'react-router-dom';

export interface IFancyButtonProps {
  onClick?: () => any;
  inverse?: boolean;
  isSingle?: boolean;
  link?: string;
}
export const BlueToRedButton: React.FC<IFancyButtonProps> = (
  props
): React.ReactElement => {
  const getButton = (): React.ReactElement => {
    return props.link ? (
      <Link to={props.link}>
        <div
          className={`iline-blue-to-red-button ${
            props.inverse ? 'inverse' : ''
          } ${props.isSingle ? 'single' : ''}`}
          onClick={props.onClick}
        >
          <p>{props.children}</p>
        </div>
      </Link>
    ) : (
      <div
        className={`iline-blue-to-red-button ${
          props.inverse ? 'inverse' : ''
        } ${props.isSingle ? 'single' : ''}`}
        onClick={props.onClick}
      >
        <p>{props.children}</p>
      </div>
    );
  };

  return getButton();
};

export default BlueToRedButton;

import React from 'react';
import { BikeTypesModel } from '../Model/BikeTypes.model';
import { GetLocaleText } from '../../../I18n';

export interface IMainPageAnchorNavProps {
	types: BikeTypesModel[];
}

const BikesAnchorNav: React.FC<IMainPageAnchorNavProps> = (
	props
): React.ReactElement => {
	const bikes_menu = GetLocaleText('bikes_menu');
	const [isVisible, setisVisible] = React.useState<boolean>(
		window.scrollY > 0 ? true : false
	);

	const scrollToHash = (hash: string): void => {
		const element: HTMLElement | null = document.getElementById(hash);
		if (!element) return;
		const yOffset = -60;
		const y =
			element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });
	};

	const setVisibility = (): void => {
		if (window.scrollY > 0) {
			setisVisible(true);
		}
		if (window.scrollY === 0) {
			setisVisible(false);
		}
	};

	React.useEffect(
		() => {
			document.addEventListener('scroll', setVisibility);
			return () => {
				document.removeEventListener('scroll', setVisibility);
			};
		},
		//eslint-disable-next-line
		[]
	);
	React.useEffect(
		() => {
			try {
				scrollToHash(window.location.hash.split('#')[1].toUpperCase());
			} catch (e) {}
		},
		//eslint-disable-next-line
		[props.types]
	);
	const types = props.types.map((type) => {
		return type.name.replace(/\s+/g, '').split('/')[0];
	});
	return (
		<React.Fragment>
			{isVisible && (
				<div className='anchor-nav'>
					<div className='anchor-nav__logo'>{bikes_menu}</div>
					<div className='anchor-nav__hashes'>
						{props.types.map((type, index) => {
							return (
								<div
									key={`types--${index}`}
									className='anchor-nav__hash'
									onClick={() => scrollToHash(types[index])}
								>
									<div className='text'>{type.name}</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
			{null}
		</React.Fragment>
	);
};

export default BikesAnchorNav;

import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
export type IAxiosInstances = { [key: string]: AxiosInstance };

export abstract class axios {
	private static instances: IAxiosInstances = {};
	private static language: string = '';

	/**
	 * ## Set axios language
	 *
	 * This is for changing the language on-the-fly.
	 * Use the **defaultLanguage** for initialisation.
	 */
	public static setLanguage(language: string): void {
		this.language = language;
	}

	/**
	 * ## Init Axios instance to use later
	 *
	 * **baseURL** is the API url that you're going to communicate with. NO SLASH AT THE END!
	 *
	 * **instanceName** defaults to 'basic'. If you have to store multiple axios instances,
	 * this will be used as the 'key' in the instances object which stores the axios instances.
	 *
	 * **defaultLanguage** - If you provide a default language, you can initialise the instance
	 * to send it as an 'Accept-Language' header with every request.
	 *
	 * You could also set the **language** property in the instance if it's a static value.
	 * Note that it is a private property.
	 */
	public static initAxios(
		baseURL: string,
		defaultLanguage: string = 'hu',
		instanceName: string = 'basic'
	): void {
		this.instances[instanceName] = Axios.create({
			baseURL,
		});

		this.setLanguage(defaultLanguage);

		this.instances[
			instanceName
		].interceptors.request.use((config: AxiosRequestConfig) =>
			this.setInstanceHeaders(config)
		);
	}

	private static setInstanceHeaders(
		config: AxiosRequestConfig
	): AxiosRequestConfig {
		config.headers['Accept-Language'] = this.language;
		return config;
	}

	public static getInstance(instanceName: string = 'basic'): AxiosInstance {
		return this.instances[instanceName];
	}
}
